import type { ISelectableListItem } from "@application/components/_common/QuickSearch/components/SelectableListItems/interface/ISelectableListItems.interface";
import type { ISelectableListItemsDefinition } from "@application/components/_common/QuickSearch/components/SelectableListItems/interface/ISelectableListItemsDefinition.interface";

const TransformEntityListToItems = (
	props: ISelectableListItemsDefinition,
): ISelectableListItem[] => {
	const { collection, keyPropertyName, labelPropertyName } = props;
	const items: ISelectableListItem[] = [];
	collection.forEach((_item: any) => {
		items.push({
			key: _item[keyPropertyName],
			label: _item[labelPropertyName],
			color: _item["color"],
		});
	});
	return items;
};

const SelectableListItemsHelper = {
	TransformEntityListToItems,
};

export default SelectableListItemsHelper;
