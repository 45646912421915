import { t } from "i18next";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FacultyController from "@application/Controllers/FacultyController";
import { ESelect } from "@application/enum/SelectList.enum";
import GetTranslationsForFaculties from "@application/helpers/translations/faculties/GetTranslationsForFaculties";
import type { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import K4CardList from "@infrastructure/components/interface/card/K4CardList";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import K4ListGrid, {
	GridDisplayFormatEnum,
} from "@infrastructure/components/interface/list/K4ListGrid";
import SearchList from "@infrastructure/components/interface/search/SearchList";
import { extendedRowsPerPage } from "@infrastructure/constants/default-pagination.constant";
import SearchListHelper from "@infrastructure/helpers/searchList.helper";
import usePageList from "@infrastructure/hooks/pageList.hook";
import useSearchList from "@infrastructure/hooks/searchList.hook";
import { ESort } from "@infrastructure/model/@types/sort/sort.d";
import {
	type AdvancedSearchMailTemplateFetch,
	type AdvancedSearchMailTemplateSubstitute,
	DebugConfig,
	EBulkActionEntity,
	EnumIcon,
	EnumSearchFilterScope,
	ErrorAPI,
	ESieveOperator,
	ExternalRouting,
	facultyAuthorizedForeignKeys,
	facultyDisabledKeys,
	getAdvancedSearchMailTemplateSubstitute,
	getListDtoMailTemplateGetToFields,
	getQueryBuilderDefinition,
	type IK4Card,
	QueryBuilderHelper,
	queryFilters,
	Services,
	statusInvitationParticipantObject,
	substituteQueryString,
	Title,
	useBulkActionStore,
	useContextModule,
	useCustomFieldContext,
	usePaginationOld,
} from "@key4-front-library/core";
import ButtonBulkAction from "@key4-front-library/core/Bo/Components/Button/ButtonBulkAction";
import DebugQueryFilter from "@key4-front-library/core/Bo/Components/DebugQueryFilter";
import { dtoDefinitionListToTAdvancedSearchFieldDefinitionList } from "@mappers";
import {
	Grid,
	MenuItem,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import type { GridCellParams, GridSortModel } from "@mui/x-data-grid-pro";

const localStoragePrefixKey = "search";

export const Faculties = () => {
	const DEFAULT_PAGE = 0;
	const debugQueryFilter = DebugConfig.QueryFilter;
	const bulkActionStore = useBulkActionStore();
	const {
		page,
		pageSize,
		totalRowCount,
		handleChangePage,
		handleChangePageSize,
		setTotalRowCount,
	} = usePaginationOld();
	// const { t } = useTranslation(['faculties', 'common', 'queryBuilder', 'searchList']);
	const { enqueueSnackbar } = useSnackbar();
	const [
		advancedSearchMailTemplateSubstitutes,
		setAdvancedSearchMailTemplateSubstitutes,
	] = useState<Array<AdvancedSearchMailTemplateSubstitute>>();
	const translations = {
		...GetTranslationsForFaculties(),
		searchList: {
			...SearchListHelper.getTranslationsSearchList(),
		},
	};

	const theme = useTheme();
	const navigate = useNavigate();
	const { client, event } = useContextModule();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<ErrorAPI | undefined>();
	const [faculties, setFaculties] = useState<Array<IK4Card>>([]);
	const [rowsCount, setRowsCount] = useState<number>(0);
	const { customForms } = useCustomFieldContext();

	const menuItems = [
		{
			label: t("old.form.buttons.details"),
			icon: "magnifying-glass",
			handleClick: (data: IK4Card) => {
				handleFacultyCardClick(data);
			},
		},
	];

	enum templateModeEnum {
		GRID = "grid",
		CARD = "card",
	}

	const [templateMode, setTemplateMode] = useState<templateModeEnum>(
		templateModeEnum.GRID,
	);
	const templateModes = [
		{
			value: templateModeEnum.GRID,
			icon: "table-list",
			prefix: "far",
		},
		{
			value: templateModeEnum.CARD,
			icon: "grid",
			prefix: "fal",
		},
	];

	const {
		search,
		fields,
		setFields,
		queryBuilderString,
		handleSearchChange,
		handleQueryBuilderChange,
		openSaveNewQueryModale,
		setOpenSaveNewQueryModale,
		newQueryFilterId,
		setNewQueryFilterId,
		handleOpenSaveNewQueryModaleClick,
		searchFilterQueriesList,
		setSearchFilterQueriesList,
	} = useSearchList();

	const {
		getSearchFilterQueriesList,
		handleCreateQuerySearchFilterClick,
		handleUpdateQueryFilterClick,
		handleDeleteQueryFilterClick,
	} = usePageList(
		setNewQueryFilterId,
		setSearchFilterQueriesList,
		enqueueSnackbar,
		client,
		event,
		setOpenSaveNewQueryModale,
		EnumSearchFilterScope.FACULTY,
	);

	const sortOptions = [
		{
			field: "participant.firstname",
			sort: ESort.ASC,
			label: `${translations.sort.firstname}: A-Z`,
			reference: "firstname_asc",
		},
		{
			field: "participant.firstname",
			sort: ESort.DESC,
			label: `${translations.sort.firstname}: Z-A`,
			reference: "firstname_desc",
		},
		{
			field: "participant.lastname",
			sort: ESort.ASC,
			label: `${translations.sort.lastname}: A-Z`,
			reference: "lastname_asc",
		},
		{
			field: "participant.lastname",
			sort: ESort.DESC,
			label: `${translations.sort.lastname}: Z-A`,
			reference: "lastname_desc",
		},
	];
	const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
	const [gridSortModelList, setGridSortModelList] = useState<GridSortModel>([]);

	const initComponent = useCallback(async () => {
		let sorts: Array<string> = [];
		gridSortModelList.forEach((criteria) => {
			sorts.push(`${criteria.sort === "asc" ? "" : "-"}${criteria.field}`);

			return true;
		});

		let typeOrder = "";
		if (selectedSort.sort === ESort.DESC) typeOrder = "-";

		if (templateMode === templateModeEnum.CARD) {
			sorts = [typeOrder + selectedSort.field];
		}

		const facultiesData = await FacultyController.getFaculties(
			client.id,
			event.id,
			search,
			sorts ?? [],
			{ page, pageSize },
			substituteQueryString(
				queryBuilderString,
				advancedSearchMailTemplateSubstitutes,
			),
		);
		if (facultiesData instanceof ErrorAPI) {
			setError(facultiesData);
			return;
		}
		if (facultiesData.pagination.totalCount) {
			setTotalRowCount(facultiesData.pagination.totalCount);
		}
		setRowsCount(facultiesData.pagination.totalCount ?? 0);
		setFaculties(facultiesData.data);
		setIsLoading(false);
	}, [
		gridSortModelList,
		selectedSort.sort,
		selectedSort.field,
		templateMode,
		templateModeEnum.CARD,
		event,
		search,
		page,
		pageSize,
		queryBuilderString,
	]);

	useEffect(() => {
		initComponent();
	}, [event, initComponent]);

	useEffect(() => {
		const fetchData = async (client: any, event: any) => {
			await getSearchFilterQueriesList(client.id, event.id);
		};
		fetchData(client, event);
	}, [event, getSearchFilterQueriesList]);

	useEffect(() => {
		const fetchData = async (): Promise<AdvancedSearchMailTemplateFetch> => {
			const definition = await Services.ProgrammeService.getDefinition();
			const { data: mails } =
				await Services.Operations.MailTemplatesService.getListPagined(
					client.id,
					event.id,
					queryFilters(`document.target${ESieveOperator.EQUALS}faculty`),
				);
			return {
				queryBuilder: getQueryBuilderDefinition({
					definition:
						dtoDefinitionListToTAdvancedSearchFieldDefinitionList(definition),
					fieldName: "Faculty",
					disabledKeys: facultyDisabledKeys,
					authorizedForeignKeys: facultyAuthorizedForeignKeys,
					selectList: [
						{
							resource: ESelect.INVITATION_STATUS,
							listValues: Object.values(statusInvitationParticipantObject).map(
								(e) => ({
									value: e.value,
									title: t(e.label).toString(),
								}),
							),
						},
					],
				}),
				mails,
			};
		};
		fetchData()
			.then((response) => {
				setAdvancedSearchMailTemplateSubstitutes(
					getAdvancedSearchMailTemplateSubstitute(response.mails),
				);
				setFields({
					...QueryBuilderHelper.getTranslatedFields({
						...getListDtoMailTemplateGetToFields(response.mails, t),
						...response.queryBuilder,
					}),
				});
			})
			.catch((e) => {
				console.error("fetchData getQueryBuilderDefinition ERROR", e);
			});
	}, [event, customForms]);

	useEffect(() => {
		handleChangePage(DEFAULT_PAGE);
	}, [search]);

	const handleChangeSortCriteria = (gridSortModelList: GridSortModel) => {
		setGridSortModelList(gridSortModelList);
	};

	const handleFacultyClick = (clickedCell: GridCellParams) => {
		if (clickedCell.field !== "key") {
			navigate(`./${clickedCell.row.id}`);
		}
	};

	const handleFacultyCardClick = (faculty: IK4Card) => {
		navigate(`./${faculty.id}`);
	};

	const handleSortClick = (e: React.ChangeEvent<HTMLInputElement>) => {
		const labelTarget = e.target.value;
		const selectedSortObject =
			sortOptions.find((item) => item.label.includes(labelTarget)) ??
			selectedSort;
		setSelectedSort(selectedSortObject);
	};

	const handleTemplateModeChange = (
		_event: React.MouseEvent<HTMLElement>,
		newTemplateMode: templateModeEnum,
	) => {
		if (newTemplateMode != null) {
			setTemplateMode(newTemplateMode);
		}
	};

	if (error) {
		throw error;
	}

	return (
		<>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Title
					title={translations.title}
					buttons={[
						{
							label: t("old.common.faculties.matching.button"),
							icon: { iconName: "arrows-repeat", prefix: "fas" },
							color: "primary",
							handleClick: () => {
								navigate("./matching");
							},
						},
						{
							label: t("old.programme.faculties.time-slots.title"),
							icon: { iconName: EnumIcon.MAGNIFYING_GLASS },
							color: "primary",
							handleClick: () => {
								navigate("./calendar");
							},
						},
					]}
				/>
			</Stack>
			<AppBox>
				<Stack>
					<Stack>
						{debugQueryFilter && (
							<DebugQueryFilter
								queryBuilderString={substituteQueryString(
									queryBuilderString,
									advancedSearchMailTemplateSubstitutes,
								)}
								onChangeQueryBuilder={handleQueryBuilderChange}
							/>
						)}
						<Stack>
							{fields && searchFilterQueriesList && (
								<SearchList
									onSearchChange={handleSearchChange}
									onQueryBuilderChange={handleQueryBuilderChange}
									fields={fields}
									searchFilterQueriesList={searchFilterQueriesList}
									openSaveNewQueryModale={openSaveNewQueryModale}
									onOpenSaveNewQueryModaleClick={
										handleOpenSaveNewQueryModaleClick
									}
									newQueryFilterId={newQueryFilterId ?? null}
									onCreateQuerySearchFilterClick={
										handleCreateQuerySearchFilterClick
									}
									onUpdateQueryFilterClick={handleUpdateQueryFilterClick}
									onDeleteQueryFilterClick={handleDeleteQueryFilterClick}
									localStoragePrefixKey={localStoragePrefixKey}
								/>
							)}
						</Stack>
						<Grid
							my={1}
							container
							item
							xs={12}
							justifyContent={"space-between"}
							direction={
								useMediaQuery(theme.breakpoints.down("sm")) ? "column" : "row"
							}
							gap={1}
						>
							<ButtonBulkAction
								isDisabled={rowsCount === 0}
								onClick={() => {
									localStorage.removeItem("bulkActionStore");
									bulkActionStore.init(EBulkActionEntity.FACULTY, {
										search,
										queryBuilderString: substituteQueryString(
											queryBuilderString,
											advancedSearchMailTemplateSubstitutes,
										),
									});
									bulkActionStore.setActiveStep(1);
									navigate(`../${EBulkActionEntity.FACULTY}/bulkaction/1`);
								}}
							/>
							<Stack direction={"row"} spacing={1} justifyContent={"end"}>
								{templateMode === templateModeEnum.CARD && (
									<TextField
										id="select-sort"
										select
										label={t("old.common.sort")}
										value={selectedSort.label || ""}
										onChange={handleSortClick}
										fullWidth
										size="small"
										InputProps={{
											style: { height: "100%" },
										}}
									>
										{sortOptions.map((item) => (
											<MenuItem key={item.reference} value={item.label}>
												{item.label}
											</MenuItem>
										))}
									</TextField>
								)}
								<ToggleButtonGroup
									value={templateMode}
									exclusive
									onChange={handleTemplateModeChange}
									aria-label="Change template mode"
								>
									{templateModes.map((item) => {
										return (
											<ToggleButton
												key={uniqueId("toggle-button")}
												sx={{
													backgroundColor:
														templateMode === item.value
															? `${theme.palette.primary.main} !important`
															: "",
													paddingTop: 0,
													paddingBottom: 0,
													minHeight: "40px",
												}}
												value={item.value}
											>
												<Icon
													color={
														templateMode === item.value
															? `${theme.palette.common.white} !important`
															: ""
													}
													prefix={item.prefix as IconPrefix}
													iconName={item.icon as IconName}
												/>
											</ToggleButton>
										);
									})}
								</ToggleButtonGroup>
							</Stack>
						</Grid>
					</Stack>
					<Grid item xs={12}>
						{templateMode === templateModeEnum.CARD && (
							<K4CardList
								isLoading={isLoading}
								page={page}
								pageSize={pageSize}
								totalRowCount={totalRowCount}
								handleChangePage={handleChangePage}
								handleChangePageSize={handleChangePageSize}
								rowsPerPageOptions={extendedRowsPerPage}
								menuItems={menuItems}
								data={faculties}
								handleCardSingleClick={handleFacultyCardClick}
							/>
						)}
						{templateMode === templateModeEnum.GRID && (
							<K4ListGrid
								page={page}
								pageSize={pageSize}
								totalRowCount={totalRowCount}
								handleChangePage={handleChangePage}
								handleChangePageSize={handleChangePageSize}
								handleSingleClick={handleFacultyClick}
								isDoubleClick={false}
								data={faculties}
								handleChangeSortCriteria={handleChangeSortCriteria}
								schemeAndColumnTitles={{
									id: {
										headerName: t("old.programme.faculties.sort.id"),
									},
									key: {
										headerName: t("old.programme.faculties.sort.key"),
										minWidth: 25,
										maxWidth: 80,
										displayFormat: {
											value: GridDisplayFormatEnum.LINK,
											to: `${ExternalRouting.Programme}/${client.key}/${event.key}/faculties`,
										},
									},
									firstname: {
										headerName: t(
											"old.programme.faculties.sort.firstname",
										).toString(),
										minWidth: 150,
									},
									lastname: {
										headerName: t(
											"old.programme.faculties.sort.lastname",
										).toString(),
										minWidth: 150,
									},
									email: {
										headerName: t("old.programme.faculties.sort.email"),
										minWidth: 150,
									},
								}}
							/>
						)}
					</Grid>
				</Stack>
			</AppBox>
		</>
	);
};
