import type { TFunction } from "i18next";

import ConfigurationsApp from "@application/Configurations";
import ConfigurationApplication from "@application/Configurations/configuration";
import {
	EIssues,
	issuesObject,
} from "@application/Configurations/issues.configuration";
import { EPublishSession } from "@application/Configurations/publish.configuration";
import HelpersApp from "@application/helpers";
import PublishHelper from "@application/helpers/publish.service";
import {
	ESessionTagField,
	type ISessionContainerPresentations,
	type ISessionSecondaryTagsBulkAction,
	type ISessionTagsBulkAction,
	type ISessionView,
	type TSessionUseFormModal,
	type TTagTypeFilterForm,
} from "@domain/interfaces/session.interface";
import type { ISessionsGrid } from "@domain/interfaces/sessions.interface";
import type {
	ISessionCreated,
	ISessionModelTag,
	ISessionModelTagType,
	TSessionPost,
	TSessionPut,
} from "@domain/model/session.model";
import ApiHelper from "@infrastructure/helpers/api.helper";
import BulkActionHelper, {
	type TPatchFilter,
} from "@infrastructure/helpers/bulkAction.helper";
import FormHelper from "@infrastructure/helpers/form.helper";
import type { PaginedResourceType } from "@infrastructure/model/@types/api/api-pagined-response.type";
import {
	EnumApiPatchOperation,
	type TJSONPatch,
} from "@infrastructure/model/interfaces/api.interface";
import type { IPagination } from "@infrastructure/model/interfaces/api/api-pagination.interface";
import DateService from "@infrastructure/services/dates/date.service";
import {
	ErrorAPI,
	FacultyHelper,
	type IK4Card,
	queryFilters,
	queryIncludeCustomFields,
	queryStringPagination,
	queryStringSorts,
	Services,
	sieveStringFiltersPageList,
	statusSessionObject,
	type TFacultyUseForm,
	type TFilter,
	type TUseFormBulkAction,
	useContextModule,
} from "@key4-front-library/core";
import type {
	DtoFaculty,
	DtoSession,
	DtoSessionWrite,
	DtoTagType,
} from "@key4-front-library/core/Dto";
import type {
	EnumBulkActionNumberingType,
	EnumSessionStatus,
} from "@key4-front-library/core/Enums";
import type {
	TypeApiResponsePaginedPagination,
	TypeCustomFieldValues,
	TypeUseFormListForms,
} from "@key4-front-library/core/Types";
import type { GridRowsProp, GridValidRowModel } from "@mui/x-data-grid-pro";

const useEntity = () => {
	const { client, event } = useContextModule();

	const create = async (
		useFormData: TypeUseFormListForms,
	): Promise<boolean> => {
		try {
			const session: DtoSessionWrite =
				ConfigurationsApp.SessionConfiguration.defaultModel;
			HelpersApp.SessionHelper.mapUseFormToDtoSessionWrite(
				useFormData,
				session,
			);

			await Services.Events.Programme.SessionsService.post(
				client.id,
				event.id,
				session,
			);
			return true;
		} catch {
			return false;
		}
	};

	const read = async (id: string): Promise<DtoSession> => {
		return await Services.Events.Programme.SessionsService.get(
			client.id,
			event.id,
			id,
			queryIncludeCustomFields(true),
		);
	};

	const update = async (
		id: string,
		useFormData: TypeUseFormListForms,
	): Promise<boolean> => {
		try {
			const session: DtoSessionWrite =
				ConfigurationsApp.SessionConfiguration.defaultModel;
			HelpersApp.SessionHelper.mapUseFormToDtoSessionWrite(
				useFormData,
				session,
			);

			return await Services.Events.Programme.SessionsService.put(
				client.id,
				event.id,
				id,
				session,
			);
		} catch {
			return false;
		}
	};

	const readListTagsType = async (): Promise<Array<DtoTagType>> => {
		return await Services.Events.Programme.SessionsService.getListTags(
			client.id,
			event.id,
			queryStringSorts(["order"]),
		);
	};

	return { create, read, readListTagsType, update };
};

/**
 * Save Order of Presentations in a Session
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param sessionId Current session ID
 * @returns Success boolean
 */
const updateSessionPresentationsOrder = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	ids: Array<string>,
) =>
	await Services.Events.Programme.SessionsService.putPresentationsReOrder(
		clientId,
		eventId,
		sessionId,
		{ ids },
	);

/**
 * @deprecated
 */
const getSessionsDatagrid = async (
	clientId: string,
	eventId: string,
	search: string,
	sorts: Array<string>,
	pagination: TypeApiResponsePaginedPagination,
	filter: string,
): Promise<PaginedResourceType<Array<ISessionsGrid>>> => {
	const filterString = sieveStringFiltersPageList(
		ConfigurationApplication.Session.sieveKeys,
		search,
		filter,
	);

	try {
		const sessions =
			await Services.Events.Programme.SessionsService.getListPagined(
				clientId,
				eventId,
				[
					...queryStringPagination(pagination),
					...queryFilters(filterString),
					...queryStringSorts(sorts),
				],
			);
		const { data } = sessions;
		const formatedSessions: Array<ISessionsGrid> = [];

		for (const session of data) {
			const displayEntireDate =
				session.startDate &&
				!session.startDate.startsWith("0001-01-01") &&
				session.endDate &&
				HelpersApp.SessionHelper.getSessionEntireDate(
					session.startDate,
					session.endDate,
					session.isFullDay,
				);
			formatedSessions.push({
				id: session.id,
				dates: session.startDate && displayEntireDate ? displayEntireDate : "",
				hasAnomalies: session.hasAnomalies,
				hasClashes: session.hasClashes,
				status: statusSessionObject[session.status],
				title: session.title ?? undefined,
				code: session.code ?? null,
				room: session.room?.name ?? undefined,
				primaryTag: HelpersApp.SessionHelper.PopulateTags(session),
				key: session.key,
			});
		}
		return Promise.resolve({
			pagination: sessions.pagination,
			data: formatedSessions,
		});
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};
/**
 * @deprecated
 */
const getSessionsListBulkActionStepSelect = async (
	clientId: string,
	eventId: string,
	search: string,
	sorts: Array<string>,
	filter: string,
	t: TFunction,
): Promise<Array<GridValidRowModel> | number | ErrorAPI> => {
	try {
		const filterString = sieveStringFiltersPageList(
			ConfigurationApplication.Session.sieveKeys,
			search,
			filter,
		);

		const sessions =
			await Services.Events.Programme.SessionsService.getListPagined(
				clientId,
				eventId,
				[
					...queryStringPagination({
						page: 0,
						pageSize: 100,
					}),
					...queryFilters(filterString),
					...queryStringSorts(sorts),
				],
			);

		if (
			!sessions.pagination.totalCount ||
			sessions.pagination.totalCount > 100
		) {
			if (!sessions.pagination.totalCount)
				return Promise.reject(new ErrorAPI(400, "No pagination set"));
			return Promise.resolve(sessions.pagination.totalCount ?? 0);
		}
		return Promise.resolve(
			sessions.data.map((session: any) => {
				const status = statusSessionObject[session.status as EnumSessionStatus];
				const displayEntireDate =
					session.startDate &&
					session.endDate &&
					HelpersApp.SessionHelper.getSessionEntireDate(
						session.startDate,
						session.endDate,
						session.isFullDay,
					);
				const primaryTag = {
					chip: session.primaryTag
						? {
								backgroundColor: session.primaryTag.backgroundColor,
								borderColor: session.primaryTag.borderColor,
								fontColor: session.primaryTag.fontColor,
								label: session.primaryTag.label,
							}
						: undefined,
				};

				return {
					id: session.id,
					key: session.key,
					code: session.code,
					primaryTag,
					title: session.title,
					date: displayEntireDate,
					hasAnomalies: {
						isDisplayed: session.hasAnomalies,
						icon: issuesObject[EIssues.ANOMALIES].icon,
					},
					hasClashes: {
						isDisplayed: session.hasClashes,
						icon: issuesObject[EIssues.CLASHES].icon,
					},
					status: {
						iconColor: status.bgColor,
						label: t(status.label),
					},
				};
			}),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};
/**
 * @deprecated
 */
const patchSessionEditBulkAction = async (
	clientId: string,
	eventId: string,
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
	useFormValues: TUseFormBulkAction,
): Promise<boolean | ErrorAPI> => {
	return BulkActionHelper.fetchActionMiddleware(
		filters,
		isNoStepSelect,
		rows,
		selectedItems,
		async (patchFilters: TPatchFilter): Promise<boolean> => {
			// TODO D1: Remove getTagsPatch when tags has better implementation
			const tagPatch = BulkActionHelper.getTagsPatch(useFormValues);

			let patch1: Array<TJSONPatch> = [];
			let patch2: Array<TJSONPatch> = [];

			if (
				tagPatch.operationsAdd[0].value.length > 0 &&
				tagPatch.operationsRemove[0].value.length > 0
			) {
				patch1 = tagPatch.operationsRemove;
				patch2 = tagPatch.operationsAdd;
			} else if (tagPatch.operationsAdd[0].value.length > 0) {
				patch1 = tagPatch.operationsAdd;
			} else if (tagPatch.operationsRemove[0].value.length > 0) {
				patch1 = tagPatch.operationsRemove;
			}

			const operations: Array<TJSONPatch> =
				BulkActionHelper.mapBulkActionFormForPatch(useFormValues);
			await Services.Events.Programme.SessionsService.patchBulkAction(
				clientId,
				eventId,
				{
					filters: patchFilters.filterString,
					excludedIds: patchFilters.excludedIds,
					operations: operations.concat(patch1),
				},
			);

			if (patch2.length > 0) {
				await Services.Events.Programme.SessionsService.patchBulkAction(
					clientId,
					eventId,
					{
						filters: patchFilters.filterString,
						excludedIds: patchFilters.excludedIds,
						operations: patch2,
					},
				);
			}

			return Promise.resolve(true);
		},
		ConfigurationApplication.Session.sieveKeys,
	);
};
/**
 * @deprecated
 */
const patchSessionTransitionBulkAction = async (
	clientId: string,
	eventId: string,
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
	value: string,
): Promise<boolean | ErrorAPI> => {
	return BulkActionHelper.fetchActionMiddleware(
		filters,
		isNoStepSelect,
		rows,
		selectedItems,
		async (patchFilters: TPatchFilter): Promise<boolean> => {
			await Services.Events.Programme.SessionsService.patchBulkAction(
				clientId,
				eventId,
				{
					filters: patchFilters.filterString,
					excludedIds: patchFilters.excludedIds,
					operations: [
						{
							op: EnumApiPatchOperation.REPLACE,
							path: "status",
							value,
						},
					],
				},
			);
			return Promise.resolve(true);
		},
		ConfigurationApplication.Session.sieveKeys,
	);
};
/**
 * @deprecated
 */
const patchSessionPublishBulkAction = async (
	clientId: string,
	eventId: string,
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
	value: string | null,
): Promise<boolean | ErrorAPI> => {
	return BulkActionHelper.fetchActionMiddleware(
		filters,
		isNoStepSelect,
		rows,
		selectedItems,
		async (patchFilters: TPatchFilter): Promise<boolean> => {
			await Services.Events.Programme.SessionsService.patchBulkAction(
				clientId,
				eventId,
				{
					filters: patchFilters.filterString,
					excludedIds: patchFilters.excludedIds,
					operations: [
						{
							op: EnumApiPatchOperation.REPLACE,
							path: "publicationDate",
							value,
						},
					],
				},
			);
			return Promise.resolve(true);
		},
		ConfigurationApplication.Session.sieveKeys,
	);
};

/**
 * @deprecated
 */
const deleteSessionBulkAction = async (
	clientId: string,
	eventId: string,
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
): Promise<boolean | ErrorAPI> => {
	return BulkActionHelper.fetchActionMiddleware(
		filters,
		isNoStepSelect,
		rows,
		selectedItems,
		async (patchFilters: TPatchFilter): Promise<boolean> => {
			await Services.Events.Programme.SessionsService.deleteBulkAction(
				clientId,
				eventId,
				{
					filters: patchFilters.filterString,
					excludedIds: patchFilters.excludedIds,
				},
			);
			return Promise.resolve(true);
		},
		ConfigurationApplication.Session.sieveKeys,
	);
};
/**
 * @deprecated
 */
const putNumberingSessionBulkAction = async (
	clientId: string,
	eventId: string,
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
	type: EnumBulkActionNumberingType,
): Promise<boolean | ErrorAPI> => {
	return BulkActionHelper.fetchActionMiddleware(
		filters,
		isNoStepSelect,
		rows,
		selectedItems,
		async (patchFilters: TPatchFilter): Promise<boolean> => {
			await Services.Events.Programme.SessionsService.putBulkActionNumbering(
				clientId,
				eventId,
				{
					filters: patchFilters.filterString,
					excludedIds: patchFilters.excludedIds,
					type,
				},
			);
			return Promise.resolve(true);
		},
		ConfigurationApplication.Session.sieveKeys,
	);
};
/**
 * @deprecated
 */
const getSessionDetails = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	culture: string,
): Promise<ISessionView | ErrorAPI> => {
	const session = await Services.Events.Programme.SessionsService.get(
		clientId,
		eventId,
		sessionId,
		queryIncludeCustomFields(true),
	);
	const TransformSessionScheduleDataForReading = (_session: any) => {
		const startDate =
			_session.startDate &&
			DateService.Convert.GetDateFromIsoString2(_session.startDate);

		const endDate =
			_session.endDate &&
			DateService.Convert.GetDateFromIsoString2(_session.endDate);

		const startTime =
			_session.startHour &&
			DateService.GetDateFromIsoString(_session.startHour);

		const endTime =
			_session.endHour && DateService.GetDateFromIsoString(_session.endHour);

		const formatedStartDate =
			startDate &&
			DateService.Format.ShortDate({
				date: startDate,
				culture,
			});

		const formatedEndDate =
			endDate &&
			DateService.Format.ShortDate({
				date: endDate,
				culture,
			});

		const formatedStartTime =
			startTime &&
			DateService.Format.TimeSimple({
				date: startTime,
				culture,
			});

		const formatedEndTime =
			endTime &&
			DateService.Format.TimeSimple({
				date: endTime,
				culture,
			});

		const dateInterval =
			startDate &&
			endDate &&
			DateService.Differences.DateDiffDays({
				startDate,
				endDate,
			})?.toString();

		return {
			startDate: formatedStartDate,
			endDate: formatedEndDate,
			startTime: formatedStartTime,
			endTime: formatedEndTime,
			dateInterval,
		};
	};

	const TransformSessionTags = (_session: any) => {
		const formatedTags = [];
		for (const element of _session.tagTypes) {
			const tags = [];
			for (const tag of element.tags) {
				if (tag.label) {
					tags.push({
						id: tag.id,
						tagTypeId: tag.tagTypeId,
						label: tag.label,
						backgroundColor: tag.backgroundColor ?? "",
						fontColor: tag.fontColor ?? "",
						borderColor: tag.borderColor ?? "",
					});
				}
			}
			if (element.label) {
				formatedTags.push({
					id: element.id,
					label: element.label,
					tags,
				});
			}
		}
		return formatedTags;
	};

	const TransformSessionPublish = (_session: any) => {
		let activePublish = PublishHelper.getActivePublish(EPublishSession.NULL);
		let shortDateTime;
		if (_session.publicationDate) {
			const publicationDateObject =
				_session.publicationDate &&
				DateService.GetDateFromIsoString(_session.publicationDate);
			shortDateTime =
				publicationDateObject &&
				DateService.Format.ShortDateTimeInLocalTimeZone({
					date: publicationDateObject,
				});
			const isPublicationDateBeforeNow =
				DateService.Differences.isIsoStringBeforeNow(_session.publicationDate);
			if (isPublicationDateBeforeNow) {
				activePublish = PublishHelper.getActivePublish(
					EPublishSession.PUBLISHED_SINCE,
				);
			} else {
				activePublish = PublishHelper.getActivePublish(
					EPublishSession.PUBLISH_ON,
				);
			}
		}
		return {
			activePublish,
			shortDateTime,
		};
	};

	try {
		return Promise.resolve({
			sessionDisplay: HelpersApp.SessionHelper.getSessionDisplay(session),
			sessionUseForm:
				HelpersApp.SessionHelper.getSessionUseFormFromModel(session),
			schedule: TransformSessionScheduleDataForReading(session),
			tags: TransformSessionTags(session),
			publish: TransformSessionPublish(session),
		});
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

type GetPresentationsProps = {
	clientId: string;
	sessionId: string;
	eventId: string;
	pagination?: IPagination;
	sorts: Array<string>;
};
/**
 * @deprecated
 */
const getPresentations = async (
	props: GetPresentationsProps,
): Promise<Array<ISessionContainerPresentations> | ErrorAPI> => {
	const { clientId, eventId, sessionId, pagination, sorts } = props;
	const presentations =
		await Services.Events.Programme.SessionsService.getListPaginedPresentations(
			clientId,
			eventId,
			sessionId,
			[...queryStringPagination(pagination), ...queryStringSorts(sorts)],
		);
	try {
		const getSpeakersForEachPresentation = async (
			_presentations: Array<any>,
		) => {
			const presentations = JSON.parse(JSON.stringify(_presentations));
			if (!presentations) return [];
			for (const presentation of presentations) {
				const speakers =
					await Services.Events.Programme.PresentationsService.getListPaginedSpeaker(
						clientId,
						eventId,
						sessionId,
						presentation.id,
						[...queryStringSorts(["order"])],
					);
				presentation.speakers = speakers.data;
			}
			return presentations;
		};
		return Promise.resolve(getSpeakersForEachPresentation(presentations.data));
	} catch (e: any) {
		return Promise.reject(null);
	}
};

type GetChairsProps = {
	clientId: string;
	sessionId: string;
	eventId: string;
	pagination?: IPagination;
	sorts: Array<string>;
};
/**
 * @deprecated
 */
const getChairs = async (
	props: GetChairsProps,
): Promise<Array<DtoFaculty> | ErrorAPI> => {
	const { clientId, eventId, sessionId, pagination, sorts } = props;
	const chairs =
		await Services.Events.Programme.SessionsService.getListPaginedChair(
			clientId,
			eventId,
			sessionId,
			[...queryStringPagination(pagination), ...queryStringSorts(sorts)],
		);

	try {
		return Promise.resolve(chairs.data);
	} catch (e: any) {
		return Promise.reject(null);
	}
};
/**
 * @deprecated
 */
const getChairSessionDetailsFacultyModal = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	participantId: string,
	doesIncludeCustomFields: boolean,
): Promise<TFacultyUseForm | ErrorAPI> => {
	try {
		const chair = await Services.Events.Programme.SessionsService.getChair(
			clientId,
			eventId,
			sessionId,
			participantId,
			queryIncludeCustomFields(doesIncludeCustomFields),
		);
		return Promise.resolve(FacultyHelper.remapFacultyModelToUseForm(chair));
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};
/**
 * @deprecated
 */
const getListTagsType = async (
	clientId: string,
	eventId: string,
): Promise<Array<TTagTypeFilterForm> | ErrorAPI> => {
	try {
		const listTagsType =
			await Services.Events.Programme.SessionsService.getListTags(
				clientId,
				eventId,
				queryStringSorts(["order"]),
			);

		const tagsListTypeFilterForm: Array<TTagTypeFilterForm> = listTagsType
			.filter((tag) => tag.tags && tag.tags.length > 0)
			.map((tag) => {
				return {
					id: tag.id,
					label: tag.label ?? "",
					tags: tag.tags
						? tag.tags.map((tag2) => {
								return {
									key: tag2.id,
									label: tag2.label ?? "",
									fontColor:
										tag2.fontColor ?? FormHelper.DEFAULT_CHIP_FONT_COLOR,
									backgroundColor:
										tag2.backgroundColor ??
										FormHelper.DEFAULT_CHIP_BACKGROUND_COLOR,
									borderColor:
										tag2.borderColor ?? FormHelper.DEFAULT_CHIP_BORDER_COLOR,
								};
							})
						: [],
				};
			});

		return Promise.resolve(tagsListTypeFilterForm ?? []);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};
/**
 * @deprecated
 */
const getTagsTypeBulkAction = async (
	clientId: string,
	eventId: string,
): Promise<ISessionTagsBulkAction | ErrorAPI> => {
	return ApiHelper.tryCatch<ISessionTagsBulkAction>(
		async (): Promise<ISessionTagsBulkAction> => {
			let secondaryTags: Array<ISessionSecondaryTagsBulkAction> = [];

			const tags: Array<any> =
				await Services.Events.Programme.SessionsService.getListTags(
					clientId,
					eventId,
					queryStringSorts(["order"]),
				);

			if (tags.length === 0) {
				return {
					primaryTag: null,
					secondaryTags,
				};
			}

			// don't count tags[0] because it's the primary tag
			if (tags.length > 1) {
				secondaryTags = tags
					.filter((_, index) => index !== 0)
					.map((tag: ISessionModelTagType) => {
						if (!tag.max || tag.max > 1) {
							return {
								kind: ESessionTagField.MULTISELECT,
								id: tag.id,
								ids: tag.tags.map((item) => item.id),
								autocompleteMultiChipColor: {
									label: tag.label ?? "",
									isSelectAll: !tag.max,
									maxTags: tag.max ?? undefined,
									items: tag.tags.map((tag2: ISessionModelTag) => {
										return {
											key: tag2.id,
											label: tag2.label ?? "",
											fontColor: tag2.fontColor ?? undefined,
											backgroundColor: tag2.backgroundColor ?? undefined,
											borderColor: tag2.borderColor ?? undefined,
										};
									}),
								},
							};
						}
						return {
							kind: ESessionTagField.SELECT,
							id: tag.id,
							ids: tag.tags.map((item) => item.id),
							selectChipColor: {
								label: tag.label ?? "",
								items: tag.tags.map((tag2: ISessionModelTag) => {
									return {
										key: tag2.id,
										label: tag2.label ?? "",
										fontColor: tag2.fontColor ?? undefined,
										backgroundColor: tag2.backgroundColor ?? undefined,
										borderColor: tag2.borderColor ?? undefined,
									};
								}),
							},
						};
					});
			}

			return Promise.resolve({
				primaryTag: {
					kind: ESessionTagField.SELECT,
					id: tags[0].id,
					ids: tags[0].tags.map((item: any) => item.id),
					selectChipColor: {
						label: tags[0].label ?? "",
						items: tags[0].tags.map((tag: any) => {
							return {
								key: tag.id,
								label: tag.label ?? "",
								fontColor: tag.fontColor ?? undefined,
								backgroundColor: tag.backgroundColor ?? undefined,
								borderColor: tag.borderColor ?? undefined,
							};
						}),
					},
				},
				secondaryTags,
			});
		},
	);
};

type CloneSessionProps = {
	clientId: string;
	eventId: string;
	sessionId: string;
};
/**
 * @deprecated
 */
const cloneSession = async (props: CloneSessionProps) => {
	const { clientId, eventId, sessionId } = props;
	return Services.Events.Programme.SessionsService.postClone(
		clientId,
		eventId,
		sessionId,
	);
};
/**
 * @deprecated
 */
const saveOrderPresentations = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	presentationIds: Array<string>,
) => {
	const updateData = async (ids: Array<string>) =>
		Services.Events.Programme.SessionsService.putPresentationsReOrder(
			clientId,
			eventId,
			sessionId,
			{ ids },
		);
	updateData(presentationIds);
};

type SaveOrderChairsProps = {
	clientId: string;
	eventId: string;
	sessionId: string;
	chairs: Array<IK4Card>;
};
/**
 * @deprecated
 */
const saveOrderChairs = async (props: SaveOrderChairsProps) => {
	const { clientId, eventId, sessionId, chairs } = props;
	const chairsIds = chairs.map((chair) => chair.id);
	const updateData = async (ids: Array<string>) =>
		Services.Events.Programme.SessionsService.putChairsReOrder(
			clientId,
			eventId,
			sessionId,
			{ ids },
		);
	updateData(chairsIds);
};

type UpdateStatusProps = {
	clientId: string;
	eventId: string;
	sessionId: string;
	status: EnumSessionStatus;
};
/**
 * @deprecated
 */
const assignSessionStatus = async (props: UpdateStatusProps) => {
	const { clientId, eventId, sessionId, status } = props;
	return Services.Events.Programme.SessionsService.putStatus(
		clientId,
		eventId,
		sessionId,
		{
			status,
		},
	);
};
/**
 * @deprecated
 */
const createSession = async (
	clientId: string,
	eventId: string,
	session: TSessionUseFormModal,
): Promise<ISessionCreated> => {
	// ! Remap session
	const cfValues: TypeCustomFieldValues | null | undefined =
		HelpersApp.SessionHelper.remapSessionForSaving(session);

	const sessionPost: TSessionPost = {
		roomId: session.roomId,
		code: session.code,
		startDate: session.startDate,
		startHour: session.startHour,
		endDate: session.endDate,
		endHour: session.endHour,
		timeZone: session.timeZone,
		status: session.status,
		publicationDate: session.publicationDate,
		title: session.title,
		maxPax: session.maxPax,
		description: session.description,
		organizedBy: session.organizedBy,
		expectedAudience: session.expectedAudience,
		isEverywhere: session.isEverywhere,
		isWholeProgramme: session.isWholeProgramme,
		isFullDay: session.isFullDay,
		primaryTagId: session.primaryTagId,
		duration: session.duration,
		tagIds: session.tagIds,
		isPrivate: session.isPrivate,
		room: session.room,
		customFieldValues: cfValues,
		key: session.key,
	};

	try {
		return Promise.resolve(
			await Services.Events.Programme.SessionsService.post(
				clientId,
				eventId,
				sessionPost,
			),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};
/**
 * @deprecated
 */
const updateSession = async (
	clientId: string,
	eventId: string,
	session: TSessionUseFormModal,
): Promise<boolean> => {
	// ! Remap session
	const cfValues: TypeCustomFieldValues | null | undefined =
		HelpersApp.SessionHelper.remapSessionForSaving(session);

	const sessionPut: TSessionPut = {
		roomId: session.roomId,
		code: session.code,
		startDate: session.startDate,
		startHour: session.startHour,
		endDate: session.endDate,
		endHour: session.endHour,
		timeZone: session.timeZone,
		status: session.status,
		publicationDate: session.publicationDate,
		title: session.title,
		maxPax: session.maxPax,
		description: session.description,
		organizedBy: session.organizedBy,
		expectedAudience: session.expectedAudience,
		isEverywhere: session.isEverywhere,
		isWholeProgramme: session.isWholeProgramme,
		isFullDay: session.isFullDay,
		primaryTagId: session.primaryTagId,
		duration: session.duration,
		tagIds: session.tagIds,
		isPrivate: session.isPrivate,
		room: session.room,
		customFieldValues: cfValues,
		key: session.key,
	};

	try {
		return Promise.resolve(
			await Services.Events.Programme.SessionsService.put(
				clientId,
				eventId,
				session.id!,
				sessionPut,
			),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

type DeleteChairFromSessionProps = {
	clientId: string;
	eventId: string;
	sessionId: string;
	chairId: string;
};
/**
 * @deprecated
 */
const deleteChairFromSession = async (props: DeleteChairFromSessionProps) => {
	const { clientId, eventId, sessionId, chairId } = props;
	return Services.Events.Programme.SessionsService.deleteChair(
		clientId,
		eventId,
		sessionId,
		chairId,
	);
};

/**
 * @deprecated
 */
const deleteSession = async (
	clientId: string,
	eventId: string,
	sessionId: string,
) => {
	return Services.Events.Programme.SessionsService.deleteEntity(
		clientId,
		eventId,
		sessionId,
	);
};

const SessionController = {
	assignSessionStatus,
	cloneSession,
	createSession,
	deleteChairFromSession,
	deleteSession,
	deleteSessionBulkAction,
	getChairs,
	getChairSessionDetailsFacultyModal,
	getListTagsType,
	getPresentations,
	getSessionDetails,
	getSessionsDatagrid,
	getSessionsListBulkActionStepSelect,
	getTagsTypeBulkAction,
	patchSessionEditBulkAction,
	patchSessionPublishBulkAction,
	patchSessionTransitionBulkAction,
	putNumberingSessionBulkAction,
	saveOrderChairs,
	saveOrderPresentations,
	updateSession,
	useEntity,
	updateSessionPresentationsOrder,
};

export default SessionController;
