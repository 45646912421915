import { t } from "i18next";
import { Fragment, useCallback, useEffect, useState } from "react";

import DashboardFactory from "@application/factory/dashboard/DashboardFactory";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import Card from "@infrastructure/components/interface/card/Card";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import type {
	IIssue,
	IIssueValues,
} from "@infrastructure/model/interfaces/indicators/issue.interface";
import { NoData, Services, useContextModule } from "@key4-front-library/core";
import {
	alpha,
	Avatar,
	Box,
	Grid,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";

const DashboardIssueValuesRender = (props: IIssueValues) => {
	const { key, name, value } = props;
	return (
		<Box key={key}>
			<Typography component="span" variant="body1" sx={{ fontWeight: 500 }}>
				<NumberThousandSeparator value={value} />
			</Typography>
			&nbsp;
			<Typography
				component="span"
				color={"text_special.main"}
				variant="body2"
				sx={{ fontWeight: 300 }}
			>
				{name}
			</Typography>
		</Box>
	);
};

const DashboardAlertsRender = (props: IIssue) => {
	const { name, values, icon, themeColor } = props;

	const countIssues: number =
		values.length > 0
			? values
					.map((issue) => issue.value)
					.reduce((sum: number, current: number) => sum + current)
			: 0;

	if (countIssues < 1) {
		return <Fragment key={name}></Fragment>;
	}

	return (
		<Grid
			container
			key={name}
			display={"flex"}
			flexDirection={"row"}
			alignItems={"center"}
			flexWrap={"nowrap"}
			sx={{
				backgroundColor: alpha(themeColor.main, 0.12),
				my: 1,
				pb: 1,
				borderRadius: 2,
			}}
			spacing={1}
		>
			<Grid item alignItems={"baseline"}>
				<Avatar sx={{ backgroundColor: themeColor.main }}>
					<Box sx={{ mb: "5px" }} alignItems={"baseline"}>
						<Icon prefix="far" iconName={icon as IconName} size="1x" />
					</Box>
				</Avatar>
			</Grid>
			<Grid item>
				<Stack alignItems={"baseline"}>
					<Typography
						component="span"
						color={themeColor.main}
						variant="body1"
						sx={{ fontWeight: 500 }}
					>
						{name}
					</Typography>
					<Stack
						divider={<>,&nbsp;</>}
						direction={"row"}
						alignItems={"baseline"}
						flexWrap={"wrap"}
					>
						{values.map((value) => {
							return DashboardIssueValuesRender({
								...value,
							});
						})}
					</Stack>
				</Stack>
			</Grid>
		</Grid>
	);
};

const DashboardAlerts = () => {
	const { client, event } = useContextModule();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [indicators, setIndicators] = useState<Array<IIssue>>([]);

	let countIssues = 0;

	indicators.forEach((indicator) => {
		countIssues +=
			indicator.values.length > 0
				? indicator.values
						.map((issue) => issue.value)
						.reduce((sum: number, current: number) => sum + current)
				: 0;
	});

	const fetchdata = useCallback(async (clientId: string, eventId: string) => {
		setIsLoading(true);
		await Services.Events.Programme.DashboardService.getIssues(
			clientId,
			eventId,
		)
			.then((data) => {
				setIndicators(DashboardFactory.PopulateIssuesIndicators(data));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		if (event) {
			fetchdata(client.id, event.id);
		}
	}, [event]);

	if (!indicators || isLoading) {
		return (
			<Grid item xs={12} md={6} lg={3} display={"flex"}>
				<Skeleton
					variant="rounded"
					width="100%"
					height={"100%"}
					sx={{
						borderRadius: "16px",
						minHeight: 300,
					}}
				/>
			</Grid>
		);
	}

	return (
		<Grid item xs={12} md={6} lg={3} display={"flex"}>
			<Card title={t("old.programme.dashboard.alerts.title")}>
				{!(countIssues > 0) ? (
					<NoData />
				) : (
					<Stack>
						{indicators.map((indicator) =>
							DashboardAlertsRender({
								...indicator,
							}),
						)}
					</Stack>
				)}
			</Card>
		</Grid>
	);
};

export default DashboardAlerts;
