import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import DashboardFactory from "@application/factory/dashboard/DashboardFactory";
import type { IChartSeriesItem } from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import { Services, useContextModule } from "@key4-front-library/core";

import DashboardDonutChart from "../DashboardDonutChart";

const DashboardInvitationsDonutChart = () => {
	const { client, event } = useContextModule();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [statusStatistics, setStatusStatistics] = useState<
		Array<IChartSeriesItem>
	>([]);

	const fetchData = useCallback(async (clientId: string, eventId: string) => {
		setIsLoading(true);
		await Services.Events.Programme.DashboardService.getSessionPublicationStatus(
			clientId,
			eventId,
		)
			.then((_data) => {
				setStatusStatistics(
					DashboardFactory.TransformDonutChartSeries({
						type: "publications",
						serie: _data,
					}),
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		fetchData(client.id, event.id);
	}, [event, client]);

	return (
		<DashboardDonutChart
			title={t("old.programme.dashboard.charts.donuts.publications.title")}
			statistics={statusStatistics}
			isLoading={isLoading}
		/>
	);
};

export default DashboardInvitationsDonutChart;
