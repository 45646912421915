import { t } from "i18next";
import {
	type ChangeEvent,
	type CSSProperties,
	useCallback,
	useEffect,
	useState,
} from "react";

import type { IconName } from "@fortawesome/fontawesome-svg-core";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import type {
	Keyword,
	KeywordsConfiguration,
} from "@key4-front-library/article/interfaces";
import {
	type DtoKeyword,
	type DtoKeywordWrite,
	EConfirmationModalAction,
	EnumKeywordDescription,
	EnumTarget,
} from "@key4-front-library/core";
import {
	ConfirmationModal,
	RichTextEditor,
} from "@key4-front-library/core/Bo/Components";
import { List } from "@key4-front-library/core/Bo/Components/FormControl";
import type { TypeSelectItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelect";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import {
	useKeywordHook,
	useSnackBarHook,
} from "@key4-front-library/core/Bo/Hooks";
import {
	Box,
	Button,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey, yellow } from "@mui/material/colors";

const styles = {
	list: {
		border: ["solid", "1px", grey[800]].join(" "),
	} as CSSProperties,
	pre: {
		backgroundColor: yellow[200],
		border: ["solid", "1px", grey[400]].join(" "),
	} as CSSProperties,
};

const TabSessionsViewTemplateEditor = () => {
	const [template, setTemplate] = useState<DtoKeyword | undefined>();
	const [templateName, setTemplateName] = useState<string>("");
	const [templateContent, setTemplateContent] = useState<string | undefined>();
	const [templateListItems, setTemplateListItems] = useState<
		Array<TypeSelectItem>
	>([]);

	const [keywordsConfiguration, setKeywordsConfiguration] = useState<
		KeywordsConfiguration | undefined
	>();

	const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
		useState<boolean>(false);

	const { useReadList: readListKeywords, useReadListSessionViewTemplate } =
		useKeywordHook();

	const { data: keywords, isLoading: isLoadingKeywords } = readListKeywords();
	const {
		data: templates,
		isLoading: isLoadingTemplates,
		refetch,
	} = useReadListSessionViewTemplate();

	const { read, create, update, deleteEntity } =
		ControllersBo.KeywordController.useEntity();

	const { sendSuccess, sendError } = useSnackBarHook();

	const translationNameSpace = "old.programme.programmeExport";
	const translationBaseKey: string = [
		translationNameSpace,
		["viewSessionTemplate"].join("."),
	].join(".");

	const translations = {
		title: t([translationBaseKey, "title"].join(".")),
		actions: {
			created: t([translationBaseKey, "actions", "created"].join(".")),
			updated: t([translationBaseKey, "actions", "updated"].join(".")),
			deleted: t([translationBaseKey, "actions", "deleted"].join(".")),
		},
		buttons: {
			add: t("old.form.buttons.add"),
			delete: t("old.form.buttons.delete"),
			save: t("old.form.buttons.save"),
		},
		editor: {
			list: t([translationBaseKey, "editor", "list"].join(".")),
			label: t([translationBaseKey, "editor", "label"].join(".")),
			isNew: t([translationBaseKey, "editor", "isNew"].join(".")),
			textfieldName: t(
				[translationBaseKey, "editor", "textfieldName"].join("."),
			),
			richTextEditor: {
				keywordsButton: {
					label: t(
						"old.common.field.richTextEditor.plugin.keywords.button.label",
					),
					tooltip: t(
						"old.common.field.richTextEditor.plugin.keywords.button.tooltip",
					),
				},
			},
			htmlContent: t([translationBaseKey, "editor", "htmlContent"].join(".")),
		},
		errors: {
			delete: t([translationBaseKey, "errors", "delete"].join(".")),
			update: t([translationBaseKey, "errors", "update"].join(".")),
			undefined: t([translationBaseKey, "errors", "undefined"].join(".")),
			name: t([translationBaseKey, "errors", "name"].join(".")),
			content: t([translationBaseKey, "errors", "content"].join(".")),
		},
	};

	const getConfiguration = useCallback(
		(keywords: Array<DtoKeyword> | undefined) => {
			if (keywords) {
				const keywordsItems: Array<Keyword> = keywords.map((keyword) => {
					return {
						id: keyword.id,
						name: keyword.name,
						target: keyword.target,
					} as Keyword;
				});

				const configuration: KeywordsConfiguration = {
					items: keywordsItems,
					button: translations.editor.richTextEditor.keywordsButton,
				};
				setKeywordsConfiguration(configuration);
			}
		},
		[keywords],
	);

	useEffect(() => {
		getConfiguration(keywords);
	}, [keywords]);

	useEffect(() => {
		if (templates) {
			setTemplateListItems(
				templates.map((template) => {
					return {
						key: template.id ?? "",
						label: template.name ?? "",
					} as TypeSelectItem;
				}),
			);
		}
	}, [templates]);

	const openConfirmationModal = (): void => {
		setIsOpenConfirmationModal(true);
	};
	const closeConfirmationModal = (): void => {
		setIsOpenConfirmationModal(false);
	};

	const updateTemplateName = (value: string): void => {
		setTemplateName(value);
	};
	const updateTemplateContent = (value: string): void => {
		setTemplateContent(value);
	};

	const clearTemplate = (): void => {
		setTemplate(undefined);
		setTemplateName("");
		setTemplateContent(undefined);
	};

	const loadTemplate = (id: string | null): void => {
		if (id) {
			read(id)
				.then((viewTemplate) => {
					setTemplate(viewTemplate);
					setTemplateName(viewTemplate.name ?? "");
					setTemplateContent(viewTemplate.body ?? "");
				})
				.catch(() => {
					sendError(translations.errors.undefined);
					clearTemplate();
				});
		} else {
			clearTemplate();
		}
	};

	const saveTemplate = async (): Promise<void> => {
		if (!templateName) {
			sendError(translations.errors.name);
			return Promise.reject(translations.errors.name);
		}
		if (!templateContent) {
			sendError(translations.errors.content);
			return Promise.reject(translations.errors.content);
		}
		if (!template?.id) {
			await create({
				name: templateName,
				description: EnumKeywordDescription.SessionViewTemplate,
				target: EnumTarget.Session,
				body: templateContent ?? "",
			} as DtoKeywordWrite)
				.then(() => {
					sendSuccess(translations.actions.created);
					refetch();
				})
				.catch(() => sendError(translations.errors.update));
		} else {
			await update(template.id, {
				...template,
				name: templateName,
				target: EnumTarget.Session,
				body: templateContent ?? "",
			} as DtoKeywordWrite)
				.then(() => {
					sendSuccess(translations.actions.updated);
					refetch();
				})
				.catch(() => sendError(translations.errors.update));
		}
	};

	const deleteTemplate = async () => {
		await deleteEntity(template?.id ?? "")
			.then(() => {
				refetch();
				clearTemplate();
				closeConfirmationModal();
				sendSuccess(translations.actions.deleted);
			})
			.catch(() => sendError(translations.errors.delete));
	};

	const handleButtonAddClick = (): void => {
		clearTemplate();
	};
	const handleButtonSaveClick = async (): Promise<void> => saveTemplate();
	const handleButtonDeleteClick = (): void => {
		openConfirmationModal();
	};

	const handleFieldTemplateNameChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void => {
		updateTemplateName(event.target.value);
	};
	const handleFieldTemplateContentChange = (value: string): void => {
		updateTemplateContent(value);
	};

	const handleSelectChange = (id: string | null): void => {
		loadTemplate(id);
	};

	const handleConfirmationModalClose = (): void => {
		closeConfirmationModal();
	};
	const handleConfirmationModalButtonDeleteClick = async (): Promise<void> =>
		deleteTemplate();

	if (isLoadingTemplates || isLoadingKeywords || !keywordsConfiguration) {
		return <Skeleton variant={"rectangular"} width={"100%"} height={"70px"} />;
	}

	if (templates) {
		return (
			<Box sx={{ my: 5 }}>
				<IconCard
					title={translations.title}
					icon={"book" as IconName}
					button={{
						label: translations.buttons.add,
						icon: "plus" as IconName,
						onClick: handleButtonAddClick,
					}}
				>
					<Stack spacing={2} m={5}>
						<Box style={styles.list}>
							<List
								label={translations.editor.list}
								value={template?.id ?? ""}
								onChange={handleSelectChange}
								items={templateListItems}
							/>
						</Box>

						<Stack>
							<Stack spacing={1}>
								<Stack direction={"row"}>
									<Typography variant={"h6"}>
										{translations.editor.label}&nbsp;
									</Typography>
									{!template && (
										<Typography variant={"caption"} color="error.main">
											({translations.editor.isNew})
										</Typography>
									)}
								</Stack>
								<TextField
									label={translations.editor.textfieldName}
									variant="outlined"
									onChange={handleFieldTemplateNameChange}
									value={templateName ?? ""}
								/>
								<RichTextEditor
									value={templateContent ?? template?.body ?? ""}
									onChange={handleFieldTemplateContentChange}
									keywordsConfiguration={keywordsConfiguration}
								/>
								<Stack
									justifyContent={"flex-end"}
									direction={"row"}
									spacing={1}
								>
									<Button
										variant="contained"
										color={"error"}
										onClick={handleButtonDeleteClick}
									>
										{translations.buttons.delete}
									</Button>
									<Button variant="contained" onClick={handleButtonSaveClick}>
										{translations.buttons.save}
									</Button>
								</Stack>
							</Stack>
							<Stack>
								<Typography variant={"h6"}>
									{translations.editor.htmlContent}
								</Typography>
								<iframe
									srcDoc={templateContent ?? ""}
									title="HTML Content"
									width="100%"
									height={"500px"}
									style={{ overflow: "scroll", border: "1px solid #0000001f" }}
								/>
							</Stack>
						</Stack>

						<ConfirmationModal
							open={isOpenConfirmationModal}
							text={t(
								[translationBaseKey, "confirmationModal", "prompt"].join("."),
							)}
							action={EConfirmationModalAction.DELETE}
							handleAction={handleConfirmationModalButtonDeleteClick}
							handleModaleClose={handleConfirmationModalClose}
							maxWidth={"sm"}
						/>
					</Stack>
				</IconCard>
			</Box>
		);
	}
};

export default TabSessionsViewTemplateEditor;
