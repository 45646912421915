import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ConvocationConfiguration from "@application/Configurations/ConvocationConfiguration";
import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import { ContainerConvocationModal } from "@application/Containers";
import ControllersApp from "@application/Controllers/ControllersApp";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import HooksApp from "@application/hooks";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import {
	ConfirmationModal,
	getColumns,
	useContextModule,
	useSnackBarHook,
} from "@key4-front-library/core";
import { DataGridOld } from "@key4-front-library/core/Bo/Components";
import { EConfirmationModalAction } from "@key4-front-library/core/Enums/Modal";
import type {
	TypeContact,
	TypeParticipant,
} from "@key4-front-library/core/Types";
import { Skeleton, Stack } from "@mui/material";
import type { GridSortModel } from "@mui/x-data-grid-pro";

export const SessionInvitation = () => {
	const { sessionId = "" } = useParams();
	const { client, event } = useContextModule();
	const [currentConvocationId, setCurrentConvocationId] = useState<
		string | undefined
	>();
	const [convocationModalIsOpen, setConvocationModalIsOpen] =
		useState<boolean>(false);
	const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
		useState<boolean>(false);

	const [sortModel, setSortModel] = useState<GridSortModel>([
		{ field: "lastname", sort: "asc" },
	]);

	const navigate = useNavigate();

	const { sendSuccess, sendError } = useSnackBarHook();
	const { create, deleteEntity } =
		ControllersApp.ConvocationController.useEntity();
	const { readList } = HooksApp.ConvocationHook.useEntity();

	const translationBaseKey: string = [
		"old",
		"programme",
		"sessionDetails",
		"convocation",
	].join(".");
	const translations = {
		buttons: {
			add: t("old.form.buttons.add"),
		},
		creating: {
			success: [translationBaseKey, "creating", "success"].join("."),
			errors: {
				undefined: [translationBaseKey, "creating", "errors", "undefined"].join(
					".",
				),
				onCreate: [translationBaseKey, "creating", "errors", "onCreate"].join(
					".",
				),
			},
		},
		deleting: {
			success: [translationBaseKey, "deleting", "success"].join("."),
			errors: {
				undefined: [translationBaseKey, "deleting", "errors", "undefined"].join(
					".",
				),
				onCreate: [translationBaseKey, "deleting", "errors", "onCreate"].join(
					".",
				),
			},
		},
		convocation: {
			label: t([translationBaseKey, "label"].join(".")),
		},
	};

	const getTranslatedColumnLabels = (): Array<string> =>
		ConvocationConfiguration.datagrid.columns.map((column) =>
			t(
				[translationBaseKey, "datagrid", "headerLabels", column.field].join(
					".",
				),
			),
		);

	const actionsConfigurations = GetMenuActionsConfigurations();
	const addConfig = actionsConfigurations.find(
		(actionsConfig) => actionsConfig.reference === MenuActionsReference.ADD,
	);

	const handleCloseModalConfirmation = (): void => {
		setCurrentConvocationId(undefined);
		setConfirmationModalIsOpen(false);
	};

	const navigateToParticipantDetailPage = (participantId: string): void => {
		navigate(`/${client.key}/${event.key}/faculties/${participantId}`);
	};

	const convocationCreate = async (
		convenedPerson: TypeParticipant | TypeContact,
	): Promise<void> => {
		await create(sessionId, convenedPerson.id)
			.then(() => {
				sendSuccess(t(translations.creating.success));
				refetch();
			})
			.catch((error) => {
				sendError(
					error?.message[0]?.technicalMessage ??
						t(translations.creating.errors.onCreate),
				);
			})
			.finally(() => {
				setConvocationModalIsOpen(false);
			});
	};

	const convocationDelete = async (): Promise<void> => {
		if (!currentConvocationId) {
			sendError(t(translations.deleting.errors.undefined));
			handleCloseModalConfirmation();
			return Promise.reject(new Error("--error--"));
		}

		await deleteEntity(sessionId, currentConvocationId)
			.then(() => {
				sendSuccess(t(translations.deleting.success));
				refetch();
			})
			.catch((error) => {
				sendError(error?.message ?? t(translations.deleting.errors.onCreate));
			})
			.finally(() => {
				handleCloseModalConfirmation();
			});
	};

	const handleConvocationModalSubmit = async (
		data?: TypeParticipant | TypeContact,
	): Promise<void> => {
		if (data) {
			await convocationCreate(data);
		}
	};

	const handleConfirmationModalConfirmedAction = async (): Promise<void> => {
		await convocationDelete();
		setCurrentConvocationId(undefined);
	};

	const handleChangeSortCriteria = (sortModel: GridSortModel): void => {
		setSortModel(sortModel);
	};

	const handleGridActionButtonDetails = (convocationId: string): void => {
		navigateToParticipantDetailPage(convocationId);
	};

	const handleGridActionButtonDelete = (convocationId: string): void => {
		setCurrentConvocationId(convocationId);
		setConfirmationModalIsOpen(true);
	};

	const {
		data: convocations,
		isLoading,
		refetch,
	} = readList(sessionId, sortModel, {
		actionDetails: handleGridActionButtonDetails,
		actionDelete: handleGridActionButtonDelete,
	});

	useEffect(() => {
		refetch();
	}, []);

	if (isLoading || !convocations) {
		return (
			<Skeleton
				variant={"rounded"}
				animation={"pulse"}
				width={"100%"}
				height={400}
				sx={{ borderRadius: 3 }}
			/>
		);
	}

	return (
		<Stack>
			<IconCard
				title={translations.convocation.label}
				icon={undefined}
				button={{
					label: translations.buttons.add,
					icon: addConfig?.icon!,
					onClick: () => {
						setConvocationModalIsOpen(true);
					},
				}}
			>
				<DataGridOld
					sx={{ marginTop: "1.5em" }}
					density={"standard"}
					handleChangeSortCriteria={handleChangeSortCriteria}
					columns={getColumns(
						getTranslatedColumnLabels(),
						ConvocationConfiguration.datagrid.columns,
					)}
					rows={convocations}
					isAutoHeight={true}
					isHideFooter={true}
				/>
			</IconCard>

			<ContainerConvocationModal
				isOpen={convocationModalIsOpen}
				handleModaleClose={() => {
					setConvocationModalIsOpen(false);
				}}
				onSubmit={handleConvocationModalSubmit}
			/>

			<ConfirmationModal
				open={confirmationModalIsOpen}
				action={EConfirmationModalAction.DELETE}
				handleAction={handleConfirmationModalConfirmedAction}
				handleModaleClose={handleCloseModalConfirmation}
				maxWidth={"sm"}
			/>
		</Stack>
	);
};
