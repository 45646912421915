import {
	ApplicationColors,
	TextColors,
	ThemeColors,
} from "@key4-front-library/core";
import { alpha, type SxProps } from "@mui/material";

const buttonStyle = {
	width: "2em",
	height: "2em",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	cursor: "pointer",
	borderRadius: 30,
	backgroundColor: alpha(TextColors.light.secondary, 0.2),
	color: TextColors.light.primary,
	"&:hover": {
		backgroundColor: alpha(ThemeColors.primary.main, 0.2),
	},
} as SxProps;

const Styles = {
	tabs: {
		position: "sticky",
		top: 0,
		zIndex: 1300,
		background: ApplicationColors.white.main,
	} as SxProps,
	tab: {
		"&:hover": {
			backgroundColor: alpha(ThemeColors.primary.light!, 0.1),
		},
	} as SxProps,
	tabLabel: {
		fontSize: "0.875rem",
		fontWeight: 500,
	} as SxProps,
	boxButtonAdd: {
		...buttonStyle,
		minWidth: "2em",
	} as SxProps,
	boxButton: {
		...buttonStyle,
		opacity: "0.3",
		"&:hover": {
			opacity: 1,
			backgroundColor: alpha(ThemeColors.primary.main, 0.2),
		},
	},

	icon: {
		width: "1em",
		height: "1em",
	} as SxProps,
};

export default Styles;
