import {
	type DtoDefinition,
	ETypeField,
	type TAdvancedSearchFieldDefinition,
} from "@key4-front-library/core";

export const dtoDefinitionListToTAdvancedSearchFieldDefinitionList = (
	definitions: Array<DtoDefinition>,
): Array<TAdvancedSearchFieldDefinition> =>
	definitions.map((def) => {
		return {
			name: def.name ?? "",
			fields: def.fields
				? def.fields.map((field) => {
						return {
							key: field.key ?? "",
							type: field.type ? (field.type as ETypeField) : ETypeField.TEXT,
							resources: field.resources,
						};
					})
				: [],
			foreignKeys: def.foreignKeys ?? [],
			resources: def.resources ?? undefined,
		};
	});
