import type { PropositionInfo } from "@application/Mappers";
import {
	ApplicationColors,
	type DtoClient,
	type DtoEvent,
	EnumParticipantStatusInvitation,
	type IconOldProps,
	type MatchDto,
	Services,
} from "@key4-front-library/core";

export function matchDtoToIcon(match?: MatchDto): IconOldProps | undefined {
	let icon: IconOldProps | undefined;
	switch (match?.matchingType) {
		case "all":
			icon = {
				color: ApplicationColors.red.main,
				prefix: "fas",
				iconName: "heart-circle-bolt",
			};
			break;
		case "emailOnly":
		case "fullNameOnly":
			icon = {
				color: ApplicationColors.red.main,
				prefix: "far",
				iconName: "heart-circle-bolt",
			};
			break;
		default:
			break;
	}
	return icon;
}

export async function submitMatching(
	client: DtoClient,
	event: DtoEvent,
	selectedPropositionInfo: PropositionInfo,
): Promise<boolean> {
	let participantId = "notSet";
	try {
		if (selectedPropositionInfo.userType === "contact") {
			const contact =
				await Services.Operations.Registration.ContactsService.get(
					client.id,
					event.communityId ?? event.id,
					selectedPropositionInfo.id,
				);
			participantId = (
				(await Services.Events.Registration.ParticipantsService.post(
					client.id,
					event.id,
					{
						email: contact.data.email,
						firstname: contact.data.firstname,
						lastname: contact.data.lastname,
						customFieldValues: contact.data.customFieldValues,
						invitationStatus: EnumParticipantStatusInvitation.UNSENT,
					},
				)) as { id: string }
			).id;
		} else {
			participantId = selectedPropositionInfo.id;
		}

		switch (selectedPropositionInfo.facultyType) {
			case "chair":
				await Services.Programme.Clients.Chairs.replace(
					client.id,
					event.id,
					selectedPropositionInfo.sessionId,
					selectedPropositionInfo.currentParticipantId,
					{
						replacementParticipantId: participantId,
					},
				);
				break;
			case "speaker":
				if (!selectedPropositionInfo.presentationId) {
					return false;
				}
				await Services.Programme.Clients.Speakers.replace(
					client.id,
					event.id,
					selectedPropositionInfo.sessionId,
					selectedPropositionInfo.presentationId,
					selectedPropositionInfo.currentParticipantId,
					{
						replacementParticipantId: participantId,
					},
				);
				break;
			default:
				return false;
		}
		return true;
	} catch {
		return false;
	}
}
