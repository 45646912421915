import React from "react";

import AnomaliesByTypesList from "@application/components/_common/Anomalies/AnomaliesByTypesList";
import { ETypeAnomalies } from "@application/enum/Anomalies.enum";
import GetTranslationsForAnomalies from "@application/helpers/translations/anomalies/GetTranslationsForAnomalies";
import type { IAnomaliesByType } from "@domain/interfaces/anomalies.interface";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import { K4Button } from "@infrastructure/components/interface/button/K4Button";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import ClearIcon from "@mui/icons-material/Clear";
import {
	Box,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";

type Props = {
	anomaliesCheckboxes: IAnomaliesByType[];
	onAnomalyCheckboxClick: (anomalyItem: IAnomaliesByType) => void;
	onSelectAllAnomaliesClick: (selectAll: boolean) => void;
	searchValue: string;
	onSearchAnomaly: (search: string) => void;
};
const AnomaliesFiltersPopover = (props: Props & dataCypressProp) => {
	const {
		anomaliesCheckboxes,
		onAnomalyCheckboxClick,
		onSelectAllAnomaliesClick,
		searchValue,
		onSearchAnomaly,
		dataCypressID,
	} = props;

	const theme = useTheme();
	const translations = GetTranslationsForAnomalies();
	const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));

	const anomaliesBySession = anomaliesCheckboxes.filter((anomalyItem) => {
		return anomalyItem.type === ETypeAnomalies.SESSION;
	});
	const anomaliesByPresentation = anomaliesCheckboxes.filter((anomalyItem) => {
		return anomalyItem.type === ETypeAnomalies.PRESENTATION;
	});
	const anomaliesByInvitation = anomaliesCheckboxes.filter((anomalyItem) => {
		return anomalyItem.type === ETypeAnomalies.INVITATION;
	});

	const AnomaliesByTypes = [
		{ type: ETypeAnomalies.SESSION, anomalies: anomaliesBySession },
		{
			type: ETypeAnomalies.PRESENTATION,
			anomalies: anomaliesByPresentation,
		},
		{
			type: ETypeAnomalies.INVITATION,
			anomalies: anomaliesByInvitation,
		},
	];
	//#endregion 'Definitions

	//#region 'Rendering'
	return (
		<Box width={mobileSize ? "90vw" : "500px"}>
			<AppBox>
				<Stack spacing={2} height={"500px"}>
					<TextField
						label={translations.filters.searchAnomaly}
						variant="outlined"
						value={searchValue}
						onChange={(event) => onSearchAnomaly(event.target.value)}
						InputProps={{
							endAdornment: (
								<IconButton
									sx={{
										visibility: searchValue ? "visible" : "hidden",
									}}
									onClick={() => onSearchAnomaly("")}
									data-cy={
										dataCypressID
											? `${dataCypressID}-search_anomaly_clear_button`
											: undefined
									}
								>
									<ClearIcon />
								</IconButton>
							),
						}}
						data-cy={
							dataCypressID ? `${dataCypressID}-search_anomaly` : undefined
						}
					/>
					<Stack spacing={2} sx={{ overflowY: "scroll", height: "100%" }}>
						{AnomaliesByTypes.map((element, index) => {
							return (
								<React.Fragment key={index}>
									{index > 0 && <Divider />}
									<Typography
										color={"text.disabled"}
										data-cy={
											dataCypressID
												? `${dataCypressID}-type_${element.type}`
												: undefined
										}
									>
										{translations.anomalies[element.type].toUpperCase()}
									</Typography>
									<AnomaliesByTypesList
										anomaliesByType={element.anomalies}
										onAnomalyCheckboxClick={onAnomalyCheckboxClick}
										dataCypressID={
											dataCypressID
												? `${dataCypressID}-type_${element.type}-anomaly`
												: undefined
										}
									/>
								</React.Fragment>
							);
						})}
					</Stack>
					<Stack
						display={"flex"}
						direction={"row"}
						justifyContent={"space-between"}
					>
						<K4Button
							label={translations.buttons.reset}
							variant="text"
							handleClick={() => onSelectAllAnomaliesClick(false)}
							dataCypressID={
								dataCypressID ? `${dataCypressID}-reset_button` : undefined
							}
						/>
						<K4Button
							label={translations.buttons.selectAll}
							variant="text"
							handleClick={() => onSelectAllAnomaliesClick(true)}
							dataCypressID={
								dataCypressID ? `${dataCypressID}-select_all_button` : undefined
							}
						/>
					</Stack>
				</Stack>
			</AppBox>
		</Box>
	);
	//#endregion 'Rendering'
};

export default AnomaliesFiltersPopover;
