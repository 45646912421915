import { type DtoFaculty, Gravatar } from "@key4-front-library/core";
import type { PropsButtonIcon } from "@key4-front-library/core/Bo/Components/Button/ButtonIcon";
import type { DataGridCellBulletColorProps } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellBulletColor";
import { Stack, Typography } from "@mui/material";
import type { GridCellParams } from "@mui/x-data-grid-pro";

export interface ChairRow {
	id: string;
	chair: DtoFaculty;
	status: DataGridCellBulletColorProps;
	invitationType: string | null | undefined;
	actions: {
		// TODO TM : Should be directly an Array of PropsButtonIcon
		listButtons: Array<PropsButtonIcon>;
	};
}

const renderChairCell = (params: GridCellParams) => {
	const columnSize =
		params.colDef.width &&
		params.colDef.minWidth &&
		params.colDef.width > params.colDef.minWidth
			? params.colDef.width
			: params.colDef.minWidth;
	const adjustedMaxWidth = `${columnSize! - 30}px`;
	return (
		<Stack
			direction={"row"}
			maxWidth={adjustedMaxWidth}
			alignSelf={"flex-start"}
			marginTop={"10px"}
			spacing={1}
			alignContent={"center"}
		>
			<Gravatar
				email={params.row.chair.email}
				firstname={params.row.chair.firstname}
				lastname={params.row.chair.lastname}
				size={30}
			/>
			<Typography>{`${params.row.chair.firstname} ${params.row.chair.lastname}`}</Typography>
		</Stack>
	);
};

const ChairDataGridHelper = {
	renderChairCell,
};

export default ChairDataGridHelper;
