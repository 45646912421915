type avatarUsernameInitialsProps = {
	firstname: string;
	lastname: string;
};

export const avatarUsernameInitials = (props: avatarUsernameInitialsProps) => {
	const { firstname, lastname } = props;
	if (
		!firstname ||
		!lastname ||
		firstname.trim().length <= 0 ||
		lastname.trim().length <= 0
	) {
		return "..";
	}
	return [firstname.slice(0, 1), lastname.slice(0, 1)]
		.join("")
		.toUpperCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "");
};
