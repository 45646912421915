import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";

import type { PropositionInfo } from "@application/Mappers";
import {
	type ActivityMatchDto,
	type ActivityReplacementDto,
	DataGrid,
	ESieveOperator,
	IconOld,
	isPluralKeyString,
	queryFilters,
	queryStringPagination,
	Services,
	type Translation,
	type TypeApiResponsePagined,
	useContextModule,
	usePagination,
} from "@key4-front-library/core";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import type {
	GridCallbackDetails,
	GridColDef,
	GridSelectionModel,
	GridSortModel,
	GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";

import {
	getColumns,
	submitMatchingList,
} from "./DataGridMatching.configuration";
import { listActivitiesToListRowsModels } from "./DataGridMatching.mapper";

type DataGridMatchingProps = Translation;

export const DataGridMatching = (props: DataGridMatchingProps) => {
	const { translation } = props;
	const component = `${translation}.matchingDataGrid`;
	const {
		page,
		pageSize,
		totalRowCount,
		handleChangePage,
		handleChangePageSize,
		setTotalRowCount,
	} = usePagination();
	const [rows, setRows] = useState<Array<GridValidRowModel>>();
	const columns: Array<GridColDef> = useMemo(
		() => getColumns(component),
		[component],
	);
	const { client, event } = useContextModule();
	const theme = useTheme();
	const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
	const [selectedPropositionInfos, setSelectedPropositionInfos] = useState<
		Array<PropositionInfo>
	>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const [sort, setSort] = useState<GridSortModel>([
		{ field: "startDate", sort: "desc" },
		{ field: "endDate", sort: "desc" },
	]);

	const { data: activities, refetch: refetchActivities } = useQuery({
		queryKey: ["matching", "faculty", page, pageSize],
		queryFn: async (): Promise<
			TypeApiResponsePagined<Array<ActivityReplacementDto>>
		> =>
			Services.Programme.Clients.ActivityService.getActivitiesList(
				client.id,
				event.id,
				[
					...queryFilters(`InvitationStatus${ESieveOperator.EQUALS}declined`),
					...queryStringPagination({
						page,
						pageSize,
					}),
				],
			),
		refetchOnMount: "always",
	});

	const { data: participantProposals } = useQuery({
		queryKey: [
			"client",
			client.id,
			"event",
			event.id,
			"participant",
			"matching",
			activities?.data,
			page,
			pageSize,
		],
		queryFn: async (): Promise<Array<ActivityMatchDto>> => {
			return Services.Registration.Clients.ParticipantsService.postMatching(
				client.id,
				event.id,
				activities?.data.map((activity) => activity.id) ?? [],
			);
		},
		enabled: !!activities?.data,
	});

	const { data: contactProposals } = useQuery({
		queryKey: [
			"client",
			client.id,
			"community",
			event.communityId,
			"contact",
			"matching",
			activities?.data,
			page,
			pageSize,
		],
		queryFn: async (): Promise<Array<ActivityMatchDto>> => {
			return Services.Registration.Clients.Contacts.postMatching(
				client.id,
				event.communityId ?? "",
				activities?.data.map((activity) => activity.id) ?? [],
			);
		},
		enabled: !!activities?.data,
	});

	useEffect(() => {
		if (activities?.data) {
			setRows(
				listActivitiesToListRowsModels({
					activities: activities.data,
					color: theme.palette.primary.main,
					pathSession: `/${client.key}/${event.key}/sessions/`,
					translation: component,
					participantProposals,
					contactProposals,
				}),
			);
		}
	}, [
		client.key,
		component,
		activities?.data,
		contactProposals,
		participantProposals,
	]);

	useEffect(() => {
		if (activities?.pagination) {
			setTotalRowCount(activities.pagination.totalCount ?? 0);
		}
	}, [activities?.pagination, setTotalRowCount]);

	useEffect(() => {
		handleChangePage(0);
	}, [handleChangePage]);

	const handleSelectRow = (
		selectionModel: GridSelectionModel,
		_details: GridCallbackDetails,
	) => {
		setSelectedRows(selectionModel as Array<string>);
		setSelectedPropositionInfos(
			rows
				?.filter(
					(row) =>
						(selectionModel as Array<string>).includes(row.id) &&
						row.propositionInfo,
				)
				.map((row) => row.propositionInfo as PropositionInfo) ?? [],
		);
	};

	const handleSaveMatching = async () => {
		try {
			setIsSubmitting(true);
			if (await submitMatchingList(client, event, selectedPropositionInfos)) {
				enqueueSnackbar(t(`${component}.save.success`), {
					variant: "success",
				});
			} else {
				enqueueSnackbar(t(`${component}.save.error`), {
					variant: "error",
				});
			}
			void refetchActivities();
		} finally {
			setIsSubmitting(false);
		}
	};

	const renderSelectedLineAction = (
		<SelectedLineAction
			translation={component}
			count={selectedRows.length}
			onClick={handleSaveMatching}
			disabled={isSubmitting || selectedRows.length === 0}
			selectedPropositionInfos={selectedPropositionInfos}
		/>
	);

	return (
		<>
			{renderSelectedLineAction}
			{rows && (
				<DataGrid
					density="standard"
					checkboxSelection
					checkboxSelectionVisibleOnly
					disableMultipleSelection
					autoHeight
					isRowSelectable={(params) => params.row.isRowSelectable}
					columns={columns}
					rows={rows}
					translation={component}
					pagination={{
						page,
						pageSize,
						totalRowCount,
						handleChangePage,
						handleChangePageSize,
					}}
					initialState={{
						sorting: {
							sortModel: sort,
						},
					}}
					onSortModelChange={setSort}
					onSelectionModelChange={handleSelectRow}
					selectionModel={selectedRows}
				/>
			)}
			{renderSelectedLineAction}
		</>
	);
};

interface SelectedLineActionProps extends Translation {
	count: number;
	onClick: () => void;
	selectedPropositionInfos: Array<PropositionInfo>;
	disabled?: boolean;
}

const SelectedLineAction = (
	props: SelectedLineActionProps,
): React.ReactElement => {
	const { count, translation, onClick, selectedPropositionInfos, disabled } =
		props;
	return (
		<Stack
			py={2}
			direction="row"
			justifyContent={"space-between"}
			width={"100%"}
			alignItems={"center"}
		>
			<Typography
				fontWeight={"medium"}
			>{`${count} ${t(`${translation}.selected${isPluralKeyString(count > 1)}`)}`}</Typography>
			<Button
				disabled={disabled ?? selectedPropositionInfos.length === 0}
				variant="text"
				onClick={onClick}
			>
				<Stack direction="row" spacing={1}>
					<IconOld prefix="fas" iconName="save" />
					<Stack>{t(`${translation}.button.match`)}</Stack>
				</Stack>
			</Button>
		</Stack>
	);
};
