import {
	Box,
	type Breakpoint,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";

type Props = {
	title?: string | JSX.Element;
	children: JSX.Element;
	open: boolean;
	dialogActions?: boolean;
	maxWidth?: Breakpoint;
	isFullWidth?: boolean;
	actions?: JSX.Element;
};

const DefaultModal = (props: Props) => {
	const {
		title,
		children,
		open = false,
		maxWidth = "md",
		isFullWidth = true,
		actions,
	} = props;

	return (
		<Dialog open={open} maxWidth={maxWidth} fullWidth={isFullWidth}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				<Box>{children}</Box>
			</DialogContent>
			<DialogActions>
				<>{actions}</>
			</DialogActions>
		</Dialog>
	);
};
export default DefaultModal;
