import { useSnackbar as useNotistackSnackbar } from "notistack";

import { ErrorAPI } from "@key4-front-library/core";

const useSnackBar = () => {
	const { enqueueSnackbar } = useNotistackSnackbar();

	const sendSuccess = (message: string) =>
		enqueueSnackbar(message, {
			variant: "success",
			autoHideDuration: 3000,
		});

	const sendWarning = (message: string) =>
		enqueueSnackbar(message, {
			variant: "warning",
			autoHideDuration: 3000,
		});

	const sendError = async (error: ErrorAPI | string) => {
		let message = typeof error === "string" ? error : "An error has occured";
		if (error instanceof ErrorAPI) {
			message =
				typeof error.message === "string"
					? error.message
					: "An error has occured";
			if (Array.isArray(message)) {
				message = message[0].technicalMessage;
			}
		}
		enqueueSnackbar(message, { variant: "error", autoHideDuration: 3000 });
	};

	return { sendSuccess, sendWarning, sendError };
};

const SnackBarHook = {
	useSnackBar,
};

export default SnackBarHook;
