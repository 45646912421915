import { DateTime } from "luxon";

import type { ISettingsSchedulerEditType } from "@application/components/settings/scheduler/scheduler/SettingsSchedulerEdit";
import DateHelper from "@application/helpers/date.helper";
import type { ICalendarSettings } from "@domain/interfaces/calendar.interface";
import type { INumberingSettings } from "@domain/interfaces/settings.interface";
import type {
	INumberingModel,
	IPutNumbering,
	ITag,
	TPutSettingsScheduler,
} from "@domain/model/settings.model";
import ApiHelper from "@infrastructure/helpers/api.helper";
import { ErrorAPI, Services, useContextModule } from "@key4-front-library/core";
import type {
	DtoClashGetSettings,
	DtoSettingsScheduler,
} from "@key4-front-library/core/Dto";

const getSettingsScheduler = async (
	clientId: string,
	eventId: string,
): Promise<ICalendarSettings | null> => {
	const fetchData = async () =>
		Services.Events.Programme.SettingsService.getScheduler(clientId, eventId);

	return fetchData()
		.then((settingsScheduler: any) => {
			// Formating hours start/end
			return Promise.resolve(
				DateHelper.getCalendarProgrammeSettings(settingsScheduler),
			);
		})
		.catch(() => {
			return Promise.reject(null);
		});
};

const getSettingsClashes = async (
	clientId: string,
	eventId: string,
): Promise<DtoClashGetSettings | ErrorAPI> => {
	try {
		const settingsClashes =
			await Services.Events.Programme.ClashesService.getSettings(
				clientId,
				eventId,
			);

		return Promise.resolve(settingsClashes);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getNumberingSettings = async (
	clientId: string,
	eventId: string,
): Promise<INumberingSettings | ErrorAPI> => {
	return ApiHelper.tryCatch<INumberingSettings>(
		async (): Promise<INumberingSettings> => {
			const numberingData: INumberingModel =
				await Services.Events.Programme.SettingsService.getNumbering(
					clientId,
					eventId,
				);

			return Promise.resolve({
				primaryTagLabel: numberingData.primaryTag ?? "",
				rows: numberingData.tags
					? numberingData.tags.map((tag: ITag) => {
							const primaryTag = {
								chip: tag
									? {
											backgroundColor: tag.backgroundColor,
											borderColor: tag.borderColor,
											fontColor: tag.fontColor,
											label: tag.label,
										}
									: undefined,
							};
							return {
								id: tag.id,
								primaryTag,
								sessionPrefix: tag.sessionPrefix ?? "",
								presentationPrefix: tag.presentationPrefix ?? "",
							};
						})
					: [],
			});
		},
	);
};

const editNumberingTag = async (
	clientId: string,
	eventId: string,
	tagId: string,
	body: IPutNumbering,
): Promise<boolean | ErrorAPI> => {
	return ApiHelper.tryCatch(async (): Promise<boolean> => {
		return Promise.resolve(
			await Services.Events.Programme.SettingsService.putNumbering(
				clientId,
				eventId,
				tagId,
				body,
			),
		);
	});
};

const updateSettingScheduler = async (
	clientId: string,
	eventId: string,
	form: ISettingsSchedulerEditType,
): Promise<boolean> => {
	const body: TPutSettingsScheduler = {
		programmeStartDate: form.startDate
			? DateTime.fromFormat(
					`${form.startDate} ${form.startTime?.toFormat("HH:mm")}`,
					"yyyy-MM-dd HH:mm",
					{ zone: "UTC" },
				)
					.toUTC()
					.toString()
			: null,
		programmeEndDate: form.endDate
			? DateTime.fromFormat(
					`${form.endDate} ${form.endTime?.toFormat("HH:mm")}`,
					"yyyy-MM-dd HH:mm",
					{ zone: "UTC" },
				)
					.toUTC()
					.toString()
			: null,
		schedulerStartHour: form.startTime
			? form.startTime.toFormat("HH:mm")
			: null,
		schedulerEndHour: form.endTime ? form.endTime.toFormat("HH:mm") : null,
	};

	return await Services.Events.Programme.SettingsService.putScheduler(
		clientId,
		eventId,
		body,
	);
};

const useEntity = () => {
	const { client, event } = useContextModule();

	const readSettingsScheduler =
		async (): Promise<DtoSettingsScheduler | null> => {
			return Services.Events.Programme.SettingsService.getScheduler(
				client.id,
				event.id,
			);
		};

	return { readSettingsScheduler };
};

const SettingsController = {
	getSettingsScheduler,
	getSettingsClashes,
	getNumberingSettings,
	editNumberingTag,
	updateSettingScheduler,
	useEntity,
};

export default SettingsController;
