import { t } from "i18next";

import IssuesDefinitions from "@application/Configurations/issues.configuration";
import type { IconName } from "@fortawesome/fontawesome-common-types";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import K4StatusBullet from "@infrastructure/components/interface/icons/K4StatusBullet";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import type { IStatus } from "@key4-front-library/core";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

type Props = {
	headerTitle: string;
	hasClashes?: boolean;
	hasAnomalies?: boolean;
	status?: IStatus;
	onTitleClick?: () => void;
};
const TitleAndIndicators = (props: Props & dataCypressProp) => {
	const { headerTitle, hasClashes, hasAnomalies, status, onTitleClick } = props;
	const iconSize = "2xs";
	const styles = {
		ellipsis: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			display: "-webkit-box",
			WebkitLineClamp: 1,
			WebkitBoxOrient: "vertical",
		},
	};

	return (
		<Stack
			direction={"row"}
			justifyContent={"space-between"}
			alignItems={"center"}
			sx={{ width: "100%" }}
		>
			<Typography
				color={"text.secondary"}
				sx={{
					...(onTitleClick && {
						"&:hover": {
							cursor: "pointer",
						},
					}),
					fontSize: ".8rem",
					...styles.ellipsis,
				}}
				onClick={() => onTitleClick?.()}
			>
				{headerTitle}
			</Typography>
			<Stack direction={"row"} spacing={1.5}>
				{hasClashes && (
					<Tooltip title={t("old.common.session_type.clashesToFix")}>
						<Box>
							<Icon
								color={IssuesDefinitions.clashes.themeColor.main}
								prefix="fas"
								iconName={IssuesDefinitions.clashes.icon as IconName}
								size={iconSize}
							/>
						</Box>
					</Tooltip>
				)}
				{hasAnomalies && (
					<Tooltip title={t("old.common.session_type.anomaliesToFix")}>
						<Box>
							<Icon
								color={IssuesDefinitions.anomalies.themeColor.main}
								prefix="fas"
								iconName={IssuesDefinitions.anomalies.icon as IconName}
								size={iconSize}
							/>
						</Box>
					</Tooltip>
				)}
				{status && (
					<K4StatusBullet
						color={status.bgColor}
						size={iconSize}
						title={t(status.label).toString()}
					/>
				)}
			</Stack>
		</Stack>
	);
};

export default TitleAndIndicators;
