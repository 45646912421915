import { DateTime, type Duration } from "luxon";

import ConfigurationsApp from "@application/Configurations";
import HelpersApp from "@application/helpers";
import type { TSessionUseFormModal } from "@domain/interfaces/session.interface";
import type {
	IPresentationCreated,
	IPresentationCreateorUpdateModel,
	IPresentationCreateorUpdateViewModel,
} from "@domain/model/presentation.model";
import StringService from "@infrastructure/services/string/string.service";
import {
	type DtoPresentation,
	type DtoPresentationWrite,
	ErrorAPI,
	FacultyHelper,
	queryIncludeCustomFields,
	Services,
	type TFacultyUseForm,
	type TypeApiResponsePost,
	type TypeUseFormListForms,
	useContextModule,
} from "@key4-front-library/core";

const datetimeFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";
const hourFormat = "HH:mm:ss";

/* ============================================================================================================================ */
/* PRESENTATIONS */
/* ============================================================================================================================ */

/**
 * Delete a Presentation
 * @param clientId Current Client ID
 * @param eventId Current event ID
 * @param sessionId Current session ID
 * @param presentationId Current presentation ID
 * @returns Success boolean
 */
const deleteEntity = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	presentationId: string,
): Promise<boolean> =>
	await Services.Events.Programme.PresentationsService.deleteEntity(
		clientId,
		eventId,
		sessionId,
		presentationId,
	);

/**
 * Clone a Presentation
 * @param clientId Current Client ID
 * @param eventId Current event ID
 * @param presentationId Id of origin Presentation
 * @returns ID of entity created
 * @typedef TypeApiResponsePost Type of Post API Response
 */
const clone = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	presentationId: string,
) =>
	await Services.Events.Programme.PresentationsService.postClone(
		clientId,
		eventId,
		sessionId,
		presentationId,
	);

/**
 * Save a presentation
 * @param viewPresentation viewmodel
 * @param clientId Current Client ID
 * @param eventId Current event ID
 * @param sessionId Current Session ID
  @returns ID of entity created or success boolean
 */
// TODO: K4PROG-2037 - refactor to reduce cognitive complexity | try
const update = async (
	viewPresentation: IPresentationCreateorUpdateViewModel,
	clientId: string,
	eventId: string,
	sessionId: string,
): Promise<IPresentationCreated | boolean | ErrorAPI> => {
	const presentation: IPresentationCreateorUpdateModel = {
		id: viewPresentation.id,
		code: viewPresentation.code,
		title: viewPresentation.title,
		description: viewPresentation.description,
		isWithoutTimeSlot: viewPresentation.isWithoutTimeSlot,
		customFieldValues: viewPresentation.customFieldValues,
		startDate: null,
		endDate: null,
		duration: null,
	};

	if (!viewPresentation.isWithoutTimeSlot) {
		let startDateHour = DateTime.fromJSDate(
			new Date(viewPresentation.startDate!),
		);
		if (viewPresentation.startHour !== null) {
			const startHour = DateTime.fromFormat(
				viewPresentation.startHour,
				hourFormat,
			);
			startDateHour = startDateHour.set({
				hour: startHour.hour,
			});
			startDateHour = startDateHour.set({
				minute: startHour.minute,
			});
		}

		let endDateHour = DateTime.fromJSDate(new Date(viewPresentation.endDate!));

		if (viewPresentation.endHour !== null) {
			const endHour = DateTime.fromFormat(viewPresentation.endHour, hourFormat);

			endDateHour = endDateHour.set({ hour: endHour.hour });
			endDateHour = endDateHour.set({ minute: endHour.minute });
		}

		presentation.startDate =
			startDateHour && viewPresentation.startDate
				? startDateHour.toFormat(datetimeFormat)
				: null;
		presentation.endDate =
			endDateHour && viewPresentation.endDate
				? endDateHour.toFormat(datetimeFormat)
				: null;
		if (!startDateHour || endDateHour) {
			const duration: Duration | null = endDateHour.diff(startDateHour, [
				"hours",
				"minutes",
			]);

			presentation.duration = `${StringService.pad(duration.hours, 2)}:${StringService.pad(duration.minutes, 2)}:00`;
		}
	}

	try {
		if (presentation.id === null) {
			delete presentation.id;
			return Promise.resolve(
				Services.Events.Programme.SessionsService.postPresentation(
					clientId,
					eventId,
					sessionId,
					presentation,
				),
			) as Promise<IPresentationCreated>;
		}
		return Promise.resolve(
			Services.Events.Programme.PresentationsService.put(
				clientId,
				eventId,
				sessionId,
				presentation.id!,
				presentation,
			),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

/**
 * Map Dto into ViewModel Presentation
 * @param presentation Current Presentation
 * @param session Current Session
 * @typedef DtoPresentation Dto of Presentation
 * @typedef TSessionUseFormModal Session ViewModel
 * @typedef IPresentationCreateorUpdateViewModel Presentation ViewModel
 * @returns ViewModel
 */
const getMappedPresentationViewModel = (
	presentation: DtoPresentation,
	session: TSessionUseFormModal,
): IPresentationCreateorUpdateViewModel => {
	return {
		id: presentation.id,
		code: presentation.code ?? "",
		startDate: presentation.startDate
			? presentation.startDate
			: session.startDate,
		startHour: presentation.startDate
			? DateTime.fromJSDate(new Date(presentation.startDate))
					.toUTC()
					.toFormat(hourFormat)
			: null,
		endDate: presentation.endDate ? presentation.endDate : session.endDate,
		endHour: presentation.endDate
			? DateTime.fromJSDate(new Date(presentation.endDate))
					.toUTC()
					.toFormat(hourFormat)
			: null,
		title: presentation.title ?? "",
		description: presentation.description ?? "",
		duration: presentation.duration ?? "",
		isWithoutTimeSlot: presentation.isWithoutTimeSlot ?? false,
		customFieldValues: presentation.customFieldValues ?? {},
	};
};

/* ============================================================================================================================ */
/* SPEAKERS */
/* ============================================================================================================================ */

/**
 * Get Speaker Details for Faculty Modal
 * @param clientId Current Client ID
 * @param eventId Current Event ID
 * @param sessionId Current Session ID
 * @param presentationId Current Presentation ID
 * @param participantId Current Participant ID
 * @param doesIncludeCustomFields Does include Custom Fields
 * @returns
 */
const getSpeakerSessionDetailsFacultyModal = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	presentationId: string,
	participantId: string,
	doesIncludeCustomFields: boolean,
): Promise<TFacultyUseForm | ErrorAPI> => {
	try {
		const chair =
			await Services.Events.Programme.PresentationsService.getSpeaker(
				clientId,
				eventId,
				sessionId,
				presentationId,
				participantId,
				[...queryIncludeCustomFields(doesIncludeCustomFields)],
				// doesIncludeCustomFields
			);
		return Promise.resolve(FacultyHelper.remapFacultyModelToUseForm(chair));
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

/**
 * Delete a Speaker from a Presentation
 * @param clientId Current Client ID
 * @param eventId Current Event ID
 * @param sessionId Current Session ID
 * @param presentationId  Current Presentation ID
 * @param speakerId Current Speaker ID
 * @returns Success boolean
 */
const deletePresentationSpeaker = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	presentationId: string,
	speakerId: string,
): Promise<boolean> =>
	await Services.Events.Programme.PresentationsService.deleteSpeaker(
		clientId,
		eventId,
		sessionId,
		presentationId,
		speakerId,
	);

/**
 * Sort speakers Order
 * @param clientId Current Client ID
 * @param eventId Current Event ID
 * @param sessionId Current Session ID
 * @param presentationId Current Presentation ID
 * @param speakerIds Speakers IDs
 */
const putOrderSpeakers = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	presentationId: string,
	speakerIds: Array<string>,
) =>
	await Services.Events.Programme.PresentationsService.putSpeakerOrder(
		clientId,
		eventId,
		sessionId,
		presentationId,
		{ ids: speakerIds },
	);

const useEntity = () => {
	const { client, event } = useContextModule();

	const create = async (
		useFormData: TypeUseFormListForms,
		sessionId: string,
	): Promise<TypeApiResponsePost> => {
		const presentation: DtoPresentationWrite =
			ConfigurationsApp.PresentationConfiguration.defaultModel;

		HelpersApp.PresentationHelper.mapUseFormToDtoPresentationWrite(
			useFormData,
			presentation,
		);
		return await Services.Events.Programme.SessionsService.postPresentation(
			client.id,
			event.id,
			sessionId,
			presentation,
		);
	};

	const read = async (
		sessionId: string,
		presentationId: string,
	): Promise<DtoPresentation> => {
		return await Services.Events.Programme.PresentationsService.get(
			client.id,
			event.id,
			sessionId,
			presentationId,
			queryIncludeCustomFields(true),
		);
	};

	const update = async (
		sessionId: string,
		presentationId: string,
		useFormData: TypeUseFormListForms,
	): Promise<boolean> => {
		try {
			const presentation: DtoPresentationWrite =
				ConfigurationsApp.PresentationConfiguration.defaultModel;
			HelpersApp.PresentationHelper.mapUseFormToDtoPresentationWrite(
				useFormData,
				presentation,
			);
			return await Services.Events.Programme.PresentationsService.put(
				client.id,
				event.id,
				sessionId,
				presentationId,
				presentation,
			);
		} catch {
			return false;
		}
	};

	return { create, read, update };
};

const PresentationController = {
	clone,
	deleteEntity,
	deletePresentationSpeaker,
	getMappedPresentationViewModel,
	getSpeakerSessionDetailsFacultyModal,
	putOrderSpeakers,
	useEntity,
	update,
};

export default PresentationController;
