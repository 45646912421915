import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import PresentationConfiguration from "@application/Configurations/PresentationConfiguration";
import ContainerSpeakerModal from "@application/Containers/ContainerSpeakerModal";
import PresentationController from "@application/Controllers/PresentationController";
import {
	ActionReference,
	ConfirmationModal,
	DataGridOld,
	EnumActionsReference,
	getColumns,
	NoData,
	type PropsButtonIcon,
	statusInvitationParticipantObject,
	useContextModule,
	useSnackBarHook,
} from "@key4-front-library/core";
import type { PropsDataGridCellButtons } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import type { DtoFaculty, DtoPresentation } from "@key4-front-library/core/Dto";
import { EConfirmationModalAction } from "@key4-front-library/core/Enums/Modal";
import { Box, Stack } from "@mui/material";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";

type PropsPresentationDetails = {
	presentation: DtoPresentation;
	sessionId: string;
	speakers: Array<DtoFaculty>;
	updateSpeakers: () => void;
};

const PresentationItemDetails = (props: PropsPresentationDetails) => {
	const { presentation, sessionId, speakers = [], updateSpeakers } = props;
	const { id: presentationId } = presentation;

	const navigate = useNavigate();

	const { client, event } = useContextModule();
	const { sendError } = useSnackBarHook();

	const [currentSpeakerId, setCurrentSpeakerId] = useState<string>("");
	const [isDatagridLoading, setIsDatagridLoading] = useState<boolean>();

	const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
	const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
		useState<boolean>(false);

	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});

	const [speakersRows, setSpeakersRows] = useState<Array<GridValidRowModel>>(
		[],
	);

	useEffect(() => {
		const rows: Array<GridValidRowModel> = [];
		speakers.forEach((faculty: DtoFaculty) => {
			rows.push({
				id: faculty.participantId,
				faculty: [faculty.firstname, faculty.lastname].join(" "),
				type: faculty.tagTypes?.[0]?.tags?.[0]?.label ?? t("common:speaker"),
				status: {
					label: t(
						statusInvitationParticipantObject[faculty.invitationStatus].label,
					),
					iconColor:
						statusInvitationParticipantObject[faculty.invitationStatus].bgColor,
				},
				actionButtons: getActionButtons(faculty, speakers),
			});
		});
		setSpeakersRows(rows);
	}, [speakers, updateSpeakers]);
	const handleSpeakerEditModaleOpen = (id: string) => {
		setCurrentSpeakerId(id);
		setEditModalIsOpen(true);
	};

	const handleConfirmationModalOpen = () => {
		setConfirmationModalIsOpen(true);
	};

	const handleConfirmationModalClose = () => {
		setConfirmationModalIsOpen(false);
	};

	const handleSpeakerDelete = async (speakerId: string) => {
		setIsDatagridLoading(true);
		try {
			await PresentationController.deletePresentationSpeaker(
				client.id,
				event.id,
				sessionId,
				presentationId,
				speakerId,
			).then(() => {
				updateSpeakers();
			});
		} catch (error) {
			sendError(t("old.common.errors.generic"));
			setIsDatagridLoading(false);
		}

		setIsDatagridLoading(false);
	};

	const handleSpeakerMoveUp = (
		speakerId: string,
		speakers: Array<DtoFaculty>,
	) => handleSpeakerMove(speakerId, speakers, EnumActionsReference.UP);

	const handleSpeakerMoveDown = (
		speakerId: string,
		speakers: Array<DtoFaculty>,
	) => handleSpeakerMove(speakerId, speakers, EnumActionsReference.DOWN);

	const handleSpeakerMove = async (
		speakerId: string,
		speakersList: Array<DtoFaculty>,
		action: string,
	) => {
		const speakerIdIndex: number = speakersList
			.map((speaker) => speaker.participantId)
			.indexOf(speakerId);
		const speakersOrderedList: Array<DtoFaculty> = speakersList;
		const newSpeakerIndex =
			action === EnumActionsReference.UP
				? speakerIdIndex - 1
				: speakerIdIndex + 1;

		speakersOrderedList.splice(
			newSpeakerIndex,
			0,
			speakersOrderedList.splice(speakerIdIndex, 1)[0],
		);

		setIsDatagridLoading(true);

		try {
			await PresentationController.putOrderSpeakers(
				client.id,
				event.id,
				sessionId,
				presentationId,
				speakersOrderedList.map((speaker) => speaker.participantId),
			).then(() => {
				updateSpeakers();
			});
		} catch (error) {
			sendError(t("old.common.errors.generic"));
			setIsDatagridLoading(false);
		}
		setIsDatagridLoading(false);
	};

	const getActionButtons = (
		data: DtoFaculty,
		speakers: Array<DtoFaculty>,
	): PropsDataGridCellButtons => {
		const emptyButton: PropsButtonIcon = {
			disabled: true,
			tooltip: undefined,
			onClick: () => {},
		};

		const listButtons: PropsDataGridCellButtons = {
			listButtons: [
				{
					...ActionReference[EnumActionsReference.DETAILS],
					tooltip: t("old.programme.presentations.speakers.buttons.details"),
					onClick: () => {
						navigate(
							["", client.key, event.key, "faculties", data.participantId].join(
								"/",
							),
						);
					},
				},
				{
					...ActionReference[EnumActionsReference.EDIT],
					tooltip: t("old.programme.presentations.speakers.buttons.edit"),
					onClick: () => {
						handleSpeakerEditModaleOpen(data.participantId);
					},
				},
			],
		};

		if (
			speakers
				.map((speaker) => speaker.participantId)
				.indexOf(data.participantId) !== 0
		) {
			listButtons.listButtons.push({
				...ActionReference[EnumActionsReference.UP],
				tooltip: t("old.programme.presentations.speakers.buttons.moveUp"),
				onClick: () => {
					handleSpeakerMoveUp(data.participantId, speakers);
				},
			});

			if (
				speakers
					.map((speaker) => speaker.participantId)
					.indexOf(data.participantId) ===
					speakers.length - 1 &&
				speakers.length > 2
			) {
				listButtons.listButtons.push(emptyButton);
			}
		}

		if (
			speakers
				.map((speaker) => speaker.participantId)
				.indexOf(data.participantId) !==
			speakers.length - 1
		) {
			if (
				speakers
					.map((speaker) => speaker.participantId)
					.indexOf(data.participantId) === 0 &&
				speakers.length > 2
			) {
				listButtons.listButtons.push(emptyButton);
			}

			listButtons.listButtons.push({
				...ActionReference[EnumActionsReference.DOWN],
				tooltip: t("old.programme.presentations.speakers.buttons.moveDown"),
				onClick: () => {
					handleSpeakerMoveDown(data.participantId, speakers);
				},
			});
		}

		listButtons.listButtons.push({
			...ActionReference[EnumActionsReference.DELETE],
			tooltip: t("old.programme.presentations.speakers.buttons.delete"),
			onClick: () => {
				confirmationModalProps.current = {
					action: EConfirmationModalAction.DELETE,
					handleAction: () => {
						handleSpeakerDelete(data.participantId).then(() => {
							handleConfirmationModalClose();
						});
					},
				};
				handleConfirmationModalOpen();
			},
		});

		return listButtons;
	};

	const getTranslatedColumnLabels = (): Array<string> =>
		PresentationConfiguration.datagrid.columns.map((column) =>
			t(
				[
					"old",
					"programme",
					"sessionDetails",
					"datagrid",
					"headerLabels",
					column.field,
				].join("."),
			),
		);

	if (!speakers) {
		return <NoData />;
	}

	return (
		<Stack direction={"column"} spacing={1} m={0} p={0}>
			<Box component="span">
				<DataGridOld
					columns={getColumns(
						getTranslatedColumnLabels(),
						PresentationConfiguration.datagrid.columns,
					)}
					rows={speakersRows}
					isAutoHeight={true}
					isHideFooter
					isLoading={isDatagridLoading}
					sx={{ border: "none" }}
				/>
			</Box>

			<ContainerSpeakerModal
				isOpen={editModalIsOpen}
				changeIsOpen={setEditModalIsOpen}
				presentationId={presentationId}
				speakerId={currentSpeakerId}
				callbackSubmit={updateSpeakers}
				sessionId={sessionId}
			/>

			<ConfirmationModal
				open={confirmationModalIsOpen}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleConfirmationModalClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
		</Stack>
	);
};

export default PresentationItemDetails;
