import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import SessionTemplates from "@application/Configurations/sessionTemplates.configuration";
import ContainerSettingsSessionTemplatesModal from "@application/Containers/ContainerSettingsSessionTemplatesModal";
import HooksApp from "@application/hooks";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import {
	ActionReference,
	ConfirmationModal,
	DataGridOld,
	getColumns,
	useContextModule,
} from "@key4-front-library/core";
import type { PropsDataGridCellButtons } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import type { SessionTemplatesRead } from "@key4-front-library/core/Dto";
import { EnumActionsReference } from "@key4-front-library/core/Enums";
import { Stack } from "@mui/material";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";

const TabSettingsSessionTemplates = () => {
	const { client, event } = useContextModule();

	const [row, setRow] = useState<Array<GridValidRowModel>>([]);
	const [isOpenSessionTemplateModal, setIsOpenSessionTemplateModal] =
		useState<boolean>(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
		useState<boolean>(false);
	const { readListTagsType } = HooksApp.SessionHook.useEntity();
	const { data: tagsList } = readListTagsType();
	const [sessionTemplatesList, setSessionTemplatesList] = useState<
		Array<SessionTemplatesRead>
	>([]);
	const [sessionTemplateToUpdate, setSessionTemplateToUpdate] = useState<
		SessionTemplatesRead | undefined
	>();
	const [isLoadingGetSessionTemplate, setIsLoadingGetSessionTemplate] =
		useState<boolean>(false);

	const [primaryTagId, setPrimaryTagId] = useState<string>("");
	const [templateToDeleteId, setTemplateToDeleteId] = useState<
		string | undefined
	>();

	const { enqueueSnackbar } = useSnackbar();

	const getActionButtons = (data: any): PropsDataGridCellButtons => {
		const isPrimaryTagAssociatedToASessionTemplate = !!Object.values(
			sessionTemplatesList,
		).filter((sessionTemplate) => sessionTemplate.primaryTagId === data.id)
			.length;

		const actionButtons: PropsDataGridCellButtons = { listButtons: [] };

		if (isPrimaryTagAssociatedToASessionTemplate) {
			actionButtons.listButtons.push(
				{
					...ActionReference[EnumActionsReference.EDIT],
					onClick: () => void onClickEditRowButton(data.id),
				},
				{
					...ActionReference[EnumActionsReference.DELETE],
					onClick: () => {
						onClickDeleteRowButton(data.id);
					},
				},
			);
		} else {
			actionButtons.listButtons.push({
				...ActionReference[EnumActionsReference.ADD],
				onClick: () => {
					onClickAddTemplateButton(data.id);
				},
			});
		}

		return actionButtons;
	};

	const getSessionTemplatesList = async () => {
		const sessionTemplatesList =
			await ControllersBo.SessionTemplatesController.getSessionTemplatesList(
				client.id,
				event.id,
			);
		setSessionTemplatesList(sessionTemplatesList);
	};

	const getSessionTemplateToUpdate = (primaryTagId: string) => {
		setIsLoadingGetSessionTemplate(true);
		const sessionTemplateToUpdate = sessionTemplatesList.filter(
			(sessionTemplate) => {
				return sessionTemplate.primaryTagId == primaryTagId;
			},
		)[0];

		setIsLoadingGetSessionTemplate(false);

		return sessionTemplateToUpdate;
	};

	const getSessionPrimaryTagsList = async (): Promise<void> => {
		const primaryTags = tagsList?.[0];
		const sessionTemplateGridRows: Array<GridValidRowModel> = [];

		primaryTags?.tags?.map((primaryTag: any) => {
			sessionTemplateGridRows.push({
				id: primaryTag.id,
				primaryTag: {
					chip: {
						label: primaryTag.label,
						backgroundColor: primaryTag.backgroundColor,
						fontColor: primaryTag.fontColor,
						borderColor: primaryTag.borderColor,
					},
				},
				actionButtons: getActionButtons(primaryTag),
			});
		});

		setRow(sessionTemplateGridRows);
	};

	const getTranslatedColumnLabels = (): Array<string> => {
		const headersNames: Array<string> = [];
		SessionTemplates.datagridHeaders.forEach((session) =>
			headersNames.push(
				t(
					`old.programme.settings.sessionTemplates.datagrid.headerLabels.${session.field}`,
				),
			),
		);
		return headersNames;
	};

	const onClickAddTemplateButton = (templateId: string) => {
		setSessionTemplateToUpdate(undefined);
		setPrimaryTagId(templateId);
		setIsOpenSessionTemplateModal(true);
	};

	const onClickEditRowButton = async (primaryTagId: string) => {
		const sessionTemplateToUpdate = getSessionTemplateToUpdate(primaryTagId);
		setSessionTemplateToUpdate(sessionTemplateToUpdate);
		setPrimaryTagId(primaryTagId);
		setIsOpenSessionTemplateModal(true);
	};

	const onClickDeleteRowButton = (sessionTemplateId: string): void => {
		setTemplateToDeleteId(sessionTemplateId);
		setIsOpenConfirmationModal(true);
	};

	const handleModalClose = (reason?: string): void => {
		if (reason == "backdropClick") return;
		setIsOpenSessionTemplateModal(false);
	};

	const deleteTemplate = async () => {
		if (templateToDeleteId) {
			try {
				const sessionTemplateToDelete =
					getSessionTemplateToUpdate(templateToDeleteId);
				await ControllersBo.SessionTemplatesController.deleteEntity(
					client.id,
					event.id,
					sessionTemplateToDelete.id,
				);
				enqueueSnackbar(
					t(
						"old.programme.settings.sessionTemplates.modal.confirmation.delete.success",
					),
					{
						variant: "success",
					},
				);
			} catch {
				enqueueSnackbar(t("old.common.errors.generic"), {
					variant: "error",
				});
			}

			getSessionPrimaryTagsList();
			setIsOpenConfirmationModal(false);
			setTemplateToDeleteId(undefined);
		}
	};

	useEffect(() => {
		getSessionTemplatesList();
	}, [isOpenSessionTemplateModal, isOpenConfirmationModal]);

	useEffect(() => {
		getSessionPrimaryTagsList();
	}, [isOpenSessionTemplateModal, tagsList, sessionTemplatesList]);

	useEffect(() => {
		getSessionTemplatesList();
	}, []);

	return (
		<IconCard
			title={t("old.programme.settings.sessionTemplates.title")}
			children={
				<Stack spacing={3.5} sx={{ mt: -3 }}>
					<DataGridOld
						columns={getColumns(
							getTranslatedColumnLabels(),
							SessionTemplates.datagridHeaders,
						)}
						rows={row}
						isAutoHeight={true}
						isHideFooter={true}
					/>

					<ContainerSettingsSessionTemplatesModal
						isOpenModal={isOpenSessionTemplateModal}
						handleModalClose={handleModalClose}
						primaryTagId={primaryTagId}
						sessionTemplateToUpdate={sessionTemplateToUpdate}
						isLoadingGetSessionTemplate={isLoadingGetSessionTemplate}
					/>

					<ConfirmationModal
						open={isOpenConfirmationModal}
						text={t(
							"old.programme.settings.sessionTemplates.modal.confirmation.delete.message",
						)}
						action={EConfirmationModalAction.DELETE}
						handleModaleClose={() => {
							setIsOpenConfirmationModal(false);
							setTemplateToDeleteId(undefined);
						}}
						handleAction={deleteTemplate}
						maxWidth={"sm"}
					/>
				</Stack>
			}
		/>
	);
};

export default TabSettingsSessionTemplates;
