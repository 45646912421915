import { t } from "i18next";
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";

import {
	activityMatchDtoToUserInformationList,
	contactDtoListToUserInformationWithMetadataList,
} from "@application/Mappers";
import type { UserInformationWithMetadata } from "@application/Types";
import {
	type ActivityMatchDto,
	type DtoContact,
	escapeSieveString,
	ESieveOperator,
	queryFilters,
	queryStringPagination,
	Services,
	type TypeApiResponsePagined,
	useContextModule,
	UserInformationRadio,
} from "@key4-front-library/core";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

export interface MatchingContactSectionProps {
	matchingId?: string;
	userSelected?: UserInformationWithMetadata;
	setContactActivityCount: (count: number) => void;
	onUserSelectedChange: (user: UserInformationWithMetadata) => void;
	search: string;
}

export const MatchingContactSection = (props: MatchingContactSectionProps) => {
	const {
		matchingId,
		userSelected,
		setContactActivityCount,
		onUserSelectedChange,
		search,
	} = props;
	const component = "old.common.matchingContactSection";
	const { client, event } = useContextModule();
	const [contactsProposals, setContactsProposals] =
		useState<Array<UserInformationWithMetadata>>();

	const { data: contacts } = useQuery({
		queryKey: [
			"client",
			client.id,
			"community",
			event.communityId,
			"contacts",
			search,
			"page=1",
			"pageSize10",
		],
		queryFn: (): Promise<TypeApiResponsePagined<Array<DtoContact>>> =>
			Services.Operations.Registration.ContactsService.getListPagined(
				client.id,
				event.communityId ?? "",
				[
					...queryFilters(
						[
							["lastname", "firstname", "email"].join(ESieveOperator.OR_IN),
							ESieveOperator.CI_CONTAINS,
							escapeSieveString(search),
						].join(""),
					),
					...queryStringPagination({ page: 0, pageSize: 10 }),
				],
			),
		enabled: !!search,
		placeholderData: (previousData) => previousData,
	});

	const { data: contactActivity } = useQuery({
		queryKey: [
			"matching",
			client.id,
			"community",
			event.communityId,
			"contact",
			"proposal",
			matchingId,
		],
		queryFn: (): Promise<Array<ActivityMatchDto>> =>
			Services.Registration.Clients.Contacts.postMatching(
				client.id,
				event.communityId ?? "",
				[matchingId ?? ""],
			),
		enabled: !!matchingId && !!event.communityId,
	});

	useEffect(() => {
		if (search) {
			setContactsProposals(
				contactDtoListToUserInformationWithMetadataList(contacts?.data),
			);
		} else if (contactActivity?.[0]) {
			setContactsProposals(
				activityMatchDtoToUserInformationList(contactActivity[0], "contact"),
			);
			setContactActivityCount(contactActivity[0].matches.length);
		}
	}, [contactActivity, search, contacts?.data, setContactActivityCount]);

	return (
		<Stack spacing={1}>
			<Typography variant="h6" align={"center"}>
				{t(`${component}.title`)}
			</Typography>
			{(!contactsProposals || contactsProposals.length === 0) && (
				<Stack pt={2}>
					<Typography fontStyle={"italic"} align="center">
						{t(`${component}.notFound`)}
					</Typography>
				</Stack>
			)}
			{contactsProposals?.map((contactProposal) => (
				<UserInformationRadio
					key={uniqueId()}
					checked={contactProposal.id === userSelected?.id}
					onClick={() => {
						onUserSelectedChange(contactProposal);
					}}
					userInformation={contactProposal.user}
				/>
			))}
		</Stack>
	);
};
