import { Fragment } from "react";
import { FormProvider, useForm } from "react-hook-form";

import type {
	ISettingsAnomaliesHookFormValues,
	ISettingsAnomaliesObject,
} from "@domain/interfaces/settings.anomalies.interface";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";

import StatusAnomaliesRender from "./StatusAnomaliesRender";

type Props = {
	anomalies: ISettingsAnomaliesObject[];
	onModaleClose: () => void;
	defaulValues: any;
	onFormSubmit: any;
};
const StatusAnomaliesEditModale = (props: Props) => {
	const { anomalies, onModaleClose, defaulValues, onFormSubmit } = props;

	const formMethods = useForm<ISettingsAnomaliesHookFormValues>({
		defaultValues: { ...defaulValues },
	});

	const {
		handleSubmit: onSubmit,
		formState: { isSubmitting },
	} = formMethods;

	return (
		<Fragment>
			<FormProvider {...formMethods}>
				<StatusAnomaliesRender anomalies={anomalies} isEdit />
			</FormProvider>
			<ValidationFormButtons
				cancelButtonClick={onModaleClose}
				saveButtonClick={onSubmit(onFormSubmit)}
				disabledSaveButton={isSubmitting}
			/>
		</Fragment>
	);
};

export default StatusAnomaliesEditModale;
