import React from "react";

import AnomalyDescription from "@application/components/_common/Anomalies/AnomalyDescription";
import AnomalyDescriptionForm from "@application/components/settings/anomalies/Status/AnomalyDescriptionForm";
import GetTranslationsForSettingsAnomalies from "@application/helpers/translations/settings/GetTranslationsForSettingsAnomalies";
import type { ISettingsAnomaliesObject } from "@domain/interfaces/settings.anomalies.interface";
import { Alert, Card, CardContent, Grid, Stack, useTheme } from "@mui/material";

type Props = {
	anomalies: ISettingsAnomaliesObject[];
	isEdit?: boolean;
};

const StatusAnomaliesRender = (props: Props) => {
	const { anomalies, isEdit } = props;

	const theme = useTheme();
	const translations = GetTranslationsForSettingsAnomalies();

	return (
		<Grid container>
			{anomalies
				? anomalies.map((section, index) => {
						section.items.sort((a, b) => (a.title < b.title ? -1 : 0));
						return (
							<Grid key={index} item xs={12}>
								{section.items.length > 0 ? (
									<Card
										sx={{
											p: 3.75,
											my: 2,
											backgroundColor: theme.palette.background.default,
										}}
									>
										<CardContent>
											<Stack spacing={2}>
												{section?.items.map((item, index) => {
													return (
														<React.Fragment key={index}>
															{!isEdit && item.checked && (
																<AnomalyDescription item={item} index={index} />
															)}
															{isEdit && (
																<AnomalyDescriptionForm
																	item={item}
																	index={index}
																	isEdit
																/>
															)}
														</React.Fragment>
													);
												})}
											</Stack>
										</CardContent>
									</Card>
								) : (
									<Alert severity="warning">
										{translations.alert.noAnomaly}
									</Alert>
								)}
							</Grid>
						);
					})
				: ""}
		</Grid>
	);
};

export default StatusAnomaliesRender;
