import {
	type EAnomalies,
	EInvitationAnomalies,
	EPresentationAnomalies,
	ESessionAnomalies,
} from "@application/enum/Anomalies.enum";

interface IAnomaly {
	value: EAnomalies;
	label: string;
}

export const sessionAnomaliesObject: {
	[key in ESessionAnomalies]: IAnomaly;
} = {
	[ESessionAnomalies.SESSION_DUPLICATE_CODE]: {
		value: ESessionAnomalies.SESSION_DUPLICATE_CODE,
		label: "duplicateCode",
	},
	[ESessionAnomalies.SESSION_DUPLICATE_TITLE]: {
		value: ESessionAnomalies.SESSION_DUPLICATE_TITLE,
		label: "duplicateTitle",
	},
	[ESessionAnomalies.SESSION_EMPTY_TITLE]: {
		value: ESessionAnomalies.SESSION_EMPTY_TITLE,
		label: "emptyTitle",
	},
	[ESessionAnomalies.SESSION_EMPTY_PRIMARY_TAG]: {
		value: ESessionAnomalies.SESSION_EMPTY_PRIMARY_TAG,
		label: "emptyPrimaryTag",
	},
	[ESessionAnomalies.SESSION_WITHOUT_CHAIR]: {
		value: ESessionAnomalies.SESSION_WITHOUT_CHAIR,
		label: "withoutChair",
	},
	[ESessionAnomalies.SESSION_WITHOUT_PRESENTATION]: {
		value: ESessionAnomalies.SESSION_WITHOUT_PRESENTATION,
		label: "withoutPresentation",
	},
	[ESessionAnomalies.SESSION_EMPTY_CODE]: {
		value: ESessionAnomalies.SESSION_EMPTY_CODE,
		label: "emptyCode",
	},
	[ESessionAnomalies.SESSION_NOT_IN_READY_TO_PUBLISH_STATUS]: {
		value: ESessionAnomalies.SESSION_NOT_IN_READY_TO_PUBLISH_STATUS,
		label: "notInReadyToPublishStatus",
	},
	[ESessionAnomalies.SESSION_INVALID_LOCATION]: {
		value: ESessionAnomalies.SESSION_INVALID_LOCATION,
		label: "invalidLocation",
	},
	[ESessionAnomalies.SESSION_INVALID_SCHEDULE]: {
		value: ESessionAnomalies.SESSION_INVALID_SCHEDULE,
		label: "invalidSchedule",
	},
	[ESessionAnomalies.SESSION_DISCONTINUITY_IN_PRESENTATION_SCHEDULE]: {
		value: ESessionAnomalies.SESSION_DISCONTINUITY_IN_PRESENTATION_SCHEDULE,
		label: "discontinuityInPresentationSchedule",
	},
	[ESessionAnomalies.SESSION_EMPTY_PUBLICATION_DATE]: {
		value: ESessionAnomalies.SESSION_EMPTY_PUBLICATION_DATE,
		label: "emptyPublicationDate",
	},
};

export const presentationAnomaliesObject: {
	[key in EPresentationAnomalies]: IAnomaly;
} = {
	[EPresentationAnomalies.PRESENTATION_DUPLICATE_CODE]: {
		value: EPresentationAnomalies.PRESENTATION_DUPLICATE_CODE,
		label: "duplicateCode",
	},
	[EPresentationAnomalies.PRESENTATION_DUPLICATE_TITLE]: {
		value: EPresentationAnomalies.PRESENTATION_DUPLICATE_TITLE,
		label: "duplicateTitle",
	},
	[EPresentationAnomalies.PRESENTATION_EMPTY_TITLE]: {
		value: EPresentationAnomalies.PRESENTATION_EMPTY_TITLE,
		label: "emptyTitle",
	},
	[EPresentationAnomalies.PRESENTATION_WITHOUT_SPEAKER]: {
		value: EPresentationAnomalies.PRESENTATION_WITHOUT_SPEAKER,
		label: "withoutSpeaker",
	},
	[EPresentationAnomalies.PRESENTATION_EMPTY_CODE]: {
		value: EPresentationAnomalies.PRESENTATION_EMPTY_CODE,
		label: "emptyCode",
	},
	[EPresentationAnomalies.PRESENTATION_INVALID_SCHEDULE]: {
		value: EPresentationAnomalies.PRESENTATION_INVALID_SCHEDULE,
		label: "invalidSchedule",
	},
};

export const invitationAnomaliesObject: {
	[key in EInvitationAnomalies]: IAnomaly;
} = {
	[EInvitationAnomalies.INVITATION_NOT_IN_ACCEPTED_STATUS]: {
		value: EInvitationAnomalies.INVITATION_NOT_IN_ACCEPTED_STATUS,
		label: "notInAcceptedStatus",
	},
};
