import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import IssuesDefinitions from "@application/Configurations/issues.configuration";
import SessionController from "@application/Controllers/SessionController";
import type { ISessionsGrid } from "@domain/interfaces/sessions.interface";
import K4ListGrid, {
	GridDisplayFormatEnum,
} from "@infrastructure/components/interface/list/K4ListGrid";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import {
	ErrorAPI,
	ExternalRouting,
	type IK4Card,
	useContextModule,
} from "@key4-front-library/core";
import { usePaginationOld } from "@key4-front-library/core/Bo/Hooks";
import type {
	GridCellParams,
	GridRowParams,
	GridSortModel,
} from "@mui/x-data-grid-pro";

type Props = {
	queryBuilderString: string;
	search: string;
	isRefreshSessions: boolean;
	isWaitingForQueryBuilderChange: boolean;
	setRowsCount: (count: number) => void;
	onDoubleClick: (sessionId: string) => void;
};

const SessionsGrid = (props: Props & dataCypressProp) => {
	const DEFAULT_PAGE = 0;

	const {
		queryBuilderString,
		search,
		setRowsCount,
		onDoubleClick,
		isRefreshSessions,
	} = props;
	const { client, event } = useContextModule();
	const {
		page,
		pageSize,
		totalRowCount,
		handleChangePage,
		handleChangePageSize,
		setTotalRowCount,
	} = usePaginationOld();
	const navigate = useNavigate();
	const [error, setError] = useState<ErrorAPI | undefined>(undefined);

	const [sessions, setSessions] = useState<Array<ISessionsGrid>>([]);
	const [sessionsPrimaryTagLabel] = useState<string>(
		t("old.common.session.tabHeader.primaryTag"),
	);

	const [gridSortModelList, setGridSortModelList] = useState<GridSortModel>([]);

	const initComponent = useCallback(async () => {
		const sorts: Array<string> = [];

		gridSortModelList.forEach((criteria) => {
			sorts.push(`${criteria.sort === "asc" ? "" : "-"}${criteria.field}`);
			return true;
		});

		const sessionsData = await SessionController.getSessionsDatagrid(
			client.id,
			event.id,
			search,
			sorts ?? [],
			{ page, pageSize, totalCount: totalRowCount },
			queryBuilderString,
		);

		const { pagination } = sessionsData;

		if (sessionsData instanceof ErrorAPI) {
			setError(sessionsData);
			return;
		}

		if (pagination.totalCount) {
			setTotalRowCount(pagination.totalCount);
			setRowsCount(pagination.totalCount);
		}
		setSessions(sessionsData.data);
	}, [gridSortModelList, event, search, page, pageSize, queryBuilderString]);

	useEffect(() => {
		initComponent();
	}, [
		gridSortModelList,
		search,
		page,
		pageSize,
		queryBuilderString,
		isRefreshSessions,
		initComponent,
	]);

	useEffect(() => {
		handleChangePage(DEFAULT_PAGE);
	}, [search, queryBuilderString]);

	const handleChangeSortCriteria = (_gridSortModelList: GridSortModel) => {
		setGridSortModelList(_gridSortModelList);
	};

	const handleSessionClick = (clickedCell: GridCellParams): void => {
		if (clickedCell.field !== "key") {
			navigate(`./${clickedCell.row.id}`);
		}
	};

	if (error) {
		throw error;
	}

	return (
		<K4ListGrid
			page={page}
			pageSize={pageSize}
			totalRowCount={totalRowCount}
			handleChangePage={handleChangePage}
			handleChangePageSize={handleChangePageSize}
			handleSingleClick={handleSessionClick}
			handleDoubleClick={(session: GridRowParams<IK4Card>) => {
				onDoubleClick(session.id.toString());
			}}
			isDoubleClick={true}
			data={sessions}
			handleChangeSortCriteria={handleChangeSortCriteria}
			schemeAndColumnTitles={{
				id: {
					headerName: "Id",
					reference: "id",
				},
				key: {
					headerName: t("old.common.session.tabHeader.key"),
					reference: "key",
					minWidth: 25,
					maxWidth: 80,
					displayFormat: {
						value: GridDisplayFormatEnum.LINK,
						to: `${ExternalRouting.Programme}/${client.key}/${event.key}/sessions`,
					},
				},
				code: {
					headerName: "Code",
					reference: "code",
					maxWidth: 80,
				},
				primaryTag: {
					headerName: sessionsPrimaryTagLabel,
					reference: "primaryTag",
					displayFormat: {
						value: GridDisplayFormatEnum.TAG,
					},
					minWidth: 280,
				},
				title: {
					headerName: t("old.common.session.tabHeader.title"),
					reference: "title",
					minWidth: 500,
				},
				dates: {
					headerName: t("old.common.session.tabHeader.dates"),
					reference: "dates",
					minWidth: 260,
				},
				room: {
					headerName: t("old.common.session.tabHeader.rooms"),
					reference: "room",
					minWidth: 100,
				},
				hasAnomalies: {
					headerName: t("old.common.session.tabHeader.anomalies"),
					reference: "anomalies",
					displayFormat: {
						value: GridDisplayFormatEnum.ICON,
						iconName: IssuesDefinitions.anomalies.icon,
						color: IssuesDefinitions.anomalies.themeColor.main,
					},
					maxWidth: 100,
				},
				hasClashes: {
					headerName: t("old.common.session.tabHeader.clashes"),
					reference: "clashes",
					displayFormat: {
						value: GridDisplayFormatEnum.ICON,
						iconName: IssuesDefinitions.clashes.icon,
						color: IssuesDefinitions.clashes.themeColor.main,
					},
					maxWidth: 100,
				},
				status: {
					headerName: t("old.common.session.tabHeader.sessionStatus"),
					displayFormat: {
						value: GridDisplayFormatEnum.SESSIONSTATUS,
					},
					maxWidth: 150,
				},
			}}
		/>
	);
};

export default SessionsGrid;
