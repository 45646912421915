import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import StringService from "@infrastructure/services/string/string.service";
import { Stack } from "@mui/material";

type DataGridHeaderProps = {
	field: string;
	headerName?: string;
};

const DataGridHeader = (props: DataGridHeaderProps & dataCypressProp) => {
	const { field, headerName, dataCypressID } = props;

	return (
		<Stack
			className="MuiDataGrid-columnHeaderTitle"
			data-cy={`${dataCypressID}-header-${StringService.transformCamelToSnake(field)}`}
		>
			{headerName}
		</Stack>
	);
};

export default DataGridHeader;
