import { forIn, trim } from "lodash";

import type {
	TypeCustomFieldValue,
	TypeCustomFieldValues,
	TypeUseFormCustomFieldsValues,
	TypeUseFormCustomFieldValue,
} from "@key4-front-library/core";
import { EnumCustomFieldKind } from "@key4-front-library/core/Enums";

export const remapCustomFieldUseformToModel = (
	customFields?: TypeUseFormCustomFieldsValues | null,
): TypeCustomFieldValues => {
	const cfValues: TypeCustomFieldValues = {};
	if (customFields) {
		forIn(
			customFields,
			(customField: TypeUseFormCustomFieldValue, key: string) => {
				if (!customFields || !cfValues) return;
				const cfValue: TypeCustomFieldValue = customField.customDefault;

				let val: string | Array<string> | null | undefined | boolean =
					typeof customField.value === "string" &&
					trim(customField.value) === ""
						? null
						: customField.value;
				if (customField.kind === EnumCustomFieldKind.RADIO) {
					if (typeof val === "string") val = [val];
					else val = [];
				}

				if (cfValue.isCustomized) {
					cfValue.customizedValue = val;
				} else {
					cfValue.value = val;
				}
				cfValues[key] = cfValue;
			},
		);
	}

	return cfValues;
};
