import { t } from "i18next";

import ConfigurationsApp from "@application/Configurations";
import {
	ESessionTagField,
	type ISessionSecondaryTagsBulkAction,
} from "@domain/interfaces/session.interface";
import FormHelper from "@infrastructure/helpers/form.helper";
import {
	EBulkActionEntity,
	EField,
	ErrorAPI,
	queryFilters,
	queryStringSorts,
	Services,
	type TPickField,
	type TPickForm,
	type TPickFormSection,
} from "@key4-front-library/core";
import type { TAutoCompleteBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteBulletColor";
import type { PropsFormControlAutocompleteSubLabel } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteSubLabel";
import type { PropsFormControlSelectChipColor } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelectChipColor";
import type {
	DtoCustomFieldFormV01,
	DtoCustomFieldSectionV01,
	DtoCustomFieldV01,
} from "@key4-front-library/core/Dto";
import {
	EnumCustomFieldDateKind,
	EnumCustomFieldKind,
	EnumCustomFieldScope,
	ESieveOperator,
} from "@key4-front-library/core/Enums";

import RoomController from "./RoomController";
import SessionController from "./SessionController";

/**
 * @deprecated
 */
const getForms = async (
	clientId: string,
	eventId: string,
	entityType: EBulkActionEntity,
): Promise<Array<TPickForm> | ErrorAPI> => {
	// try {
	let entity = "";
	let fullForms: Array<TPickForm> = [];

	// Get static fields from an entity
	switch (entityType) {
		case EBulkActionEntity.SESSION:
		default:
			entity = EnumCustomFieldScope.SESSION;
			const rooms = await RoomController.getRoomListBulkAction(
				clientId,
				eventId,
				t,
			);

			const tagsType = await SessionController.getTagsTypeBulkAction(
				clientId,
				eventId,
			);

			if (rooms instanceof ErrorAPI) return rooms;
			if (tagsType instanceof ErrorAPI) return tagsType;

			// Remap to get Trads for forms, sections and fields
			fullForms = [
				...ConfigurationsApp.SessionConfiguration.pickFormsStatic.map(
					(form: TPickForm) => {
						return {
							key: form.key,
							label: t(`old.common.session.forms.${form.key}`),
							sections: form.sections.map((section: TPickFormSection) => {
								return {
									...section,
									label: t(`old.common.session.formSections.${section.key}`),
									fields: section.fields
										// Remove primaryTagId field if primaryTag is not set (can't be done in a map)
										.filter(
											(field) =>
												!(field.key === "primaryTag" && !tagsType.primaryTag),
										)
										// add Trads with this mapping
										.map((field: TPickField) => {
											const label = t(
												`old.common.session.formFields.labels.${field.key}`,
											);
											switch (field.kind) {
												case EField.TEXT:
													return {
														...field,
														text: {
															...field.text,
															label,
														},
													};
												case EField.TEXT_AREA:
													return {
														...field,
														textArea: {
															...field.textArea,
															label,
														},
													};
												case EField.CHECKBOX:
													return {
														...field,
														checkbox: {
															...field.checkbox,
															label,
														},
													};
												case EField.DATE_PICKER:
													return {
														...field,
														datePicker: {
															...field.datePicker,
															label,
														},
													};
												case EField.TIME_PICKER:
													return {
														...field,
														timePicker: {
															...field.timePicker,
															label,
														},
													};
												case EField.DATE_TIME_PICKER:
													return {
														...field,
														dateTimePicker: {
															...field.dateTimePicker,
															label,
														},
													};
												case EField.AUTO_COMPLETE:
													return {
														...field,
														autoComplete: {
															...field.autoComplete,
															label,
														},
													};

												case EField.NUMERIC:
													return {
														...field,
														numeric: {
															...field.numeric,
															label,
														},
													};
												case EField.AUTO_COMPLETE_SUB_LABEL:
													const autoCompleteSubLabel: PropsFormControlAutocompleteSubLabel =
														{
															...field.autoCompleteSubLabel,
															label,
														};
													// Add room list items
													if (field.key === "roomId") {
														autoCompleteSubLabel.items = rooms;
													}
													return {
														...field,
														autoCompleteSubLabel,
													};
												case EField.AUTO_COMPLETE_BULLET_COLOR:
													return {
														...field,
														autoCompleteBulletColor: {
															...field.autoCompleteBulletColor,
															label,
															items: field.autoCompleteBulletColor.items.map(
																(option: TAutoCompleteBulletColorItem) => {
																	return {
																		...option,
																		label: t(option.label),
																	};
																},
															),
														},
													};
												case EField.SELECT_CHIP_COLOR:
													let selectChipColor: PropsFormControlSelectChipColor =
														{
															...field.selectChipColor,
															label,
														};
													let ids: Array<string> = [];
													// Add primary tag
													// TODO D1 : primary tag
													if (
														field.key === "primaryTag" &&
														tagsType.primaryTag
													) {
														ids = tagsType.primaryTag.ids;
														selectChipColor =
															tagsType.primaryTag.selectChipColor;
													}
													return {
														...field,
														ids,
														selectChipColor,
													};
												case EField.AUTOCOMPLETE_MULTI_CHIP_COLOR:
													return {
														...field,
														labelField: field.autocompleteMultiChipColor.label,
														autocompleteMultiChipColor: {
															...field.autocompleteMultiChipColor,
															label,
														},
													};
												case EField.NOT_IMPLEMENTED:
												default:
													return {
														...field,
														[field.kind]: {
															label,
														},
													};
											}
										}),
								};
							}),
						};
					},
				),
			];

			// ! On form static change check if tags are not broken or at wrong position
			const secondaryTags: Array<TPickField> = tagsType.secondaryTags.map(
				(tag: ISessionSecondaryTagsBulkAction) => {
					if (tag.kind === ESessionTagField.MULTISELECT) {
						return {
							...FormHelper.baseField.pick.autocompleteMultiChipColor,
							ids: tag.ids,
							key: `mf__tags__${tag.id}`,
							isMultiSelect: true,
							labelField: tag.autocompleteMultiChipColor.label,
							autocompleteMultiChipColor: tag.autocompleteMultiChipColor,
						};
					}
					return {
						...FormHelper.baseField.pick.selectChipColor,
						ids: tag.ids,
						key: `mf__tags__${tag.id}`,
						selectChipColor: tag.selectChipColor,
					};
				},
			);
			fullForms[2].sections[0].fields =
				fullForms[2].sections[0].fields.concat(secondaryTags);
			break;
	}

	const customFields =
		await Services.Operations.CustomFields.FormsService.getListV01(
			clientId,
			eventId,
			[
				...queryFilters(`scope${ESieveOperator.EQUALS}${entity}`),
				...queryStringSorts(["order"]),
			],
		);
	// Merge static field and custom field
	customFields.forEach((formCustomField: DtoCustomFieldFormV01) => {
		// Remap Form customfield
		// REMOVE fields which are readonly
		const formMapped: TPickForm = {
			key: formCustomField.key ?? "",
			label: formCustomField.label ?? "",
			sections: formCustomField.sections
				? formCustomField.sections.map((section: DtoCustomFieldSectionV01) => {
						return {
							key: section.key ?? "",
							label: section.label ?? "",
							fields: section.fields
								? section.fields
										.filter((field) => field.isReadOnly !== true)
										.map((field: DtoCustomFieldV01) => {
											switch (field.kind) {
												case EnumCustomFieldKind.TEXT:
													return {
														kind: EField.TEXT,
														isStatic: false,
														key: field.id,
														text: {
															label: field.label ?? "",
															tooltip: field.tooltip ?? undefined,
															placeholder: field.placeholder ?? undefined,
															legend: field.legend ?? undefined,
															id: field.id,
															type: "text",
														},
													};
												case EnumCustomFieldKind.TEXTAREA:
													return {
														kind: EField.TEXT_AREA,
														isStatic: false,
														key: field.id,
														textArea: {
															label: field.label ?? "",
															tooltip: field.tooltip ?? undefined,
															placeholder: field.placeholder ?? undefined,
															legend: field.legend ?? undefined,
															id: field.id,
															minRows: field.rows,
															maxRows: field.rows,
														},
													};
												case EnumCustomFieldKind.CHECKBOX:
													return {
														kind: EField.CHECKBOX,
														isStatic: false,
														key: field.id,
														checkbox: {
															label: field.label ?? "",
															tooltip: field.tooltip,
															legend: field.legend,
															id: field.id,
														},
													};
												case EnumCustomFieldKind.DATE:
													switch (field.dateKind) {
														case EnumCustomFieldDateKind.TIMEONLY:
															return {
																kind: EField.TIME_PICKER,
																isStatic: false,
																key: field.id,
																timePicker: {
																	label: field.label ?? "",
																	tooltip: field.tooltip ?? undefined,
																	legend: field.legend ?? undefined,
																	id: field.id,
																},
															};
														case EnumCustomFieldDateKind.DATETIME:
															return {
																kind: EField.DATE_TIME_PICKER,
																isStatic: false,
																key: field.id,
																dateTimePicker: {
																	label: field.label ?? "",
																	tooltip: field.tooltip ?? undefined,
																	legend: field.legend ?? undefined,
																	id: field.id,
																},
															};
														case EnumCustomFieldDateKind.DATEONLY:
														default:
															return {
																kind: EField.DATE_PICKER,
																isStatic: false,
																key: field.id,
																datePicker: {
																	label: field.label ?? "",
																	tooltip: field.tooltip ?? undefined,
																	legend: field.legend ?? undefined,
																	id: field.id,
																},
															};
													}
												case EnumCustomFieldKind.SELECT:
													return {
														kind: EField.AUTO_COMPLETE,
														isStatic: false,
														key: field.id,
														autoComplete: {
															label: field.label ?? "",
															tooltip: field.tooltip ?? undefined,
															legend: field.legend ?? undefined,
															items: field.items.map((item: any) => {
																return {
																	key: item.id,
																	label: item.label,
																};
															}),
														},
													};
												// TODO to do
												case EnumCustomFieldKind.RADIO:
													return {
														kind: EField.AUTO_COMPLETE,
														isStatic: false,
														key: "",
														autoComplete: {
															label: "",
															tooltip: "",
															legend: "",
															items: [],
														},
													};
												// case EnumCustomFieldKind.NUMERIC:
												//     return {
												//         kind: EField.NUMERIC,
												//         isStatic: false,
												//         key: field.id,
												//         numeric: {
												//             type: 'number',
												//             label:
												//                 field.label ??
												//                 '',
												//             tooltip:
												//                 field.tooltip ??
												//                 undefined,
												//             placeholder:
												//                 field.placeholder ??
												//                 undefined,
												//             legend:
												//                 field.legend ??
												//                 undefined,
												//             id: field.id,
												//             min: field.lowerBound,
												//             max: field.upperBound
												//         }
												//     };
												default:
													return {
														kind: EField.NOT_IMPLEMENTED,
														isStatic: false,
														key: field.id,
													};
											}
										})
								: [],
						};
					})
				: [],
		};

		// Search if form already exist
		const index = fullForms.findIndex(
			(form: TPickForm) => form.key === formMapped.key,
		);
		if (index !== -1) {
			formMapped.sections.forEach((section: TPickFormSection) => {
				// Search if section already exist
				const indexSection = fullForms[index].sections.findIndex(
					(section2: TPickFormSection) => section2.key === section.key,
				);
				if (indexSection !== -1) {
					fullForms[index].sections[indexSection].fields.push(
						...[...section.fields],
					);
				} else {
					fullForms[index].sections.push(section);
				}
			});
		} else {
			fullForms.push(formMapped);
		}
	});

	return Promise.resolve(fullForms);
	// } catch (e: any) {
	//     return Promise.reject(new ErrorAPI(e.status, e.message));
	// }
};

const CustomFieldController = {
	getForms,
};

export default CustomFieldController;
