import { t } from "i18next";
import { Duration } from "luxon";
import { Fragment, useEffect, useState } from "react";

import type {
	ISessionSchedule,
	TSessionDisplay,
} from "@domain/interfaces/session.interface";
import IconCardItem from "@infrastructure/components/interface/card/IconCardItem";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { CustomFieldsDisplayer } from "@key4-front-library/core";
import { Grid } from "@mui/material";

type Props = {
	schedule: ISessionSchedule;
	session: TSessionDisplay;
};
const ScheduleView = (props: Props) => {
	const { schedule, session } = props;

	const [duration, setDuration] = useState<string | null>(null);

	useEffect(() => {
		setDuration(
			session.duration
				? Duration.fromISO(session.duration).toFormat("hh:mm")
				: null,
		);
		if (
			session.endDate === session.startDate &&
			!session.endHour &&
			!session.startHour &&
			session.endDate
		) {
			setDuration(null);
		}
	}, [session]);

	return (
		<IconCard
			title={t("old.programme.sessionDetails.schedule.label")}
			icon={"calendar-days"}
			children={
				<Grid container spacing={2}>
					{session.isWholeProgramme ? (
						<Fragment>
							<Grid container item spacing={3}>
								<IconCardItem
									label={t("old.programme.sessionDetails.schedule.date")}
									value={t(
										"old.programme.sessionDetails.schedule.isWholeCongress",
									)}
								/>
							</Grid>
						</Fragment>
					) : (
						<Fragment>
							<Grid container item spacing={3}>
								{schedule.startDate && (
									<IconCardItem
										label={t("old.programme.sessionDetails.schedule.startDate")}
										value={schedule.startDate}
									/>
								)}

								{!session.isFullDay && schedule.startTime && (
									<IconCardItem
										label={t("old.programme.sessionDetails.schedule.startTime")}
										value={schedule.startTime}
									/>
								)}
							</Grid>

							<Grid container item spacing={3}>
								{schedule.endDate && (
									<IconCardItem
										label={t("old.programme.sessionDetails.schedule.endDate")}
										value={schedule.endDate}
									/>
								)}
								{!session.isFullDay && schedule.endTime && (
									<IconCardItem
										label={t("old.programme.sessionDetails.schedule.endTime")}
										value={schedule.endTime}
									/>
								)}

								{duration && (
									<IconCardItem
										label={t(
											"old.programme.sessionDetails.schedule.programmeDuration",
										)}
										value={duration}
									/>
								)}
							</Grid>
						</Fragment>
					)}
					<Grid item xs={12}>
						<CustomFieldsDisplayer
							formOrder={1}
							sectionOrder={1}
							values={session.customFieldValues}
						/>
					</Grid>
				</Grid>
			}
		/>
	);
};

export default ScheduleView;
