import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";

import IndicatorsDefinitions from "@application/Configurations/indicators.configuration";
import type { IClashesObject } from "@domain/interfaces/session.clashes.interface";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import K4Card from "@infrastructure/components/interface/card/K4Card";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import type { IMenuItems } from "@infrastructure/model/interfaces/menuItems.interface";
import {
	ApplicationColors,
	type IK4Card,
	useContextModule,
} from "@key4-front-library/core";
import {
	Card,
	CardContent,
	CardHeader,
	Chip,
	Stack,
	TextField,
	Tooltip,
	useMediaQuery,
	useTheme,
} from "@mui/material";

type Props = {
	clashes: IClashesObject;
	translations: any;
	handleClashesUpdate: (
		clientId: string,
		eventId: string,
		clashId: string,
		comments: string,
		isIgnored: boolean,
		snackbarMessage: string,
	) => void;
	menuItems: IMenuItems;
	handleCardClick: (data: IK4Card) => void;
};

const ClashesRender = (props: Props & dataCypressProp) => {
	const {
		clashes,
		translations,
		handleClashesUpdate,
		menuItems,
		handleCardClick,
	} = props;

	const theme = useTheme();
	const { client, event } = useContextModule();
	const mobileSize = useMediaQuery(theme.breakpoints.down("lg"));

	const formMethods = useForm<{ comments: string }>({
		defaultValues: {
			comments: clashes.comments,
		},
	});
	const { handleSubmit: onSubmit, control } = formMethods;

	const handleSubmitIsIgnored = (_isIgnored: boolean) => {
		handleClashesUpdate(
			client.id,
			event.id,
			clashes.id,
			clashes.comments,
			_isIgnored,
			_isIgnored
				? translations.returnMessages.desactivated_clash
				: translations.returnMessages.activated_clash,
		);
	};

	const handleSubmitComments = ({ comments }: { comments: string }) => {
		handleClashesUpdate(
			client.id,
			event.id,
			clashes.id,
			comments,
			!!clashes.ignoredDate,
			translations.returnMessages.success_comments,
		);
	};

	return (
		<Card sx={{ backgroundColor: clashes.backgroundColor }}>
			<Stack spacing={1}>
				<CardHeader
					avatar={
						<Icon
							prefix="fal"
							iconName={IndicatorsDefinitions.clashes.icon as IconName}
							size={"2x"}
							color={clashes.iconColorClash}
						/>
					}
					action={
						clashes.ignoredDate ? (
							<Tooltip title={t("old.common.session_type.show")}>
								<Chip
									label={
										<Icon
											prefix="fal"
											iconName={"eye-slash"}
											size={"1x"}
											color={clashes.iconColorClash}
										/>
									}
									variant="outlined"
									onClick={() => {
										handleSubmitIsIgnored(false);
									}}
									sx={{
										border: `solid 1px ${clashes.iconColorClash}`,
									}}
								/>
							</Tooltip>
						) : (
							<Tooltip title={t("old.common.session_type.hide")}>
								<Chip
									label={
										<Icon
											prefix="fal"
											iconName={"eye"}
											size={"1x"}
											color={ApplicationColors.white.main}
										/>
									}
									onClick={() => {
										handleSubmitIsIgnored(true);
									}}
									sx={{
										backgroundColor: clashes.iconColorClash,
									}}
								/>
							</Tooltip>
						)
					}
					title={clashes.title}
				/>

				<CardContent>
					<Stack spacing={2}>
						<Stack spacing={2} direction={mobileSize ? "column" : "row"}>
							<K4Card
								data={clashes.entity1}
								sx={{ width: "100%", height: "auto" }}
								menuItems={menuItems}
								handleCardSingleClick={handleCardClick}
							/>
							<Stack
								alignItems={"center"}
								direction={mobileSize ? "column" : "row"}
							>
								<Icon
									prefix="far"
									iconName={"brake-warning"}
									size={"3x"}
									color={clashes.iconColorClash}
								/>
							</Stack>
							<K4Card
								data={clashes.entity2}
								sx={{ width: "100%", height: "auto" }}
								menuItems={menuItems}
								handleCardSingleClick={handleCardClick}
							/>
						</Stack>
						<Stack spacing={1} alignItems={"center"} direction={"row"}>
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										fullWidth
										placeholder={translations.comments}
										sx={{
											backgroundColor: ApplicationColors.white.main,
										}}
										multiline
										maxRows={4}
									/>
								)}
								name="comments"
								control={control}
							/>
							<Tooltip title={t("old.common.session_type.saveComment")}>
								<Chip
									label={
										<Icon
											prefix="far"
											iconName={"check"}
											size={"1x"}
											color={ApplicationColors.white.main}
										/>
									}
									onClick={onSubmit(handleSubmitComments)}
									sx={{
										backgroundColor: clashes.iconColorClash,
									}}
								/>
							</Tooltip>
						</Stack>
					</Stack>
				</CardContent>
			</Stack>
		</Card>
	);
};

export default ClashesRender;
