import { t } from "i18next";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import type { ISettingsSchedulerObject } from "@domain/interfaces/settings.scheduler.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import {
	DatePicker,
	TimePicker,
	useContextModule,
} from "@key4-front-library/core";
import { Box, Grid } from "@mui/material";

export type ISettingsSchedulerEditType = {
	startDate: string | null;
	endDate: string | null;
	startTime: DateTime | null;
	endTime: DateTime | null;
};

type Props = {
	settings: ISettingsSchedulerObject;
	culture: string;
	translations: any;
	onSave: (data: ISettingsSchedulerEditType) => void;
	handleModaleClose: () => void;
};

const SettingsSchedulerEdit = (props: Props) => {
	const { settings, translations, onSave, handleModaleClose } = props;
	const { event } = useContextModule();

	const formLabels = {
		startDate: translations.scheduler.settings.startDate,
		endDate: translations.scheduler.settings.endDate,
		startTime: translations.scheduler.settings.startTime,
		endTime: translations.scheduler.settings.endTime,
	};

	const dateValidation = yup
		.string()
		.nullable()
		.required(t("old.common.formControl.error.required") ?? "")
		.not(
			["Invalid DateTime"],
			t("old.common.formControl.error.datePicker.invalid") ?? "",
		)
		.test(
			"dateRangeTest",
			t("old.programme.settings.scheduler.error.date") ?? "",
			function (_) {
				const { endDate, startDate } = this.parent;
				if (
					startDate === "Invalid DateTime" ||
					endDate === "Invalid DateTime" ||
					!startDate ||
					!endDate
				)
					return true;
				return (
					DateTime.fromFormat(startDate, "yyyy-MM-dd") <=
					DateTime.fromFormat(endDate, "yyyy-MM-dd")
				);
			},
		);

	const timeValidation = yup
		.object()
		.nullable()
		.required(t("old.common.formControl.error.required") ?? "")
		.test(
			"timeValid",
			t("old.common.formControl.error.timePicker.invalid") ?? "",
			(time: any) => {
				if (!time) return true;
				return time.invalid === null;
			},
		)
		.test(
			"timeRangeTest",
			t("old.programme.settings.scheduler.error.time") ?? "",
			function (_) {
				const { endTime, startTime } = this.parent;
				if (
					!startTime ||
					!endTime ||
					startTime.invalid !== null ||
					endTime.invalid !== null
				)
					return true;

				return startTime < endTime;
			},
		);

	const validationForm: any = {
		startDate: dateValidation,
		endDate: dateValidation,
		startTime: timeValidation,
		endTime: timeValidation,
	};

	const {
		handleSubmit: onSubmit,
		control,
		formState: { isSubmitting, isValid, errors },
		getValues,
	} = useForm<any>({
		mode: "all",
		defaultValues: {
			startDate: settings.dates.startDate
				? settings.dates.startDate.toFormat("yyyy-MM-dd")
				: null,
			endDate: settings.dates.endDate
				? settings.dates.endDate.toFormat("yyyy-MM-dd")
				: null,
			startTime: settings.time.startTime ? settings.time.startTime : null,
			endTime: settings.time.endTime ? settings.time.endTime : null,
		},
		resolver: yupResolver(yup.object(validationForm)),
	});

	const handleSubmit = (form: any) => {
		onSave(form);
		handleModaleClose();
	};

	return (
		<Box component="form">
			<Grid container spacing={2} mt={1}>
				<Grid item xs={12} lg={6} sm={6}>
					<Controller
						name="startDate"
						control={control}
						render={({
							field: { value, onChange },
							fieldState: { invalid, error },
						}) => (
							<DatePicker
								required={true}
								onChange={onChange}
								label={formLabels.startDate}
								error={invalid && error ? error.message : undefined}
								value={value}
								onClearClick={() => {
									onChange(null);
								}}
								minDate={DateTime.fromISO(event.startDate).minus({ months: 2 })}
								maxDate={DateTime.fromISO(event.startDate).plus({ months: 2 })}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} lg={6} sm={6}>
					<Controller
						name="endDate"
						control={control}
						render={({ field: { onChange, value } }) => (
							<DatePicker
								required={true}
								onChange={onChange}
								label={formLabels.endDate}
								error={errors.endDate?.message?.toString()}
								value={value}
								defaultCalendarMonth={
									DateTime.fromISO(getValues("startDate")) ?? null
								}
								onClearClick={() => {
									onChange(null);
								}}
								minDate={DateTime.fromISO(event.endDate).minus({ months: 2 })}
								maxDate={DateTime.fromISO(event.endDate).plus({ months: 2 })}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} lg={6} sm={6}>
					<Controller
						name="startTime"
						control={control}
						render={({ field: { value, onChange } }) => (
							<TimePicker
								required={true}
								onChange={onChange}
								label={formLabels.startTime}
								error={errors.startTime?.message?.toString()}
								value={value}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} lg={6} sm={6}>
					<Controller
						name="endTime"
						control={control}
						render={({ field: { value, onChange } }) => (
							<TimePicker
								required={true}
								onChange={onChange}
								label={formLabels.endTime}
								error={errors.endTime?.message?.toString()}
								value={value}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<ValidationFormButtons
						cancelButtonClick={handleModaleClose}
						saveButtonClick={onSubmit(handleSubmit)}
						disabledSaveButton={isSubmitting || !isValid}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SettingsSchedulerEdit;
