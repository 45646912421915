import { t } from "i18next";
import { toPairs } from "lodash";
import type { FieldValues, UseFormSetValue } from "react-hook-form";
import * as yup from "yup";

import ConfigurationsApp from "@application/Configurations";
import {
	EnumProgrammeExportDynamicFieldKey,
	EnumProgrammeExportDynamicFormKey,
	EnumProgrammeExportDynamicSectionKey,
	EnumProgrammeExportSectionMoveDirection,
	EnumProgrammeExportStaticFieldKey,
	EnumProgrammeExportStaticFormKey,
	EnumProgrammeExportStaticSectionKey,
} from "@application/Enums/ProgrammeExportEnum";
import type {
	TypeProgrammeExportFieldData,
	TypeProgrammeExportSection,
	TypeProgrammeExportTabsDefinition,
} from "@application/Types/ProgrammeExportType";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	ActionReference,
	EnumFormControlKind,
	FILENAME_REGEX,
	URL_REGEX,
} from "@key4-front-library/core";
import type { PropsDataGridCellButtons } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import type {
	DtoCustomFieldSection,
	DtoDocument,
	DtoProgrammeExport,
	DtoProgrammeExportDocumentConfiguration,
	DtoProgrammeExportSection,
} from "@key4-front-library/core/Dto";
import {
	EnumActionsReference,
	EnumCustomFieldDisplayScope,
	EnumCustomFieldScope,
	type EnumProgrammeExportFormat,
	EnumProgrammeExportSessionGroupBy,
} from "@key4-front-library/core/Enums";
import type {
	TypeCustomFieldForm,
	TypeCustomFieldSection,
	TypeUseFormListForms,
} from "@key4-front-library/core/Types";

enum FormsIndex {
	Static = 0,
	Dynamic = 1,
}

enum PairsIndex {
	Key = 0,
	Value = 1,
}

enum ComponentDataFormIndex {
	ComponentData = 0,
}

/**
 * Get Component Data Structure for ProgrammeExportTabs components
 * @typedef TypeProgrammeExportTabsDefinition Type of ProgrammeExportTabs definition
 * @returns Structure
 */
const getComponentDataStructure = (
	document: DtoDocument,
): TypeProgrammeExportTabsDefinition => {
	const { configuration: documentConfiguration } = document;
	const translationNameSpace = "old.programme.programmeExport";

	const configuration =
		typeof documentConfiguration === "string"
			? mapDtoDocumentContigurationToDtoProgrammeExportConfiguration(
					documentConfiguration,
				)
			: documentConfiguration;

	const translationBaseKey: string = [
		translationNameSpace,
		"tabs",
		"label",
	].join(".");

	const dynamicSections: Array<TypeProgrammeExportSection> = [];

	if (typeof configuration === "object") {
		configuration!.sections.forEach((section, index) => {
			const id = getDynamicSectionKey(index);
			dynamicSections.push({
				id,
				label: section.name || t([translationBaseKey, "newSection"].join(".")),
				formId: [
					EnumProgrammeExportDynamicFormKey.DocumentSectionsForm,
					id,
				].join("."),
				formControls: [],
				canEdit: true,
			});
		});
	}

	return {
		staticsTabs: [
			{
				label: EnumProgrammeExportStaticFormKey.GeneralInformationForm,
				key: EnumProgrammeExportStaticFormKey.GeneralInformationForm,
				formId: EnumProgrammeExportStaticFormKey.GeneralInformationForm,
				content: {
					sections: [
						{
							id: EnumProgrammeExportStaticSectionKey.GeneralInformationSection,
							label: t([translationBaseKey, "generalSection"].join(".")),
							formId: EnumProgrammeExportStaticFormKey.GeneralInformationForm,
							formControls: [],
							canEdit: false,
						},
					],
				},
			},
		],
		dynamicsTabs: [
			{
				label: EnumProgrammeExportDynamicFormKey.DocumentSectionsForm,
				key: EnumProgrammeExportDynamicFormKey.DocumentSectionsForm,
				formId: EnumProgrammeExportDynamicFormKey.DocumentSectionsForm,
				content: {
					sections: dynamicSections,
				},
			},
		],
	} as TypeProgrammeExportTabsDefinition;
};

/**
 * Generic way to obtain dynamicsSectionKey
 * @param index Order of Section
 * @returns Key
 */
const getDynamicSectionKey = (index: number): string =>
	["dynamicsSection", index < 10 ? `0${index}` : index].join("-");

/**
 * Get configuration of ActionButtons for a Cell
 * @param programmeExportId Current Export Programme ID
 * @param actions list of buttons's actions
 * @returns Cell buttons
 * @typedef PropsDataGridCellButtons Type of Cell Buttons
 */
const getGridActionButtons = (
	programmeExportId: string,
	actions: {
		clone: (id: string) => void;
		edit: (id: string) => void;
		delete: (id: string) => void;
	},
): PropsDataGridCellButtons => {
	return {
		listButtons: [
			{
				...ActionReference[EnumActionsReference.EDIT],
				onClick: () => {
					actions.edit(programmeExportId);
				},
			},
			{
				...ActionReference[EnumActionsReference.CLONE],
				onClick: () => {
					actions.clone(programmeExportId);
				},
			},
			{
				...ActionReference[EnumActionsReference.DELETE],
				onClick: () => {
					actions.delete(programmeExportId);
				},
			},
		],
	};
};

/**
 * Get an empty structure of CustomFieldForm for ProgrammeExport
 * @param document exportProgramme Document
 * @typedef DtoDocument Dto of Document
 * @returns formsData
 * @typedef TypeCustomFieldForm[] List of CustomFields
 * @description There's no CustomField in ProgrammeExport yet,
 * but it uses the CustomField mechanism for faster delivery.
 * This method build a structure with void CustomField to avoid API call.
 */
const getProgrammeExportCustomFieldForm = (
	document: DtoDocument,
): Array<TypeCustomFieldForm> => {
	const { configuration } = document;

	const dynamicsSections: Array<TypeCustomFieldSection> = [];

	if (typeof configuration === "object") {
		configuration?.sections.forEach((section, index) => {
			const { name } = section;
			const dynamicSectionKey = getDynamicSectionKey(index);
			const dynamicSection: DtoCustomFieldSection = {
				id: dynamicSectionKey,
				key: dynamicSectionKey,
				label: name,
				displayScope: EnumCustomFieldDisplayScope.BACK_OFFICE,
				order: index,
			};
			dynamicsSections.push({
				data: dynamicSection,
				fields: [],
			});
		});
	}

	const formsData: Array<TypeCustomFieldForm> = [
		{
			data: {
				id: EnumProgrammeExportStaticFormKey.GeneralInformationForm,
				key: EnumProgrammeExportStaticFormKey.GeneralInformationForm,
				label: EnumProgrammeExportStaticFormKey.GeneralInformationForm,
				displayScope: EnumCustomFieldDisplayScope.BACK_OFFICE,
				scope: EnumCustomFieldScope.SESSION,
				operationId: "",
				order: FormsIndex.Static,
			},
			sections: [
				{
					data: {
						id: EnumProgrammeExportStaticSectionKey.GeneralInformationSection,
						key: EnumProgrammeExportStaticSectionKey.GeneralInformationSection,
						label:
							EnumProgrammeExportStaticSectionKey.GeneralInformationSection,
						displayScope: EnumCustomFieldDisplayScope.BACK_OFFICE,
						order: 0,
					} as DtoCustomFieldSection,
					fields: [],
				},
			],
		},
		{
			data: {
				id: EnumProgrammeExportDynamicFormKey.DocumentSectionsForm,
				key: EnumProgrammeExportDynamicFormKey.DocumentSectionsForm,
				label: EnumProgrammeExportDynamicFormKey.DocumentSectionsForm,
				displayScope: EnumCustomFieldDisplayScope.BACK_OFFICE,
				scope: EnumCustomFieldScope.SESSION,
				operationId: "",
				order: FormsIndex.Dynamic,
			},
			sections: dynamicsSections,
		},
	];
	return formsData;
};

/**
 * Get the form Yup resolver rules
 * @param useFormData Object of useFormData
 * @typedef TypeUseFormListForms Type of useFormData
 * @param t Translator
 * @typedef TFunction type of Translator
 * @returns yupResolver
 */
const getResolver = (useFormData: TypeUseFormListForms | null): any => {
	const resolver: any = {};

	if (!useFormData) {
		return yupResolver(yup.object({}));
	}

	const staticForm =
		useFormData[EnumProgrammeExportStaticFormKey.GeneralInformationForm];
	const dynamicForm =
		useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm];

	if (!staticForm || !dynamicForm) {
		return;
	}

	const staticFormResolver: any = {};
	toPairs(staticForm).forEach((staticSection) => {
		if (!staticSection[PairsIndex.Value]) {
			return;
		}
		const staticSectioncResolver: any = {};
		const staticSectionId = staticSection[PairsIndex.Key];
		toPairs(staticSection[PairsIndex.Value]).forEach((staticField) => {
			const staticFieldId = staticField[PairsIndex.Key];

			switch (staticFieldId) {
				case EnumProgrammeExportStaticFieldKey.Name:
					staticSectioncResolver[staticFieldId] = yup
						.string()
						.nullable()
						.required(t("old.common.formControl.error.required") ?? "");
					break;
				case EnumProgrammeExportStaticFieldKey.Filename:
					staticSectioncResolver[staticFieldId] = yup
						.string()
						.nullable()
						.required(t("old.common.formControl.error.required") ?? "")
						.trim()
						.matches(
							FILENAME_REGEX,
							t("old.common.formControl.error.invalidFileName") ?? "",
						);
					break;
				case EnumProgrammeExportStaticFieldKey.FrontCoverUrl:
				case EnumProgrammeExportStaticFieldKey.BackCoverUrl:
					staticSectioncResolver[staticFieldId] = yup
						.string()
						.nullable()
						.trim()
						.matches(
							URL_REGEX,
							t("old.common.formControl.error.invalidFormat") ?? "",
						);
					break;
				default:
					break;
			}
		});

		if (Object.keys(staticSectioncResolver).length > 0) {
			staticFormResolver[staticSectionId] = yup.object(staticSectioncResolver);
		}
	});

	if (Object.keys(staticFormResolver).length > 0) {
		resolver[EnumProgrammeExportStaticFormKey.GeneralInformationForm] =
			yup.object(staticFormResolver);
	}

	const dynamicFormResolver: any = {};
	toPairs(dynamicForm).forEach((dynamicSection) => {
		if (!dynamicSection[PairsIndex.Value]) {
			return;
		}
		const dynamicSectioncResolver: any = {};
		const dynamicSectionId = dynamicSection[PairsIndex.Key];
		toPairs(dynamicSection[PairsIndex.Value]).forEach((dynamicField) => {
			const dynamicFieldId = dynamicField[PairsIndex.Key];

			if (dynamicFieldId === EnumProgrammeExportDynamicFieldKey.Name) {
				dynamicSectioncResolver[dynamicFieldId] = yup
					.string()
					.nullable()
					.required(t("old.common.formControl.error.required") ?? "");
			}
		});

		if (Object.keys(dynamicSectioncResolver).length > 0) {
			dynamicFormResolver[dynamicSectionId] = yup.object(
				dynamicSectioncResolver,
			);
		}
	});

	if (Object.keys(dynamicFormResolver).length > 0) {
		resolver[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm] =
			yup.object(dynamicFormResolver);
	}

	return yupResolver(yup.object(resolver));
};

/**
 * Convert JSON configuration Document into an DtoProgrammeExportDocumentConfiguration
 * @param programmeExport Current Export Programme
 * @typedef DtoDocument Dto of Document
 * @typedef DtoProgrammeExportDocumentConfiguration Dto of DocumentConfiguration for ProgrammeExport
 * @returns Parsed configuration | Configuration already
 */
const mapDtoDocumentContigurationToDtoProgrammeExportConfiguration = (
	configuration: string,
): DtoProgrammeExportDocumentConfiguration | null => {
	const documentConfiguration = JSON.parse(
		configuration,
	) as DtoProgrammeExportDocumentConfiguration;
	const {
		format,
		filename,
		backCoverUrl,
		frontCoverUrl,
		hasPageNumber,
		hasChairsIndex,
		hasSpeakersIndex,
		sections,
	} = documentConfiguration;
	return {
		format,
		filename,
		backCoverUrl,
		frontCoverUrl,
		hasPageNumber,
		hasChairsIndex,
		hasSpeakersIndex,
		sections: (sections as Array<any>).map((section) => {
			const {
				name,
				displaySectionName,
				dividerUrl,
				backgroundUrl,
				isGroupedByDate,
				displayGroupName,
				filters,
				sessionTemplate,
			} = section;
			return {
				name,
				displaySectionName,
				dividerUrl,
				backgroundUrl,
				isGroupedByDate,
				displayGroupName,
				filters,
				sessionTemplate,
			};
		}),
	} as DtoProgrammeExportDocumentConfiguration;
};

/**
 * Transform a Document Object into a ProgrammeExport Object
 * @param document Current Export Programme Document
 * @typedef DtoDocument Dto of Document
 * @typedef DtoProgrammeExport Dto of Programme Export
 * @returns Programme Export Object
 */
const mapDtoDocumenToDtoProgrammeExportForCloning = (
	document: DtoDocument,
): DtoProgrammeExport => {
	const { name, description, configuration } = document;

	const initialConfiguration: DtoProgrammeExportDocumentConfiguration | null =
		typeof configuration === "string"
			? mapDtoDocumentContigurationToDtoProgrammeExportConfiguration(
					configuration,
				)
			: configuration;

	let programmeExportConfiguration =
		ConfigurationsApp.ProgrammeExportConfiguration
			.defaultProgrammeExportConfigurationModel;

	if (initialConfiguration && typeof initialConfiguration === "object") {
		const {
			backCoverUrl,
			filename,
			format,
			frontCoverUrl,
			hasChairsIndex,
			hasPageNumber,
			hasSpeakersIndex,
			sections,
		} = initialConfiguration;

		programmeExportConfiguration = {
			...programmeExportConfiguration,
			backCoverUrl,
			filename,
			format,
			frontCoverUrl,
			hasChairsIndex,
			hasPageNumber,
			hasSpeakersIndex,
			sections: sections.map((section) => {
				return {
					name: section.name,
					displaySectionName: section.displaySectionName ?? "",
					dividerUrl: section.dividerUrl ?? "",
					backgroundUrl: section.backgroundUrl ?? "",
					isGroupedByDate: !!section.isGroupedByDate,
					displayGroupName: !!section.displayGroupName,
					filters: section.filters ?? "",
					sessionTemplate: section.sessionTemplate ?? "",
				};
			}),
		};
	}

	return {
		name: [name, "CLONE"].join("-"),
		description,
		configuration: programmeExportConfiguration,
	} as DtoProgrammeExport;
};

/**
 * Transform formData into Dto before update
 */
const mapUseFormToDtoProgrammeExport = (
	useFormData: TypeUseFormListForms,
): DtoProgrammeExport => {
	const programmeExport: DtoProgrammeExport =
		ConfigurationsApp.ProgrammeExportConfiguration.defaultProgrammeExportModel;
	const dynamicsSections: Array<DtoProgrammeExportSection> = [];

	toPairs(
		useFormData[EnumProgrammeExportStaticFormKey.GeneralInformationForm],
	).forEach((sectionObject) => {
		if (!sectionObject[PairsIndex.Value]) {
			return;
		}
		toPairs(sectionObject[1]).forEach((fieldObject) => {
			const fieldId = fieldObject[PairsIndex.Key];
			const fieldValue = fieldObject[PairsIndex.Value];

			switch (fieldId) {
				case EnumProgrammeExportStaticFieldKey.Name:
					programmeExport.name = fieldValue as string;
					return;
				case EnumProgrammeExportStaticFieldKey.Description:
					programmeExport.description = fieldValue as string;
					return;
				case EnumProgrammeExportStaticFieldKey.Filename:
					programmeExport.configuration.filename = fieldValue as string;
					return;
				case EnumProgrammeExportStaticFieldKey.Format:
					programmeExport.configuration.format =
						fieldValue as EnumProgrammeExportFormat;
					return;
				case EnumProgrammeExportStaticFieldKey.FrontCoverUrl:
					programmeExport.configuration.frontCoverUrl = fieldValue as string;
					return;
				case EnumProgrammeExportStaticFieldKey.BackCoverUrl:
					programmeExport.configuration.backCoverUrl = fieldValue as string;
					return;
				case EnumProgrammeExportStaticFieldKey.HasPageNumber:
					programmeExport.configuration.hasPageNumber = !!fieldValue;
					return;
				case EnumProgrammeExportStaticFieldKey.HasChairsIndex:
					programmeExport.configuration.hasChairsIndex = !!fieldValue;
					return;
				case EnumProgrammeExportStaticFieldKey.HasSpeakersIndex:
					programmeExport.configuration.hasSpeakersIndex = !!fieldValue;
					return;
				default:
					break;
			}
		});
	});

	toPairs(
		useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm],
	).forEach((sectionObject) => {
		const useFormDataSectionValue = sectionObject[PairsIndex.Value];
		if (!useFormDataSectionValue) {
			return;
		}
		const section: DtoProgrammeExportSection = {
			...ConfigurationsApp.ProgrammeExportConfiguration
				.defaultProgrammeExportSectionModel,
		};

		Object.entries(useFormDataSectionValue).forEach((field) => {
			const key = field[PairsIndex.Key];
			const value = field[PairsIndex.Value];
			switch (key) {
				case EnumProgrammeExportDynamicFieldKey.Name:
					section.name = value as string;
					break;
				case EnumProgrammeExportDynamicFieldKey.DisplaySectionName:
					section.displaySectionName = !!value;
					break;
				case EnumProgrammeExportDynamicFieldKey.DividerUrl:
					section.dividerUrl = value as string;
					break;
				case EnumProgrammeExportDynamicFieldKey.BackgroundUrl:
					section.backgroundUrl = value as string;
					break;
				case EnumProgrammeExportDynamicFieldKey.IsGroupedByDate:
					section.isGroupedByDate =
						(typeof value === "string" &&
							(Number.parseInt(value) as EnumProgrammeExportSessionGroupBy)) ===
						EnumProgrammeExportSessionGroupBy.Date;
					break;
				case EnumProgrammeExportDynamicFieldKey.DisplayGroupName:
					section.displayGroupName = !!value;
					break;
				case EnumProgrammeExportDynamicFieldKey.Filters:
					section.filters = value as string;
					break;
				case EnumProgrammeExportDynamicFieldKey.SessionTemplate:
					section.sessionTemplate = value as string;
					break;
				default:
					break;
			}
		});
		dynamicsSections.push(section);
	});
	programmeExport.configuration.sections = dynamicsSections;

	return programmeExport;
};

/**
 *  Map dynamics fields to list of FormControls
 * @param formsData form Data
 * @param componentData Tabs's Components Data
 * @param t Translator
 * @typedef TypeFormControl[] list of FormControls
 * @returns list of FormControls
 */
const mapTabsDynamicsFields = (
	formsData: Array<TypeCustomFieldForm>,
	componentData: TypeProgrammeExportTabsDefinition,
): TypeProgrammeExportTabsDefinition => {
	const form = formsData[FormsIndex.Dynamic];

	for (
		let sectionIndex = 0;
		form.sections.length > sectionIndex;
		sectionIndex++
	) {
		const section = form.sections[sectionIndex];
		if (
			(
				Object.values(EnumProgrammeExportDynamicFormKey) as Array<string>
			).includes(form.data.key)
		) {
			toPairs(
				ConfigurationsApp.ProgrammeExportConfiguration
					.dynamicListFormControlsObject[
					form.data.key as EnumProgrammeExportDynamicFormKey
				][EnumProgrammeExportDynamicSectionKey.DocumentSectionsSection],
			).forEach((value: [string, TypeProgrammeExportFieldData]) => {
				const formControl = value[PairsIndex.Value].component;
				const name = [form.data.id, section.data.id, formControl.id].join(".");
				formControl.propsComponent.label = t(formControl.propsComponent.label);
				formControl.propsComponent.legend = t(
					formControl.propsComponent.legend,
				);
				if (formControl.kind === EnumFormControlKind.RADIO) {
					formControl.propsComponent.items.forEach(
						(item) => (item.label = t(item.label)),
					);
				}
				componentData.dynamicsTabs[
					ComponentDataFormIndex.ComponentData
				].content.sections[sectionIndex].formControls.push({
					...formControl,
					name,
				});
			});
		}
	}
	return componentData;
};

/**
 *  Map statics fields to list of FormControls
 * @param formsData Form Data
 * @param t Translator
 * @typedef TypeFormControl[] list of FormControls
 * @returns list of FormControls
 */
const mapTabsStaticFields = (
	document: DtoDocument,
	formsData: Array<TypeCustomFieldForm>,
): TypeProgrammeExportTabsDefinition => {
	const formIndex = FormsIndex.Static;
	const form = formsData[formIndex];
	const sections = form.sections;
	const componentData: TypeProgrammeExportTabsDefinition =
		getComponentDataStructure(document);

	for (let sectionIndex = 0; sections.length > sectionIndex; sectionIndex++) {
		const section = sections[sectionIndex];

		if (
			(
				Object.values(EnumProgrammeExportStaticFormKey) as Array<string>
			).includes(form.data.key) &&
			(
				Object.values(EnumProgrammeExportStaticSectionKey) as Array<string>
			).includes(section.data.key)
		) {
			toPairs(
				ConfigurationsApp.ProgrammeExportConfiguration
					.staticListFormControlsObject[
					form.data.key as EnumProgrammeExportStaticFormKey
				][section.data.key as EnumProgrammeExportStaticSectionKey],
			).forEach((value: [string, TypeProgrammeExportFieldData]) => {
				const formControl = value[PairsIndex.Value].component;
				const name = [form.data.id, section.data.id, formControl.id].join(".");
				formControl.propsComponent.label = t(formControl.propsComponent.label);
				formControl.propsComponent.legend = t(
					formControl.propsComponent.legend,
				);
				componentData.staticsTabs[
					ComponentDataFormIndex.ComponentData
				].content.sections[sectionIndex].formControls.push({
					...formControl,
					name,
				});
			});
		}
	}
	return componentData;
};

/**
 * Map dynamics fields in useFormData
 * @param formsData Form Data
 * @param useFormData useForm Data
 * @typedef TypeUseFormListForms type of useFormData
 * @returns useFormData
 */
const mapUseFormDynamicsFields = (
	formsData: Array<TypeCustomFieldForm>,
	useFormData: TypeUseFormListForms,
): TypeUseFormListForms => {
	const form = formsData[FormsIndex.Dynamic];

	useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm] = {};
	for (
		let sectionIndex = 0;
		form.sections.length > sectionIndex;
		sectionIndex++
	) {
		const section = form.sections[sectionIndex];
		useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm][
			section.data.id
		] = {};
		if (
			(
				Object.values(EnumProgrammeExportDynamicFormKey) as Array<string>
			).includes(form.data.key)
		) {
			toPairs(
				ConfigurationsApp.ProgrammeExportConfiguration
					.dynamicListFormControlsObject[
					form.data.key as EnumProgrammeExportDynamicFormKey
				][EnumProgrammeExportDynamicSectionKey.DocumentSectionsSection],
			).forEach((value: [string, TypeProgrammeExportFieldData]) => {
				useFormData[form.data.id][section.data.id][value[PairsIndex.Key]] =
					value[PairsIndex.Value].value;
			});
		}
	}
	return useFormData;
};

/**
 * Map statics fields in useFormData
 * @param formsData Form Data
 * @typedef TypeUseFormListForms type of useFormData
 * @returns useFormData
 */
const mapUseFormStaticsFields = (
	formsData: Array<TypeCustomFieldForm>,
): TypeUseFormListForms => {
	const form = formsData[FormsIndex.Static];
	const sections = form.sections;

	const useFormData: TypeUseFormListForms = {};
	useFormData[EnumProgrammeExportStaticFormKey.GeneralInformationForm] = {};
	useFormData[EnumProgrammeExportStaticFormKey.GeneralInformationForm][
		EnumProgrammeExportStaticSectionKey.GeneralInformationSection
	] = {};

	for (let sectionIndex = 0; sections.length > sectionIndex; sectionIndex++) {
		const section = sections[sectionIndex];
		if (
			(
				Object.values(EnumProgrammeExportStaticFormKey) as Array<string>
			).includes(form.data.key) &&
			(
				Object.values(EnumProgrammeExportStaticSectionKey) as Array<string>
			).includes(section.data.key)
		) {
			toPairs(
				ConfigurationsApp.ProgrammeExportConfiguration
					.staticListFormControlsObject[
					form.data.key as EnumProgrammeExportStaticFormKey
				][section.data.key as EnumProgrammeExportStaticSectionKey],
			).forEach((value: [string, TypeProgrammeExportFieldData]) => {
				useFormData[form.data.id][section.data.id][value[PairsIndex.Key]] =
					value[PairsIndex.Value].value;
			});
		}
	}
	return useFormData;
};

/**
 * Map document data comming from API to set values of a Form object
 * @param useFormData Form
 * @typedef TypeUseFormListForms Type of useForm
 * @param document Current Export Programme Document
 * @typedef DtoDocument Dto of Document
 */
const mapFormTabsEditUseFormDynamicsDefaultValue = (
	useFormData: TypeUseFormListForms,
	document: DtoDocument,
): void => {
	const { configuration: documentConfiguration } = document;

	const configuration =
		typeof documentConfiguration === "string"
			? mapDtoDocumentContigurationToDtoProgrammeExportConfiguration(
					documentConfiguration,
				)
			: documentConfiguration;

	if (configuration) {
		const { sections } = configuration;

		const formKey = EnumProgrammeExportDynamicFormKey.DocumentSectionsForm;
		const sectionsKeys: Array<string> = Object.keys(useFormData[formKey]);

		for (
			let sectionIndex = 0;
			sectionsKeys.length > sectionIndex;
			sectionIndex++
		) {
			const section = sections[sectionIndex];

			toPairs(
				useFormData[formKey][
					sectionsKeys[sectionIndex] as EnumProgrammeExportDynamicSectionKey
				],
			).forEach((fieldData) => {
				const fieldId = fieldData[
					PairsIndex.Key
				] as EnumProgrammeExportDynamicFieldKey;
				if (typeof configuration === "object") {
					switch (fieldId) {
						case EnumProgrammeExportDynamicFieldKey.Name:
							useFormData[formKey][sectionsKeys[sectionIndex]][fieldId] =
								section.name ?? null;
							return;
						case EnumProgrammeExportDynamicFieldKey.DisplaySectionName:
							useFormData[formKey][sectionsKeys[sectionIndex]][fieldId] =
								section.displaySectionName ?? null;
							return;
						case EnumProgrammeExportDynamicFieldKey.DividerUrl:
							useFormData[formKey][sectionsKeys[sectionIndex]][fieldId] =
								section.dividerUrl ?? null;
							return;
						case EnumProgrammeExportDynamicFieldKey.BackgroundUrl:
							useFormData[formKey][sectionsKeys[sectionIndex]][fieldId] =
								section.backgroundUrl ?? null;
							return;
						case EnumProgrammeExportDynamicFieldKey.IsGroupedByDate:
							useFormData[formKey][sectionsKeys[sectionIndex]][fieldId] =
								section.isGroupedByDate ? "1" : "0";
							return;
						case EnumProgrammeExportDynamicFieldKey.DisplayGroupName:
							useFormData[formKey][sectionsKeys[sectionIndex]][fieldId] =
								section.displayGroupName ?? null;
							return;
						case EnumProgrammeExportDynamicFieldKey.Filters:
							useFormData[formKey][sectionsKeys[sectionIndex]][fieldId] =
								section.filters ?? null;
							return;
						case EnumProgrammeExportDynamicFieldKey.SessionTemplate:
							useFormData[formKey][sectionsKeys[sectionIndex]][fieldId] =
								section.sessionTemplate ?? null;
							return;
						default:
							break;
					}
				}
			});
		}
	}
};

/**
 * Map document data comming from API to set values of a Form object
 * @param useFormData Form
 * @typedef TypeUseFormListForms Type of useForm
 * @param document Current Export Programme Document
 * @typedef DtoDocument Dto of Document
 */
const mapFormTabsEditUseFormStaticsDefaultValue = (
	useFormData: TypeUseFormListForms,
	document: DtoDocument,
): void => {
	const { name, description, configuration: documentConfiguration } = document;

	const configuration =
		typeof documentConfiguration === "string"
			? mapDtoDocumentContigurationToDtoProgrammeExportConfiguration(
					documentConfiguration,
				)
			: documentConfiguration;

	const formKey = EnumProgrammeExportStaticFormKey.GeneralInformationForm;

	toPairs(useFormData[formKey]).forEach((sectionData) => {
		const sectionId = sectionData[PairsIndex.Key];
		if (!sectionData[PairsIndex.Value]) {
			return;
		}
		toPairs(sectionData[PairsIndex.Value]).forEach((fieldData) => {
			const fieldId = fieldData[PairsIndex.Key];

			switch (fieldId) {
				case EnumProgrammeExportStaticFieldKey.Name:
					useFormData[formKey][sectionId][fieldId] = name ?? null;
					return;
				case EnumProgrammeExportStaticFieldKey.Description:
					useFormData[formKey][sectionId][fieldId] = description ?? null;
					return;
				default:
					break;
			}

			// ! TODO (later) - cf. K4PROG-2113 - Implement value of FORMAT with a toggle button component
			if (configuration && typeof configuration === "object") {
				const {
					filename,
					format,
					frontCoverUrl,
					backCoverUrl,
					hasPageNumber,
					hasChairsIndex,
					hasSpeakersIndex,
				} = configuration;
				switch (fieldId) {
					case EnumProgrammeExportStaticFieldKey.Filename:
						useFormData[formKey][sectionId][fieldId] = filename ?? null;
						return;
					case EnumProgrammeExportStaticFieldKey.Format:
						useFormData[formKey][sectionId][fieldId] = format ?? null;
						return;
					case EnumProgrammeExportStaticFieldKey.FrontCoverUrl:
						useFormData[formKey][sectionId][fieldId] = frontCoverUrl ?? null;
						return;
					case EnumProgrammeExportStaticFieldKey.BackCoverUrl:
						useFormData[formKey][sectionId][fieldId] = backCoverUrl ?? null;
						return;
					case EnumProgrammeExportStaticFieldKey.HasPageNumber:
						useFormData[formKey][sectionId][fieldId] = hasPageNumber;
						return;
					case EnumProgrammeExportStaticFieldKey.HasChairsIndex:
						useFormData[formKey][sectionId][fieldId] = hasChairsIndex;
						return;
					case EnumProgrammeExportStaticFieldKey.HasSpeakersIndex:
						useFormData[formKey][sectionId][fieldId] = hasSpeakersIndex;
						return;
					default:
						break;
				}
			}
		});
	});
};

/**
 * Set values of form
 * @param useFormData Form
 * @param setValue react-hook-form setValue method
 * @returns Success boolean
 */
const formsSetValues = (
	useFormData: TypeUseFormListForms,
	setValue: UseFormSetValue<FieldValues>,
): Promise<boolean> => {
	Object.entries(useFormData).forEach((form) => {
		const formKey = form[PairsIndex.Key];
		const formValue = form[PairsIndex.Value];

		Object.entries(formValue).forEach((section) => {
			const sectionKey = section[PairsIndex.Key];
			const sectionValue = section[PairsIndex.Value];

			Object.entries(sectionValue).forEach((field) => {
				const fieldKey = field[PairsIndex.Key];
				const fieldValue = field[PairsIndex.Value];
				if (fieldValue) {
					setValue(`${formKey}.${sectionKey}.${fieldKey}`, fieldValue, {
						shouldDirty: false,
					});
				}
			});
		});
	});
	return Promise.resolve(true);
};

/**
 * Move section order in Export Programme Template
 * @param useFormData form
 * @param sectionKey current section Key
 * @param direction Move to Left or Right
 */
const moveSection = (
	useFormData: TypeUseFormListForms,
	sectionKey: string,
	direction: EnumProgrammeExportSectionMoveDirection,
): void => {
	const formKey = EnumProgrammeExportDynamicFormKey.DocumentSectionsForm;

	const originalDynamicForm = useFormData[formKey];

	const sectionKeys = Object.keys(originalDynamicForm);
	const sectionValues = Object.values(originalDynamicForm);

	if (sectionKeys.length < 2) {
		return;
	}

	const currentIndex = sectionKeys.indexOf(sectionKey);
	const newIndex =
		currentIndex +
		(direction === EnumProgrammeExportSectionMoveDirection.Left ? -1 : 1);

	if (direction === EnumProgrammeExportSectionMoveDirection.Left) {
		if (currentIndex === 0) {
			return;
		}

		useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm] = {};
		for (let i = 0; i < newIndex; i++) {
			useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm][
				sectionKeys[i]
			] = sectionValues[i];
		}
		useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm][
			sectionKeys[currentIndex]
		] = sectionValues[currentIndex];
		for (let i = newIndex; i < sectionKeys.length; i++) {
			useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm][
				sectionKeys[i]
			] = sectionValues[i];
		}
	} else if (direction === EnumProgrammeExportSectionMoveDirection.Right) {
		if (currentIndex === sectionKeys.length - 1) {
			return;
		}

		useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm] = {};
		for (let i = 0; i < currentIndex + 2; i++) {
			if (i !== currentIndex) {
				useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm][
					sectionKeys[i]
				] = sectionValues[i];
			}
		}
		useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm][
			sectionKeys[currentIndex]
		] = sectionValues[currentIndex];
		for (let i = currentIndex + 1; i < sectionKeys.length; i++) {
			useFormData[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm][
				sectionKeys[i]
			] = sectionValues[i];
		}
	}
};

const ProgrammeExportHelper = {
	formsSetValues,
	getDynamicSectionKey,
	getGridActionButtons,
	getProgrammeExportCustomFieldForm,
	getResolver,
	mapDtoDocumentContigurationToDtoProgrammeExportConfiguration,
	mapDtoDocumenToDtoProgrammeExportForCloning,
	mapFormTabsEditUseFormDynamicsDefaultValue,
	mapFormTabsEditUseFormStaticsDefaultValue,
	mapTabsDynamicsFields,
	mapTabsStaticFields,
	mapUseFormDynamicsFields,
	mapUseFormStaticsFields,
	mapUseFormToDtoProgrammeExport,
	moveSection,
};

export default ProgrammeExportHelper;
