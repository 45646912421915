import { t } from "i18next";

import type { TSessionDisplay } from "@domain/interfaces/session.interface";
import IconCardItem from "@infrastructure/components/interface/card/IconCardItem";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { CustomFieldsDisplayer } from "@key4-front-library/core";
import { Grid } from "@mui/material";

type Props = {
	session: TSessionDisplay;
};
const RoomView = (props: Props) => {
	const { session } = props;

	return (
		<IconCard
			title={t("old.programme.sessionDetails.room.label")}
			icon={"screen-users"}
			children={
				<Grid container spacing={2}>
					{session.isEverywhere ? (
						<IconCardItem
							label={t("old.programme.sessionDetails.room.label")}
							value={t("old.programme.sessionDetails.room.isEverywhere")}
						/>
					) : (
						<Grid container item spacing={2}>
							{session.room?.name && (
								<IconCardItem
									label={t("old.programme.sessionDetails.room.label")}
									value={session.room.name.toString()}
								/>
							)}
							{(session.room?.capacity || session.room?.capacity === 0) && (
								<IconCardItem
									label={t("old.programme.rooms.capacity")}
									value={session.room.capacity.toString()}
								/>
							)}
							{session.expectedAudience && (
								<IconCardItem
									label={t(
										"old.programme.sessionDetails.room.expectedAudience",
									)}
									value={session.expectedAudience.toString()}
								/>
							)}
						</Grid>
					)}
					<Grid item xs={12}>
						<CustomFieldsDisplayer
							formOrder={1}
							sectionOrder={0}
							values={session.customFieldValues}
						/>
					</Grid>
				</Grid>
			}
		/>
	);
};

export default RoomView;
