/**
 * K4Button est un composant destiné à remplacer le composant Button de MUI au sein du projet.
 *
 * Il permet de créer des boutons déjà stylisés pour correspondre à ceux qui sont les plus
 * récurrents dans les maquettes de Jérôme Morlet.
 *
 * Pour chaque style de bouton, il faut utiliser la propriété "variant".
 */

import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import type { TColor } from "@infrastructure/model/interfaces/color.interface";
import {
	ApplicationColors,
	ThemeColors,
	useIdentity,
} from "@key4-front-library/core";
import {
	alpha,
	Button as MuiButton,
	type SxProps,
	type Theme,
	useTheme,
} from "@mui/material";

type Props = {
	label: string;
	color?: TColor;
	disabled?: boolean;
	endIcon?: JSX.Element;
	handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	size?: "small" | "medium" | "large";
	startIcon?: JSX.Element;
	variant?:
		| "contained"
		| "outlined"
		| "text"
		| "contained-alt"
		| "outlined-grey";
	sx?: SxProps<Theme>;
};

export const K4Button = (props: Props & dataCypressProp) => {
	const {
		label,
		color = "primary",
		disabled = false,
		endIcon,
		handleClick,
		size,
		startIcon,
		variant = "contained",
		sx,
	} = props;
	const theme = useTheme();
	const identity = useIdentity();

	function getThemeColor() {
		switch (color) {
			case "primary":
				return ThemeColors.primary;
			case "secondary":
				return ThemeColors.secondary;
			case "error":
				return ThemeColors.error;
			case "info":
				return ThemeColors.info;
			case "warning":
				return ThemeColors.warning;
			case "success":
				return ThemeColors.success;
			default:
				return ThemeColors.primary;
		}
	}

	if (variant === "contained") {
		return (
			<MuiButton
				color={color}
				disabled={disabled}
				endIcon={endIcon}
				onClick={handleClick}
				size={size}
				startIcon={startIcon}
				variant="contained"
				sx={{ borderRadius: 2, ...sx }}
			>
				{label}
			</MuiButton>
		);
	}

	if (variant === "outlined") {
		return (
			<MuiButton
				color={color}
				disabled={disabled}
				endIcon={endIcon}
				onClick={handleClick}
				size={size}
				startIcon={startIcon}
				variant="outlined"
				sx={
					color === "primary" && identity.darkmode
						? {
								borderColor: theme.palette.common.white,
								color: theme.palette.common.white,
								"&:hover": {
									borderColor: theme.palette.common.white,
									color: theme.palette.common.white,
									bgcolor: alpha(theme.palette.common.white, 0.06),
								},
								"&:disabled": {
									borderColor: alpha(theme.palette.common.white, 0.1),
								},
								borderRadius: 2,
								...sx,
							}
						: {
								borderColor: getThemeColor().main,
								color: getThemeColor().main,
								"&:hover": {
									borderColor: getThemeColor().main,
									color: getThemeColor().main,
									bgcolor: alpha(getThemeColor().main, 0.06),
								},
								"&:disabled": {
									borderColor: alpha(theme.palette.common.black, 0.1),
								},
								borderRadius: 2,
								...sx,
							}
				}
			>
				{label}
			</MuiButton>
		);
	}

	if (variant === "text") {
		return (
			<MuiButton
				color={color}
				disabled={disabled}
				endIcon={endIcon}
				onClick={handleClick}
				size={size}
				startIcon={startIcon}
				variant="text"
				sx={
					color === "primary" && identity.darkmode
						? {
								color: theme.palette.common.white,
								"&:hover": {
									color: theme.palette.common.white,
									bgcolor: alpha(theme.palette.common.white, 0.06),
								},
								borderRadius: 2,
								...sx,
							}
						: {
								color: getThemeColor().main,
								"&:hover": {
									color: getThemeColor().main,
									bgcolor: alpha(getThemeColor().main, 0.06),
								},
								borderRadius: 2,
								...sx,
							}
				}
			>
				{label}
			</MuiButton>
		);
	}

	if (variant === "contained-alt") {
		return (
			<MuiButton
				disabled={disabled}
				endIcon={endIcon}
				onClick={handleClick}
				size={size}
				startIcon={startIcon}
				variant="contained"
				sx={{
					borderRadius: 2,
					bgcolor: identity.darkmode
						? ApplicationColors.darkGrey.main
						: theme.palette.common.white,
					color: alpha(
						theme.palette.text.primary,
						identity.darkmode ? 1 : 0.87,
					),
					"&:hover": {
						bgcolor: identity.darkmode
							? ApplicationColors.darkGrey.light
							: theme.palette.common.white,
						color: identity.darkmode
							? theme.palette.common.white
							: theme.palette.primary.main,
					},
					"&:disabled": {
						bgcolor: identity.darkmode
							? ApplicationColors.darkGrey.dark
							: theme.palette.common.white,
						color: alpha(
							identity.darkmode
								? theme.palette.common.white
								: theme.palette.common.black,
							0.26,
						),
						boxShadow: `0px 4px 14px ${alpha(ApplicationColors.veryDarkBlue.main, 0.12)}`,
					},
					...sx,
				}}
			>
				{label}
			</MuiButton>
		);
	}

	if (variant === "outlined-grey") {
		return (
			<MuiButton
				disabled={disabled}
				endIcon={endIcon}
				onClick={handleClick}
				size={size}
				startIcon={startIcon}
				variant="outlined"
				sx={{
					borderColor: alpha(
						theme.palette.text.primary,
						identity.darkmode ? 0.5 : 0.25,
					),
					color: alpha(theme.palette.text.primary, identity.darkmode ? 1 : 0.5),
					"&:hover": {
						borderColor: alpha(
							theme.palette.text.primary,
							identity.darkmode ? 0.5 : 0.25,
						),
						color: alpha(
							theme.palette.text.primary,
							identity.darkmode ? 1 : 0.5,
						),
						bgcolor: alpha(theme.palette.text.primary, 0.06),
					},
					...sx,
				}}
			>
				{label}
			</MuiButton>
		);
	}

	return <></>;
};
