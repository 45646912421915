import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import NavigationTabsWithIcons from "@application/components/_common/Navigation/NavigationTabsWithIcons";
import { MenuItemReference } from "@application/enum/MenuItemReference.enum";
import { SettingsMenuItemReference } from "@application/enum/SettingsMenuItemReference.enum";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import Loading from "@infrastructure/components/interface/loading/Loading";
import PeriodContainer from "@infrastructure/components/interface/period/PeriodContainer";
import { useSettingsContext } from "@infrastructure/contexts/settingsContext";
import { Title, useContextModule } from "@key4-front-library/core";
import type { DtoClient, DtoEvent } from "@key4-front-library/core/Dto";
import { Stack } from "@mui/material";

export const Settings = () => {
	const { client, event } = useContextModule();
	const { settingsContext, setSettingsContext } = useSettingsContext();

	useEffect(() => {
		const updateData = (_client: DtoClient, _event: DtoEvent) => {
			setSettingsContext({
				settingsMenuItems: SettingsFactory.GetSettingsMenuItems(
					_client,
					_event,
				),
				generalMenuItems: SettingsFactory.GetGeneralMenuItems(_client, _event),
				icons: SettingsFactory.GetIconsItems(),
			});
		};

		updateData(client, event);
	}, [client, event, setSettingsContext]);

	if (!settingsContext) return <Loading />;

	return (
		<Stack>
			<Title
				title={event.name ?? ""}
				reference={
					<PeriodContainer
						dateStartIso={event.startDate}
						dateEndIso={event.endDate}
					/>
				}
			/>
			<NavigationTabsWithIcons
				menuItems={settingsContext.settingsMenuItems}
				baseUrl={["", client.key, event.key, MenuItemReference.SETTINGS].join(
					"/",
				)}
				defaultTabsValue={SettingsMenuItemReference.SCHEDULER}
				routeParentLastPartPath={MenuItemReference.SETTINGS}
			/>
			<Outlet />
		</Stack>
	);
};
