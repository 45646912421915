import type { IconName } from "@fortawesome/fontawesome-svg-core";
import Card from "@infrastructure/components/interface/card/Card";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import type { IIndicator } from "@infrastructure/model/interfaces/indicators/indicator.interface";
import {
	Box,
	Grid,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";

type BigIndicatorRenderProps = {
	indicator: IIndicator;
};
const BigIndicatorRender = (props: BigIndicatorRenderProps) => {
	const { indicator } = props;
	const theme = useTheme();

	return (
		<Box key={indicator.key} sx={{ mt: 1, mb: 4 }}>
			<Grid container display={"flex"} alignItems={"center"}>
				<Grid item>
					<Icon
						prefix="far"
						iconName={indicator.icon as IconName}
						color={theme.palette.text.secondary}
						size="5x"
					/>
				</Grid>
				<Grid item sx={{ mx: 3 }} display={"flex"} alignItems={"center"}>
					<Stack>
						<Typography variant="h3" sx={{ fontWeight: "bold" }}>
							<NumberThousandSeparator value={indicator.value} />
						</Typography>
						<Typography variant="h5">{indicator.name}</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

type SmallIndicatorRenderProps = {
	indicator: IIndicator;
};
const SmallIndicatorRender = (props: SmallIndicatorRenderProps) => {
	const { indicator } = props;
	const theme = useTheme();

	return (
		<Box key={indicator.key} sx={{ my: 1 }}>
			<Grid container>
				<Grid item xs={10} md={10} lg={10}>
					<Grid container alignItems={"center"}>
						<Grid
							item
							xs={6}
							md={4}
							lg={5}
							display={"flex"}
							justifyContent={"flex-end"}
							alignItems={"center"}
						>
							<Typography variant="h5" sx={{ fontWeight: "bold" }}>
								<NumberThousandSeparator value={indicator.value} />
								&nbsp;
							</Typography>
						</Grid>
						<Grid item xs={6} md={8} lg={7}>
							<Typography variant="body1">{indicator.name}</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={2} md={2} lg={2}>
					<Icon
						prefix="far"
						iconName={indicator.icon as IconName}
						color={theme.palette.text.secondary}
						size="2x"
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

type Props = {
	title: string;
	indicators: Array<IIndicator>;
	isLoading: boolean;
};
const DashboardIndicator = (props: Props) => {
	const { title, indicators, isLoading } = props;
	if (isLoading) {
		return (
			<Grid
				item
				xs={12}
				md={6}
				lg={3}
				sx={{
					display: "flex",
				}}
			>
				<Skeleton
					variant="rounded"
					width="100%"
					height={"100%"}
					sx={{
						borderRadius: "16px",
						minHeight: 300,
					}}
				/>
			</Grid>
		);
	}

	return (
		<Grid
			item
			xs={12}
			md={6}
			lg={3}
			sx={{
				display: "flex",
			}}
		>
			<Card title={title}>
				<Stack>
					<BigIndicatorRender indicator={indicators[0]} />
					<SmallIndicatorRender indicator={indicators[1]} />
					<SmallIndicatorRender indicator={indicators[2]} />
				</Stack>
			</Card>
		</Grid>
	);
};

export default DashboardIndicator;
