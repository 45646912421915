import type { ICalendarSession } from "@domain/interfaces/calendar.interface";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { ApplicationColors } from "@key4-front-library/core";
import {
	Box,
	lighten,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";

type Props = {
	session: ICalendarSession;
};

const K4CalendarCardContent = ({ session }: Props) => {
	const theme = useTheme();

	// #region 'Tooltip'
	const hour = session.allDay
		? ""
		: `${session.hourStart} - ${session.hourEnd}`;

	const infoSessions = [];

	if ((session.hourStart || session.hourEnd) && !session.allDay) {
		infoSessions.push(hour);
	}

	if (session.title && session.title !== "undefined")
		infoSessions.push(session.title);
	if (session.code) infoSessions.push(session.code);

	const tooltipSession = infoSessions.join("\n");
	// #endregion 'Tooltip'

	return (
		<Tooltip
			title={
				<Box sx={{ whiteSpace: "pre-line", fontSize: "1rem" }}>
					{tooltipSession}
				</Box>
			}
			followCursor
		>
			<Stack
				px=".8em"
				py=".4em"
				id="full-calendar"
				sx={{
					cursor: session.isReadOnly ? "default" : "pointer",
					fontSize: "1.2em;",
					overflow: "hidden",
					height: "100%",
					backgroundImage:
						session.invitationStatusAccepted === false
							? [
									"repeating-linear-gradient(",
									"-45deg,",
									"transparent 0px 5px,",
									[
										lighten(ApplicationColors.grey.light!, 0.5),
										" 2px 10px",
									].join(""),
									")",
								].join("")
							: undefined,
				}}
			>
				<Stack direction={"row"}>
					{(session.hourStart || session.hourEnd) && !session.allDay && (
						<Stack>
							<Typography color={"inherit"} fontSize={"inherit"}>
								{`${session.hourStart} - ${session.hourEnd}`}
							</Typography>
						</Stack>
					)}
					{/* TODO Waiting API change to add icons status/hasClashes/hasAnomalies */}
					<Stack sx={{ marginLeft: "auto" }} direction={"row"} spacing={".5em"}>
						{session.hasAnomalies && (
							<Icon
								size="lg"
								color={theme.palette.warning.main}
								prefix="fas"
								iconName={"triangle-exclamation"}
							/>
						)}
						{session.hasClashes && (
							<Icon
								size="lg"
								color={theme.palette.error.main}
								prefix="fas"
								iconName={"calendar-circle-exclamation"}
							/>
						)}
					</Stack>
				</Stack>
				<Stack pt={".3em"} alignItems={"flex-start"}>
					<Typography
						color={"inherit"}
						fontSize={"inherit"}
						fontWeight={"bold"}
						align={"left"}
					>
						{session.title && session.title !== "undefined"
							? session.title
							: ""}
					</Typography>
				</Stack>
				<Stack pt={".3em"} alignItems={"flex-start"}>
					<Typography color={"inherit"} fontSize={"inherit"}>
						{session.code}
					</Typography>
				</Stack>
			</Stack>
		</Tooltip>
	);
};

export default K4CalendarCardContent;
