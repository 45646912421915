import { ESessionFacultyClashes } from "@application/enum/Clashes.enum";
import { MenuItemReference } from "@application/enum/MenuItemReference.enum";
import HelpersApp from "@application/helpers";
import type { IClashesObject } from "@domain/interfaces/session.clashes.interface";
import type {
	IClashesEntitiesSessionSameRoomSameTime,
	ISessionFacultyClashesModel,
} from "@domain/model/clashes.model";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import {
	ApplicationColors,
	type IK4Card,
	statusSessionObject,
} from "@key4-front-library/core";
import type { DtoEvent } from "@key4-front-library/core/Dto";
import { Link, Typography } from "@mui/material";

type TransformSessionClashesDataForReadingProps = {
	clash: ISessionFacultyClashesModel;
	client: string;
	event: DtoEvent;
	translations: any;
};
const TransformSessionClashesDataForReading = (
	props: TransformSessionClashesDataForReadingProps & dataCypressProp,
): IClashesObject => {
	const TransformSessionClashesEntityForReading = (
		entity: IClashesEntitiesSessionSameRoomSameTime,
	): IK4Card => {
		const {
			startDate,
			endDate,
			isFullDay,
			hasAnomalies,
			hasClashes,
			title,
			code,
			id,
			primaryTag,
			status,
		} = entity.entity;

		const displayEntireDate =
			startDate &&
			endDate &&
			HelpersApp.SessionHelper.getSessionEntireDate(
				startDate,
				endDate,
				isFullDay,
			);
		return {
			id,
			headerTitle: startDate && displayEntireDate ? displayEntireDate : "",
			hasAnomalies,
			hasClashes,
			contentTitle: title,
			contentSubtitle: code,
			status: statusSessionObject[status],
			tags: [
				{
					label: primaryTag?.label ?? "",
					backgroundColor: primaryTag?.backgroundColor ?? "",
					fontColor: primaryTag?.fontColor ?? "",
					borderColor: primaryTag?.borderColor ?? "",
				},
			],
			key: "", // TODO: feature/K4PROG-2037 KEY
		};
	};

	const { clash, client, event, translations, dataCypressID } = props;
	const iconColorClash = clash.ignoredDate
		? ApplicationColors.greyStone.light
		: ApplicationColors.red.dark;
	let title;
	const backgroundColor = clash.ignoredDate
		? ApplicationColors.greyStoneLight.main
		: ApplicationColors.red.contrastText;
	let formatedEntites: Array<IK4Card> = [];
	switch (clash.type) {
		case ESessionFacultyClashes.SESSION_SAME_ROOM_SAME_TIME:
			title = (
				<Typography
					color={iconColorClash}
					data-cy={
						dataCypressID
							? `${dataCypressID}-clash_${clash.id}-title`
							: undefined
					}
				>
					<>
						{translations.sessionClashOnTheRoom}&nbsp;
						{clash.entities[0].entity.room && (
							<Link
								href={`/${client}/${event.key}/${MenuItemReference.ROOMS}/${clash.entities[0].entity.room.id}`}
								data-cy={
									dataCypressID
										? `${dataCypressID}-clash_${clash.id}-title_redirection`
										: undefined
								}
							>
								{clash.entities[0].entity.room.name ?? ""}
							</Link>
						)}
					</>
				</Typography>
			);
			formatedEntites = clash.entities.map((entity) => {
				return TransformSessionClashesEntityForReading(entity);
			});
			break;

		case ESessionFacultyClashes.FACULTY_OVER_LAPPING_SCHEDULES:
		case ESessionFacultyClashes.FACULTY_ALLOW_TIME_CHANGE_ROOMS:
			const facultyName = `${clash.entities[0].entity.firstname} ${clash.entities[0].entity.lastname}`;
			title = (
				<Typography
					color={iconColorClash}
					data-cy={
						dataCypressID
							? `${dataCypressID}-clash_${clash.id}-title`
							: undefined
					}
				>
					<>
						{translations.invitationClashOnTheFaculty}&nbsp;
						<Link
							href={`/${client}/${event.key}/${MenuItemReference.FACULTIES}/${clash.entities[0].entity.id}`}
							data-cy={
								dataCypressID
									? `${dataCypressID}-clash_${clash.id}-title_redirection`
									: undefined
							}
						>
							{facultyName}
						</Link>
					</>
				</Typography>
			);
			formatedEntites = clash.entities[0].entity.children.map((entity) => {
				return TransformSessionClashesEntityForReading(entity);
			});
			break;
		default:
			break;
	}

	return {
		id: clash.id,
		iconColorClash: iconColorClash ?? "",
		title,
		link: "",
		entity1: formatedEntites[0],
		entity2: formatedEntites[1],
		comments: clash.comments ?? "",
		backgroundColor,
		ignoredDate: clash.ignoredDate !== null ? clash.ignoredDate : "",
	};
};

const ClashesFactory = {
	TransformSessionClashesDataForReading,
};
export default ClashesFactory;
