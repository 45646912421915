import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type { IMenuItems } from "@infrastructure/model/interfaces/navigation/menuItems.interface";
import { ApplicationColors } from "@key4-front-library/core";
import { Badge, Box, Tab, Tabs } from "@mui/material";

type Props = {
	menuItems: IMenuItems;
	badges: { reference: string; number: number }[];
	baseUrl: string;
	defaultTabsValue: string;
	routeParentLastPartPath: string;
};

const NavigationTabsWithBadges = (props: Props) => {
	const {
		menuItems,
		badges,
		baseUrl,
		defaultTabsValue,
		routeParentLastPartPath,
	} = props;

	const location = useLocation();
	const navigate = useNavigate();

	const [tabValue, setTabValue] = useState<string>(defaultTabsValue);

	useEffect(() => {
		const routeComponents = location.pathname.split("/");
		const routeLastPartPath = routeComponents[routeComponents.length - 1];
		if (
			routeLastPartPath === "" ||
			routeLastPartPath === routeParentLastPartPath
		) {
			navigate(`${baseUrl}/${defaultTabsValue}`, { replace: true });
			setTabValue(defaultTabsValue);
		} else {
			setTabValue(routeLastPartPath);
		}
	}, [
		baseUrl,
		location,
		defaultTabsValue,
		routeParentLastPartPath,
		navigate,
		setTabValue,
	]);

	const handleChange = (_: React.SyntheticEvent, _newTabValue: string) => {
		if (_newTabValue !== tabValue) {
			setTabValue(_newTabValue);
			navigate(`${baseUrl}/${_newTabValue}`);
		}
	};

	const addNumberToBadge = (
		_badges: { reference: string; number: number }[],
		_menuItemReference: string,
	) => {
		const badge = _badges.find(
			(_badge) => _badge.reference === _menuItemReference,
		);
		if (badge) return badge?.number;
	};

	return (
		<Box sx={{ borderBottom: 1, borderColor: "divider", mb: 5 }}>
			<Tabs
				value={tabValue}
				onChange={handleChange}
				centered={false}
				variant="scrollable"
				scrollButtons="auto"
			>
				{menuItems.map((item) => {
					const badgeNumber = addNumberToBadge(badges, item.reference);
					return (
						<Tab
							key={item.reference}
							value={item.reference}
							label={
								<Box component="span">
									{item.translationKey
										? t(item.translationKey)
										: item.name?.toLocaleUpperCase()}
								</Box>
							}
							{...(item.badgeColor &&
								(badgeNumber === undefined || badgeNumber > 0) && {
									icon: (
										<Box
											mr={1}
											pl={badgeNumber && badgeNumber > 99 ? 1.5 : 1}
											pb={0.5}
										>
											<Badge
												badgeContent={badgeNumber}
												sx={{
													"& .MuiBadge-badge": {
														backgroundColor: item.badgeColor.main,
														color: ApplicationColors.white.main,
													},
												}}
											/>
										</Box>
									),
									iconPosition: "end",
								})}
						/>
					);
				})}
			</Tabs>
		</Box>
	);
};

export default NavigationTabsWithBadges;
