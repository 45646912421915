import type { DateTime } from "luxon";
import { useEffect, useState } from "react";

import { ESchedulerView } from "@domain/interfaces/calendar.interface";
import type {
	IconName,
	IconPrefix,
	SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import {
	Button,
	IconButton,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	useTheme,
} from "@mui/material";
import {
	DesktopDatePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";

type Props = {
	calendar: any;
	range: { end: DateTime; start: DateTime };
	view: ESchedulerView;
	changeView: (view: ESchedulerView) => void;
	locale: string;
};

const HeaderCalendarMultiRoom = ({
	calendar,
	view,
	range,
	changeView = (_view: ESchedulerView) => {},
	locale,
}: Props) => {
	const theme = useTheme();
	const [title, setTitle] = useState<any>();
	const [currentView, setCurrentView] = useState<ESchedulerView>(
		ESchedulerView.ROOM,
	);
	const [currentDate, setCurrentDate] = useState<DateTime>(range.start);
	const [isOpen, setIsOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const viewModes = [
		{
			value: ESchedulerView.ROOM,
			icon: "calendar-days",
			prefix: "far",
			size: "lg",
			disabled: false,
		},
		{
			value: ESchedulerView.MULTIROOM,
			icon: "calendar-day",
			prefix: "far",
			size: "lg",
			disabled: true,
		},
	];

	useEffect(() => {
		if (calendar.current) {
			const calendarApi = calendar.current.getApi();
			setTitle(calendarApi.view.title);
		}
	}, [calendar]);

	useEffect(() => {
		setCurrentView(view);
	}, [view]);

	useEffect(() => {
		if (calendar.current) {
			const calendarApi = calendar.current.getApi();
			setTitle(calendarApi.view.title);
		}
	}, [calendar, locale]);

	const handlePreviousClick = () => {
		const calendarApi = calendar.current.getApi();
		calendarApi.gotoDate(currentDate.minus({ days: 1 }).toFormat("yyyy-MM-dd"));
		setCurrentDate(currentDate.minus({ days: 1 }));
		setTitle(calendarApi.view.title);
	};

	const handleNextClick = () => {
		const calendarApi = calendar.current.getApi();
		calendarApi.gotoDate(currentDate.plus({ days: 1 }).toFormat("yyyy-MM-dd"));
		setCurrentDate(currentDate.plus({ days: 1 }));
		setTitle(calendarApi.view.title);
	};

	const handlechangeViewClick = (
		_event: React.MouseEvent<HTMLElement>,
		view: ESchedulerView,
	) => {
		changeView(view);
		if (calendar.current) {
			const calendarApi = calendar.current.getApi();
			calendarApi.changeView(view);
			setTitle(calendarApi.view.title);
		}
	};

	const handleDateClick = (e: any) => {
		setIsOpen(!isOpen);
		setAnchorEl(e.currentTarget);
	};

	const handleDateRangeChange = (newDate: DateTime | null) => {
		if (!newDate) return;
		const calendarApi = calendar.current.getApi();
		calendarApi.gotoDate(newDate.toFormat("yyyy-MM-dd"));
		setCurrentDate(newDate);
		setTitle(calendarApi.view.title);
	};

	return (
		<Stack>
			<Stack
				direction="row"
				alignItems={"center"}
				justifyContent={"space-between"}
				my=".8em"
			>
				<Stack sx={{ width: "80px", marginLeft: "1em" }}></Stack>
				<Stack
					direction="row"
					alignItems={"center"}
					sx={{ fontWeight: "light" }}
				>
					<IconButton
						aria-label="left"
						sx={{ width: "40px", height: "40px" }}
						onClick={handlePreviousClick}
						disabled={
							currentDate.toFormat("yyyy-MM-dd") ===
							range.start.toFormat("yyyy-MM-dd")
						}
					>
						<Typography
							component={"div"}
							fontSize=".7em"
							fontWeight={"inherit"}
						>
							<Icon prefix="fal" iconName={"chevron-left"} color={"inherit"} />
						</Typography>
					</IconButton>
					<Button size="medium" onClick={handleDateClick}>
						<Typography
							fontSize="1.8rem"
							fontWeight={"Light"}
							sx={{ textTransform: "capitalize" }}
						>
							{title}
						</Typography>
					</Button>
					<IconButton
						aria-label="left"
						sx={{ width: "40px", height: "40px" }}
						disabled={
							currentDate.toFormat("yyyy-MM-dd") ===
							range.end.toFormat("yyyy-MM-dd")
						}
						onClick={handleNextClick}
					>
						<Typography
							component={"div"}
							fontSize=".7em"
							fontWeight={"inherit"}
						>
							<Icon prefix="fal" iconName={"chevron-right"} color={"inherit"} />
						</Typography>
					</IconButton>
				</Stack>
				<ToggleButtonGroup
					sx={{ width: "80px", marginRight: "1em" }}
					value={currentView}
					exclusive
					onChange={handlechangeViewClick}
					aria-label="text alignment"
				>
					{viewModes.map((item: any, key: any) => {
						return (
							<ToggleButton
								disabled={item.disabled}
								key={key}
								sx={{
									backgroundColor:
										currentView === item.value
											? `${theme.palette.primary.main} !important`
											: "",
									padding: ".5em 1em",
								}}
								value={item.value}
							>
								<Icon
									size={item.size as SizeProp}
									color={
										currentView === item.value
											? `${theme.palette.common.white} !important`
											: ""
									}
									prefix={item.prefix as IconPrefix}
									iconName={item.icon as IconName}
								/>
							</ToggleButton>
						);
					})}
				</ToggleButtonGroup>
			</Stack>
			<Stack>
				<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
					<DesktopDatePicker
						disableMaskedInput
						minDate={range.start}
						maxDate={range.end}
						open={isOpen}
						onClose={() => {
							setIsOpen(false);
						}}
						value={currentDate}
						onChange={handleDateRangeChange}
						renderInput={() => <></>}
						PopperProps={{
							placement: "bottom",
							anchorEl,
						}}
					/>
				</LocalizationProvider>
			</Stack>
		</Stack>
	);
};

export default HeaderCalendarMultiRoom;
