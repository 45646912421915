import { t } from "i18next";
import { cloneDeep } from "lodash";
import { useSnackbar } from "notistack";
import { type BaseSyntheticEvent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import ControllersApp from "@application/Controllers/ControllersApp";
import HelpersApp from "@application/helpers";
import HooksApp from "@application/hooks";
import {
	ApplicationColors,
	ButtonCancel,
	ButtonSave,
	Dialog,
	EnumApiErrorStatus,
	ErrorAPI,
	FormTabs,
	Gravatar,
} from "@key4-front-library/core";
import DialogTitle from "@key4-front-library/core/Bo/Components/DialogTitle";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export type PropsContainerChairModal = {
	activeTabKey?: string;
	callbackSubmit?: () => void;
	changeIsOpen: (newIsOpen: boolean) => void;
	chairId?: string;
	isOpen: boolean;
	sessionId: string;
};

const ContainerChairModal = (props: PropsContainerChairModal) => {
	const {
		activeTabKey,
		callbackSubmit,
		changeIsOpen,
		chairId,
		sessionId,
		isOpen,
	} = props;
	const { enqueueSnackbar } = useSnackbar();

	const [chair, setChair] = useState<any>(null);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

	const { readWholeForm } = HooksApp.ChairHook.useEntity();
	const { create, readChair, update } =
		ControllersApp.ChairController.useEntity();
	const { data, isFetching, refetch, isFetchedAfterMount } = readWholeForm(
		sessionId,
		chair,
	);

	const formMethods = useForm<TypeUseFormListForms>({
		mode: "onSubmit",
		resolver: HelpersApp.ChairHelper.getResolver(
			data ? cloneDeep(data.useFormData) : undefined,
			chairId,
		),
	});

	const { handleSubmit: onSubmit, reset } = formMethods;

	useEffect(() => {
		if (isOpen && chairId) {
			getChair(chairId);
		} else {
			setChair(null);
		}
		void refetch();
	}, [isOpen]);

	useEffect(() => {
		void refetch();
	}, [chair]);

	const getChair = async (chairId: string) => {
		const chair = await readChair(sessionId, chairId);
		setChair(chair);
		void refetch();
	};

	const displaySnackbar = (isError: boolean, customMessage?: string | null) => {
		enqueueSnackbar(customMessage ?? t("old.common.errors.generic"), {
			variant: isError ? "error" : "success",
		});
	};

	const handleSubmit = async (useFormData: TypeUseFormListForms) => {
		// Create a new chair
		if (!chairId) {
			const newChair = await create(useFormData, sessionId);
			if (newChair instanceof ErrorAPI) {
				if (newChair.status === EnumApiErrorStatus.STATUS_422) {
					displaySnackbar(
						true,
						t("old.programme.sessionDetails.chairs.form.add.chairAlreadyExist"),
					);
				} else {
					displaySnackbar(true);
				}
			} else {
				displaySnackbar(
					false,
					t("old.programme.sessionDetails.chairs.form.add.success"),
				);
				changeIsOpen(false);
				callbackSubmit?.();
			}

			// Update a chair
		} else if (await update(sessionId, useFormData, chairId)) {
			displaySnackbar(
				false,
				t("old.programme.sessionDetails.chairs.form.edit.success"),
			);
			changeIsOpen(false);
			callbackSubmit?.();
			getChair(chairId);
		} else {
			displaySnackbar(true);
		}
		setIsLoadingSubmit(false);
	};

	const handleInvalid = (_error: any) => {
		setIsLoadingSubmit(false);
		displaySnackbar(
			true,
			t("old.programme.sessionDetails.chairs.form.errors.invalidForm"),
		);
	};

	useEffect(() => {
		if (!isFetching && data) {
			reset(data.useFormData);
		}
	}, [isFetching]);

	return (
		<Dialog isOpen={isOpen}>
			<>
				<DialogTitle
					title={t(
						`old.programme.sessionDetails.chairs.form.${chairId ? "edit" : "add"}.title`,
					)}
					onCloseClick={() => {
						changeIsOpen(false);
					}}
				/>
				<DialogContent>
					{chairId && (
						<Box display={"flex"} pb={2}>
							<Stack pr={2} justifyContent={"center"}>
								<Gravatar
									email={chair?.email}
									firstname={chair?.firstname}
									lastname={chair?.lastname}
								/>
							</Stack>
							<Stack>
								<Typography>
									{chair?.firstname} {chair?.lastname}
								</Typography>
								<Typography color={ApplicationColors.blueLight.dark}>
									{chair?.email}
								</Typography>
							</Stack>
						</Box>
					)}

					{data && isFetchedAfterMount ? (
						<FormProvider {...formMethods}>
							<FormTabs activeTabKey={activeTabKey} {...data.componentData} />
						</FormProvider>
					) : (
						<Stack spacing={2}>
							<Skeleton
								sx={{ height: 40 }}
								animation="wave"
								variant="rectangular"
							/>
							<Skeleton
								sx={{ height: 450 }}
								animation="wave"
								variant="rectangular"
							/>
						</Stack>
					)}
				</DialogContent>
				<DialogActions>
					<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
						<ButtonCancel
							onClick={() => {
								changeIsOpen(false);
							}}
						/>
						<ButtonSave
							isLoading={isLoadingSubmit}
							onClick={(
								e: BaseSyntheticEvent<object, any, any> | undefined,
							) => {
								setIsLoadingSubmit(true);
								onSubmit(handleSubmit, handleInvalid)(e);
							}}
						/>
					</Stack>
				</DialogActions>
			</>
		</Dialog>
	);
};

export default ContainerChairModal;
