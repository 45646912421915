import type {
	FieldValues,
	UseFormGetValues,
	UseFormSetValue,
	UseFormTrigger,
} from "react-hook-form";

import {
	EnumPresentationFieldKey,
	EnumPresentationFormKey,
	EnumPresentationSectionKey,
} from "@application/Enums/PresentationEnum";
import { EnumSpeakerFieldKey } from "@application/Enums/SpeakerEnum";
import HelpersApp from "@application/helpers";
import type { TypePresentationStaticField } from "@application/Types/PresentationType";
import type { IPresentationCreateorUpdateViewModel as IPresentationViewModel } from "@domain/model/presentation.model";
import {
	type DtoPresentationWrite,
	EnumFormControlKind,
	getCellBulletColorWithLabel,
	getCellButtonsList,
} from "@key4-front-library/core";
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

const emptyEntity: IPresentationViewModel = {
	startDate: null,
	endDate: null,
	isWithoutTimeSlot: false,
	code: null,
	title: null,
	description: null,
	duration: null,
	endHour: null,
	startHour: null,
	id: null,
	customFieldValues: {},
};

const columns: Array<GridColDef> = [
	{
		field: "faculty",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "type",
		minWidth: 200,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "status",
		renderCell: (params: GridRenderCellParams) =>
			getCellBulletColorWithLabel(params),
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
		sortComparator: (a, b) => {
			const labelA = a.label;
			const labelB = b.label;
			return labelA.localeCompare(labelB);
		},
	},
	{
		field: "actionButtons",
		renderCell: getCellButtonsList,
		minWidth: 220,
		flex: 1,
		hideSortIcons: true,
		disableColumnMenu: true,
	},
];

const DEFAULT_DURATION = "00:20";

const defaultModel: DtoPresentationWrite = {
	isWithoutTimeSlot: false,
};
const staticListFormControlsObject: {
	[key in EnumPresentationFormKey]: {
		[key in EnumPresentationSectionKey]?: TypePresentationStaticField;
	};
} = {
	[EnumPresentationFormKey.GENERAL]: {
		[EnumPresentationSectionKey.MAIN]: {
			[EnumPresentationFieldKey.CODE]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.CODE,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.CODE}`,
					},
				},
			},
			[EnumPresentationFieldKey.TITLE]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.TITLE,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.TITLE}`,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumPresentationFieldKey.DESCRIPTION]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.DESCRIPTION,
					kind: EnumFormControlKind.TEXT_AREA,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.DESCRIPTION}`,
						minRows: 3,
					},
				},
			},
			[EnumPresentationFieldKey.IS_WITHOUT_TIME_SLOT]: {
				value: false,
				component: {
					id: EnumPresentationFieldKey.IS_WITHOUT_TIME_SLOT,
					kind: EnumFormControlKind.CHECKBOX,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.IS_WITHOUT_TIME_SLOT}`,
					},
					cbChange: (
						name: string,
						value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						HelpersApp.PresentationHelper.callbackDisplay(
							[
								EnumPresentationFieldKey.END_HOUR,
								EnumPresentationFieldKey.END_DATE,
								EnumPresentationFieldKey.START_DATE,
								EnumPresentationFieldKey.START_HOUR,
								EnumPresentationFieldKey.DURATION,
							],
							name,
							value,
							setValues,
							getValues,
							trigger,
						);
					},
				},
			},
			[EnumPresentationFieldKey.START_DATE]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.START_DATE,
					kind: EnumFormControlKind.DATE_PICKER,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.START_DATE}`,
					},
					gridSize: { xs: 12, md: 4, lg: 4 },
					cbChange: (
						name: string,
						value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const path = `${name.split(".")[0]}.${name.split(".")[1]}`;

						if (
							!getValues(`${path}.${EnumPresentationFieldKey.END_DATE}`) &&
							value
						) {
							setValues(`${path}.${EnumPresentationFieldKey.END_DATE}`, value);
						}

						if (
							!getValues(`${path}.${EnumPresentationFieldKey.DURATION}`) &&
							value
						) {
							HelpersApp.PresentationHelper.setValuesDuration(
								path,
								setValues,
								getValues,
							);
						}

						if (
							getValues(`${path}.${EnumPresentationFieldKey.DURATION}`) &&
							value
						) {
							HelpersApp.PresentationHelper.changeDatesOnDuration(
								path,
								setValues,
								getValues,
							);
						}
						HelpersApp.PresentationHelper.triggerDates(path, trigger);
					},
				},
			},
			[EnumPresentationFieldKey.START_HOUR]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.START_HOUR,
					kind: EnumFormControlKind.TIME_PICKER,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.START_HOUR}`,
						minutesStep: 5,
					},
					gridSize: { xs: 12, md: 4, lg: 4 },
					cbChange: (
						name: string,
						value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const path = `${name.split(".")[0]}.${name.split(".")[1]}`;

						if (
							!getValues(`${path}.${EnumPresentationFieldKey.DURATION}`) &&
							value
						) {
							HelpersApp.PresentationHelper.setValuesDuration(
								path,
								setValues,
								getValues,
							);
						}

						if (
							getValues(`${path}.${EnumPresentationFieldKey.DURATION}`) &&
							value
						) {
							HelpersApp.PresentationHelper.changeDatesOnDuration(
								path,
								setValues,
								getValues,
							);
						}
						HelpersApp.PresentationHelper.triggerDates(path, trigger);
					},
				},
			},
			[EnumPresentationFieldKey.EMPTY]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.EMPTY,
					kind: EnumFormControlKind.EMPTY,
					propsComponent: {
						label: "",
					},
					gridSize: { xs: 0, md: 4, lg: 4 },
				},
			},
			[EnumPresentationFieldKey.END_DATE]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.END_DATE,
					kind: EnumFormControlKind.DATE_PICKER,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.END_DATE}`,
					},
					gridSize: { xs: 12, md: 4, lg: 4 },
					cbChange: (
						name: string,
						_value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const path = `${name.split(".")[0]}.${name.split(".")[1]}`;

						HelpersApp.PresentationHelper.setValuesDuration(
							path,
							setValues,
							getValues,
						);
						HelpersApp.PresentationHelper.triggerDates(path, trigger);
					},
				},
			},
			[EnumPresentationFieldKey.END_HOUR]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.END_HOUR,
					kind: EnumFormControlKind.TIME_PICKER,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.END_HOUR}`,
						minutesStep: 5,
					},
					gridSize: { xs: 12, md: 4, lg: 4 },
					cbChange: (
						name: string,
						_value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const path = `${name.split(".")[0]}.${name.split(".")[1]}`;

						HelpersApp.PresentationHelper.setValuesDuration(
							path,
							setValues,
							getValues,
						);
						HelpersApp.PresentationHelper.triggerDates(path, trigger);
					},
				},
			},
			[EnumPresentationFieldKey.DURATION]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.DURATION,
					kind: EnumFormControlKind.AUTOCOMPLETE,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.DURATION}`,
						freeSolo: true,
						items: [],
					},
					gridSize: { xs: 12, md: 4, lg: 4 },
					cbChange: (
						name: string,
						value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const path = `${name.split(".")[0]}.${name.split(".")[1]}`;

						HelpersApp.PresentationHelper.changeDatesOnDuration(
							path,
							setValues,
							getValues,
						);
						if (!value) {
							setValues(`${path}.${EnumPresentationFieldKey.END_HOUR}`, null);
						}
						HelpersApp.PresentationHelper.triggerDates(path, trigger);
					},
				},
			},
			[EnumPresentationFieldKey.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT]: {
				value: null,
				component: {
					id: EnumSpeakerFieldKey.PARTICIPANT_OR_CONTACT,
					kind: EnumFormControlKind.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT,
					propsComponent: {
						required: false,
						updateOtherFieldsAfterParticipantOrContactSelection: false,
					},
					gridSize: { xs: 12, md: 8, lg: 8 },
				},
			},
			[EnumSpeakerFieldKey.QUALITY]: {
				value: null,
				component: {
					id: EnumSpeakerFieldKey.QUALITY,
					kind: EnumFormControlKind.SELECT,
					propsComponent: {
						label: `old.programme.sessionDetails.chairs.form.fields.${EnumSpeakerFieldKey.QUALITY}`,
						items: [],
						isNullable: true,
					},

					gridSize: { xs: 12, md: 4, lg: 4 },
				},
			},
		},
	},
	[EnumPresentationFormKey.ABSTRACT]: {
		[EnumPresentationSectionKey.MAIN]: {
			[EnumPresentationFieldKey.ABSTRACT_URL]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.ABSTRACT_URL,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						placeholder: "https://",
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.ABSTRACT_URL}`,
						endIcon: {
							iconName: "link",
							onClick: undefined,
						},
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumPresentationFieldKey.ABSTRACT_EMBARGO_DATE]: {
				value: null,
				component: {
					id: EnumPresentationFieldKey.ABSTRACT_EMBARGO_DATE,
					kind: EnumFormControlKind.DATE_TIME_PICKER,
					propsComponent: {
						label: `old.programme.presentations.formControls.labels.${EnumPresentationFieldKey.ABSTRACT_EMBARGO_DATE}`,
					},

					gridSize: { xs: 12, md: 4, lg: 4 },
				},
			},
		},
	},
};

const PresentationConfiguration = {
	datagrid: {
		columns,
	},
	defaultModel,
	DEFAULT_DURATION,
	emptyEntity,
	staticListFormControlsObject,
};

export default PresentationConfiguration;
