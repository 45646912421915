import { t } from "i18next";
import { useEffect } from "react";

import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import type { ICalendarSession } from "@domain/interfaces/calendar.interface";
import { Draggable } from "@fullcalendar/interaction";
import K4CalendarCardContent from "@infrastructure/components/calendar/K4CalendarCardContent";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import SimpleTextSearchFilter from "@infrastructure/components/interface/search/SimpleTextSearchFilter";
import { ApplicationColors } from "@key4-front-library/core";
import { Card, Link, Stack, Typography, useTheme } from "@mui/material";

export interface IUnplannedSessionsCardProps {
	sessions: Array<ICalendarSession> | null;
	handleSessionClick: (id: string) => void;
	onOpenFilterModalClick: () => void;
	onSearchChange: (search: string) => void;
	isBadge: boolean;
}

const UnplannedSessionsCard = ({
	sessions,
	handleSessionClick,
	onOpenFilterModalClick,
	onSearchChange,
	isBadge,
}: IUnplannedSessionsCardProps) => {
	const theme = useTheme();

	const actionsConfigurations = GetMenuActionsConfigurations();
	const getConfig = (type: MenuActionsReference) =>
		actionsConfigurations.find((x) => x.reference === type);

	const filterConfig = getConfig(MenuActionsReference.FILTER);

	useEffect(() => {
		const draggableEl = document.getElementById("external-events");
		if (!draggableEl) return;
		const draggable = new Draggable(draggableEl, {
			itemSelector: ".fc-event",
			eventData: (eventEl) => {
				const dataSession = eventEl.getAttribute("data-event");
				if (!dataSession) return;
				const session = JSON.parse(decodeURIComponent(atob(dataSession)));

				if (!session.minDuration) session.minDuration = 60;

				return {
					...session,
				};
			},
		});

		return () => {
			draggable.destroy();
		};
	}, []);

	const strokeDropAnimationColor = encodeURIComponent(
		theme.palette.primary.main,
	);
	const fillDropAnimationColor = encodeURIComponent(
		ApplicationColors.blueLight.main,
	);
	return (
		<>
			{/* Don't remove id="external-events" and className="fc-event" it's used for D&D */}
			<Card id="unplanned-session-card" sx={{ height: "100%", p: 2 }}>
				<Stack
					spacing={3}
					sx={{
						overflow: "visible",
						height: "100%",
					}}
				>
					<Stack spacing={2}>
						<Typography variant="h5" fontWeight="light">
							{t("old.programme.scheduler.unplanned")}
						</Typography>
						<SimpleTextSearchFilter
							handleChange={onSearchChange}
							secondIconName={filterConfig?.icon}
							secondIconHandleClick={onOpenFilterModalClick}
							isBadge={isBadge}
						/>
					</Stack>
					<Stack
						id="external-events"
						sx={{
							height: "100%",
							overflow: "auto",
						}}
					>
						<Stack
							id="unplanned-sessions-section"
							spacing=".5em"
							sx={{ display: "flex" }}
						>
							{sessions?.map((session: ICalendarSession) => {
								const dataSession = btoa(
									encodeURIComponent(JSON.stringify(session)),
								);
								return (
									<Link
										key={session.id}
										width={"100%"}
										component="button"
										underline="none"
										onDoubleClick={() => {
											handleSessionClick(session.id);
										}}
									>
										<Card
											className="fc-event"
											data-event={dataSession}
											sx={{
												backgroundColor:
													session.backgroundColor ??
													SchedulerConfiguration.defaultSessionColor
														.backgroundColor,
												color:
													session.textColor ??
													SchedulerConfiguration.defaultSessionColor.textColor,
												borderColor:
													session.borderColor ??
													SchedulerConfiguration.defaultSessionColor
														.borderColor,
												boxShadow: "unset",
												"&:active": {
													opacity: ".6",
												},
												borderStyle: "solid",
												borderWidth: "2px",
												borderRadius: "3px",
												padding: 0,
											}}
										>
											<K4CalendarCardContent session={session} />
										</Card>
									</Link>
								);
							})}
							{(!sessions || sessions.length === 0) && (
								<Stack pb="10em">
									<Stack pt={"1rem"} alignItems={"center"}>
										<Typography>
											<i>
												{t(
													"old.common.scheduler.unplannedSessionBracket.empty",
												)}
											</i>
										</Typography>
									</Stack>{" "}
								</Stack>
							)}
						</Stack>
						<Stack
							id="unplanned-sessions-section-drop"
							sx={{
								display: "none",
								backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='${fillDropAnimationColor}' stroke='${strokeDropAnimationColor}' stroke-width='3' stroke-dasharray='10%2c15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
								flex: "2",
							}}
							alignItems="center"
							justifyContent="center"
							height="100%"
						>
							<Stack
								height="100%"
								width="100%"
								sx={{
									background: "transparent",
								}}
								justifyContent="center"
								alignItems="center"
							>
								<Typography
									sx={{ textAlign: "center" }}
									component={"div"}
									color={ApplicationColors.greyViolet.light}
									fontSize="3rem"
								>
									<Icon
										prefix="fal"
										iconName={"rectangle-history-circle-plus"}
										color={"inherit"}
									/>
								</Typography>
								<Typography
									component={"span"}
									variant="body1"
									fontSize={".8rem"}
								>
									{t("old.programme.scheduler.unplannedInfoDD")}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Card>
		</>
	);
};

export default UnplannedSessionsCard;
