import { t } from "i18next";
import { useEffect, useState } from "react";

import tabsAdvancedSettings from "@application/Configurations/tabs/tabs.advancedSettings.configuration";
import type { ISettingsSessionTagType } from "@domain/interfaces/settings.session-tag-type.interface";
import type { ISettingsSessionTag } from "@domain/interfaces/settings.session-tag.interface";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import type { IErrorMessageReturn } from "@infrastructure/model/interfaces/api/api-errors-message-return.interface";
import {
	ActionReference,
	ApplicationColors,
	ConfirmationModal,
	DataGridOld,
	type DtoTagType,
	EConfirmationModalAction,
	EnumActionsReference,
	EnumApiErrorStatus,
	EnumContextualMenu,
	EnumIcon,
	getColumns,
	IconOld,
	type IEntity,
	type ITagModel,
	queryStringSorts,
	Services,
	useContextModule,
	useSnackBarHook,
} from "@key4-front-library/core";
import ContextualMenu from "@key4-front-library/core/Bo/Components/ContextualMenu";
import DefaultModal from "@key4-front-library/core/Bo/Components/Modal/DefaultModal";
import {
	Box,
	Button,
	Grid,
	List,
	ListItemButton,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";

import AdvancedSettingsSessionTagEditModal from "./AdvancedSettingsSessionTagEditModal";
import AdvancedSettingsSessionTagTypeEditModal from "./SettingsSessionTagTypeEdit";

export const AdvancedSettingsTags = () => {
	const { client, event } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();
	const theme = useTheme();
	const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));

	const [dataGridRow, setDataGridRow] = useState<Array<GridValidRowModel>>([]);
	const [selectedIndex, setSelectedIndex] = useState(0);

	// TAG variables
	const [currentTag, setCurrentTag] = useState<ITagModel | null>(null);
	const [isEditTagModalOpen, setIsEditTagModalOpen] = useState<boolean>(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
		useState<boolean>(false);

	// TAG TYPES variables
	const [currentTagType, setCurrentTagType] = useState<DtoTagType | null>(null);
	const [isEditTagTypeModalOpen, setIsEditTagTypeModalOpen] =
		useState<boolean>(false);
	const [isOpenConfirmationModalTagType, setIsOpenConfirmationModalTagType] =
		useState<boolean>(false);
	const [isLoadingTagType, setIsLoadingTagType] = useState<boolean>(false);
	const [tagTypes, setTagTypes] = useState<Array<DtoTagType>>([]);

	const getTranslatedColumnLabels = (): Array<string> => {
		const headersNames: Array<string> = [];
		tabsAdvancedSettings.tagsDatagridHeaders.forEach((header) =>
			headersNames.push(
				t(
					`old.programme.advancedSettings.tags.datagrid.headerLabels.${header.field}`,
				),
			),
		);
		return headersNames;
	};

	const fetchTagsAndTagTypes = async () => {
		setIsLoadingTagType(true);
		await Services.Events.Programme.SessionsService.getListTags(
			client.id,
			event.id,
			queryStringSorts(["order"]),
		)
			.then(async (_data: Array<any>) => {
				const tagTypesWithOrderedTags = _data;

				for (const tagType of tagTypesWithOrderedTags) {
					tagType.tags = await Services.Events.Programme.TagsService.getTags(
						client.id,
						event.id,
						tagType.id,
						queryStringSorts(["order"]),
					);
				}

				if (!currentTagType) {
					setCurrentTagType(tagTypesWithOrderedTags[0]);
				}

				setTagTypes(_data);
			})
			.catch((error: string) => {
				sendError(error);
			})
			.finally(() => {
				setIsLoadingTagType(false);
			});
	};

	const getActionButtons = (
		totalTagsNumber: number,
		index: number,
		tag: any,
	) => {
		const emptyButton = {
			disabled: true,
			onClick: () => {},
		};

		return {
			listButtons: [
				{
					...ActionReference[EnumActionsReference.EDIT],
					tooltip: t("old.programme.advancedSettings.tags.buttons.edit"),
					onClick: () => {
						setCurrentTag(tag);
						setIsEditTagModalOpen(true);
					},
				},
				index !== 0
					? {
							...ActionReference[EnumActionsReference.UP],
							tooltip: t("old.programme.advancedSettings.tags.buttons.moveUp"),
							onClick: () => {
								handleTagMove(tag.id, EnumActionsReference.UP);
							},
						}
					: emptyButton,
				index !== totalTagsNumber - 1
					? {
							...ActionReference[EnumActionsReference.DOWN],
							tooltip: t(
								"old.programme.advancedSettings.tags.buttons.moveDown",
							),
							onClick: () => {
								handleTagMove(tag.id, EnumActionsReference.DOWN);
							},
						}
					: emptyButton,
				{
					...ActionReference[EnumActionsReference.DELETE],
					tooltip: t("old.programme.advancedSettings.tags.buttons.delete"),
					onClick: () => {
						setCurrentTag(tag);
						setIsOpenConfirmationModal(true);
					},
				},
			],
		};
	};

	// Actions on TAGS TYPES
	const handleCreateOrUpdateTagType = async (
		_data: ISettingsSessionTagType,
	) => {
		if (!currentTagType) {
			try {
				await Services.Events.Programme.SessionsService.postTagType(
					client.id,
					event.id,
					_data,
				).then((_response: any) => {
					fetchTagsAndTagTypes();
					setIsEditTagTypeModalOpen(false);
					sendSuccess(
						t(
							"old.programme.advancedSettings.tagTypes.returnMessages.success_creation",
						),
					);
				});
			} catch (error: any) {
				if (error.status === EnumApiErrorStatus.STATUS_422) {
					sendError(
						t(
							"old.programme.advancedSettings.tagTypes.returnMessages.tag_type_already_exist",
						),
					);
				} else {
					sendError(t("old.common.errors.generic"));
				}
			}
		} else {
			try {
				await Services.Events.Programme.TagTypesService.put(
					client.id,
					event.id,
					currentTagType.id,
					_data,
				).then((_response: boolean | IErrorMessageReturn) => {
					fetchTagsAndTagTypes();
					setIsEditTagTypeModalOpen(false);
					sendSuccess(
						t(
							"old.programme.advancedSettings.tagTypes.returnMessages.success_modification",
						),
					);
				});
			} catch (error: any) {
				if (error.status === EnumApiErrorStatus.STATUS_422) {
					sendError(
						t(
							"old.programme.advancedSettings.tagTypes.returnMessages.tag_type_already_exist",
						),
					);
				} else {
					sendError(t("old.common.errors.generic"));
				}
			}
		}
	};

	const handleDeleteTagType = async () => {
		await Services.Events.Programme.TagTypesService.deleteEntity(
			client.id,
			event.id,
			currentTagType!.id,
		)
			.then(() => {
				fetchTagsAndTagTypes();
				setIsOpenConfirmationModalTagType(false);
				setSelectedIndex(0);
				sendSuccess(
					t(
						"old.programme.advancedSettings.tagTypes.returnMessages.success_deletion",
					),
				);
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	const handleListItemClick = (
		_event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number,
		tagType: DtoTagType,
	) => {
		setCurrentTagType(tagType);
		setSelectedIndex(index);
	};

	const handleTagTypeMove = async (tagId: string, action: string) => {
		const tagTypeListId: Array<string> = [];
		tagTypes.forEach((element: any) => {
			tagTypeListId.push(element.id);
		});

		const tagTypeIdIndex: number = tagTypeListId.map((id) => id).indexOf(tagId);
		const newTagTypeIndex =
			action === EnumActionsReference.UP
				? tagTypeIdIndex - 1
				: tagTypeIdIndex + 1;
		const tagsTypeOrderedList: Array<any> = tagTypeListId;

		tagsTypeOrderedList.splice(
			newTagTypeIndex,
			0,
			tagsTypeOrderedList.splice(tagTypeIdIndex, 1)[0],
		);

		await Services.Events.Programme.TagTypesService.putReOrderTagTypes(
			client.id,
			event.id,
			{
				ids: tagsTypeOrderedList,
			},
		)
			.then(() => {
				setSelectedIndex(newTagTypeIndex);
				fetchTagsAndTagTypes();
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	// Actions on TAGS
	const handleDeleteTag = async () => {
		await Services.Events.Programme.TagsService.deleteEntity(
			client.id,
			event.id,
			currentTagType!.id,
			currentTag!.id,
		)
			.then(() => {
				setIsOpenConfirmationModal(false);
				fetchTagsAndTagTypes();
				sendSuccess(
					t(
						"old.programme.advancedSettings.tags.returnMessages.success_deletion",
					),
				);
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	const handleCreateOrUpdateTag = async (_data: ISettingsSessionTag) => {
		if (!currentTag) {
			try {
				await Services.Events.Programme.TagsService.post(
					client.id,
					event.id,
					currentTagType!.id,
					_data,
				).then((_response: IEntity) => {
					fetchTagsAndTagTypes();
					sendSuccess(
						t(
							"old.programme.advancedSettings.tags.returnMessages.success_creation",
						),
					);
					setIsEditTagModalOpen(false);
				});
			} catch (error: any) {
				if (error.status === EnumApiErrorStatus.STATUS_422) {
					sendError(
						t(
							"old.programme.advancedSettings.tags.returnMessages.tag_already_exist",
						),
					);
				} else {
					sendError(t("old.common.errors.generic"));
				}
			}
		} else {
			try {
				await Services.Events.Programme.TagsService.put(
					client.id,
					event.id,
					currentTagType!.id,
					currentTag.id,
					_data,
				).then((_response: boolean | IErrorMessageReturn) => {
					fetchTagsAndTagTypes();
					setIsEditTagModalOpen(false);
					sendSuccess(
						t(
							"old.programme.advancedSettings.tags.returnMessages.success_modification",
						),
					);
				});
			} catch (error: any) {
				if (error.status === EnumApiErrorStatus.STATUS_422) {
					sendError(
						t(
							"old.programme.advancedSettings.tags.returnMessages.tag_already_exist",
						),
					);
				} else {
					sendError(t("old.common.errors.generic"));
				}
			}
		}
	};

	const handleTagMove = async (tagId: string, action: string) => {
		const tagListId: Array<string> = [];
		tagTypes[selectedIndex]?.tags?.forEach((element: any) => {
			tagListId.push(element.id);
		});

		const tagIdIndex: number = tagListId.map((id) => id).indexOf(tagId);
		const newTagTypeIndex =
			action === EnumActionsReference.UP ? tagIdIndex - 1 : tagIdIndex + 1;

		const tagsOrderedList: Array<any> = tagListId;

		tagsOrderedList.splice(
			newTagTypeIndex,
			0,
			tagsOrderedList.splice(tagIdIndex, 1)[0],
		);

		await Services.Events.Programme.TagsService.putReOrder(
			client.id,
			event.id,
			currentTagType!.id,
			{ ids: tagsOrderedList },
		)
			.then(() => {
				fetchTagsAndTagTypes();
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	useEffect(() => {
		const rows: Array<GridValidRowModel> = [];

		tagTypes[selectedIndex]?.tags?.forEach((tag: any, index: number) => {
			rows.push({
				id: tag.id,
				label: {
					chip: {
						label: tag.label,
						backgroundColor: tag.backgroundColor,
						fontColor: tag.fontColor,
						borderColor: tag.borderColor,
					},
				},
				actionButtons: getActionButtons(
					tagTypes[selectedIndex]?.tags?.length ?? 0,
					index,
					tag,
				),
			});
		});

		setDataGridRow(rows);
	}, [tagTypes, selectedIndex]);

	useEffect(() => {
		fetchTagsAndTagTypes();
	}, []);

	const renderIconCardChildren = () => {
		return isLoadingTagType ? (
			<Skeleton height={500}></Skeleton>
		) : (
			<Grid container direction="row" spacing={3}>
				<Grid item xs={12} md={3} sx={{ ml: mobileSize ? 0 : -3 }}>
					<Stack spacing={2} sx={{ mt: mobileSize ? 0 : -1 }}>
						<Box
							sx={{
								width: "100%",
								maxWidth: 360,
								bgcolor: ApplicationColors.greyLight.light,
								border: `2px solid ${ApplicationColors.greyLight.main}`,
							}}
						>
							<List component="nav">
								{tagTypes.map((tagType, index) => {
									return (
										<ListItemButton
											key={tagType.id}
											selected={selectedIndex === index}
											onClick={(event) => {
												handleListItemClick(event, index, tagType);
											}}
										>
											<ListItemText primary={tagType.label} />
											<ContextualMenu
												index={index}
												minIndex={1}
												maxIndex={tagTypes.length}
												menu={{
													items: [
														{
															label: t(
																"old.programme.advancedSettings.tagTypes.buttons.edit",
															),
															reference: EnumContextualMenu.EDIT,
															icon: EnumIcon.PEN_TO_SQUARE,
															onClick: () => {
																setIsEditTagTypeModalOpen(true);
															},
														},
														{
															label: t(
																"old.programme.advancedSettings.tagTypes.buttons.moveUp",
															),
															reference: EnumContextualMenu.UP,
															icon: EnumIcon.UP_LONG,
															onClick: () => {
																currentTagType &&
																	handleTagTypeMove(
																		currentTagType.id,
																		EnumActionsReference.UP,
																	);
															},
														},

														{
															label: t(
																"old.programme.advancedSettings.tagTypes.buttons.moveDown",
															),
															reference: EnumContextualMenu.DOWN,
															icon: EnumIcon.DOWN_LONG,
															onClick: () => {
																currentTagType &&
																	handleTagTypeMove(
																		currentTagType.id,
																		EnumActionsReference.DOWN,
																	);
															},
														},
														{
															label: t(
																"old.programme.advancedSettings.tagTypes.buttons.delete",
															),
															reference: EnumContextualMenu.DELETE,
															icon: EnumIcon.TRASH_CAN,
															onClick: () => {
																setIsOpenConfirmationModalTagType(true);
															},
														},
													],
												}}
											></ContextualMenu>
										</ListItemButton>
									);
								})}
							</List>
						</Box>
						<Button
							sx={{ maxWidth: "fit-content" }}
							variant="contained"
							onClick={() => {
								setCurrentTagType(null);
								setIsEditTagTypeModalOpen(true);
							}}
						>
							<IconOld iconName="plus" mr={2} />
							<Typography ml={1}>
								{t("old.programme.advancedSettings.tagTypes.buttons.add")}
							</Typography>
						</Button>
					</Stack>
				</Grid>
				<Grid item xs={12} md={9} sx={{ mt: mobileSize ? 0 : -7 }}>
					<Stack spacing={2}>
						<Stack
							direction={mobileSize ? "column" : "row"}
							justifyContent="space-between"
						>
							<Typography variant="h6">
								{" "}
								{tagTypes[selectedIndex]?.label}
							</Typography>
							{tagTypes[selectedIndex]?.max && (
								<Typography variant="h6">
									{selectedIndex === 0 &&
										`${t("old.programme.advancedSettings.tags.mainTag")} / `}
									{tagTypes[selectedIndex]?.max &&
									tagTypes[selectedIndex].max! > 1
										? t(
												"old.programme.advancedSettings.tags.maxSelectionNumberPlural",
												{ entity: tagTypes[selectedIndex].max },
											)
										: t(
												"old.programme.advancedSettings.tags.maxSelectionNumberSingular",
												{ entity: tagTypes[selectedIndex].max },
											)}
								</Typography>
							)}
						</Stack>
						<DataGridOld
							isAutoHeight
							isHideFooter
							sx={{ minHeight: "30rem" }}
							columns={getColumns(
								getTranslatedColumnLabels(),
								tabsAdvancedSettings.tagsDatagridHeaders,
							)}
							rows={dataGridRow}
						/>
						<Button
							sx={{ maxWidth: "fit-content", alignSelf: "end" }}
							variant="contained"
							onClick={() => {
								setCurrentTag(null);
								setIsEditTagModalOpen(true);
							}}
						>
							<IconOld iconName="plus" />
							<Typography ml={1}>
								{t("old.programme.advancedSettings.tags.buttons.add")}
							</Typography>
						</Button>
					</Stack>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<IconCard
				title={t("old.programme.advancedSettings.tags.title")}
				contentSpacing={mobileSize ? 0 : 3}
			>
				{renderIconCardChildren()}
			</IconCard>

			<DefaultModal
				open={isEditTagModalOpen}
				title={
					currentTag
						? t("old.programme.advancedSettings.tags.modale.edit.title")
						: t("old.programme.advancedSettings.tags.modale.create.title")
				}
			>
				<AdvancedSettingsSessionTagEditModal
					tag={currentTag}
					updateTag={handleCreateOrUpdateTag}
					editModaleClose={() => {
						setIsEditTagModalOpen(false);
					}}
				/>
			</DefaultModal>

			<ConfirmationModal
				open={isOpenConfirmationModal}
				text={t("old.programme.advancedSettings.tags.modale.delete.message")}
				action={EConfirmationModalAction.DELETE}
				handleModaleClose={() => {
					setIsOpenConfirmationModal(false);
				}}
				handleAction={handleDeleteTag}
				maxWidth={"sm"}
			/>

			<ConfirmationModal
				open={isOpenConfirmationModalTagType}
				text={t(
					"old.programme.advancedSettings.tagTypes.modale.delete.message",
				)}
				action={EConfirmationModalAction.DELETE}
				handleModaleClose={() => {
					setIsOpenConfirmationModalTagType(false);
				}}
				handleAction={handleDeleteTagType}
				maxWidth={"sm"}
			/>

			<DefaultModal
				open={isEditTagTypeModalOpen}
				title={
					currentTagType
						? t("old.programme.advancedSettings.tagTypes.modale.edit.title")
						: t("old.programme.advancedSettings.tagTypes.modale.create.title")
				}
			>
				<AdvancedSettingsSessionTagTypeEditModal
					tagType={currentTagType}
					editModaleClose={() => {
						setIsEditTagTypeModalOpen(false);
					}}
					isPrimaryTag={selectedIndex === 0 && currentTagType !== null}
					updateTagType={(data: ISettingsSessionTagType) => {
						handleCreateOrUpdateTagType(data);
					}}
				></AdvancedSettingsSessionTagTypeEditModal>
			</DefaultModal>
		</>
	);
};
