import type { DtoToken } from "@key4-front-library/core";

export const dtoTokenToExternalLink = (
	token: DtoToken,
	domain: string | null | undefined,
): string | undefined => {
	if (domain && token.expireAt && new Date(token.expireAt) > new Date()) {
		return `${domain.replace(/\/$/, "")}/page-external-session-link?token=${token.id}`;
	}
};
