import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import NavigationTabsWithBadges from "@application/components/_common/Navigation/NavigationTabsWithBadges";
import Faculty, {
	EFacultyType,
	type InvitationRow,
} from "@application/Configurations/faculty.configuration";
import IndicatorsDefinitions from "@application/Configurations/indicators.configuration";
import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import BadTabsConfiguration from "@application/Configurations/tabs/badTabs.config";
import ContainerChairModal from "@application/Containers/ContainerChairModal";
import ContainerSendMailModal from "@application/Containers/ContainerSendMailModal";
import ContainerSpeakerModal from "@application/Containers/ContainerSpeakerModal";
import CalendarController from "@application/Controllers/CalendarController";
import FacultyController from "@application/Controllers/FacultyController";
import PresentationController from "@application/Controllers/PresentationController";
import SessionController from "@application/Controllers/SessionController";
import SettingsController from "@application/Controllers/SettingsController";
import { EFacultyMenuItemReference } from "@application/enum/FacultyMenuItemReference.enum";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import { MenuItemReference } from "@application/enum/MenuItemReference.enum";
import NavigationFactory from "@application/factory/navigation/NavigationFactory";
import GetTranslationsForFacultyDetails from "@application/helpers/translations/faculties/GetTranslationsForFacultyDetails";
import {
	ESchedulerView,
	type ICalendarSession,
	type ICalendarSettings,
} from "@domain/interfaces/calendar.interface";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import { ERoomView } from "@domain/interfaces/room.interface";
import type {
	IconName,
	IconPrefix,
	SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import K4Calendar from "@infrastructure/components/calendar/K4Calendar";
import Card2 from "@infrastructure/components/interface/card/Card";
import Gravatar from "@infrastructure/components/interface/gravatar/Gravatar";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import Loading from "@infrastructure/components/interface/loading/Loading";
import { isGuid } from "@infrastructure/services/api/guid";
import {
	ActionReference,
	ButtonsTitle,
	capitalizeStr,
	ConfirmationModal,
	DataGridOld,
	type DtoFacultyGetDetails,
	type DtoParticipantGet,
	EMenuPath,
	EnumActionsReference,
	EnumApiErrorStatus,
	EnumCustomFieldScope,
	ErrorAPI,
	ESieveOperator,
	ExternalRouting,
	FacultyHelper,
	getColumns,
	queryFilters,
	queryStringPagination,
	Services,
	statusInvitationParticipantObject,
	type TFacultyForm,
	TitleRef,
	useContextModule,
	useIdentity,
} from "@key4-front-library/core";
import {
	alpha,
	Box,
	Divider,
	Grid,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	useTheme,
} from "@mui/material";

const viewModes = [
	{
		value: ERoomView.LIST,
		icon: "table-list",
		prefix: "far",
		size: "lg",
	},
	{
		value: ERoomView.SCHEDULER,
		icon: "calendar-days",
		prefix: "far",
		size: "lg",
	},
];

const FacultyDetails = () => {
	const identity = useIdentity();
	const translations = GetTranslationsForFacultyDetails();
	const theme = useTheme();
	const { client, event } = useContextModule();

	const { participantId } = useParams();

	const navigate = useNavigate();

	const [participant, setParticipant] = useState<DtoParticipantGet>();
	const [faculty, setFaculty] = useState<DtoFacultyGetDetails>();
	const [invitations, setInvitations] = useState<Array<InvitationRow>>([]);

	const [sessions, setSessions] = useState<Array<ICalendarSession> | null>(
		null,
	);
	const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
	const [, setError] = useState<ErrorAPI | undefined>();
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});
	const [viewMode, setViewMode] = useState<ERoomView>(ERoomView.LIST);

	const [calendarSettings, setCalendarSettings] = useState<ICalendarSettings>();

	const [isOpenSendMailModal, setIsOpenSendMailModal] = useState(false);

	const [isOpenFacultyModal, setIsOpenFacultyModal] = useState(false);
	const [facultyModal, setFacultyModal] = useState<TFacultyForm>({
		...FacultyHelper.defaultFacultyForm,
	});

	const [facultyModalType, setFacultyModalType] = useState<EFacultyType>(
		EFacultyType.CHAIR,
	);

	const [activeClashesCounter, setActiveClashesCounter] = useState<
		number | undefined
	>(0);
	const [unactiveClashesCounter, setUnactiveClashesCounter] = useState<
		number | undefined
	>(0);

	const [CFScope, setCFScope] = useState(EnumCustomFieldScope.FACULTY);

	let menuItems =
		participantId &&
		BadTabsConfiguration.GetFacultyMenuConfiguration(client.key, event.key);
	if (unactiveClashesCounter === 0 && activeClashesCounter === 0) {
		menuItems =
			menuItems &&
			menuItems.filter(
				(menuItem) => menuItem.reference !== EFacultyMenuItemReference.CLASHES,
			);
	}

	const actionsConfigurations = GetMenuActionsConfigurations();
	const getConfig = (type: MenuActionsReference) =>
		actionsConfigurations.find((x) => x.reference === type);

	const redirectionConfig = getConfig(MenuActionsReference.REDIRECTION);

	useEffect(() => {
		if (faculty) {
			// TODO TM : This kind of mechanism should be in a "Mapping" layer
			const f = { ...FacultyHelper.defaultFacultyForm };
			f.participant = {
				...FacultyHelper.defaultFacultyForm.participant!,
			};

			const rows: Array<InvitationRow> = [];

			faculty.sessions?.forEach((session, sessionIndex) => {
				session.chairs?.forEach((chair, chairIndex) => {
					if (chair.participantId === faculty.participantId) {
						const uniqueKey = `chair-${sessionIndex}-${chairIndex}`;
						rows.push({
							id: uniqueKey,
							session,
							presentation: null,
							invitationType: chair.qualities?.at(0)
								? chair.qualities.at(0)?.label
								: t("old.common.chair"),
							status: {
								label: t(
									statusInvitationParticipantObject[chair.invitationStatus]
										.label,
								),
								iconColor:
									statusInvitationParticipantObject[chair.invitationStatus]
										.bgColor,
							},
							actions: {
								listButtons: [
									{
										...ActionReference[EnumActionsReference.DETAILS],
										onClick: () => {
											handleNavigateToSessionDetailsClick(session.id);
										},
									},
									{
										...ActionReference[EnumActionsReference.EDIT],
										onClick: () => {
											f.participant!.id = faculty.participantId;
											f.sessionId = session.id;
											handleOpenFacultyModal(f, EFacultyType.CHAIR);
										},
									},
									{
										...ActionReference[EnumActionsReference.DELETE],
										onClick: () => {
											confirmationModalProps.current = {
												action: EConfirmationModalAction.DELETE,
												handleAction: () => {
													handleDeleteFaculty(
														faculty.participantId,
														EFacultyType.CHAIR,
														session.id,
													);
												},
											};
											setOpenConfirmationModal(true);
										},
									},
								],
							},
						});
					}
				});

				session.presentations?.forEach((presentation, presentationIndex) => {
					presentation.speakers?.forEach((speaker, speakerIndex) => {
						if (speaker.participantId === faculty.participantId) {
							const uniqueKey = `presentation-${sessionIndex}-${presentationIndex}-${speakerIndex}`;
							rows.push({
								id: uniqueKey,
								session,
								presentation,
								invitationType: speaker.qualities.at(0)
									? speaker.qualities.at(0)?.label
									: t("old.common.speaker"),
								status: {
									label: t(
										statusInvitationParticipantObject[speaker.invitationStatus]
											.label,
									),
									iconColor:
										statusInvitationParticipantObject[speaker.invitationStatus]
											.bgColor,
								},
								actions: {
									listButtons: [
										{
											...ActionReference[EnumActionsReference.DETAILS],
											onClick: () => {
												handleNavigateToSessionDetailsClick(session.id);
											},
										},
										{
											...ActionReference[EnumActionsReference.EDIT],
											onClick: () => {
												f.participant!.id = faculty.participantId;
												f.presentationId = presentation.id;
												f.sessionId = session.id;
												handleOpenFacultyModal(f, EFacultyType.SPEAKER);
											},
										},
										{
											...ActionReference[EnumActionsReference.DELETE],
											onClick: () => {
												confirmationModalProps.current = {
													action: EConfirmationModalAction.DELETE,
													handleAction: () => {
														handleDeleteFaculty(
															faculty.participantId,
															EFacultyType.SPEAKER,
															session.id,
															presentation.id,
														);
													},
												};
												setOpenConfirmationModal(true);
											},
										},
									],
								},
							});
						}
					});
				});
			});

			if (rows.length) {
				setInvitations(rows);
			} else {
				navigate(["", client.key, event.key, EMenuPath.FACULTIES].join("/"));
			}
		}
	}, [faculty]);

	const getTranslatedColumnLabels = (): Array<string> => {
		const headersNames: Array<string> = [];
		Faculty.invitationsDatagridHeaders.forEach((header) =>
			headersNames.push(
				t(`old.programme.facultyDetails.datagrid.headerLabels.${header.field}`),
			),
		);
		return headersNames;
	};

	const initComponent = useCallback(async () => {
		// TODO error display
		if (!participantId) return;

		setParticipant(
			await Services.Events.Registration.ParticipantsService.get(
				client.id,
				event.id,
				participantId,
			),
		);

		let facultyData;
		try {
			facultyData = await FacultyController.getFacultyDetails(
				client.id,
				event.id,
				participantId,
			);
		} catch (error: any) {
			if (error.status === 404 || error.status === undefined) {
				navigate(["", client.key, event.key, EMenuPath.FACULTIES].join("/"));
			}
			return;
		}

		const settingsClashes = await SettingsController.getSettingsClashes(
			client.id,
			event.id,
		);

		// TODO error display
		if (settingsClashes instanceof ErrorAPI) return;

		const dataCalendarSettings = await CalendarController.getCalendarSettings(
			client.id,
			event.id,
		);

		if (dataCalendarSettings instanceof ErrorAPI) {
			setError(dataCalendarSettings);
			return;
		}

		const dataSessions = await CalendarController.getFacultySessions(
			client.id,
			event.id,
			participantId,
			settingsClashes.isSpeakerBusySessionTimeSlot,
			dataCalendarSettings,
		);

		if (dataSessions instanceof ErrorAPI) {
			setError(dataSessions);
			return;
		}

		if (facultyData instanceof ErrorAPI) {
			setError(facultyData);
			return;
		}
		setFaculty(facultyData);
		setSessions(dataSessions);
		setCalendarSettings(dataCalendarSettings);
		setIsApiLoading(false);
	}, [participantId, client, event, navigate]);

	const initBadgesCountersTabs = useCallback(() => {
		if (!participantId || !event.id) return;

		if (!isGuid(participantId)) {
			setError(
				new ErrorAPI(EnumApiErrorStatus.STATUS_404, "session not found"),
			);

			return;
		}

		const fetchData = async () => {
			return {
				_activeClashes:
					await Services.Events.Programme.FacultiesService.getFacultyClashes(
						client.id,
						event.id,
						participantId,
						[
							...queryFilters(`ignoredDate${ESieveOperator.EQUALS}null`),
							...queryStringPagination({
								page: 0,
								pageSize: 100,
							}),
						],
					),

				_unactiveClashes:
					await Services.Events.Programme.FacultiesService.getFacultyClashes(
						client.id,
						event.id,
						participantId,
						[
							...queryFilters(`ignoredDate${ESieveOperator.NOT_EQUALS}null`),
							...queryStringPagination({
								page: 0,
								pageSize: 100,
							}),
						],
					),
			};
		};

		fetchData().then((results) => {
			const { _activeClashes, _unactiveClashes } = results;
			if (_activeClashes instanceof ErrorAPI) {
				setError(_activeClashes);
				return;
			}
			if (_unactiveClashes instanceof ErrorAPI) {
				setError(_unactiveClashes);
				return;
			}

			setActiveClashesCounter(_activeClashes.pagination.totalCount);
			setUnactiveClashesCounter(_unactiveClashes.pagination.totalCount);
		});
	}, [participantId, event]);

	useEffect(() => {
		initComponent();
		initBadgesCountersTabs();
	}, [event, initBadgesCountersTabs, initComponent]);

	useEffect(() => {
		setCFScope(
			facultyModalType === EFacultyType.CHAIR
				? EnumCustomFieldScope.CHAIR
				: EnumCustomFieldScope.SPEAKER,
		);
	}, [facultyModalType]);

	const refreshFaculty = async () => {
		if (!participantId) return;

		const facultyData = await FacultyController.getFacultyDetails(
			client.id,
			event.id,
			participantId,
		);

		if (facultyData instanceof ErrorAPI) return;

		setFaculty(facultyData);
	};

	const buttons = [
		{
			label: translations.buttons.participant,
			link: "",
			icon: { icon: redirectionConfig?.icon! },
			color: "secondary" as const,
			handleClick: () => {
				window.open(
					[
						ExternalRouting.Registration,
						client.key,
						event.key,
						EMenuPath.PARTICIPANTS,
						participantId,
					].join("/"),
				);
			},
		},
		{
			label: translations.buttons.sendMail,
			icon: "paper-plane" as IconName,
			color: "primary" as const,
			handleClick: () => {
				setIsOpenSendMailModal(true);
			},
		},
	];

	const titleIcons = [];
	faculty &&
		faculty.anomaliesCount > 0 &&
		titleIcons.unshift({
			name: IndicatorsDefinitions.anomalies.icon,
			color: theme.palette.warning.main,
		});
	faculty &&
		faculty.clashesCount > 0 &&
		titleIcons.unshift({
			name: IndicatorsDefinitions.clashes.icon,
			color: theme.palette.error.main,
		});

	const handleOpenFacultyModal = (
		facultyForm: TFacultyForm,
		type: EFacultyType,
	) => {
		setFacultyModalType(type);
		setFacultyModal(facultyForm);
		setIsOpenFacultyModal(true);
	};

	const handleModaleClose = () => {
		setOpenConfirmationModal(false);
	};

	const handleSendMailModalClose = (reason?: string) => {
		if (reason == "backdropClick") return;
		setIsOpenSendMailModal(false);
	};

	const handleDeleteFaculty = async (
		_id: string,
		chairsOrSpeakers: EFacultyType,
		sessionId: string,
		presentationId?: string,
	) => {
		if (_id) {
			switch (chairsOrSpeakers) {
				case EFacultyType.CHAIR:
					await SessionController.deleteChairFromSession({
						clientId: client.id,
						chairId: _id,
						sessionId,
						eventId: event.id,
					});
					break;
				case EFacultyType.SPEAKER:
					await PresentationController.deletePresentationSpeaker(
						client.id,
						event.id,
						sessionId,
						presentationId!,
						_id,
					);
					break;
				default:
					break;
			}
			handleModaleClose();
			setIsApiLoading(true);
			initComponent();
		}
	};

	const handleChangeViewClick = (
		_event: React.MouseEvent<HTMLElement>,
		newViewMode: ERoomView,
	) => {
		if (newViewMode === null) return;

		setViewMode(newViewMode);
	};

	const handleNavigateToSessionDetailsClick = (sessionId: string) => {
		navigate(
			["", client.key, event.key, EMenuPath.SESSIONS, sessionId].join("/"),
		);
	};

	if (isApiLoading || !faculty || !calendarSettings) return <Loading />;

	const rightMenuRendering = () => {
		return (
			<Card2
				title={
					<Stack direction={"row"} spacing={2}>
						<Icon
							prefix="far"
							iconName="id-card"
							color={theme.palette.primary.main}
						/>
						{/* TODO TM : Maybe this should use an other translations ? */}
						<Typography variant="h5">
							{capitalizeStr(translations.participant)}
						</Typography>
					</Stack>
				}
			>
				<>
					<Divider />
					{participant?.invitationStatus && (
						<Stack mb="1rem" spacing=".3rem" marginTop={"1rem"}>
							<Typography variant="subtitle1" color={theme.palette.grey[500]}>
								{capitalizeStr(translations.status)}
							</Typography>
							<Box
								sx={{
									padding: "10px 10px",
									borderRadius: "4px",
									bgcolor:
										statusInvitationParticipantObject[
											participant.invitationStatus
										].bgColor,
									color:
										statusInvitationParticipantObject[
											participant.invitationStatus
										].fontColor,
								}}
								pr={1.25}
							>
								<Typography>
									{t(
										statusInvitationParticipantObject[
											participant.invitationStatus
										].label,
									)}
								</Typography>
							</Box>
						</Stack>
					)}
					{participant?.profile?.name && (
						<Stack mb="1rem" spacing=".3rem">
							<Typography variant="subtitle1" color={theme.palette.grey[500]}>
								{translations.profile}
							</Typography>
							<Typography>{participant.profile.name}</Typography>
						</Stack>
					)}
					{faculty.email && (
						<Stack mb="1rem" spacing=".3rem">
							<Typography color="text.disabled">
								{translations.email}
							</Typography>
							<Typography>{participant?.email}</Typography>
						</Stack>
					)}
				</>
			</Card2>
		);
	};

	return (
		<>
			<Grid pt="3rem" container alignItems="center" data-testid="title">
				<Grid item xs>
					<Stack direction={"row"} spacing={1.5} alignItems={"center"}>
						<Gravatar
							email={faculty.email ?? ""}
							firstname={faculty.firstname ?? ""}
							lastname={faculty.lastname ?? ""}
						/>
						<Typography
							sx={{
								fontWeight: "light",
								fontSize: "34px",
								color: identity.darkmode
									? theme.palette.common.white
									: alpha(theme.palette.common.black, 0.87),
							}}
						>
							{`${faculty.firstname} ${faculty.lastname}`}
						</Typography>
					</Stack>
				</Grid>
				<ButtonsTitle buttons={buttons} />
			</Grid>
			<Stack pb="1rem">
				<TitleRef reference={faculty.key} />
			</Stack>

			<Grid container columnSpacing={{ sm: 7.5 }} rowSpacing="1rem">
				<Grid item xs={12} md={9} container rowSpacing="1rem">
					<Grid item xs={12} display={{ sm: "none" }}>
						{rightMenuRendering()}
					</Grid>
					<Grid item xs={12}>
						<Card2>
							<>
								<Stack
									direction="row"
									alignItems={"center"}
									justifyContent={"space-between"}
									marginBottom={"1rem"}
									marginTop={"-3rem"}
								>
									<Stack
										direction={"row"}
										spacing={1}
										alignContent={"center"}
										sx={{ marginLeft: "1.5em" }}
									>
										<Icon
											prefix="far"
											iconName="mail-bulk"
											size="2x"
											sx={{ alignSelf: "center" }}
										/>
										<Typography
											fontSize="2em"
											fontWeight={"Light"}
											sx={{
												width: "80px",
												marginLeft: "1.5em",
											}}
										>
											{translations.invitations}
										</Typography>
									</Stack>
									<ToggleButtonGroup
										sx={{
											width: "80px",
											marginRight: "1.5em",
										}}
										value={viewMode}
										exclusive
										onChange={handleChangeViewClick}
										aria-label="text alignment"
									>
										{viewModes.map((item: any, key: any) => {
											return (
												<ToggleButton
													key={key}
													sx={{
														padding: ".5em 1em",
														backgroundColor:
															viewMode === item.value
																? `${theme.palette.primary.main} !important`
																: "",
													}}
													value={item.value}
												>
													<Icon
														size={item.size as SizeProp}
														color={
															viewMode === item.value
																? `${theme.palette.common.white} !important`
																: ""
														}
														prefix={item.prefix as IconPrefix}
														iconName={item.icon as IconName}
													/>
												</ToggleButton>
											);
										})}
									</ToggleButtonGroup>
								</Stack>
								<Divider />
								{(!viewMode || viewMode === ERoomView.LIST) && (
									<DataGridOld
										sx={{ marginTop: "1.5em" }}
										rowHeight={95}
										density={"standard"}
										columns={getColumns(
											getTranslatedColumnLabels(),
											Faculty.invitationsDatagridHeaders,
										)}
										rows={invitations}
										isAutoHeight={true}
										isHideFooter={true}
									/>
								)}

								{(!viewMode || viewMode === ERoomView.SCHEDULER) && (
									<Stack sx={{ marginTop: "1.5rem" }}>
										<K4Calendar
											isReadOnly={true}
											calendarSettings={calendarSettings}
											views={{
												calendar: SchedulerConfiguration.roomScheduler,
											}}
											view={ESchedulerView.CALENDAR}
											events={sessions ?? []}
											locale={translations.locale}
										/>
									</Stack>
								)}
							</>
						</Card2>
					</Grid>
				</Grid>

				<Grid item xs={12} md={3} container>
					<Grid item xs={true} display={{ xs: "none", sm: "block" }}>
						{rightMenuRendering()}
					</Grid>
				</Grid>
			</Grid>

			{participantId && menuItems && (
				<Grid item xs={12} mt={2}>
					<NavigationTabsWithBadges
						menuItems={NavigationFactory.GetMenuItemsTranslations({
							menuItems,
						})}
						badges={[
							{
								reference: EFacultyMenuItemReference.CLASHES,
								number: activeClashesCounter ?? 0,
							},
						]}
						baseUrl={`/${client.key}/${event.key}/${MenuItemReference.FACULTIES}/${participantId}`}
						defaultTabsValue={EFacultyMenuItemReference.MAILS}
						routeParentLastPartPath={participantId}
					/>
					<Outlet context={{ initBadgesCountersTabs }} />
				</Grid>
			)}

			<ConfirmationModal
				open={openConfirmationModal}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleModaleClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>

			<ContainerSendMailModal
				isOpenModal={isOpenSendMailModal}
				handleModalClose={handleSendMailModalClose}
				facultyId={faculty.id}
			/>

			{facultyModalType === EFacultyType.SPEAKER && CFScope && (
				<ContainerSpeakerModal
					changeIsOpen={(isOpen: boolean) => {
						setIsOpenFacultyModal(isOpen);
					}}
					isOpen={isOpenFacultyModal}
					speakerId={facultyModal.participant?.id}
					presentationId={facultyModal.presentationId!}
					callbackSubmit={refreshFaculty}
					sessionId={facultyModal.sessionId!}
				></ContainerSpeakerModal>
			)}
			{facultyModalType === EFacultyType.CHAIR && CFScope && (
				<ContainerChairModal
					changeIsOpen={(isOpen: boolean) => {
						setIsOpenFacultyModal(isOpen);
					}}
					isOpen={isOpenFacultyModal}
					chairId={facultyModal.participant?.id}
					sessionId={facultyModal.sessionId!}
					callbackSubmit={refreshFaculty}
				></ContainerChairModal>
			)}
		</>
	);
};

export default FacultyDetails;
