import { t } from "i18next";
import { cloneDeep } from "lodash";
import { type BaseSyntheticEvent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import ControllersApp from "@application/Controllers/ControllersApp";
import HelpersApp from "@application/helpers";
import HooksApp from "@application/hooks";
import type { TypeSessionExtraData } from "@application/Types/SessionType";
import {
	ButtonCancel,
	ButtonSave,
	type DataCypress,
	Dialog,
	FormTabs,
	LinkWithCopy,
	useSnackBarHook,
} from "@key4-front-library/core";
import DialogTitle from "@key4-front-library/core/Bo/Components/DialogTitle";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { Skeleton, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export type PropsContainerSessionModal = {
	activeTabKey?: string;
	callbackSubmit?: () => void;
	changeIsOpen: (newIsOpen: boolean) => void;
	id?: string;
	isOpen: boolean;
	listExtraData?: Array<TypeSessionExtraData>; // Not working with primaryTag and secondariesTags
};

// eslint-disable-next-line max-lines-per-function
const ContainerSessionModal = (
	props: PropsContainerSessionModal & DataCypress,
) => {
	const {
		activeTabKey,
		callbackSubmit,
		changeIsOpen,
		id,
		isOpen,
		listExtraData,
	} = props;
	const { sendSuccess, sendError } = useSnackBarHook();

	// const translationsForSession = GetTranslationsForCreateEditSession(t);

	const { readSettingsScheduler } = HooksApp.SettingsHook.useEntity();
	const { data: dataSettingsScheduler } = readSettingsScheduler();

	const { useSessionCustomFormQuery } = HooksApp.SessionHook.useEntity();
	const { data, isFetching, refetch } = useSessionCustomFormQuery(
		id,
		listExtraData,
		dataSettingsScheduler,
	);

	const { update, create } = ControllersApp.SessionController.useEntity();

	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

	const formMethods = useForm<TypeUseFormListForms>({
		mode: "onSubmit",
		resolver: HelpersApp.SessionHelper.getResolver(
			data ? cloneDeep(data.useFormData) : undefined,
			t,
		),
	});

	const { handleSubmit: onSubmit, reset } = formMethods;

	useEffect(() => {
		if (!isFetching && data) {
			reset(data.useFormData);
		}
	}, [isFetching]);

	useEffect(() => {
		if (isOpen) {
			refetch();
		}
	}, [isOpen]);

	const handleSubmit = async (useFormData: TypeUseFormListForms) => {
		if (!id) {
			if (await create(useFormData)) {
				sendSuccess(
					t("old.programme.sessions.create_edit_modale.snackbar.created"),
				);
				changeIsOpen(false);
				callbackSubmit?.();
			} else {
				sendError(
					t("old.programme.sessions.create_edit_modale.snackbar.error"),
				);
			}
		} else if (await update(id, useFormData)) {
			sendSuccess(
				t("old.programme.sessions.create_edit_modale.snackbar.updated"),
			);
			changeIsOpen(false);
			callbackSubmit?.();
		} else {
			sendError(t("old.programme.sessions.create_edit_modale.snackbar.error"));
		}
		setIsLoadingSubmit(false);
	};

	const handleInvalid = (_error: any) => {
		setIsLoadingSubmit(false);
		sendError(t("old.programme.sessions.create_edit_modale.snackbar.invalid"));
	};

	return (
		<Dialog isOpen={isOpen}>
			<>
				<DialogTitle
					title={
						<Stack direction={"row"} spacing={1} alignItems={"center"}>
							<Typography variant="h5">
								{id
									? t("old.programme.sessions.create_edit_modale.title.edit")
									: t("old.programme.sessions.create_edit_modale.title.create")}
							</Typography>
							{id && (
								<>
									<Typography variant="h5">{"-"}</Typography>
									<LinkWithCopy
										text={data?.raw?.key!}
										path={`sessions/${data?.raw?.id}`}
									/>
								</>
							)}
						</Stack>
					}
					onCloseClick={() => {
						changeIsOpen(false);
					}}
				/>
				<DialogContent>
					{data && !isFetching && (
						<FormProvider {...formMethods}>
							<FormTabs activeTabKey={activeTabKey} {...data.componentData} />
						</FormProvider>
					)}
					{isFetching && (
						<Stack spacing={2}>
							<Skeleton
								sx={{ height: 40 }}
								animation="wave"
								variant="rectangular"
							/>
							<Skeleton
								sx={{ height: 450 }}
								animation="wave"
								variant="rectangular"
							/>
						</Stack>
					)}
				</DialogContent>
				<DialogActions>
					<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
						<ButtonCancel
							onClick={() => {
								changeIsOpen(false);
							}}
						/>
						<ButtonSave
							isLoading={isLoadingSubmit}
							disabled={isLoadingSubmit || !isOpen}
							onClick={(
								e: BaseSyntheticEvent<object, any, any> | undefined,
							) => {
								setIsLoadingSubmit(true);
								onSubmit(handleSubmit, handleInvalid)(e);
							}}
						/>
					</Stack>
				</DialogActions>
			</>
		</Dialog>
	);
};

export default ContainerSessionModal;
