import { t } from "i18next";
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";

import { activityMatchDtoToUserInformationList } from "@application/Mappers";
import { participantDtoListToUserInformationList } from "@application/Mappers/Participant";
import type { UserInformationWithMetadata } from "@application/Types";
import {
	type ActivityMatchDto,
	type DtoParticipantGet,
	escapeSieveString,
	ESieveOperator,
	ExternalRouting,
	queryFilters,
	queryStringPagination,
	Services,
	type TypeApiResponsePagined,
	useContextModule,
	UserInformationRadio,
} from "@key4-front-library/core";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

export interface MatchingParticipantSectionProps {
	matchingId?: string;
	userSelected?: UserInformationWithMetadata;
	setParticipantActivityCount: (count: number) => void;
	onUserSelectedChange: (user: UserInformationWithMetadata) => void;
	search: string;
}

export const MatchingParticipantSection = (
	props: MatchingParticipantSectionProps,
): React.ReactElement => {
	const {
		matchingId,
		userSelected,
		setParticipantActivityCount,
		onUserSelectedChange,
		search,
	} = props;
	const component = "old.common.matchingParticipantSection";
	const { client, event } = useContextModule();
	const [participantsProposals, setParticipantsProposals] =
		useState<Array<UserInformationWithMetadata>>();

	const { data: participants } = useQuery({
		queryKey: [
			"client",
			client.id,
			"event",
			event.id,
			"participants",
			search,
			"page=1",
			"pageSize10",
		],
		queryFn: (): Promise<TypeApiResponsePagined<Array<DtoParticipantGet>>> =>
			Services.Events.Registration.ParticipantsService.getListPagined(
				client.id,
				event.id,
				[
					...queryFilters(
						[
							["lastname", "firstname", "email"].join(ESieveOperator.OR_IN),
							ESieveOperator.CI_CONTAINS,
							escapeSieveString(search),
						].join(""),
					),
					...queryStringPagination({ page: 0, pageSize: 10 }),
				],
			),
		enabled: !!search,
		placeholderData: (previousData) => previousData,
	});

	const { data: participantActivity } = useQuery({
		queryKey: [
			"matching",
			"participants",
			"proposal",
			matchingId,
			client.id,
			event.id,
		],
		queryFn: (): Promise<Array<ActivityMatchDto>> =>
			Services.Registration.Clients.ParticipantsService.postMatching(
				client.id,
				event.id,
				[matchingId ?? ""],
			),
		enabled: !!matchingId,
	});

	useEffect(() => {
		if (search) {
			setParticipantsProposals(
				participantDtoListToUserInformationList(participants?.data),
			);
		} else if (participantActivity?.[0]) {
			setParticipantsProposals(
				activityMatchDtoToUserInformationList(
					participantActivity[0],
					"participant",
					`${ExternalRouting.Registration}/${client.key}/${event.key}/participants`,
				),
			);
			setParticipantActivityCount(participantActivity[0].matches.length);
		}
	}, [
		participantActivity,
		search,
		participants,
		client.key,
		event.key,
		setParticipantActivityCount,
	]);

	return (
		<Stack spacing={1}>
			<Typography variant="h6" align={"center"}>
				{t(`${component}.title`)}
			</Typography>
			{(!participantsProposals || participantsProposals.length === 0) && (
				<Stack pt={2}>
					<Typography fontStyle={"italic"} align="center">
						{t(`${component}.notFound`)}
					</Typography>
				</Stack>
			)}
			{participantsProposals?.map((participantProposal) => (
				<UserInformationRadio
					key={uniqueId()}
					checked={participantProposal.id === userSelected?.id}
					onClick={() => {
						onUserSelectedChange(participantProposal);
					}}
					userInformation={participantProposal.user}
				/>
			))}
		</Stack>
	);
};
