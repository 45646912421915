import { t } from "i18next";

import { DataGridMatching } from "@application/components/Organisms/DataGridMatching/DataGridMatching";
import { AppBox, Title } from "@key4-front-library/core";
import { Stack } from "@mui/material";

export const MatchingList = () => {
	const component = "old.common.matchingList";

	return (
		<Stack>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Title title={t(`${component}.title`)} />
			</Stack>
			<AppBox>
				<Stack>
					<DataGridMatching translation={component} />
				</Stack>
			</AppBox>
		</Stack>
	);
};
