import type {
	IChartSerie,
	IChartSeriesItem,
} from "@infrastructure/model/interfaces/chart/chart.series.item.interface";

import DateService from "../dates/date.service";

//#region 'GetFormatedSerie'

type ObjectDataType = {
	[key: string]: any;
};

type GetFromDateToDateProps = {
	data: ObjectDataType[];
	name: string;
	value: string;
	category?: "date" | "string";
};

const GetFormatedSerie = (
	props: GetFromDateToDateProps,
): IChartSeriesItem[] => {
	const {
		data: objectsData,
		name: propertyName,
		value: propertyValue,
		category = "date",
	} = props;

	const serie: IChartSeriesItem[] = [];

	objectsData.forEach((item) => {
		const nameRef = item[`${propertyName}`];
		const valueRef = item[`${propertyValue}`];

		const name =
			category === "string"
				? nameRef
				: DateService.Format.MonthDayDate({
						date: DateService.GetDateFromJSNumber({
							date: +nameRef,
						})!,
					});

		const value = valueRef;

		serie.push({ name, value });
	});

	return serie;
};

//#endregion 'GetFormatedSerie'

//#region 'GetAllAvailableKeyInSeriesSet'
type GetAllAvailableKeyInSeriesSetProps = {
	series: IChartSerie[];
};

const GetAllAvailableKeyInSeriesSet = (
	props: GetAllAvailableKeyInSeriesSetProps,
) => {
	const { series } = props;
	const keys: string[] = [];
	series.forEach((serie) => serie.data.map((d) => keys.push(d.name)));
	return [...new Set(keys)];
};

//#endregion 'GetAllAvailableKeyInSeriesSet'

const ChartService = {
	GetFormatedSerie,
	GetAllAvailableKeyInSeriesSet,
};

export default ChartService;
