import ConfigurationsApp from "@application/Configurations";
import ControllersApp from "@application/Controllers/ControllersApp";
import HelpersApp from "@application/helpers";
import type {
	TypeExportProgrammeShort,
	TypeProgrammeExport,
	TypeProgrammeExportTabsDefinition,
} from "@application/Types/ProgrammeExportType";
import type {
	TypeCustomFieldForm,
	TypeUseFormListForms,
} from "@key4-front-library/core";
import type { DtoDocument } from "@key4-front-library/core/Dto";
import { useQuery } from "@tanstack/react-query";

/**
 * Hook Entity
 * @returns Methods for fetching data
 */
const useEntity = () => {
	const { read: readProgrammeExport, readList: readListProgrammeExport } =
		ControllersApp.ProgrammeExportController.useEntity();

	/**
	 * Get Document Export Programme by ID
	 * @param id Current Document Export Programme ID
	 * @returns data, isLoading, isFetching, refetch
	 */
	const read = (id: string | null) => {
		const { data, isLoading, isFetching, refetch } = useQuery({
			queryKey: ["get", "programme", "export", id ?? ""],
			queryFn: async (): Promise<{
				raw: DtoDocument | null;
				componentData: TypeProgrammeExportTabsDefinition;
				useFormData: TypeUseFormListForms;
				sectionsCount: number;
			}> => {
				let sectionsCount = 0;

				// Initialise an empty programmeExport Document
				let document: DtoDocument =
					ConfigurationsApp.ProgrammeExportConfiguration.defaultDocumentModel;

				if (id) {
					document = await readProgrammeExport(id);
				}

				// Parse DtoDocument's configuration to programmeExport's configuration
				let configuration = null;
				if (typeof document.configuration === "string") {
					configuration =
						HelpersApp.ProgrammeExportHelper.mapDtoDocumentContigurationToDtoProgrammeExportConfiguration(
							document.configuration,
						);
				}
				if (configuration && typeof configuration === "object") {
					sectionsCount = configuration.sections.length;
					document = { ...document, configuration };
				}

				// Get Custom Fields
				// * There's no CustomField in ProgrammeExport yet, but it uses the CustomField mechanism for faster delivery.
				const formsData: Array<TypeCustomFieldForm> =
					HelpersApp.ProgrammeExportHelper.getProgrammeExportCustomFieldForm(
						document,
					);

				// Build structure of Statics and Dynamics form's sections fields
				let useFormData: TypeUseFormListForms | null = null;
				useFormData =
					HelpersApp.ProgrammeExportHelper.mapUseFormStaticsFields(formsData);
				useFormData = HelpersApp.ProgrammeExportHelper.mapUseFormDynamicsFields(
					formsData,
					useFormData,
				);

				// Map fields into Modal's Tabs controls components
				let componentData: TypeProgrammeExportTabsDefinition | null = null;
				componentData = HelpersApp.ProgrammeExportHelper.mapTabsStaticFields(
					document,
					formsData,
				);
				componentData = HelpersApp.ProgrammeExportHelper.mapTabsDynamicsFields(
					formsData,
					componentData,
				);

				// Bind default values of form
				HelpersApp.ProgrammeExportHelper.mapFormTabsEditUseFormStaticsDefaultValue(
					useFormData,
					document,
				);
				HelpersApp.ProgrammeExportHelper.mapFormTabsEditUseFormDynamicsDefaultValue(
					useFormData,
					document,
				);

				return { raw: document, componentData, useFormData, sectionsCount };
			},
			placeholderData: (previousData) => previousData,
		});

		return { data, isLoading, isFetching, refetch };
	};

	/**
	 * Get List of Document Export Programme
	 * @param actions Buttons's Actions for DataGrid
	 * @returns Pagined data, isFetching, refetch
	 */
	const readListDataGrid = (actions: {
		clone: (id: string) => void;
		edit: (id: string) => void;
		delete: (id: string) => void;
	}) => {
		const { data, isFetching, refetch } = useQuery({
			queryKey: ["get", "programme", "export", "list"],
			queryFn: async (): Promise<Array<TypeProgrammeExport>> => {
				const programmeExportList = await readListProgrammeExport();

				return programmeExportList.data.map((document) => {
					const { id, name, configuration } = document;
					const documentConfiguration =
						typeof configuration === "string" ? configuration : "";
					const programmeExportConfiguration =
						HelpersApp.ProgrammeExportHelper.mapDtoDocumentContigurationToDtoProgrammeExportConfiguration(
							documentConfiguration,
						);
					const sectionsCount =
						programmeExportConfiguration &&
						typeof programmeExportConfiguration === "object"
							? programmeExportConfiguration.sections.length || 0
							: 0;
					return {
						id,
						name,
						sectionsCount,
						actionButtons:
							HelpersApp.ProgrammeExportHelper.getGridActionButtons(
								id,
								actions,
							),
					};
				});
			},
			placeholderData: (previousData) => previousData,
		});

		return { data, isFetching, refetch };
	};

	/**
	 * Get Simple List of Document Export Programme
	 * @returns Pagined data
	 */
	const readListExportProgrammeShort = () => {
		const { data } = useQuery({
			queryKey: ["get", "programme", "export", "list"],
			queryFn: async (): Promise<Array<TypeExportProgrammeShort>> => {
				const programmeExportList = await readListProgrammeExport();
				return programmeExportList.data.map((item) => {
					const { id, name } = item;
					return {
						id,
						name,
					};
				});
			},
			placeholderData: (previousData) => previousData,
		});

		return { data };
	};

	return { read, readListDataGrid, readListExportProgrammeShort };
};

const ProgrammeExportHook = {
	useEntity,
};

export default ProgrammeExportHook;
