//#region 'Imports'
import React from "react";
import { useDrag, useDrop } from "react-dnd";

import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";

import type { Identifier, XYCoord } from "dnd-core";
//#endregion 'Imports'

type Props = {
	id: string;
	index: number;
	card: any;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
	children: ReactJSXElement;
	handleDropAction: () => void;
};

interface DragItem {
	index: number;
	id: string;
	type: string;
}

const ItemTypes = {
	CARD: "card",
};

const style = {
	cursor: "move",
};

const DraggableCard = (props: Props & dataCypressProp) => {
	//#region "Definition"
	// * keep unused variable "card" to make drag works
	const { id, index, moveCard, children, handleDropAction, dataCypressID } =
		props;
	//#endregion "Definition"

	const ref = React.useRef<HTMLDivElement>(null);
	const [{ handlerId }, drop] = useDrop<
		DragItem,
		void,
		{ handlerId: Identifier | null }
	>({
		accept: ItemTypes.CARD,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
				isOver: monitor.isOver(),
				isOverCurrent: monitor.isOver({ shallow: true }),
			};
		},
		drop(_item: unknown, monitor) {
			const didDrop = monitor.didDrop();
			if (didDrop) {
				return;
			}
			handleDropAction();
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.CARD,
		item: () => {
			return { id, index };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? 0.5 : 1;
	drag(drop(ref));

	//#region "rendering"
	return (
		<div
			ref={ref}
			style={{ ...style, opacity }}
			data-handler-id={handlerId}
			data-cy={dataCypressID}
		>
			{children}
		</div>
	);
	//#endregion "rendering"
};

export default DraggableCard;
