import { t } from "i18next";
import { toPairs } from "lodash";
import * as yup from "yup";

import ConfigurationsApp from "@application/Configurations";
import {
	EnumChairFieldKey,
	EnumChairFormKey,
	EnumChairSectionKey,
} from "@application/Enums/ChairEnum";
import type { TypeChairStaticFieldData } from "@application/Types/ChairType";
import type { TypeSessionStaticFieldData } from "@application/Types/SessionType";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	CustomFieldHelper,
	EnumCustomFieldKind,
	EnumFormControlKind,
	EnumParticipantStatusInvitation,
	type TypeContact,
	type TypeParticipant,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabs";
import type {
	DtoChairGetQuality,
	DtoSessionGetChair,
	SessionTemplatesRead,
} from "@key4-front-library/core/Dto";
import type { DtoChairWrite } from "@key4-front-library/core/Dto/ChairDto";
import type {
	TypeCustomFieldForm,
	TypeUseFormFieldValues,
	TypeUseFormListForms,
} from "@key4-front-library/core/Types";

const getResolver: any = (
	useFormData: TypeUseFormListForms | undefined,
	chairId?: string,
) => {
	const resolver: any = {};
	if (!useFormData) {
		return yupResolver(yup.object({}));
	}

	toPairs(useFormData).forEach((form) => {
		const formId = form[0];
		if (form[1] === undefined) return;
		const formResolver: any = {};
		toPairs(form[1]).forEach((section) => {
			const sectionId = section[0];
			if (section[1] === undefined) return;
			const sectionResolver: any = {};
			toPairs(section[1]).forEach((field) => {
				const fieldId = field[0];
				const fieldValue = field[1];
				switch (fieldId) {
					case EnumChairFieldKey.PARTICIPANT_OR_CONTACT:
						if (!chairId) {
							sectionResolver[fieldId] = yup
								.object()
								.nullable()
								.required(t("old.common.formControl.error.required") ?? "");
						}

						break;
					case EnumChairFieldKey.QUALITY:
						sectionResolver[fieldId] = yup.string().nullable();
						break;
				}

				CustomFieldHelper.setupResolver(
					t,
					sectionResolver,
					section[1],
					fieldId,
					fieldValue,
				);
			});

			if (Object.keys(sectionResolver).length > 0) {
				formResolver[sectionId] = yup.object(sectionResolver);
			}
		});
		if (Object.keys(formResolver).length > 0) {
			resolver[formId] = yup.object(formResolver);
		}
	});

	return yupResolver(yup.object(resolver));
};

const mapUseFormToDtoChairWrite = (
	useFormData: TypeUseFormListForms,
	chairPost: DtoChairWrite,
) => {
	toPairs(useFormData).forEach((formObject) => {
		if (formObject[1] === undefined) return;
		toPairs(formObject[1]).forEach((sectionObject) => {
			if (sectionObject[1] === undefined) return;
			toPairs(sectionObject[1]).forEach((fieldObject) => {
				const fieldId = fieldObject[0];
				const fieldValue = fieldObject[1];
				// STATIC FIELDS
				switch (fieldId as EnumChairFieldKey) {
					case EnumChairFieldKey.PARTICIPANT_OR_CONTACT:
						chairPost.participantId = (
							fieldValue as TypeParticipant | TypeContact
						).id;
						return;
					case EnumChairFieldKey.QUALITY:
						chairPost.qualityIds = fieldValue
							? ([fieldValue] as Array<string>)
							: [];
						return;
					case EnumChairFieldKey.INVITATION:
						chairPost.invitationStatus =
							fieldValue as EnumParticipantStatusInvitation;
						return;
					default:
				}

				// CUSTOM FIELDS
				if (fieldId === "customFieldValues") {
					if (!fieldValue) return;

					toPairs(fieldValue as TypeUseFormFieldValues).forEach(
						(customField) => {
							const customfieldId: string = customField[0];
							const customfieldValue = customField[1];
							const customFieldMetadataValue =
								sectionObject[1].metadata![customfieldId].customField!;
							switch (
								sectionObject[1].metadata![customfieldId].customField!.kind
							) {
								case EnumCustomFieldKind.SELECT:
								case EnumCustomFieldKind.RADIO:
									const val =
										typeof customfieldValue === "string"
											? [customfieldValue]
											: [];
									const isInherited: boolean =
										customFieldMetadataValue.isInherited;
									chairPost.customFieldValues![customfieldId] = {
										isCustomized: isInherited,
										value: isInherited
											? customFieldMetadataValue.customDefault.value
											: val,
										customizedValue:
											JSON.stringify(val) !==
												JSON.stringify(
													customFieldMetadataValue.customDefault.value,
												) && isInherited
												? val
												: null,
									};
									return;
								default:
									const valDefault:
										| string
										| boolean
										| Array<string>
										| null
										| undefined =
										typeof customfieldValue === "number"
											? `${customfieldValue}`
											: (customfieldValue as
													| string
													| boolean
													| Array<string>
													| null
													| undefined);

									chairPost.customFieldValues![customfieldId] = {
										isCustomized: customFieldMetadataValue.isInherited,
										value: customFieldMetadataValue.isInherited
											? customFieldMetadataValue.customDefault.value
											: valDefault,
										customizedValue:
											customFieldMetadataValue.customDefault.value !==
												valDefault && customFieldMetadataValue.isInherited
												? valDefault
												: null,
									};
									return;
							}
						},
					);
				}
			});
		});
	});
};

const mapFormTabsPropsStaticFields = (
	formsData: Array<TypeCustomFieldForm>,
	componentData: PropsFormTabs,
	qualities: Array<DtoChairGetQuality>,
	participantId?: string,
) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			// Add static fields
			if (
				(Object.values(EnumChairFormKey) as Array<string>).includes(
					form.data.key,
				) &&
				(Object.values(EnumChairSectionKey) as Array<string>).includes(
					section.data.key,
				)
			) {
				toPairs(
					ConfigurationsApp.ChairConfiguration.staticListFormControlsObject[
						form.data.key as EnumChairFormKey
					][section.data.key as EnumChairSectionKey],
				).forEach((val: [string, TypeChairStaticFieldData]) => {
					let formControl = val[1].component;
					const name = [form.data.id, section.data.id, formControl.id].join(
						".",
					);
					formControl.propsComponent.label = t(
						formControl.propsComponent.label,
					);

					switch (formControl.kind) {
						case EnumFormControlKind.SELECT:
							formControl.propsComponent.items = qualities.map((quality) => {
								return {
									key: quality.id,
									label: quality.label ?? "",
								};
							});
							break;

						case EnumFormControlKind.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT:
							participantId && (formControl = undefined!);
							break;

						case EnumFormControlKind.SELECT_BULLET_COLOR:
							formControl.propsComponent.items =
								formControl.propsComponent.items.map((item) => {
									return {
										...item,
										label: t(item.label),
									};
								});
							break;
						default:
							break;
					}

					componentData.tabs[i].content.sections[j].formControls.push({
						...formControl,
						name,
					});
				});
			}
		}
	}
};

const mapFormTabsUseFormDefaultValue = (
	formsData: Array<TypeCustomFieldForm>,
	useFormData: TypeUseFormListForms,
	shouldDisplayQualities: boolean,
) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			if (
				(Object.values(EnumChairFormKey) as Array<string>).includes(
					form.data.key,
				) &&
				(Object.values(EnumChairSectionKey) as Array<string>).includes(
					section.data.key,
				)
			) {
				toPairs(
					ConfigurationsApp.ChairConfiguration.staticListFormControlsObject[
						form.data.key as EnumChairFormKey
					][section.data.key as EnumChairSectionKey],
				).forEach((val: [string, TypeSessionStaticFieldData]) => {
					useFormData[form.data.id][section.data.id][val[0]] = val[1].value;

					useFormData[form.data.id][section.data.id].metadata![
						val[0] as EnumChairFieldKey
					] = {
						visibility:
							(val[0] as EnumChairFieldKey) === EnumChairFieldKey.QUALITY
								? shouldDisplayQualities
								: true,
					};
				});
			}
		}
	}
};

const mapFormTabsEditUseFormDefaultValue = (
	useFormData: TypeUseFormListForms,
	chair: DtoSessionGetChair,
) => {
	toPairs(useFormData).forEach((formData) => {
		const formId = formData[0];
		const form = formData[1];
		toPairs(form).forEach((sectionData) => {
			const sectionId = sectionData[0];
			const section = sectionData[1];
			toPairs(section).forEach((fieldData) => {
				const fieldId = fieldData[0];
				switch (fieldId as EnumChairFieldKey) {
					case EnumChairFieldKey.PARTICIPANT_OR_CONTACT:
						useFormData[formId][sectionId][fieldId] = chair.participantId;
						break;
					case EnumChairFieldKey.QUALITY:
						useFormData[formId][sectionId][fieldId] =
							chair.qualities?.[0]?.id ?? null;
						break;
					case EnumChairFieldKey.INVITATION:
						useFormData[formId][sectionId][fieldId] = chair.invitationStatus;
						break;
					case EnumChairFieldKey.EMAIL:
						useFormData[formId][sectionId][fieldId] =
							chair.replacement?.email ?? null;
						break;
					case EnumChairFieldKey.FIRSTNAME:
						useFormData[formId][sectionId][fieldId] =
							chair.replacement?.firstname ?? null;
						break;
					case EnumChairFieldKey.LASTNAME:
						useFormData[formId][sectionId][fieldId] =
							chair.replacement?.lastname ?? null;
						break;
					case EnumChairFieldKey.COMMENTS:
						useFormData[formId][sectionId][fieldId] =
							chair.replacement?.comment ?? null;
						break;
					default:
				}
			});
		});
	});
};

const mapFormsTabsCreateChairDefaultValues = (
	useFormData: TypeUseFormListForms,
	sessionTemplate?: SessionTemplatesRead,
	qualities?: Array<DtoChairGetQuality>,
) => {
	toPairs(useFormData).forEach((formData) => {
		const formId = formData[0];
		const form = formData[1];
		toPairs(form).forEach((sectionData) => {
			const sectionId = sectionData[0];
			const section = sectionData[1];

			toPairs(section).forEach((fieldData) => {
				const fieldId = fieldData[0];
				switch (fieldId as EnumChairFieldKey) {
					case EnumChairFieldKey.INVITATION:
						useFormData[formId][sectionId][fieldId] =
							sessionTemplate?.chairInvitationStatus ??
							EnumParticipantStatusInvitation.UNSENT;
						break;
					case EnumChairFieldKey.QUALITY:
						useFormData[formId][sectionId][fieldId] =
							qualities?.[0]?.id ?? null;
						break;
				}
			});
		});
	});
};

const ChairHelper = {
	getResolver,
	mapFormTabsPropsStaticFields,
	mapFormTabsEditUseFormDefaultValue,
	mapUseFormToDtoChairWrite,
	mapFormTabsUseFormDefaultValue,
	mapFormsTabsCreateChairDefaultValues,
};

export default ChairHelper;
