import { t } from "i18next";
import { DateTime, Duration } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import NavigationTabsWithBadges from "@application/components/_common/Navigation/NavigationTabsWithBadges";
import RoomEditionForm from "@application/components/rooms/RoomEditionForm";
import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import BadTabs from "@application/Configurations/tabs/badTabs.config";
import ContainerSessionModal from "@application/Containers/ContainerSessionModal";
import CalendarController from "@application/Controllers/CalendarController";
import RoomController from "@application/Controllers/RoomController";
import { EnumSessionFacultyClashesEntityType } from "@application/enum/Clashes.enum";
import { MenuItemReference } from "@application/enum/MenuItemReference.enum";
import { ERoomMenuItemReference } from "@application/enum/RoomMenuItemReference.enum";
import {
	EnumSessionFieldKey,
	EnumSessionFormKey,
} from "@application/Enums/SessionEnum";
import NavigationFactory from "@application/factory/navigation/NavigationFactory";
import DateHelper from "@application/helpers/date.helper";
import GetTranslationsForRooms from "@application/helpers/translations/GetTranslationsForRooms";
import GetTranslationsForCreateEditSession from "@application/helpers/translations/sessions/GetTranslationsForCreateEditSession";
import type { TypeSessionExtraData } from "@application/Types/SessionType";
import {
	ESchedulerView,
	type ICalendarSession,
	type ICalendarSettings,
} from "@domain/interfaces/calendar.interface";
import type { TRoomDetails } from "@domain/interfaces/room.interface";
import type { TSessionCalendar } from "@domain/model/calendar.model";
import type { IRoomForm } from "@domain/model/room.model";
import K4Calendar from "@infrastructure/components/calendar/K4Calendar";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import {
	ErrorAPI,
	ESieveOperator,
	Locales,
	queryFilters,
	queryStringPagination,
	Services,
	Title,
	useContextModule,
	useSnackBarHook,
} from "@key4-front-library/core";
import { Card } from "@mui/material";

const RoomDetails = () => {
	const { event, client } = useContextModule();
	const { roomId } = useParams();
	const { sendSuccess, sendError } = useSnackBarHook();
	const [room, setRoom] = useState<TRoomDetails>();
	const [sessions, setSessions] = useState<Array<ICalendarSession> | null>(
		null,
	);
	const [calendarSettings, setCalendarSettings] = useState<ICalendarSettings>();
	const [openRoomEditModal, setOpenRoomEditModal] = useState(false);
	const translations = GetTranslationsForRooms(true);

	const [activeClashesCounter, setActiveClashesCounter] = useState<
		number | undefined
	>(0);
	const [activeAnomaliesCounter] = useState<number | undefined>(0);
	const [unactiveClashesCounter, setUnactiveClashesCounter] = useState<
		number | undefined
	>(0);
	const [unactiveAnomaliesCounter] = useState<number | undefined>(0);
	const [isOpenSessionModal, setIsOpenSessionModal] = useState(false);
	const [sessionId, setSessionId] = useState<string>();
	const [listExtraData, setListExtraData] =
		useState<Array<TypeSessionExtraData>>();
	const [activeTabKey, setActiveTabKey] = useState<string>();

	let menuItems =
		roomId && BadTabs.GetRoomMenuConfiguration(client.key, event.key, roomId);
	if (unactiveClashesCounter === 0 && activeClashesCounter === 0) {
		menuItems =
			menuItems &&
			menuItems.filter(
				(menuItem) => menuItem.reference !== ERoomMenuItemReference.CLASHES,
			);
	}
	if (unactiveAnomaliesCounter === 0 && activeAnomaliesCounter === 0) {
		menuItems =
			menuItems &&
			menuItems.filter(
				(menuItem) => menuItem.reference !== ERoomMenuItemReference.ANOMALIES,
			);
	}

	const translationSessions = GetTranslationsForCreateEditSession(t);

	const buttons = [
		{
			label: translationSessions.buttons.edit,
			link: "",
			icon: { iconName: "pencil" },
			color: "primary" as const,
			handleClick: () => {
				handleEditModaleOpen();
			},
		},
		{
			label: translationSessions.create_edit_modale.title.create,
			link: "",
			icon: { iconName: "plus" },
			color: "primary" as const,
			handleClick: () => {
				setSessionId(undefined);
				setActiveTabKey(EnumSessionFormKey.ROOMSCHEDULE);
				setListExtraData([
					{
						key: EnumSessionFieldKey.ROOM_ID,
						value: roomId ?? null,
					},
				]);
				setIsOpenSessionModal(true);
			},
		},
	];

	const initComponent = useCallback(async () => {
		if (!roomId) return;
		const room = await RoomController.getRoomDetails(
			client.id,
			event.id,
			roomId,
		);

		const dataCalendarSettings = await CalendarController.getCalendarSettings(
			client.id,
			event.id,
		);

		if (room instanceof ErrorAPI || dataCalendarSettings instanceof ErrorAPI)
			return;

		const dataSessions = await CalendarController.getSessionsByRoom(
			client.id,
			event.id,
			[roomId],
			dataCalendarSettings,
		);
		if (dataSessions instanceof ErrorAPI) return;
		setRoom(room);
		setSessions(dataSessions);
		setCalendarSettings(dataCalendarSettings);
	}, [event.id, roomId]);

	const initBadgesCountersTabs = useCallback(() => {
		if (!roomId) return;

		const fetchData = async () => {
			return {
				_activeClashes:
					await Services.Events.Programme.RoomsService.getListPaginedClashes(
						client.id,
						event.id,
						roomId,
						[
							...queryFilters(
								`ignoredDate${ESieveOperator.EQUALS}null${ESieveOperator.AND}entityType${
									ESieveOperator.EQUALS +
									EnumSessionFacultyClashesEntityType.ALL
								}`,
							),
							...queryStringPagination({
								page: 0,
								pageSize: 100,
							}),
						],
					),
				_unactiveClashes:
					await Services.Events.Programme.RoomsService.getListPaginedClashes(
						client.id,
						event.id,
						roomId,
						[
							...queryFilters(
								`ignoredDate${ESieveOperator.NOT_EQUALS}null${ESieveOperator.AND}entityType${
									ESieveOperator.EQUALS +
									EnumSessionFacultyClashesEntityType.ALL
								}`,
							),
							...queryStringPagination({
								page: 0,
								pageSize: 100,
							}),
						],
					),
			};
		};

		fetchData().then((results) => {
			const { _activeClashes, _unactiveClashes } = results;
			setActiveClashesCounter(_activeClashes.pagination.totalCount);
			setUnactiveClashesCounter(_unactiveClashes.pagination.totalCount);
		});
	}, [roomId, event]);

	const updateSession = async (
		sessionId: string,
		session: TSessionCalendar,
	) => {
		const response = await CalendarController.putSession(
			client.id,
			event.id,
			sessionId,
			session,
		);
		if (response instanceof ErrorAPI) return;
	};

	const refreshSessions = async () => {
		if (!roomId || !calendarSettings) return;
		const dataSessions = await CalendarController.getSessionsByRoom(
			client.id,
			event.id,
			[roomId],
			calendarSettings,
		);

		if (dataSessions instanceof ErrorAPI) {
			sendError(t("old.common.errors.generic"));
			return;
		}

		setSessions(dataSessions);
	};

	useEffect(() => {
		initComponent();
		initBadgesCountersTabs();
	}, [event, initBadgesCountersTabs, initComponent]);

	const handleEditModaleOpen = (_roomId?: string) => {
		setOpenRoomEditModal(true);
	};

	const handleModaleClose = () => {
		setOpenRoomEditModal(false);
	};

	const handleSaveRoom = async (_room: IRoomForm) => {
		if (roomId) {
			try {
				await RoomController.updateRoom(client.id, event.id, _room);
				sendSuccess(
					translations.editionModal.returnMessages.success_modification,
				);
				initComponent();
			} catch (error: any) {
				sendError(error.message);
			} finally {
				handleModaleClose();
			}
		}
	};
	/**
	 * Handle 2 Drag & drop actions:
	 * - action on dragging session inside the calendar
	 */
	const handleSessionDrop = async (session: any) => {
		if (!roomId) return;

		let endStr = session.event.endStr;
		let minDuration =
			session.event.extendedProps.minDuration ??
			SchedulerConfiguration.defaultMinDuration;
		if (!session.event.endStr) {
			endStr = session.event.startStr;
			minDuration = SchedulerConfiguration.defaultMinDuration;
		}

		const { dateStart, dateEnd } = DateHelper.setDurationAndDatesSession(
			DateTime.fromISO(session.event.startStr),
			DateTime.fromISO(endStr),
			minDuration,
		);

		try {
			await updateSession(session.event.id, {
				roomId,
				startDate: dateStart.toFormat("yyyy-MM-dd"),
				startHour: dateStart.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
				// ! Do not change to endDate because we want same startDate/endDate on fullDay
				endDate: session.event.allDay
					? dateStart.toFormat("yyyy-MM-dd")
					: dateEnd.toFormat("yyyy-MM-dd"),
				endHour: dateEnd.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
				timeZone: "UTC",
				isFullDay: session.event.allDay,
				duration: null,
				tagIds: session.event.extendedProps.tagsId,
				isWholeProgramme: false,
				isEverywhere: false,
			});
		} catch {
			sendError(t("old.common.errors.generic"));
		}

		await refreshSessions();
		session.event.remove();
	};

	const handleResizeSession = async (session: any) => {
		const newRoomId = session?.event?.extendedProps
			? session.event.extendedProps.roomId
			: null;
		const durationToNumberFormat = session?.event?.extendedProps?.minDuration
			? Number.parseInt(
					Duration.fromISO(session.event.extendedProps.minDuration).toFormat(
						"mm",
					),
				)
			: 0;

		if (
			!DateHelper.isDurationEligible(
				DateTime.fromISO(session.event.startStr),
				DateTime.fromISO(session.event.endStr),
				durationToNumberFormat,
			)
		) {
			sendError(
				Locales.Parsers.TranslateParserError.schedulerMinDuration(
					Duration.fromISO(session.event.extendedProps.minDuration).toFormat(
						"mm",
					),
				),
			);
			refreshSessions();
			return;
		}

		const { dateStart, dateEnd } = DateHelper.setDurationAndDatesSession(
			DateTime.fromISO(session.event.startStr),
			DateTime.fromISO(session.event.endStr),
			session.event.extendedProps.minDuration,
		);

		try {
			await updateSession(session.event.id, {
				roomId: newRoomId,
				startDate: dateStart.toFormat("yyyy-MM-dd"),
				startHour: dateStart.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
				endDate: dateEnd.toFormat("yyyy-MM-dd"),
				endHour: dateEnd.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
				timeZone: "UTC",
				isFullDay: session.event.allDay,
				duration: session.event.extendedProps.minDuration,
				tagIds: session.event.extendedProps.tagsId,
				isWholeProgramme: false,
				isEverywhere: false,
			});
		} catch {
			sendError(t("old.common.errors.generic"));
		}

		refreshSessions();
	};

	const handleEventClick = async (newSessionId: string) => {
		setSessionId(newSessionId);
		setIsOpenSessionModal(true);
	};

	const handleSessionCallbackSubmit = () => {
		refreshSessions();
	};

	const renderingRoleEditionModal = (translations: any) => {
		return (
			<DefaultModal
				open={openRoomEditModal}
				title={translations.editionModal.title}
				maxWidth={"sm"}
			>
				<RoomEditionForm
					room={room}
					handleSaveRoom={handleSaveRoom}
					handleModaleClose={handleModaleClose}
					translations={translations}
				/>
			</DefaultModal>
		);
	};

	return (
		<>
			{room && (
				<Title
					title={room.name}
					reference={translations.details.capacity + room.capacity}
					buttons={buttons}
				/>
			)}
			{calendarSettings && (
				<Card>
					<K4Calendar
						calendarSettings={calendarSettings}
						views={{
							calendar: SchedulerConfiguration.roomScheduler,
						}}
						handleSessionDrop={handleSessionDrop}
						handleResizeSession={handleResizeSession}
						view={ESchedulerView.CALENDAR}
						events={sessions ?? []}
						locale={translations.details.locale}
						handleEventClick={handleEventClick}
					/>
				</Card>
			)}

			{roomId && menuItems && (
				<>
					<NavigationTabsWithBadges
						menuItems={NavigationFactory.GetMenuItemsTranslations({
							menuItems,
						})}
						badges={[
							{
								reference: ERoomMenuItemReference.CLASHES,
								number: activeClashesCounter ?? 0,
							},
							{
								reference: ERoomMenuItemReference.ANOMALIES,
								number: activeAnomaliesCounter ?? 0,
							},
						]}
						baseUrl={`/${client.key}/${event.key}/${MenuItemReference.ROOMS}/${roomId}`}
						defaultTabsValue={ERoomMenuItemReference.CLASHES}
						routeParentLastPartPath={roomId}
					/>
					<Outlet context={{ initBadgesCountersTabs }} />
				</>
			)}

			{renderingRoleEditionModal(translations)}
			<ContainerSessionModal
				isOpen={isOpenSessionModal}
				changeIsOpen={setIsOpenSessionModal}
				callbackSubmit={handleSessionCallbackSubmit}
				id={sessionId}
				listExtraData={listExtraData}
				activeTabKey={activeTabKey}
			/>
		</>
	);
};

export default RoomDetails;
