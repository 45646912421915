import { t } from "i18next";
import { useEffect, useState } from "react";

import {
	ButtonCancel,
	ButtonSave,
} from "@infrastructure/components/interface/uikit/K4Button";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
} from "@mui/material";

type Props = {
	isOpen: boolean;
	onCloseClick: () => void;
	onSubmitClick: (titleQuery: string) => void;
};

const SaveNewQueryModal = (props: Props) => {
	const { isOpen = false, onCloseClick, onSubmitClick } = props;

	const translations = {
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
	};

	useEffect(() => {
		if (!isOpen) setTitleQuery(undefined);
	}, [isOpen]);

	const [titleQuery, setTitleQuery] = useState<string>();

	return (
		<Dialog open={isOpen} maxWidth={"md"} fullWidth>
			<DialogTitle>
				{t("old.searchList.searchFilter.modalNewQuery.title")}
			</DialogTitle>
			<DialogContent>
				<Stack mt={".5em"}>
					<TextField
						fullWidth
						error={titleQuery === ""}
						id="outlined-error-helper-text"
						defaultValue={titleQuery}
						helperText={
							titleQuery === ""
								? t("old.searchList.searchFilter.modalNewQuery.emptyField")
								: ""
						}
						placeholder={t(
							"old.searchList.searchFilter.modalNewQuery.placeholder",
						)}
						onChange={(event) => {
							setTitleQuery(event.target.value);
						}}
					/>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ paddingRight: "24px", paddingBottom: "10px" }}>
				<Stack direction={"row"} spacing=".8rem">
					<ButtonCancel
						label={translations.buttons.cancel}
						onClick={onCloseClick}
						dataCypressID="button-cancel"
					/>
					<ButtonSave
						disabled={titleQuery === "" || !titleQuery}
						label={translations.buttons.save}
						onClick={() => {
							onSubmitClick(titleQuery ?? "");
						}}
						dataCypressID="button-save"
					/>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
export default SaveNewQueryModal;
