import { Stack } from "@mui/material";

import K4ChipColor, { type K4ChipColorProps } from "../../chip/K4ChipColor";

export interface K4DataGridCellChipColorProps {
	chip: K4ChipColorProps | undefined;
}

const K4DataGridCellChipColor = (props: K4DataGridCellChipColorProps) => {
	const { chip } = props;
	return (
		<Stack direction={"row"} alignItems={"center"}>
			{chip && (
				<K4ChipColor
					label={chip.label}
					backgroundColor={chip.backgroundColor}
					borderColor={chip.borderColor}
					fontColor={chip.fontColor}
				/>
			)}
		</Stack>
	);
};

export default K4DataGridCellChipColor;
