import { Fragment, useState } from "react";

import SettingsClashesEdit from "@application/components/settings/scheduler/clashes/SettingsClashesEdit";
import type { ISettingsClashesObject } from "@domain/interfaces/settings.clashes.interface";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";

type SettingsClashesItemProps = {
	label: string;
	value: string;
};
const SettingsClashesItem = (props: SettingsClashesItemProps) => {
	const { label, value } = props;
	return (
		<Grid item xs={4} md={4} lg={4}>
			<Typography variant={"body2"} color={"text.secondary"}>
				{label}
			</Typography>
			<Typography variant={"body1"}>{value}</Typography>
		</Grid>
	);
};

type Props = {
	title: string;
	icon: IconName;
	settings: ISettingsClashesObject;
	culture: string;
	translations: any;
	saveSettings: (data: ISettingsClashesObject) => void;
};
const SettingsClashesView = (props: Props) => {
	const { title, icon, settings, translations, culture, saveSettings } = props;

	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const handleEditModaleOpen = (): void => {
		setModaleOpenState(true);
	};
	const handleEditModaleClose = (): void => {
		setModaleOpenState(false);
	};

	return (
		<Fragment>
			<IconCard
				title={title}
				icon={icon}
				children={
					<Grid container spacing={3}>
						<SettingsClashesItem
							label={
								translations.clashes.settings.facultyClashDetectionInterval
							}
							value={settings.formatedValues.facultyClashDetectionInterval}
						/>
						<SettingsClashesItem
							label={translations.clashes.settings.roomClashDetectionInterval}
							value={settings.formatedValues.roomClashDetectionInterval}
						/>
						<Grid item xs={12} md={12} lg={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											settings.formatedValues.isSpeakerBusySessionTimeSlot
										}
										disabled={true}
									/>
								}
								label={
									translations.clashes.settings.isSpeakerBusySessionTimeSlot
								}
							/>
						</Grid>
					</Grid>
				}
				button={{
					label: translations.button,
					icon: "pen-to-square" as IconName,
					onClick: handleEditModaleOpen,
				}}
			/>
			<DefaultModal open={modaleOpenState} title={translations.scheduler.title}>
				<SettingsClashesEdit
					settings={settings}
					culture={culture}
					translations={translations}
					onSave={saveSettings}
					handleModaleClose={handleEditModaleClose}
				/>
			</DefaultModal>
		</Fragment>
	);
};

export default SettingsClashesView;
