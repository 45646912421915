export enum ESessionAnomalies {
	SESSION_DUPLICATE_CODE = "sessionDuplicateCode",
	SESSION_DUPLICATE_TITLE = "sessionDuplicateTitle",
	SESSION_EMPTY_TITLE = "sessionEmptyTitle",
	SESSION_EMPTY_PRIMARY_TAG = "sessionEmptyPrimaryTag",
	SESSION_WITHOUT_CHAIR = "sessionWithoutChair",
	SESSION_WITHOUT_PRESENTATION = "sessionWithoutPresentation",
	SESSION_EMPTY_CODE = "sessionEmptyCode",
	SESSION_NOT_IN_READY_TO_PUBLISH_STATUS = "sessionNotInReadyToPublishStatus",
	SESSION_INVALID_LOCATION = "sessionInvalidLocation",
	SESSION_INVALID_SCHEDULE = "sessionInvalidSchedule",
	SESSION_DISCONTINUITY_IN_PRESENTATION_SCHEDULE = "sessionDiscontinuityInPresentationSchedule",
	SESSION_EMPTY_PUBLICATION_DATE = "sessionEmptyPublicationDate",
}

export enum EPresentationAnomalies {
	PRESENTATION_DUPLICATE_CODE = "presentationDuplicateCode",
	PRESENTATION_DUPLICATE_TITLE = "presentationDuplicateTitle",
	PRESENTATION_EMPTY_TITLE = "presentationEmptyTitle",
	PRESENTATION_WITHOUT_SPEAKER = "presentationWithoutSpeaker",
	PRESENTATION_EMPTY_CODE = "presentationEmptyCode",
	PRESENTATION_INVALID_SCHEDULE = "presentationInvalidSchedule",
}

export enum EInvitationAnomalies {
	INVITATION_NOT_IN_ACCEPTED_STATUS = "invitationNotInAcceptedStatus",
}
export type EAnomalies =
	| ESessionAnomalies
	| EPresentationAnomalies
	| EInvitationAnomalies;

export enum EStatusAnomalies {
	DRAFT = "draft",
	VALIDATED = "validated",
	READYTOPUBLISH = "readyToPublish",
	PLANNED_OR_PUBLISHED = "plannedOrPublished ",
}

export enum ETypeAnomalies {
	SESSION = "session",
	PRESENTATION = "presentation",
	INVITATION = "invitation",
}
