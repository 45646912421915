import {
	getFileName,
	queryIncludeCustomFields,
	Services,
} from "@key4-front-library/core";

const getExport = (clientId: string, eventId: string, eventCode: string) => {
	return Services.ProgrammeService.getExportProgramme(
		clientId,
		eventId,
		getFileName(eventCode, "full-export"),
		[...queryIncludeCustomFields(true)],
	);
};

const ProgrammeController = { getExport };

export default ProgrammeController;
