import HelpersApp from "@application/helpers";
import type { TypeConvocationGridViewRow } from "@application/Types/ConvocationType";
import {
	DefaultPage,
	type IEntity,
	PAGINATION_PAGE_SIZE,
	queryStringPagination,
	queryStringSorts,
	Services,
	type TypeApiQueryPagination,
	useContextModule,
} from "@key4-front-library/core";

const useEntity = () => {
	const { client, event } = useContextModule();

	const readList = async (
		sessionId: string,
		actions: {
			details: (id: string) => void;
			delete: (id: string) => void;
		},
		sortCriteria?: Array<string>,
		pagination?: TypeApiQueryPagination,
	): Promise<Array<TypeConvocationGridViewRow>> => {
		const { page, pageSize } = pagination ?? {
			page: DefaultPage,
			pageSize: PAGINATION_PAGE_SIZE,
		};
		return await Services.Events.Programme.SessionsService.getListPaginedConvocations(
			client.id,
			event.id,
			sessionId,
			[
				...queryStringSorts(sortCriteria ?? ["lastname"]),
				...queryStringPagination({ page, pageSize }),
			],
		).then(({ data: convocations }) => {
			return convocations.map((convocation) => {
				const { id, firstname, lastname, key } = convocation;
				return {
					id,
					key: key ?? "",
					firstname: firstname ?? "",
					lastname: lastname ?? "",
					actionButtons: HelpersApp.ConvocationHelper.getGridActionButtons(
						convocation,
						actions,
					),
				} as TypeConvocationGridViewRow;
			});
		});
	};

	const create = async (
		sessionId: string,
		convenedPersonId: string,
	): Promise<IEntity> => {
		const existingParticipant =
			await Services.Events.Registration.ParticipantsService.get(
				client.id,
				event.id,
				convenedPersonId,
			);
		if (!existingParticipant.id) {
			const contact =
				await Services.Operations.Registration.ContactsService.get(
					client.id,
					event.communityId ?? event.id,
					convenedPersonId,
				);

			const newParticipant =
				await Services.Events.Registration.ParticipantsService.post(
					client.id,
					event.id,
					{
						email: contact.data.email,
						firstname: contact.data.firstname?.replace(
							/(^\w{1})|(\s+\w{1})/g,
							(letter) => letter.toUpperCase(),
						),
						lastname: contact.data.lastname?.replace(
							/(^\w{1})|(\s+\w{1})/g,
							(letter) => letter.toUpperCase(),
						),
						customFieldValues: contact.data.customFieldValues,
					},
				);
			return await Services.Events.Programme.SessionsService.postConvocation(
				client.id,
				event.id,
				sessionId,
				newParticipant.id,
			);
		}
		return await Services.Events.Programme.SessionsService.postConvocation(
			client.id,
			event.id,
			sessionId,
			existingParticipant.id,
		);
	};

	const deleteEntity = async (
		sessionId: string,
		participantId: string,
	): Promise<boolean> => {
		return await Services.Events.Programme.SessionsService.deleteConvocation(
			client.id,
			event.id,
			sessionId,
			participantId,
		);
	};

	return { readList, create, deleteEntity };
};

const ConvocationController = {
	useEntity,
};

export default ConvocationController;
