import {
	ErrorAPI,
	type IEntity,
	Services,
	type TypeApiQueryString,
	type TypeApiResponsePaginedPagination,
	type TypeMailTemplate,
} from "@key4-front-library/core";
import type {
	DtoLayoutGet,
	DtoMailSenderGet,
	DtoMailTemplate,
	DtoMailTemplateGet,
} from "@key4-front-library/core/Dto";
import {
	EnumMailTemplateType,
	EnumTarget,
} from "@key4-front-library/core/Enums";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";

const getMailTemplatesListPaginated = async (
	clientId: string,
	eventId: string,
	queryStrings?: TypeApiQueryString[] | undefined,
): Promise<{
	pagination: TypeApiResponsePaginedPagination;
	data: GridValidRowModel[];
}> => {
	try {
		const mailTemplates =
			await Services.Operations.MailTemplatesService.getListPagined(
				clientId,
				eventId,
				queryStrings,
			);

		const { data } = mailTemplates;
		const formattedMailTemplates: GridValidRowModel[] = [];

		for (const mailTemplate of data) {
			formattedMailTemplates.push({
				id: mailTemplate.id,
				type: {
					isDisplayed: true,
					icon: {
						name:
							mailTemplate.type === EnumMailTemplateType.CUSTOM
								? "palette"
								: "bell",
						prefix: "far",
					},
					name: mailTemplate.type,
					tooltipTitle: "",
				},
				name: mailTemplate.name,
				subject: mailTemplate.subject,
				mailSenderId: mailTemplate.mailSenderId,
				cc: mailTemplate.cc,
				layoutId: mailTemplate.layoutId,
				bcc: mailTemplate.bcc,
				body: mailTemplate.body,
				target: mailTemplate.target,
				actionButtons: {
					edit: {
						icon: {
							name: "pen-to-square",
							color: "",
						},
						onClick: () => {},
					},
					delete: {
						icon: {
							name: "trash-can",
							color: "",
						},
						onClick: () => {},
					},
				},
			});
		}
		return Promise.resolve({
			pagination: mailTemplates.pagination,
			data: formattedMailTemplates,
		});
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getMailTemplatesList = async (
	clientId: string,
	eventId: string,
	queryStrings?: TypeApiQueryString[] | undefined,
): Promise<DtoMailTemplateGet[]> => {
	try {
		return Promise.resolve(
			await Services.Operations.MailTemplatesService.getList(
				clientId,
				eventId,
				queryStrings,
			),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getMailTemplate = async (
	clientId: string,
	eventId: string,
	templateId: string,
): Promise<DtoMailTemplateGet> => {
	try {
		return Promise.resolve(
			await Services.Operations.MailTemplatesService.get(
				clientId,
				eventId,
				templateId,
			),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const createTemplate = async (
	clientId: string,
	eventId: string,
	mailTemplate: TypeMailTemplate,
): Promise<IEntity> => {
	const completeMailTemplate = {
		name: mailTemplate.name,
		mailSenderId: mailTemplate.mailSenderId,
		subject: mailTemplate.subject || null,
		layoutId: mailTemplate.layoutId || null,
		cc: mailTemplate.cc || null,
		bcc: mailTemplate.bcc || null,
		type: EnumMailTemplateType.CUSTOM,
		description: mailTemplate.description || null,
		body: mailTemplate.body || null,
		target: mailTemplate.target || EnumTarget.Faculty,
	};

	try {
		return Promise.resolve(
			await Services.Operations.MailTemplatesService.post(
				clientId,
				eventId,
				completeMailTemplate as DtoMailTemplate,
			),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const updateTemplate = async (
	clientId: string,
	eventId: string,
	mailTemplateId: string,
	mailTemplate: TypeMailTemplate,
): Promise<IEntity> => {
	const completeMailTemplate = {
		name: mailTemplate.name,
		mailSenderId: mailTemplate.mailSenderId,
		subject: mailTemplate.subject || null,
		layoutId: mailTemplate.layoutId || null,
		cc: mailTemplate.cc || null,
		bcc: mailTemplate.bcc || null,
		type: mailTemplate.type,
		description: mailTemplate.description || null,
		body: mailTemplate.body || null,
		target: mailTemplate.target,
	};

	try {
		return Promise.resolve(
			await Services.Operations.MailTemplatesService.put(
				clientId,
				eventId,
				mailTemplateId,
				completeMailTemplate as DtoMailTemplate,
			),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const deleteTemplate = async (
	clientId: string,
	operationId: string,
	mailTemplateId: string,
): Promise<void> => {
	try {
		await Services.Operations.MailTemplatesService.deleteEntity(
			clientId,
			operationId,
			mailTemplateId,
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getMailSenders = async (
	clientId: string,
): Promise<DtoMailSenderGet[]> => {
	try {
		return Promise.resolve(
			await Services.Clients.MailSendersService.getList(clientId),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getLayouts = async (clientId: string): Promise<DtoLayoutGet[]> => {
	try {
		return Promise.resolve(
			await Services.Clients.LayoutsService.getList(clientId),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const clone = async (
	clientId: string,
	eventId: string,
	templateId: string,
): Promise<boolean> => {
	try {
		return await Services.Operations.MailTemplatesService.get(
			clientId,
			eventId,
			templateId,
		)
			.then(async (template) => {
				return await Services.Operations.MailTemplatesService.post(
					clientId,
					eventId,
					{
						...template,
						name: [template.name, "CLONE"].join("-"),
					},
				)
					.then(() => {
						return Promise.resolve(true);
					})
					.catch((error) => {
						throw new Error(error);
					});
			})
			.catch((error) => {
				throw new Error(error);
			});
	} catch (error: any) {
		return Promise.reject(new ErrorAPI(error.status, error.message));
	}
};

const MailTemplatesController = {
	getMailTemplatesListPaginated,
	getMailSenders,
	getLayouts,
	getMailTemplate,
	getMailTemplatesList,
	createTemplate,
	updateTemplate,
	deleteTemplate,
	clone,
};

export default MailTemplatesController;
