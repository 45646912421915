import type { MouseEventHandler } from "react";

import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";

interface Props {
	label: string;
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonClearAll = (props: Props & dataCypressProp) => {
	const { label, isDisabled = false, onClick, dataCypressID } = props;
	const theme = useTheme();
	return (
		<Button
			disabled={isDisabled}
			onClick={onClick}
			variant="outlined"
			sx={{ bgColor: theme.palette.grey[500] }}
			data-cy={dataCypressID}
			startIcon={
				<Icon
					color="inherit"
					prefix="fas"
					iconName={"rotate-left"}
					size={"1x"}
				/>
			}
		>
			<Typography color="inherit">{label}</Typography>
		</Button>
	);
};

export default K4ButtonClearAll;
