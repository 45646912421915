import { t } from "i18next";
import type { MouseEventHandler } from "react";

import K4ButtonClearAllDecorated from "@application/components/button/K4ButtonClearAllDecorated";
import K4ButtonSaveDecorated from "@application/components/button/K4ButtonSaveDecorated";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import {
	Box,
	type Breakpoint,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
} from "@mui/material";

type Props = {
	children: JSX.Element;
	open: boolean;
	dialogActions?: boolean;
	maxWidth?: Breakpoint;
	isFullWidth?: boolean;
	actions?: JSX.Element;
	onClearAllClick: MouseEventHandler<HTMLButtonElement>;
	onSaveClick: MouseEventHandler<HTMLButtonElement>;
};

const FilterModal = (props: Props & dataCypressProp) => {
	const {
		children,
		open = false,
		maxWidth = "md",
		isFullWidth = true,
		dataCypressID,
		onClearAllClick,
		onSaveClick,
	} = props;
	return (
		<Dialog open={open} maxWidth={maxWidth} fullWidth={isFullWidth}>
			<Stack
				direction={"row"}
				width={"100%"}
				display={"flex"}
				pr={5}
				alignItems={"center"}
			>
				<DialogTitle
					data-cy={dataCypressID ? `${dataCypressID}-title` : undefined}
				>
					{t("old.common.modale.filter.title")}
				</DialogTitle>
				<Stack ml="auto" direction={"row"} spacing={1}>
					<K4ButtonClearAllDecorated
						onClick={onClearAllClick}
						dataCypressID={
							dataCypressID ? `${dataCypressID}-cancel_button` : undefined
						}
					/>
					<K4ButtonSaveDecorated
						onClick={onSaveClick}
						dataCypressID={
							dataCypressID ? `${dataCypressID}-save_button` : undefined
						}
					/>
				</Stack>
			</Stack>
			<DialogContent>
				<Box>{children}</Box>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
};
export default FilterModal;
