import GetTranslationsForClashes from "@application/helpers/translations/clashes/GetTranslationsForClashes";
import { Box, FormControlLabel, Stack, Switch, Tab, Tabs } from "@mui/material";

type Props = {
	tabIndex?: number;
	isOnlyActive: boolean;
	onTabChange?: (event: React.SyntheticEvent, newValue: number) => void;
	OnIsOnlyActiveChange: () => void;
	isGroupButtons?: boolean;
};
const ClashesFilters = (props: Props) => {
	const {
		tabIndex,
		isOnlyActive,
		onTabChange,
		OnIsOnlyActiveChange,
		isGroupButtons = true,
	} = props;

	const translations = GetTranslationsForClashes();

	const a11yProps = (index: number) => {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	};

	return (
		<Stack
			spacing={1}
			direction={"row"}
			justifyContent={"flex-end"}
			flexWrap={"wrap"}
			alignItems={"center"}
		>
			{isGroupButtons && (
				<Tabs
					value={tabIndex}
					onChange={onTabChange}
					variant="scrollable"
					scrollButtons="auto"
				>
					<Tab
						label={<Box component="span">{translations.filters.all}</Box>}
						{...a11yProps(0)}
					/>
					<Tab
						label={<Box component="span">{translations.filters.sessions}</Box>}
						{...a11yProps(1)}
					/>
					<Tab
						label={
							<Box component="span">{translations.filters.invitations}</Box>
						}
						{...a11yProps(2)}
					/>
				</Tabs>
			)}

			<FormControlLabel
				control={
					<Switch
						checked={isOnlyActive}
						onChange={OnIsOnlyActiveChange}
						inputProps={{ "aria-label": "only active" }}
					/>
				}
				label={translations.filters.isOnlyActive}
			/>
		</Stack>
	);
};

export default ClashesFilters;
