import { t } from "i18next";
import { useEffect, useState } from "react";

import type { IconName } from "@fortawesome/fontawesome-svg-core";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { SearchBase, useDebounce } from "@key4-front-library/core";
import { Badge, IconButton, Paper } from "@mui/material";

import { Icon } from "../icons/Icon";

interface Props {
	handleChange: (value: string) => void;
	secondIconName?: IconName;
	secondIconHandleClick?: () => void;
	isBadge?: boolean;
}

const SimpleTextSearchFilter = (props: Props & dataCypressProp) => {
	const {
		handleChange,
		secondIconName,
		secondIconHandleClick,
		isBadge = false,
	} = props;
	const [searchValue, setSearchValue] = useState("");
	const debouncedSearchValue = useDebounce(searchValue);

	useEffect(() => {
		handleChange(searchValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchValue]);

	const formfields = { search: t("old.common.filters.search.label") };

	const pageResources = {
		search: {
			label: formfields.search,
		},
		buttons: {
			search: t("old.form.buttons.search"),
			reset: t("old.form.buttons.reset"),
		},
	};

	const SecondIcon = () => {
		return (
			<IconButton
				sx={{ p: "10px" }}
				aria-label="reset-search"
				color={undefined}
				onClick={() => {
					secondIconHandleClick?.();
				}}
				data-testid="simpleTextSearchFilterClear"
			>
				<>
					{isBadge && (
						<Badge
							color="primary"
							overlap="circular"
							badgeContent=" "
							sx={{
								"& .MuiBadge-badge": {
									fontSize: 9,
									height: 15,
									minWidth: 15,
								},
							}}
						>
							<Icon prefix="far" iconName={secondIconName as IconName} />
						</Badge>
					)}
					{!isBadge && (
						<Icon prefix="far" iconName={secondIconName as IconName} />
					)}
				</>
			</IconButton>
		);
	};

	return (
		<Paper
			variant="outlined"
			sx={{
				p: "2px 4px",
				display: "flex",
				alignItems: "center",
			}}
			data-testid="simpleTextSearchFilter"
		>
			<SearchBase
				onChange={(value: string) => {
					setSearchValue(value);
				}}
				label={pageResources.search.label}
				labelReset={pageResources.buttons.reset}
				onResetSearch={() => {
					setSearchValue("");
					handleChange("");
				}}
				value={searchValue}
			/>
			{secondIconName && <SecondIcon />}
		</Paper>
	);
};

export default SimpleTextSearchFilter;
