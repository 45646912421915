import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ChairConfiguration from "@application/Configurations/Chair.configuration";
import { EFacultyType } from "@application/Configurations/faculty.configuration";
import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import ContainerChairModal from "@application/Containers/ContainerChairModal";
import SessionController from "@application/Controllers/SessionController";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import type { ChairRow } from "@application/helpers/chairDatagrid.helper";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import {
	ActionReference,
	ConfirmationModal,
	DataGridOld,
	type DtoFaculty,
	EMenuPath,
	EnumActionsReference,
	FacultyHelper,
	getColumns,
	queryStringSorts,
	statusInvitationParticipantObject,
	type TFacultyForm,
	useContextModule,
} from "@key4-front-library/core";

import { useChairs } from "../../../Hooks/Chair";

const ChairsView = () => {
	const { sessionId = "" } = useParams();
	const { event, client } = useContextModule();
	const [chairs, setChairs] = useState<Array<DtoFaculty>>();

	const chairsQuery = useChairs({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		queryStrings: queryStringSorts(["order"]),
	});

	useEffect(() => {
		if (chairsQuery.data?.data) {
			setChairs(chairsQuery.data.data);
		}
	}, [chairsQuery.data?.data]);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [rows, setRows] = useState<Array<ChairRow>>([]);

	useEffect(() => {
		if (chairs) {
			const data: Array<ChairRow> = [];
			chairs.forEach((faculty: DtoFaculty) => {
				const qualityTagType = faculty.tagTypes?.[0];
				const invitationType =
					qualityTagType?.tags?.[0]?.label ?? t("common:chair");

				data.push({
					id: faculty.participantId,
					chair: faculty,
					invitationType,
					status: {
						label: t(
							statusInvitationParticipantObject[faculty.invitationStatus].label,
						),
						iconColor:
							statusInvitationParticipantObject[faculty.invitationStatus]
								.bgColor,
					},
					actions: {
						listButtons: [
							{
								...ActionReference[EnumActionsReference.DETAILS],
								onClick: () => {
									navigate(
										[
											"",
											client.key,
											event.key,
											EMenuPath.FACULTIES,
											faculty.participantId,
										].join("/"),
									);
								},
							},
							{
								...ActionReference[EnumActionsReference.EDIT],
								onClick: () => {
									editFacultyModal(faculty);
								},
							},
							{
								...ActionReference[EnumActionsReference.DELETE],
								onClick: () => {
									confirmationModalProps.current = {
										action: EConfirmationModalAction.DELETE,
										handleAction: () => {
											handleChairDelete(faculty.participantId);
										},
									};
									setOpenConfirmationModal(true);
								},
							},
						],
					},
				});
			});
			setRows(data);
		}
	}, [chairs]);

	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});

	const actionsConfigurations = GetMenuActionsConfigurations();

	const addConfig = actionsConfigurations.find(
		(x) => x.reference === MenuActionsReference.ADD,
	);

	const handleModaleClose = () => {
		setOpenConfirmationModal(false);
	};

	const handleOpenFacultyModal = (
		faculty: TFacultyForm,
		type: EFacultyType,
	) => {
		if (type === EFacultyType.CHAIR) faculty.sessionId = sessionId!;
		setFacultyModalType(type);
		setFacultyModal(faculty);
		changeOpenStatus(true);
	};

	const editFacultyModal = useCallback(
		(chair: DtoFaculty) => {
			const faculty = { ...FacultyHelper.defaultFacultyForm };
			faculty.participant = {
				...FacultyHelper.defaultFacultyForm.participant!,
			};
			faculty.participant.id = chair.participantId;
			handleOpenFacultyModal(faculty, EFacultyType.CHAIR);
		},
		[handleOpenFacultyModal],
	);

	const handleChairDelete = async (chairId: string) => {
		if (chairId) {
			try {
				await SessionController.deleteChairFromSession({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					chairId,
				});

				enqueueSnackbar(
					t(
						"old.programme.sessionDetails.chairs.returnMessages.success_deletion",
					),
					{ variant: "success" },
				);
				chairsQuery.refetch();
			} catch (error: any) {
				enqueueSnackbar(error.message, { variant: "error" });
			} finally {
				handleModaleClose();
			}
		}
	};

	const getTranslatedColumnLabels = (): Array<string> => {
		const headersNames: Array<string> = [];
		ChairConfiguration.datagridHeaders.forEach((header) => {
			if (header.field === "invitationType") {
				headersNames.push(
					t(
						`old.programme.facultyDetails.datagrid.headerLabels.${header.field}`,
					),
				);
			} else {
				headersNames.push(
					t(
						`old.programme.sessionDetails.chairs.datagrid.headerLabels.${header.field}`,
					),
				);
			}
		});
		return headersNames;
	};

	const [facultyModalType, setFacultyModalType] = useState<EFacultyType>(
		EFacultyType.CHAIR,
	);
	const [facultyModal, setFacultyModal] = useState<TFacultyForm>({
		...FacultyHelper.defaultFacultyForm,
	});

	const [isOpenFacultyModal, setIsOpenFacultyModal] = useState(false);

	const changeOpenStatus = (isOpen: boolean) => {
		if (!isOpen) {
			setFacultyModal({
				...FacultyHelper.defaultFacultyForm,
			});
		}
		setIsOpenFacultyModal(isOpen);
	};

	return (
		<>
			{facultyModalType === EFacultyType.CHAIR && (
				<ContainerChairModal
					changeIsOpen={changeOpenStatus}
					isOpen={isOpenFacultyModal}
					chairId={facultyModal.participant?.id}
					sessionId={facultyModal.sessionId!}
					callbackSubmit={chairsQuery.refetch}
				></ContainerChairModal>
			)}
			<IconCard
				title={t("old.programme.sessionDetails.chairs.label")}
				icon={"user-crown"}
				button={{
					label: t("old.programme.sessionDetails.chairs.titleButton"),
					icon: addConfig?.icon!,
					onClick: () => {
						handleOpenFacultyModal(
							{ ...FacultyHelper.defaultFacultyForm },
							EFacultyType.CHAIR,
						);
					},
				}}
			>
				{chairs && (
					<DataGridOld
						sx={{ marginTop: "1.5em" }}
						density={"standard"}
						columns={getColumns(
							getTranslatedColumnLabels(),
							ChairConfiguration.datagridHeaders,
						)}
						rows={rows}
						isAutoHeight={true}
						isHideFooter={true}
					/>
				)}
			</IconCard>
			<ConfirmationModal
				open={openConfirmationModal}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleModaleClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
		</>
	);
};

export default ChairsView;
