import {
	type EPublishSession,
	publish,
} from "@application/Configurations/publish.configuration";

export const getActivePublish = (activeValue: EPublishSession) => {
	const result = publish.filter((item) => {
		return item.value === activeValue;
	});

	return result[0] ? result[0] : publish[0];
};

const PublishHelper = {
	getActivePublish,
};

export default PublishHelper;
