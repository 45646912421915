import { t } from "i18next";

import IndicatorsDefinitions from "@application/Configurations/indicators.configuration";
import IssuesDefinitions from "@application/Configurations/issues.configuration";
import type {
	IChartSerie,
	IChartSeriesItem,
} from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import type { IIndicator } from "@infrastructure/model/interfaces/indicators/indicator.interface";
import type {
	IIssue,
	IIssueValues,
} from "@infrastructure/model/interfaces/indicators/issue.interface";
import ChartService from "@infrastructure/services/chart/chart.service";
import {
	type DtoDashboardGetFaculties,
	type DtoDashboardGetInvitationStatus,
	type DtoDashboardGetIssueItem,
	type DtoDashboardGetIssues,
	type DtoDashboardGetOverview,
	type DtoDashboardGetProgramme,
	type DtoDashboardGetSessionPublicationStatus,
	type DtoDashboardGetSessionStatus,
	statusInvitationArray,
	statusInvitationParticipantObject,
	statusPublicationArray,
	statusPublicationObject,
	statusSessionArray,
	statusSessionObject,
} from "@key4-front-library/core";

export { t } from "i18next";

const TransformBarChartSeries = (
	data: DtoDashboardGetOverview,
): Array<IChartSerie> => {
	const sessionsByDaydata = ChartService.GetFormatedSerie({
		data: data.sessionsByDay,
		name: "day",
		value: "count",
		category: "date",
	});

	const sessionsByDay_Serie: IChartSerie = {
		name: t("old.programme.dashboard.charts.bar.series.sessionsByDay.name"),
		data: sessionsByDaydata,
	};

	const invitationsByDaydata = ChartService.GetFormatedSerie({
		data: data.invitationsByDay,
		name: "day",
		value: "count",
		category: "date",
	});

	const invitationsByDay_Serie: IChartSerie = {
		name: t("old.programme.dashboard.charts.bar.series.invitationsByDay.name"),
		data: invitationsByDaydata,
	};

	return [sessionsByDay_Serie, invitationsByDay_Serie];
};

type TransformDonutChartSeriesProps = {
	serie:
		| any
		| DtoDashboardGetSessionStatus
		| DtoDashboardGetInvitationStatus
		| DtoDashboardGetSessionPublicationStatus;
	type: "sessions" | "invitations" | "publications";
};
const TransformDonutChartSeries = (props: TransformDonutChartSeriesProps) => {
	const { serie, type } = props;

	let data: Array<IChartSeriesItem> = [];
	switch (type) {
		case "sessions":
			data = statusSessionArray.map((status) => {
				return {
					name: statusSessionObject[status].label,
					value: serie[status],
					color: statusSessionObject[status].bgColor,
				};
			});
			break;
		case "invitations":
			data = statusInvitationArray.map((status) => {
				return {
					name: statusInvitationParticipantObject[status].label,
					value: serie[status],
					color: statusInvitationParticipantObject[status].bgColor,
				};
			});
			break;

		case "publications":
			data = statusPublicationArray.map((status) => {
				return {
					name: statusPublicationObject[status].label,
					value: serie[status],
					color: statusPublicationObject[status].bgColor,
				};
			});
			break;
		default:
			break;
	}
	return data;
};

const PopulateProgrammeIndicators = (data: DtoDashboardGetProgramme) => {
	const indicators: Array<IIndicator> = [];
	indicators.push({
		key: IndicatorsDefinitions.sessions.key,
		name: t("old.programme.dashboard.indicators.programme.indicators.sessions"),
		icon: IndicatorsDefinitions.sessions.icon,
		value: data.sessions,
	});
	indicators.push({
		key: IndicatorsDefinitions.rooms.key,
		name: t("old.programme.dashboard.indicators.programme.indicators.rooms"),
		icon: IndicatorsDefinitions.rooms.icon,
		value: data.rooms,
	});
	indicators.push({
		key: IndicatorsDefinitions.presentations.key,
		name: t(
			"old.programme.dashboard.indicators.programme.indicators.presentations",
		),
		icon: IndicatorsDefinitions.presentations.icon,
		value: data.presentations,
	});

	return indicators;
};

const PopulateFacultiesIndicators = (data: DtoDashboardGetFaculties) => {
	const indicators: Array<IIndicator> = [];
	indicators.push({
		key: IndicatorsDefinitions.faculties.key,
		name: t(
			"old.programme.dashboard.indicators.faculties.indicators.faculties",
		),
		icon: IndicatorsDefinitions.faculties.icon,
		value: data.faculties,
	});
	indicators.push({
		key: IndicatorsDefinitions.chairs.key,
		name: t("old.programme.dashboard.indicators.faculties.indicators.chairs"),
		icon: IndicatorsDefinitions.chairs.icon,
		value: data.chairs,
	});
	indicators.push({
		key: IndicatorsDefinitions.speakers.key,
		name: t("old.programme.dashboard.indicators.faculties.indicators.speakers"),
		icon: IndicatorsDefinitions.speakers.icon,
		value: data.speakers,
	});
	return indicators;
};

const PopulateIssuesIndicators = (data: DtoDashboardGetIssues) => {
	const accessors: Array<keyof DtoDashboardGetIssueItem> = [
		"sessions",
		"presentations",
		"invitations",
	];

	const anomaliesValues: Array<IIssueValues> = [];
	const clashesValues: Array<IIssueValues> = [];

	accessors.forEach((accessor: keyof DtoDashboardGetIssueItem) => {
		if (data.anomalies[accessor] > 0) {
			anomaliesValues.push({
				key: `${IssuesDefinitions.anomalies.key}-${accessor}`,
				name: t(`old.programme.dashboard.alerts.anomalies.issues.${accessor}`),
				value: data.anomalies[accessor],
			});
		}
		if (data.clashes[accessor] > 0) {
			clashesValues.push({
				key: `${IssuesDefinitions.clashes.key}-${accessor}`,
				name: t(`old.programme.dashboard.alerts.clashes.issues.${accessor}`),
				value: data.clashes[accessor],
			});
		}
	});
	return [
		{
			key: IssuesDefinitions.anomalies.key,
			name: t("old.programme.dashboard.alerts.anomalies.title"),
			icon: IssuesDefinitions.anomalies.icon,
			themeColor: IssuesDefinitions.anomalies.themeColor,
			values: anomaliesValues,
		},
		{
			key: IssuesDefinitions.clashes.key,
			name: t("old.programme.dashboard.alerts.clashes.title"),
			icon: IssuesDefinitions.clashes.icon,
			themeColor: IssuesDefinitions.clashes.themeColor,
			values: clashesValues,
		},
	] as Array<IIssue>;
};

const DashboardFactory = {
	TransformBarChartSeries,
	TransformDonutChartSeries,
	PopulateProgrammeIndicators,
	PopulateFacultiesIndicators,
	PopulateIssuesIndicators,
};

export default DashboardFactory;
