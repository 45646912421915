import { useEffect, useState } from "react";

import {
	getColorFromHash,
	getContrastedColor,
} from "@infrastructure/services/color/color.service";
import { avatarUsernameInitials } from "@infrastructure/services/gravatar/avatar.service";
import {
	gravatarGetTestExistServiceUrl,
	gravatarHashEmailAddress,
	gravatarIsExistImage,
} from "@infrastructure/services/gravatar/gravatar.service";
import { Avatar } from "@mui/material";

type Props = {
	email: string;
	firstname: string;
	lastname: string;
	displaySize?: number;
};

// #region 'Component'
const Gravatar = (props: Props) => {
	// #region 'defnition'
	const { email, firstname, lastname, displaySize = 40 } = props;

	const [exist, setExist] = useState<boolean>(false);
	const hash = gravatarHashEmailAddress(email);
	const imageUrl = gravatarGetTestExistServiceUrl(hash, displaySize);
	const initials = avatarUsernameInitials({ firstname, lastname });
	const bgColor = getColorFromHash(hash);
	const fgColor = getContrastedColor(bgColor);

	// #endregion 'defnition'

	// #region 'isExsitImage'
	useEffect(() => {
		const isExsitImage = async (serviceUrl: string) => {
			await gravatarIsExistImage(serviceUrl).then((response) => {
				setExist(response);
			});
		};
		isExsitImage(imageUrl);
	}, [imageUrl]);
	// #endregion 'isExsitImage'

	// #region 'Rendering'
	if (!exist) {
		return (
			<Avatar
				sx={{
					bgcolor: bgColor,
					color: fgColor,
					fontWeight: "clear",
					width: displaySize,
					height: displaySize,
					fontSize: displaySize / 2.2,
				}}
			>
				{initials}
			</Avatar>
		);
	}

	return (
		<Avatar sx={{ width: displaySize, height: displaySize }} src={imageUrl} />
	);
	// #endregion 'Rendering'
};
// #endregion 'Component'

export default Gravatar;
