import IconsApplicationConfiguration from "@application/Configurations/icons.application.configuration";
import BadTabs from "@application/Configurations/tabs/badTabs.config";
import type { EAnomalies } from "@application/enum/Anomalies.enum";
import AnomaliesFactory from "@application/factory/anomalies/anomaliesFactory";
import type {
	ISettingsAnomaliesHookFormValues,
	ISettingsAnomaliesObject,
} from "@domain/interfaces/settings.anomalies.interface";
import type { ISettingsClashesObject } from "@domain/interfaces/settings.clashes.interface";
import type { ISettingsSchedulerObject } from "@domain/interfaces/settings.scheduler.interface";
import type {
	IAnomaly,
	ISettingsAnomaliesModel,
} from "@domain/model/settings.anomalies.model";
import type { ISettingsClashesModel } from "@domain/model/settings.clashes.model";
import type { ISettingsSchedulerModel } from "@domain/model/settings.scheduler.model";
import DateService from "@infrastructure/services/dates/date.service";
import type {
	DtoClient,
	DtoEvent,
	DtoSettingsScheduler,
} from "@key4-front-library/core/Dto";

const GetIconsItems = () => IconsApplicationConfiguration.GetIconsItems();

const GetSettingsMenuItems = (client: DtoClient, event: DtoEvent) =>
	BadTabs.GetSettingsMenuConfiguration(client.key, event.key);

const GetAdvancedSettingsMenuItems = (client: DtoClient, event: DtoEvent) =>
	BadTabs.GetAdvancedSettingsMenuConfiguration(client.key, event.key);

const GetGeneralMenuItems = (client: DtoClient, event: DtoEvent) =>
	BadTabs.GetGeneralMenuConfiguration(client.key, event.key);

type TransformSettingsSchedulerDataForReadingProps = {
	settings: DtoSettingsScheduler;
	culture: string;
};
const TransformSettingsSchedulerDataForReading = (
	props: TransformSettingsSchedulerDataForReadingProps,
): ISettingsSchedulerObject | undefined => {
	const { settings, culture } = props;
	if (!settings) return undefined;
	const startDate = DateService.GetDateFromIsoString(
		settings.programmeStartDate,
	);

	const endDate = DateService.GetDateFromIsoString(settings.programmeEndDate);

	const startTime = DateService.GetDateFromIsoString(
		settings.schedulerStartHour,
	);

	const endTime = DateService.GetDateFromIsoString(settings.schedulerEndHour);

	const formatedStartDate = DateService.Format.ShortDate({
		date: startDate!,
		culture,
	});

	const formatedEndDate = DateService.Format.ShortDate({
		date: endDate!,
		culture,
	});

	const formatedStartTime = DateService.Format.TimeSimple({
		date: startTime!,
		culture,
	});

	const formatedEndTime = DateService.Format.TimeSimple({
		date: endTime!,
		culture,
	});

	const dateInterval = DateService.Differences.DateDiffDays({
		startDate,
		endDate,
	});

	const timeInterval = DateService.Differences.DateDiffHours({
		startTime,
		endTime,
	});

	const formatedValues: ISettingsSchedulerModel = {
		programmeStartDate: formatedStartDate,
		programmeEndDate: formatedEndDate,
		schedulerStartHour: formatedStartTime,
		schedulerEndHour: formatedEndTime,
	};

	return {
		dates: {
			startDate,
			endDate,
			interval: dateInterval,
		},
		time: {
			startTime,
			endTime,
			interval: timeInterval,
		},
		formatedValues,
	} as ISettingsSchedulerObject;
};

type TransformSettingsSchedulerDataForWritingProps = {
	settings: ISettingsSchedulerObject;
	culture: string;
};
const TransformSettingsSchedulerDataForWriting = (
	props: TransformSettingsSchedulerDataForWritingProps,
): ISettingsSchedulerModel => {
	const { settings } = props;

	const startDateIso = settings.dates.startDate
		? DateService.Format.IsoStringDate({ date: settings.dates.startDate })
		: null;
	const endDateIso = settings.dates.endDate
		? DateService.Format.IsoStringDate({ date: settings.dates.endDate })
		: null;

	const startTime = DateService.Format.TimeSimple({
		date: settings.time.startTime!,
	});
	const endTime = DateService.Format.TimeSimple({
		date: settings.time.endTime!,
	});

	return {
		programmeStartDate: startDateIso,
		programmeEndDate: endDateIso,
		schedulerStartHour: startTime,
		schedulerEndHour: endTime,
	} as ISettingsSchedulerModel;
};

type TransformSettingsClashesDataProps = {
	settings: ISettingsClashesModel;
	culture: string;
};
const TransformSettingsClashesDataForReading = (
	props: TransformSettingsClashesDataProps,
): ISettingsClashesObject => {
	const { settings, culture } = props;

	const facultyClashDetectionInterval = DateService.GetDateFromIsoString(
		settings.facultyClashDetectionInterval,
	);

	const roomClashDetectionInterval = DateService.GetDateFromIsoString(
		settings.roomClashDetectionInterval,
	);
	const isSpeakerBusySessionTimeSlot = settings.isSpeakerBusySessionTimeSlot;

	const formatedFacultyClashDetectionInterval = DateService.Format.TimeSimple({
		date: facultyClashDetectionInterval!,
		culture,
	});

	const formatedRoomClashDetectionInterval = DateService.Format.TimeSimple({
		date: roomClashDetectionInterval!,
		culture,
	});

	const formatedValues: ISettingsClashesModel = {
		facultyClashDetectionInterval: formatedFacultyClashDetectionInterval,
		roomClashDetectionInterval: formatedRoomClashDetectionInterval,
		isSpeakerBusySessionTimeSlot,
	};

	return {
		facultyClashDetectionInterval,
		roomClashDetectionInterval,
		isSpeakerBusySessionTimeSlot,
		formatedValues,
	} as ISettingsClashesObject;
};

type TransformSettingsClashesDataForWritingProps = {
	settings: ISettingsClashesObject;
	culture: string;
};
const TransformSettingsClashesDataForWriting = (
	props: TransformSettingsClashesDataForWritingProps,
): ISettingsClashesModel => {
	const { settings } = props;

	const facultyClash = DateService.Format.TimeSimple({
		date: settings.facultyClashDetectionInterval!,
	});
	const roomClash = DateService.Format.TimeSimple({
		date: settings.roomClashDetectionInterval!,
	});

	return {
		facultyClashDetectionInterval: facultyClash,
		roomClashDetectionInterval: roomClash,
		isSpeakerBusySessionTimeSlot: settings.isSpeakerBusySessionTimeSlot,
	} as ISettingsClashesModel;
};

type TransformSettingsAnomaliesDataProps = {
	anomalies: ISettingsAnomaliesModel[];
	translations: any;
	translationsAnomalies: any;
	isEdit?: boolean;
};
const TransformSettingsAnomaliesData = (
	props: TransformSettingsAnomaliesDataProps,
): ISettingsAnomaliesObject[] => {
	const { anomalies, translations, translationsAnomalies, isEdit } = props;
	const formatedAnomalies: ISettingsAnomaliesObject[] = [];

	for (const anomaly of anomalies) {
		const formatedItems = [];
		if (anomaly.anomalies)
			for (const item of anomaly.anomalies) {
				const { title, text, icon } =
					AnomaliesFactory.TransformSessionOrPresentationOrInvitationAnomaly(
						item.type,
						translationsAnomalies,
					);
				const myItem = {
					title: title,
					text: text,
					icon: icon,
				};

				// If anomalies is in read-only mode and no anomalies is checked inside a category from EStatusAnomalies,
				// don't push the item so we'll get an empty array of items to not display the category.
				// In edit mode, we want to display all anomalies
				if ((!isEdit && item.enabled) || isEdit)
					formatedItems.push({
						id: item.type,
						checked: item.enabled,
						...myItem,
					});
			}
		formatedAnomalies.push({
			section: translations.status[anomaly.status],
			sectionType: anomaly.status,
			items: formatedItems,
		});
	}
	return formatedAnomalies;
};

type TransformSettingsAnomaliesDataForDefaultValuesFormProps = {
	anomalies: ISettingsAnomaliesModel[];
};
const TransformSettingsAnomaliesDataForDefaultValuesForm = (
	props: TransformSettingsAnomaliesDataForDefaultValuesFormProps,
): any => {
	const { anomalies } = props;
	let formatedDefaultValuesAnomalies = {};
	for (const anomaly of anomalies) {
		if (anomaly.anomalies)
			for (const item of anomaly.anomalies) {
				formatedDefaultValuesAnomalies = {
					...formatedDefaultValuesAnomalies,
					[item.type]: item.enabled,
				};
			}
	}
	return formatedDefaultValuesAnomalies;
};

type TransformSettingsAnomaliesDataForWritingProps = {
	anomaliesForm: ISettingsAnomaliesHookFormValues;
};
const TransformSettingsAnomaliesDataForWriting = (
	props: TransformSettingsAnomaliesDataForWritingProps,
): IAnomaly[] => {
	const { anomaliesForm } = props;
	const payload = [];
	for (const [key, value] of Object.entries(anomaliesForm)) {
		payload.push({ type: key as EAnomalies, enabled: value });
	}
	return payload;
};

const SettingsFactory = {
	GetIconsItems,
	GetAdvancedSettingsMenuItems,
	GetSettingsMenuItems,
	GetGeneralMenuItems,
	TransformSettingsSchedulerDataForReading,
	TransformSettingsSchedulerDataForWriting,
	TransformSettingsClashesDataForReading,
	TransformSettingsClashesDataForWriting,
	TransformSettingsAnomaliesData,
	TransformSettingsAnomaliesDataForDefaultValuesForm,
	TransformSettingsAnomaliesDataForWriting,
};

export default SettingsFactory;
