import { createContext, useContext, useState } from "react";

import type { ISettingsContext } from "@domain/interfaces/settings.context.interface";

type ISettingsContextType = {
	settingsContext: ISettingsContext | null;
	setSettingsContext: (settingsContext: ISettingsContext) => void;
};

const SettingsContext = createContext<ISettingsContextType | null>(null);

type Props = {
	children: JSX.Element;
};

export const SettingsProvider = (props: Props) => {
	const { children } = props;

	const [settingsContext, setSettingsContext] =
		useState<ISettingsContext | null>(null);

	return (
		<SettingsContext.Provider value={{ settingsContext, setSettingsContext }}>
			{children}
		</SettingsContext.Provider>
	);
};

export const useSettingsContext = () => {
	const context = useContext(SettingsContext);
	if (!context) {
		throw new Error(
			"useSettingsContext must be used within a SettingsProvider. Wrap a parent component in <SettingsProvider> to fix this error.",
		);
	}
	return context;
};
