import type { ReactNode } from "react";

import type { ISettingsAnomaliesItems } from "@domain/interfaces/settings.anomalies.interface";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";

type AnomalyDescriptionProps = {
	children?: ReactNode;
	item: ISettingsAnomaliesItems;
	index: number;
	isEdit?: boolean;
	styles?: {
		li?: any;
		iconColor?: string;
		chipColor?: string;
		chipBackgroundColor?: string;
		textColor?: string;
		boxBackgroundColor?: string;
	};
	watchIsChecked?: boolean;
};
const AnomalyDescription = (
	props: AnomalyDescriptionProps & dataCypressProp,
) => {
	const {
		children,
		item,
		index,
		isEdit,
		styles,
		watchIsChecked,
		dataCypressID,
	} = props;
	const theme = useTheme();

	return (
		<Box
			sx={{
				backgroundColor: styles?.boxBackgroundColor
					? styles?.boxBackgroundColor
					: isEdit && watchIsChecked
						? "secondary.light"
						: "background.paper",
				borderRadius: 4,
				p: 1,
				...(styles && styles?.li),
			}}
		>
			<Stack
				spacing={1}
				direction={{
					md: "row",
				}}
				alignItems={"center"}
			>
				{isEdit && children}
				<Chip
					icon={
						<Box padding={1}>
							<Icon
								prefix="far"
								iconName={item.icon as IconName}
								color={styles?.iconColor ?? theme.palette.primary.light}
								size="2x"
							/>
						</Box>
					}
					label={item.title}
					sx={{
						color: styles?.chipColor ?? theme.palette.primary.light,
						backgroundColor:
							styles?.chipBackgroundColor ?? theme.palette.background.default,
						height: "max-content",
					}}
					data-cy={`${dataCypressID}_item_${index}`}
				/>
				<Typography
					component={"span"}
					color={styles?.textColor}
					data-cy={`${dataCypressID}_text_${index}`}
				>
					<Stack direction={"row"}>
						{item.text}
						{item.code && <>&nbsp;-&nbsp;{item.code}</>}
					</Stack>
				</Typography>
			</Stack>
		</Box>
	);
};

export default AnomalyDescription;
