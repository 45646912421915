import type {
	TPagination,
	TypeApiQueryString,
} from "@infrastructure/model/interfaces/api.interface";
import {
	ErrorAPI,
	escapeSieveString,
	ESieveOperator,
} from "@key4-front-library/core";

const KEY_FILTER = "filters";
const KEY_INCLUDE_CUSTOM_FIELDS = "includeCustomFields";
const KEY_PAGE = "page";
const KEY_PAGE_SIZE = "pageSize";

const queryStringPagination = (
	pagination: TPagination | undefined,
): Array<TypeApiQueryString> => {
	if (pagination) {
		return [
			{
				key: KEY_PAGE,
				value: `${pagination.page + 1}`,
			},
			{
				key: KEY_PAGE_SIZE,
				value: `${pagination.pageSize}`,
			},
		];
	}
	return [];
};

const queryStringSorts = (
	sorts: Array<string> | undefined,
): Array<TypeApiQueryString> => {
	if (sorts && sorts.length > 0) {
		return [{ key: "sorts", value: sorts.join(",") }];
	}
	return [];
};

const queryStringFullText = (search: string): Array<TypeApiQueryString> => {
	if (search !== "") {
		return [
			{
				key: KEY_FILTER,
				value: `searchVector${ESieveOperator.STARTS_WITH}${escapeSieveString(search)}`,
			},
		];
	}
	return [];
};

const queryIncludeCustomFields = (
	value: boolean,
): Array<TypeApiQueryString> => {
	return [
		{
			key: KEY_INCLUDE_CUSTOM_FIELDS,
			value: value.toString(),
		},
	];
};

const queryFilters = (
	filter: string | undefined,
): Array<TypeApiQueryString> => {
	if (filter && filter !== "") {
		return [
			{
				key: KEY_FILTER,
				value: filter,
			},
		];
	}
	return [];
};

const tryCatch = async <T>(cb: () => Promise<T>): Promise<T | ErrorAPI> => {
	try {
		return Promise.resolve(await cb());
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const ApiHelper = {
	queryStringFullText,
	queryStringPagination,
	queryStringSorts,
	queryFilters,
	queryIncludeCustomFields,
	tryCatch,
};

export default ApiHelper;
