import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Stack, type SxProps } from "@mui/material";

import K4StatusBullet from "./K4StatusBullet";

interface IK4StatusBulletLegend {
	text: string;
	bullet: {
		color: string;
		size?: string;
		sx?: SxProps;
	};
}

const K4StatusBulletLegend = (
	props: IK4StatusBulletLegend & dataCypressProp,
) => {
	const { text, bullet, dataCypressID } = props;
	return (
		<Stack spacing={1} direction="row">
			<K4StatusBullet
				color={bullet.color}
				dataCypressID={dataCypressID ? `${dataCypressID}-bullet` : undefined}
				size={bullet.size}
				sx={bullet.sx}
			/>
			<span
				data-cy={dataCypressID ? `${dataCypressID}-label` : undefined}
				style={{ paddingTop: "1px" }}
			>
				{text}
			</span>
		</Stack>
	);
};

export default K4StatusBulletLegend;
