import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Container, Skeleton, Stack } from "@mui/material";

type Props = {
	arrayOfCards: any[] | undefined;
	isLoading: boolean;
	renderCard: JSX.Element | any;
};
const DraggableList = (props: Props & dataCypressProp) => {
	const { arrayOfCards, isLoading, renderCard, dataCypressID } = props;

	if (isLoading) {
		return (
			<Container maxWidth={"xl"}>
				{[...Array(10).keys()].map((item) => (
					<Skeleton key={item} animation="wave" width={"xl"} height={50} />
				))}
			</Container>
		);
	}

	return (
		<Stack spacing={2}>
			{arrayOfCards?.map((card, index) =>
				renderCard(card, index, card.isExpanded, dataCypressID),
			)}
		</Stack>
	);
};

export default DraggableList;
