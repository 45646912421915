import { t } from "i18next";
import { DateTime, Duration } from "luxon";
import { useSnackbar } from "notistack";
import type React from "react";
import { useCallback, useState } from "react";

import ModalSettingsSessionTemplates from "@application/components/SessionTemplates/ModalSettingsSessionTemplates";
import {
	type SessionTemplatesRead,
	TIME_PARSING_FORMAT,
	type TypeUseFormCreateOrUpdateSessionTemplateValue,
	useContextModule,
} from "@key4-front-library/core";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import type { SessionTemplateWrite } from "@key4-front-library/core/Dto/SessionTemplatesDto";

type ContainerSettingsSessionTemplatesModalProps = {
	primaryTagId: string;
	isOpenModal: boolean;
	handleModalClose: (reason?: string) => void;
	sessionTemplateToUpdate?: SessionTemplatesRead;
	isLoadingGetSessionTemplate: boolean;
};

const ContainerSettingsSessionTemplatesModal = (
	props: ContainerSettingsSessionTemplatesModalProps,
): React.ReactNode => {
	const {
		isOpenModal,
		primaryTagId,
		handleModalClose,
		isLoadingGetSessionTemplate,
		sessionTemplateToUpdate,
	} = props;

	const { client, event } = useContextModule();
	const { enqueueSnackbar } = useSnackbar();

	const [
		isLoadingCreateOrUpdateSessionTemplate,
		setIsLoadingCreateOrUpdateSessionTemplate,
	] = useState<boolean>(false);

	const formatSessionTemplateTimes = (
		sessionTime?: string,
	): string | undefined => {
		if (!sessionTime) {
			return;
		}
		return DateTime.fromISO(sessionTime).toFormat(TIME_PARSING_FORMAT);
	};

	const formatDuration = (duration: string | undefined): string | undefined =>
		duration && duration !== "00:00"
			? Duration.fromObject({
					hours: Number.parseInt(duration.split(":")[0]),
					minutes: Number.parseInt(duration.split(":")[1]),
				}).toISO()
			: undefined;

	const displayErrorSnackBar = useCallback((): void => {
		enqueueSnackbar(t("old.common.errors.generic"), {
			variant: "error",
		});
	}, [enqueueSnackbar, t]);

	const createNewTemplate = useCallback(
		async (
			newTemplate: TypeUseFormCreateOrUpdateSessionTemplateValue,
		): Promise<void> => {
			setIsLoadingCreateOrUpdateSessionTemplate(true);
			const createBody: SessionTemplateWrite = {
				...newTemplate,
				presentationDuration: formatDuration(newTemplate.presentationDuration),
				startHour: formatSessionTemplateTimes(newTemplate.startHour),
				endHour: formatSessionTemplateTimes(newTemplate.endHour),
				duration: formatDuration(newTemplate.duration),
				status: newTemplate.status,
			};

			try {
				await ControllersBo.SessionTemplatesController.createTemplate(
					client.id,
					event.id,
					createBody,
				);
				handleModalClose();
				enqueueSnackbar(
					t(
						"old.programme.settings.sessionTemplates.modal.addTemplate.success",
					),
					{
						variant: "success",
					},
				);
			} catch {
				displayErrorSnackBar();
			}

			setIsLoadingCreateOrUpdateSessionTemplate(false);
		},
		[
			client.id,
			displayErrorSnackBar,
			enqueueSnackbar,
			event.id,
			handleModalClose,
			t,
		],
	);

	const updateTemplate = useCallback(
		async (
			updatedTemplate: TypeUseFormCreateOrUpdateSessionTemplateValue,
		): Promise<void> => {
			if (!sessionTemplateToUpdate) {
				return;
			}
			setIsLoadingCreateOrUpdateSessionTemplate(true);
			const updateBody: SessionTemplateWrite = {
				...updatedTemplate,
				presentationDuration: formatDuration(
					updatedTemplate.presentationDuration,
				),
				startHour: formatSessionTemplateTimes(updatedTemplate.startHour),
				endHour: formatSessionTemplateTimes(updatedTemplate.endHour),
				duration: formatDuration(updatedTemplate.duration),
				status: updatedTemplate.status,
			};

			try {
				await ControllersBo.SessionTemplatesController.updateTemplate(
					client.id,
					event.id,
					sessionTemplateToUpdate.id,
					updateBody,
				);
				handleModalClose();
				enqueueSnackbar(
					t(
						"old.programme.settings.sessionTemplates.modal.editTemplate.success",
					),
					{
						variant: "success",
					},
				);
			} catch {
				displayErrorSnackBar();
			}

			setIsLoadingCreateOrUpdateSessionTemplate(false);
		},
		[
			client.id,
			displayErrorSnackBar,
			enqueueSnackbar,
			event.id,
			handleModalClose,
			sessionTemplateToUpdate,
			t,
		],
	);

	const handleSubmit = useCallback(
		(form: any): void => {
			const newTemplate = form;
			newTemplate.primaryTagId = primaryTagId;
			if (sessionTemplateToUpdate) {
				void updateTemplate(newTemplate);
			} else {
				void createNewTemplate(form);
			}
		},
		[createNewTemplate, primaryTagId, sessionTemplateToUpdate, updateTemplate],
	);

	return (
		<ModalSettingsSessionTemplates
			isOpenModal={isOpenModal}
			handleModalClose={handleModalClose}
			handleSubmit={handleSubmit}
			primaryTagId={primaryTagId}
			templateToEdit={sessionTemplateToUpdate}
			isLoading={
				isLoadingGetSessionTemplate || isLoadingCreateOrUpdateSessionTemplate
			}
		/>
	);
};

export default ContainerSettingsSessionTemplatesModal;
