import { t } from "i18next";
import { useEffect, useState } from "react";

import ProgrammeExportConfiguration from "@application/Configurations/ProgrammeExportConfiguration";
import ContainerProgrammeExportModal from "@application/Containers/ContainerProgrammeExportModal";
import ControllersApp from "@application/Controllers/ControllersApp";
import { EnumProgrammeExportStaticSectionKey } from "@application/Enums/ProgrammeExportEnum";
import HooksApp from "@application/hooks";
import SnackBarHook from "@application/hooks/SnackBarHook";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import {
	ConfirmationModal,
	DataGridOld,
	getColumns,
} from "@key4-front-library/core";
import { EConfirmationModalAction } from "@key4-front-library/core/Enums/Modal";
import { Skeleton, Stack } from "@mui/material";

const TabSettingsProgrammeExport = () => {
	const { sendSuccess, sendError } = SnackBarHook.useSnackBar();

	const { cloneEntity, deleteEntity } =
		ControllersApp.ProgrammeExportController.useEntity();

	const defaultTabKey =
		EnumProgrammeExportStaticSectionKey.GeneralInformationSection;
	const [activeTabKey, setActiveTabKey] = useState<string | null>(null);
	const [
		currentProgrammeExportTemplateId,
		setCurrentProgrammeExportTemplateId,
	] = useState<string | null>(null);

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
		useState<boolean>(false);

	const { readListDataGrid: readList } =
		HooksApp.ProgrammeExportHook.useEntity();

	const translationBaseKey: string = "old.programme.settings.programmeExport";

	const getTranslatedColumnLabels = (): string[] => {
		const headersNames: string[] = [];
		ProgrammeExportConfiguration.datagridHeaders.forEach((item) =>
			headersNames.push(
				t(
					[translationBaseKey, "datagrid", "headerLabels", item.field].join(
						".",
					),
				),
			),
		);
		return headersNames;
	};

	const handleChangeTab = (key: string | null) =>
		setActiveTabKey(key === "default" ? defaultTabKey : (key ?? null));

	const handleSessionCallbackSubmit = (): void => {
		refetch();
	};
	const handleButtonProgrammeExportAddClick = (): void => {
		setActiveTabKey(defaultTabKey);
		setCurrentProgrammeExportTemplateId(null);
		refetch();
		setIsOpenModal(true);
	};

	const handleDataGridRowButtonProgrammeExportCloneClick = (
		exportProgrammeId: string,
	) => {
		cloneEntity(exportProgrammeId)
			.then(() => {
				sendSuccess(t([translationBaseKey, "actions", "cloned"].join(".")));
				refetch();
			})
			.catch((error) => sendError(error))
			.finally(() => {
				setCurrentProgrammeExportTemplateId(null);
				setIsOpenConfirmationModal(false);
			});
	};

	const handleDataGridRowButtonProgrammeExportEditClick = (
		exportProgrammeId: string,
	): void => {
		setIsOpenModal(true);
		setActiveTabKey(defaultTabKey);
		setCurrentProgrammeExportTemplateId(exportProgrammeId);
	};

	const handleDataGridRowButtonProgrammeExportDeleteClick = (
		exportProgrammeId: string,
	): void => {
		setCurrentProgrammeExportTemplateId(exportProgrammeId);
		setIsOpenConfirmationModal(true);
	};

	const handleButtonConfirmationModalProgrammeExportDeleteClick = (): void => {
		deleteEntity(currentProgrammeExportTemplateId!)
			.then(() => {
				sendSuccess(t([translationBaseKey, "actions", "deleted"].join(".")));
				refetch();
			})
			.catch((error) => sendError(error))
			.finally(() => {
				setCurrentProgrammeExportTemplateId(null);
				setIsOpenConfirmationModal(false);
			});
	};

	const handleConfirmationModaleClose = (): void => {
		setCurrentProgrammeExportTemplateId(null);
		setIsOpenConfirmationModal(false);
	};

	const columns = getColumns(
		getTranslatedColumnLabels(),
		ProgrammeExportConfiguration.datagridHeaders,
	);

	const { data, isFetching, refetch } = readList({
		clone: handleDataGridRowButtonProgrammeExportCloneClick,
		edit: handleDataGridRowButtonProgrammeExportEditClick,
		delete: handleDataGridRowButtonProgrammeExportDeleteClick,
	});

	useEffect(() => {
		refetch();
	}, []);

	if (isFetching) {
		return (
			<Skeleton
				sx={{ width: "100%", height: 600 }}
				animation="wave"
				variant="rectangular"
			/>
		);
	}

	return (
		data && (
			<IconCard
				title={t("old.programme.settings.tabs.programmeExport.label")}
				icon={"book" as IconName}
				button={{
					label: t(`old.form.buttons.add`),
					icon: "plus" as IconName,
					onClick: handleButtonProgrammeExportAddClick,
				}}
			>
				<Stack spacing={3.5} sx={{ mt: -3 }}>
					<DataGridOld columns={columns} rows={data} isAutoHeight={true} />
					<ContainerProgrammeExportModal
						id={currentProgrammeExportTemplateId}
						isOpen={isOpenModal}
						activeTabKey={activeTabKey}
						onChangeTab={handleChangeTab}
						changeIsOpen={setIsOpenModal}
						callbackSubmit={handleSessionCallbackSubmit}
					/>
					<ConfirmationModal
						open={isOpenConfirmationModal}
						text={
							t([translationBaseKey, "confirmationModal", "prompt"].join("."))!
						}
						action={EConfirmationModalAction.DELETE}
						handleAction={
							handleButtonConfirmationModalProgrammeExportDeleteClick
						}
						handleModaleClose={handleConfirmationModaleClose}
						maxWidth={"sm"}
					/>
				</Stack>
			</IconCard>
		)
	);
};

export default TabSettingsProgrammeExport;
