import { t } from "i18next";

import { activityToProposalCardInfo } from "@application/Mappers";
import {
	type ActivityMatchDto,
	type ActivityReplacementDto,
	Chip,
	getMatchingType,
	IconOld,
	Link,
	UserInformation,
} from "@key4-front-library/core";
import { Stack, Typography } from "@mui/material";
import type { GridValidRowModel } from "@mui/x-data-grid";

export const listActivitiesToListRowsModels = (props: {
	activities: Array<ActivityReplacementDto>;
	color: string;
	pathSession: string;
	translation: string;
	participantProposals?: Array<ActivityMatchDto>;
	contactProposals?: Array<ActivityMatchDto>;
}): Array<GridValidRowModel> => {
	const {
		activities,
		color,
		pathSession,
		translation,
		participantProposals,
		contactProposals,
	} = props;
	return activities.map((activity) => {
		const proposalCardInfo = activityToProposalCardInfo({
			activity,
			participantProposals,
			contactProposals,
		});

		return {
			isRowSelectable: proposalCardInfo.isRowSelectable,
			propositionInfo: proposalCardInfo.propositionInfo,
			id: activity.id,
			key: (
				<Link
					to={pathSession + activity.session.id}
					color={color}
					justifyContent={"center"}
				>
					{activity.session.key}
				</Link>
			),
			proposal: (
				<Stack width={"100%"}>
					<UserInformation {...proposalCardInfo.userInformation} />
				</Stack>
			),
			type: <Chip {...getMatchingType(activity.type)} />,
			actions: (
				<Link to={activity.id} color={color}>
					<Stack direction="row" spacing={1} alignItems={"end"}>
						<IconOld prefix="far" iconName="heart-circle-bolt" />
						<Stack>
							<Typography>{t(`${translation}.manage`)}</Typography>
						</Stack>
					</Stack>
				</Link>
			),
		};
	});
};
