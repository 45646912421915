import ControllersApp from "@application/Controllers/ControllersApp";
import type { GridSortModel } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";

/**
 * Hook Entity
 * @returns Methods for fetching data
 */
const useEntity = () => {
	const { readList: readListConvocations } =
		ControllersApp.ConvocationController.useEntity();

	const readList = (
		sessionId: string,
		sortModel: GridSortModel,
		actions: {
			actionDetails: (convocationId: string) => void;
			actionDelete: (convocationId: string) => void;
		},
	) => {
		const queryKey = ["convocations", sessionId, sortModel];
		const { actionDetails, actionDelete } = actions;

		const { data, isLoading, refetch } = useQuery({
			queryKey,
			queryFn: async () => {
				return await readListConvocations(
					sessionId,
					{
						details: actionDetails,
						delete: actionDelete,
					},
					sortModel
						.filter((criteria) => criteria.field !== "actionButtons")
						.map((criteria) =>
							[criteria.sort === "desc" ? "-" : "", criteria.field].join(""),
						),
				);
			},
			placeholderData: (previousData) => previousData,
			enabled: !!sessionId,
		});

		return { data, isLoading, refetch };
	};

	return { readList };
};

const ConvocationHook = {
	useEntity,
};

export default ConvocationHook;
