import ConfigurationApplication from "@application/Configurations/configuration";
import BulkActionHelper, {
	type TPatchFilter,
} from "@infrastructure/helpers/bulkAction.helper";
import type { TPagination } from "@infrastructure/model/interfaces/api.interface";
import {
	type DtoFacultyGet,
	ErrorAPI,
	type IK4Card,
	queryFilters,
	queryStringPagination,
	queryStringSorts,
	Services,
	sieveStringFiltersPageList,
	type TFilter,
	type TypeApiResponsePagined,
} from "@key4-front-library/core";
import type { GridRowsProp, GridValidRowModel } from "@mui/x-data-grid-pro";

const getFaculties = async (
	clientId: string,
	eventId: string,
	search: string,
	sorts: Array<string>,
	pagination: TPagination,
	filter: string,
): Promise<TypeApiResponsePagined<Array<IK4Card>>> => {
	try {
		const filterString = sieveStringFiltersPageList(
			ConfigurationApplication.Faculty.sieveKeys,
			search,
			filter,
		);
		const faculties =
			await Services.Events.Programme.FacultiesService.getListPagined(
				clientId,
				eventId,
				[
					...queryStringPagination(pagination),
					...queryStringSorts(sorts),
					...queryFilters(filterString),
				],
			);

		const { data } = faculties;
		const formatedfaculties: Array<IK4Card> = [];
		for (const faculty of data) {
			formatedfaculties.push({
				id: faculty.participantId,
				firstname: faculty.firstname ?? undefined,
				lastname: faculty.lastname ?? undefined,
				email: faculty.email ?? undefined,
				headerTitle: faculty.key,
				hasAnomalies: faculty.hasAnomalies,
				hasClashes: faculty.hasClashes,
				contentTitle: `${faculty.firstname} ${faculty.lastname}`,
				contentSubtitle: faculty.profile ? faculty.profile.name : null,
				isGravatar: true,
				key: faculty.key,
				link: faculty.participantId,
			});
		}
		return Promise.resolve({
			pagination: faculties.pagination,
			data: formatedfaculties,
			hasMore: faculties.hasMore,
		});
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getFacultyDetails = async (
	clientId: string,
	eventId: string,
	participantId: string,
): Promise<any | ErrorAPI> => {
	try {
		const faculty = await Services.Events.Programme.FacultiesService.getDetails(
			clientId,
			eventId,
			participantId,
		);

		return Promise.resolve(faculty);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getFacultiesListBulkActionStepSelect = async (
	clientId: string,
	eventId: string,
	search: string,
	sorts: Array<string>,
	filter: string,
): Promise<Array<GridValidRowModel> | number | ErrorAPI> => {
	try {
		const filterString = sieveStringFiltersPageList(
			ConfigurationApplication.Faculty.sieveKeys,
			search,
			filter,
		);

		const faculties =
			await Services.Events.Programme.FacultiesService.getListPagined(
				clientId,
				eventId,
				[
					...queryStringPagination({
						page: 0,
						pageSize: 100,
					}),
					...queryStringSorts(sorts),
					...queryFilters(filterString),
				],
			);

		if (
			!faculties.pagination.totalCount ||
			faculties.pagination.totalCount > 100
		) {
			if (!faculties.pagination.totalCount)
				return Promise.reject(new ErrorAPI(400, "No pagination set"));
			return Promise.resolve(faculties.pagination.totalCount ?? 0);
		}

		return Promise.resolve(
			faculties.data.map((faculty: DtoFacultyGet) => {
				return {
					id: faculty.participantId,
					key: faculty.key,
					firstname: faculty.firstname,
					lastname: faculty.lastname,
					email: faculty.email,
				};
			}),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const postFacultyBulkActionInvitations = async (
	clientId: string,
	eventId: string,
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
): Promise<boolean | ErrorAPI> => {
	return BulkActionHelper.fetchActionMiddleware(
		filters,
		isNoStepSelect,
		rows,
		selectedItems,
		async (patchFilters: TPatchFilter): Promise<boolean> => {
			await Services.Events.Programme.FacultiesService.postBulkInvitation(
				clientId,
				eventId,
				{
					filters: patchFilters.filterString,
					excludedIds: patchFilters.excludedIds,
				},
			);
			return Promise.resolve(true);
		},
		ConfigurationApplication.Faculty.sieveKeys,
	);
};

const FacultyController = {
	getFaculties,
	getFacultyDetails,
	getFacultiesListBulkActionStepSelect,
	postFacultyBulkActionInvitations,
};

export default FacultyController;
