import PresentationHelper from "@application/helpers/PresentationHelper";
import {
	ApplicationColors,
	type DtoFaculty,
	type DtoPresentation,
	statusInvitationParticipantObject,
	TextColors,
	useIdentity,
} from "@key4-front-library/core";
import AvatarWithBadge from "@key4-front-library/core/Bo/Components/AvatarWithBadge";
import {
	alpha,
	AvatarGroup,
	Box,
	Stack,
	type SxProps,
	Typography,
	useTheme,
} from "@mui/material";

type PropsPresentationSummary = {
	isExpanded: boolean;
	presentation: DtoPresentation;
	speakers: Array<DtoFaculty>;
};

const PresentationItemSummary = (props: PropsPresentationSummary) => {
	const { isExpanded, presentation, speakers } = props;
	const { key, code, title, startDate, endDate } = presentation;

	const theme = useTheme();
	const identity = useIdentity();

	const styles = {
		avatars: {
			"& .MuiAvatar-root": {
				width: 32,
				height: 32,
				fontSize: 16,
				borderColor: identity.darkmode
					? ApplicationColors.darkGrey.main
					: ApplicationColors.white.main,
			},
			"&>.MuiAvatar-root": {
				bgcolor: identity.darkmode
					? alpha(ApplicationColors.white.main, 0.08)
					: alpha(TextColors.light.primary, 0.33),
				color: ApplicationColors.white.main,
			},
		} as SxProps,
	};

	const period = PresentationHelper.getPresentationDates(
		presentation.isWithoutTimeSlot,
		startDate,
		endDate,
	);

	return (
		<Stack
			direction={"row"}
			alignItems={"center"}
			justifyContent={"space-between"}
			sx={{ width: "100%" }}
			m={0}
			p={0}
		>
			<Stack direction={"column"} spacing={1} m={0} p={0}>
				<Box component="span">
					<Typography
						variant={"h6"}
						color={
							identity.darkmode
								? theme.palette.primary.main
								: theme.palette.primary.light
						}
					>
						{title}
					</Typography>
				</Box>
				<Stack direction={"row"} spacing={1}>
					<Typography
						variant={"subtitle2"}
						color={theme.palette.text.secondary}
					>
						{key}
						{code && ` ${code}`}
					</Typography>
					<Typography variant={"subtitle2"} color={theme.palette.text.primary}>
						{period}
					</Typography>
				</Stack>
			</Stack>

			<AvatarGroup max={4} sx={styles.avatars}>
				{!isExpanded &&
					speakers.map((speaker: DtoFaculty, index: number) => (
						<AvatarWithBadge
							key={[speaker.participantId, index].join("-")}
							data={{
								email: speaker.email ?? "",
								firstname: speaker.firstname ?? "",
								lastname: speaker.lastname ?? "",
								status: {
									bgColor:
										statusInvitationParticipantObject[speaker.invitationStatus]
											.bgColor,
									label:
										statusInvitationParticipantObject[speaker.invitationStatus]
											.label,
									// TODO: K4PROG-2037 - Suggestion : Add Label in overlay title
								},
							}}
						/>
					))}
			</AvatarGroup>
		</Stack>
	);
};

export default PresentationItemSummary;
