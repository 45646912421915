import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { pickTextColorBasedOnBgColor } from "@infrastructure/services/color/color.service";
import type { ITag } from "@key4-front-library/core";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";

type Props = {
	contentTitle: string | undefined;
	contentSubtitle?: string | null;
	tags?: ITag[];
	contentTitleStyle?: any;
	onTitleSubtitleClick?: () => void;
};
const TitleSubtitleAndTags = (props: Props & dataCypressProp) => {
	const {
		contentTitle,
		contentSubtitle,
		tags,
		contentTitleStyle,
		dataCypressID,
		onTitleSubtitleClick,
	} = props;

	const styles = {
		ellipsis: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			display: "-webkit-box",
			WebkitLineClamp: 1,
			WebkitBoxOrient: "vertical",
		},
		labelEllipsis: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	};

	return (
		<Stack>
			<Box display={"inline-block"}>
				<Stack direction={"row"}>
					<Typography
						sx={{
							...(onTitleSubtitleClick && {
								"&:hover": {
									cursor: "pointer",
								},
							}),
							...styles.ellipsis,
							...contentTitleStyle,
						}}
						data-cy={
							dataCypressID ? `${dataCypressID}-content_title` : undefined
						}
						onClick={() => onTitleSubtitleClick?.()}
					>
						{contentTitle}
					</Typography>
				</Stack>
			</Box>
			<Box display={"inline-block"}>
				<Stack direction={"row"}>
					<Typography
						color={"text.secondary"}
						sx={{
							...(onTitleSubtitleClick && {
								"&:hover": {
									cursor: "pointer",
								},
							}),
							...styles.ellipsis,
						}}
						data-cy={
							dataCypressID ? `${dataCypressID}-content_subtitle` : undefined
						}
						onClick={() => onTitleSubtitleClick?.()}
					>
						{contentSubtitle}
					</Typography>
				</Stack>
			</Box>
			<Stack
				alignContent="center"
				sx={{
					...styles.ellipsis,
				}}
			>
				{tags?.map((tag, i) => {
					return (
						tag.label && (
							<Tooltip key={i} title={tag.label} placement="top">
								<Chip
									label={tag.label}
									style={{
										backgroundColor: tag.backgroundColor,
									}}
									sx={{
										mr: 0.375,
										mb: 0.625,
										"& .MuiChip-label": {
											color:
												tag.fontColor ??
												pickTextColorBasedOnBgColor(tag.backgroundColor)
													.textColor,
											...styles.labelEllipsis,
										},
										...(tag.borderColor && {
											borderColor: tag.borderColor,
										}),
										maxWidth: "10rem",
									}}
									{...(tag.borderColor && {
										variant: "outlined",
									})}
									size="small"
								/>
							</Tooltip>
						)
					);
				})}
			</Stack>
		</Stack>
	);
};

export default TitleSubtitleAndTags;
