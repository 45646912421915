import { useCallback, useEffect, useState } from "react";

import ControllersApp from "@application/Controllers/ControllersApp";
import {
	mapComponentData,
	mapDefaultValues,
	mapExistingData,
} from "@application/helpers/PresentationHelper";
import type { TypeApiQueryString } from "@infrastructure/model/interfaces/api.interface";
import {
	type DtoPresentation,
	Services,
	type SessionTemplatesRead,
	useSnackBarHook,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabs";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import { EnumCustomFieldScope } from "@key4-front-library/core/Enums";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";

export type TypePresentationSchedule =
	| "FullDate"
	| "DateOnly"
	| "HourOnly"
	| "None";

/**
 * Represents the data of a session form.
 * With the raw data from the API, the component data and the custom form data
 */
interface PresentationFormData {
	raw: DtoPresentation | null;
	componentData: PropsFormTabs;
	useFormData: TypeUseFormListForms;
}

export const usePresentationCustomFormQuery = (
	sessionId: string,
	sessionTemplateQuery: UseQueryResult<Array<SessionTemplatesRead>>,
	id?: string,
): PresentationFormData | undefined => {
	const { sendSuccess } = useSnackBarHook();
	const { readWholeFormByScope } =
		ControllersBo.CustomFieldController.useEntity();
	const { readListSpeakerQualities } =
		ControllersApp.SpeakerController.useEntity();
	const { read: readSession } = ControllersApp.SessionController.useEntity();
	const { readSettingsScheduler } =
		ControllersApp.SettingsController.useEntity();
	const { read } = ControllersApp.PresentationController.useEntity();
	const [presentationData, setPresentationData] =
		useState<PresentationFormData>();

	const presentationCb = useCallback(
		async (
			sessionId: string,
			id?: string,
			sessionTemplate?: SessionTemplatesRead,
		) => {
			const formsData = await readWholeFormByScope(
				EnumCustomFieldScope.PRESENTATION,
			);
			const useFormData: TypeUseFormListForms = {};
			const componentData: PropsFormTabs = { tabs: [] };
			const session = await readSession(sessionId);

			const settingsScheduler = await readSettingsScheduler();
			const qualities = await readListSpeakerQualities();

			mapDefaultValues(
				formsData,
				useFormData,
				session,
				sendSuccess,
				qualities.length > 0,
				sessionTemplate,
			);
			mapComponentData(
				formsData,
				componentData,
				session,
				settingsScheduler,
				qualities,
				id,
			);

			let presentation: DtoPresentation | null = null;
			if (id) {
				presentation = await read(sessionId, id);
				mapExistingData(useFormData, presentation);
			}

			setPresentationData({ raw: presentation, componentData, useFormData });
		},
		[
			read,
			readListSpeakerQualities,
			readSession,
			readSettingsScheduler,
			readWholeFormByScope,
			sendSuccess,
		],
	);

	useEffect(() => {
		void presentationCb(sessionId, id, sessionTemplateQuery.data?.[0]);
	}, [sessionId, id, sessionTemplateQuery.data]);

	return presentationData;
};

interface UsePresentationsArgs {
	clientId?: string;
	eventId?: string;
	sessionId?: string;
	queryParams: Array<TypeApiQueryString>;
}

export function usePresentations(
	args: UsePresentationsArgs,
): UseQueryResult<Array<DtoPresentation>> {
	const { clientId, eventId, sessionId, queryParams } = args;
	return useQuery({
		queryKey: ["presentations", clientId, eventId, sessionId, queryParams],
		queryFn: () =>
			clientId && eventId && sessionId
				? Services.Events.Programme.PresentationsService.getList(
						clientId,
						eventId,
						sessionId,
						queryParams,
					)
				: Promise.resolve([]),
		enabled: !!clientId && !!eventId && !!sessionId,
	});
}
