import { DateTime } from "luxon";

import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import DateHelper from "@application/helpers/date.helper";
import {
	CalendarSettings,
	EFilterUnplannedSessions,
	filtersUnplannedSessionsObject,
	type ICalendarSession,
	type ICalendarSettings,
	type TFilterUnplannedSessionsForm,
} from "@domain/interfaces/calendar.interface";
import type { TSessionCalendar } from "@domain/model/calendar.model";
import {
	EnumApiErrorStatus,
	EnumParticipantStatusInvitation,
	EnumSessionStatus,
	ErrorAPI,
	escapeSieveString,
	ESieveOperator,
	inArraySieveString,
	queryFilters,
	Services,
} from "@key4-front-library/core";
import type { TAutocompleteMultiChipItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiChip";
import type {
	DtoCalendarGetFaculty,
	DtoCalendarGetSession,
	DtoCalendarGetSettings,
	DtoFacultyGetDetails,
} from "@key4-front-library/core/Dto";

const getCalendarSettings = async (
	clientId: string,
	eventId: string,
): Promise<CalendarSettings | ErrorAPI> => {
	const fetchData = async () =>
		Services.Events.Programme.CalendarService.getSettings(clientId, eventId);

	return fetchData()
		.then(async (calendarSettings: DtoCalendarGetSettings) => {
			let rooms = null;
			if (calendarSettings.rooms) {
				rooms = [];
				calendarSettings.rooms.forEach((element) => {
					rooms.push({
						id: element.id,
						title: element.name ?? "N/A",
					});
				});
			}

			const calendarProgrammeSettings =
				await Services.Events.Programme.SettingsService.getScheduler(
					clientId,
					eventId,
				);
			const formattedCalendarProgrammeSettings =
				DateHelper.getCalendarProgrammeSettings(calendarProgrammeSettings);

			return Promise.resolve(
				new CalendarSettings(
					formattedCalendarProgrammeSettings.date,
					formattedCalendarProgrammeSettings.hour,
					formattedCalendarProgrammeSettings.dateDurationDays,
					rooms ?? undefined,
				),
			);
		})
		.catch((e: ErrorAPI) => {
			return Promise.reject(new ErrorAPI(e.status, e.message));
		});
};

const getSessionsByRoom = async (
	clientId: string,
	eventId: string,
	roomIds: Array<string>,
	calendarSettings: ICalendarSettings,
): Promise<Array<ICalendarSession> | ErrorAPI> => {
	if (roomIds.length === 0)
		return new ErrorAPI(EnumApiErrorStatus.STATUS_400, "no roomIds set");
	let roomIdsQuery = "";
	roomIds.forEach((roomId) => {
		roomIdsQuery += roomId + ESieveOperator.OR_IN;
	});

	roomIdsQuery = roomIdsQuery.slice(0, -1);
	try {
		const sessionsData: Array<DtoCalendarGetSession> =
			await Services.Events.Programme.CalendarService.getAllSessions(
				clientId,
				eventId,
				[
					...queryFilters(
						`roomId${ESieveOperator.EQUALS + roomIdsQuery + ESieveOperator.AND}isplanned${ESieveOperator.EQUALS}true`,
					),
				],
			);

		const sessions: Array<ICalendarSession> = [];
		sessionsData.forEach((session) => {
			if ((!session.startDate || !session.endDate) && !session.isWholeProgramme)
				return;

			let hourStart = session.startHour
				? DateTime.fromISO(session.startHour).toLocaleString(
						DateTime.TIME_24_SIMPLE,
					)
				: null;
			let hourEnd = session.endHour
				? DateTime.fromISO(session.endHour).toLocaleString(
						DateTime.TIME_24_SIMPLE,
					)
				: null;

			let tagsId: Array<string> | null = null;
			if (session.tags !== null) {
				tagsId = [];
				if (session.tags) {
					session.tags.forEach((tag) => {
						tagsId?.push(tag.id);
					});
				}
			}
			let start = `${session.startDate} ${session.startHour}`;
			let end = `${session.endDate} ${session.endHour}`;

			// If whole programme session change dates to calendar settings range dates
			if (session.isWholeProgramme) {
				({ hourStart, hourEnd, start, end } =
					DateHelper.getCalendarWholeProgrammeDateSession(calendarSettings));
			}

			// If is full day add one day to end date FullCalendar libs bug ?
			if (session.isFullDay) {
				end = session.endDate
					? `${DateTime.fromISO(session.endDate).plus({ days: 1 }).toFormat("yyyy-MM-dd")} ${session.endHour}`
					: end;
			}

			sessions.push({
				id: session.id,
				title: session.title ?? undefined,
				start,
				end,
				hasClashes: session.hasClashes,
				hasAnomalies: session.hasAnomalies,
				code: session.code ?? null,
				hourStart,
				hourEnd,
				allDay: session.isWholeProgramme ? true : session.isFullDay,
				status: EnumSessionStatus.VALIDATED,
				minDuration: session.minDuration ?? null,
				backgroundColor:
					session.style?.backgroundColor ??
					SchedulerConfiguration.defaultSessionColor.backgroundColor,
				borderColor:
					session.style?.borderColor ??
					SchedulerConfiguration.defaultSessionColor.borderColor,
				textColor:
					session.style?.fontColor ??
					SchedulerConfiguration.defaultSessionColor.textColor,
				tagsId,
				resourceId: session.roomId ?? undefined,
				roomId: session.roomId ?? null,
				timezone: session.timeZone ?? null,
				isEverywhere: session.isEverywhere,
			});
		});

		return Promise.resolve(sessions);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

function getUnplannedSessionDatesFilter(
	values: Array<TAutocompleteMultiChipItem> | Array<string>,
): Array<string> {
	const datesFilter: Array<string> = [];
	values.map((value) => {
		if (!(value === null || typeof value !== "string")) {
			datesFilter.push(
				`(${inArraySieveString(
					filtersUnplannedSessionsObject[EFilterUnplannedSessions.DATE]
						.sieveKey,
					[DateTime.fromISO(value).toFormat("yyyy-MM-dd")],
					ESieveOperator.GREATER_THAN_OR_EQUAL_TO,
				)},${inArraySieveString(
					filtersUnplannedSessionsObject[EFilterUnplannedSessions.DATE]
						.sieveKey,
					[DateTime.fromISO(value).plus({ days: 1 }).toFormat("yyyy-MM-dd")],
					ESieveOperator.LESS_THAN,
				)})`,
			);
		}
	});
	return datesFilter;
}

const getUnplannedSessions = async (
	clientId: string,
	eventId: string,
	search: string,
	advancedFilter: TFilterUnplannedSessionsForm,
): Promise<Array<ICalendarSession> | ErrorAPI> => {
	try {
		let filterSieveString =
			search && search !== ""
				? `${ESieveOperator.AND}(` +
					`code${ESieveOperator.CI_CONTAINS}${escapeSieveString(search)}${ESieveOperator.OR}title${ESieveOperator.CI_CONTAINS}${escapeSieveString(
						search,
					)})`
				: "";
		// Remap Items autocompletes to sieve query
		const searchFilterAdvanced: Array<string> = [];
		for (const filterKey in advancedFilter) {
			if (
				(filterKey !== "rooms" &&
					filterKey !== "sessionStatus" &&
					filterKey !== "dates" &&
					filterKey !== "tags") ||
				filterKey === "tags"
			)
				continue;
			if (advancedFilter[filterKey].length > 0) {
				// #MYK4-6708 - Filter by date (fullDay instead of absolute equality)
				if (
					filterKey === EFilterUnplannedSessions.DATE &&
					advancedFilter[filterKey].length > 0
				) {
					searchFilterAdvanced.push(
						getUnplannedSessionDatesFilter(
							advancedFilter[EFilterUnplannedSessions.DATE],
						).join(ESieveOperator.OR),
					);
					continue;
				}

				searchFilterAdvanced.push(
					`(${inArraySieveString(
						filtersUnplannedSessionsObject[filterKey].sieveKey,
						advancedFilter[filterKey].map((value) =>
							typeof value === "string" ? value : value.key,
						),
					)})`,
				);
			}
		}

		// Remap Dynamic tags type autocompletes to sieve

		// Merge tags type Ids selected in a array string
		const mappedTags: Array<string> =
			advancedFilter.tags?.reduce((acc: Array<string>, curr) => {
				if (curr && curr.length > 0) {
					acc = acc.concat(
						curr.map((value) =>
							typeof value === "string" ? value : value.key,
						),
					);
				}

				return acc;
			}, []) ?? [];

		// Change array strings to sieve query
		if (mappedTags.length > 0) {
			searchFilterAdvanced.push(
				`(${inArraySieveString(filtersUnplannedSessionsObject.tags.sieveKey, mappedTags)})`,
			);
		}

		if (searchFilterAdvanced.length > 0) {
			filterSieveString +=
				ESieveOperator.AND + searchFilterAdvanced.join(ESieveOperator.AND);
		}

		const sessionsData: Array<DtoCalendarGetSession> =
			await Services.Events.Programme.CalendarService.getAllSessions(
				clientId,
				eventId,
				[
					...queryFilters(
						`isplanned${ESieveOperator.EQUALS}false${filterSieveString}`,
					),
				],
			);
		const sessions: Array<ICalendarSession> = [];
		sessionsData.forEach((session) => {
			const hourStart = session.startHour
				? DateTime.fromISO(session.startHour).toLocaleString(
						DateTime.TIME_24_SIMPLE,
					)
				: null;

			const hourEnd = session.endHour
				? DateTime.fromISO(session.endHour).toLocaleString(
						DateTime.TIME_24_SIMPLE,
					)
				: null;

			let tagsId: Array<string> | null = null;
			if (session.tags) {
				tagsId = [];
				session.tags.forEach((tag) => {
					tagsId?.push(tag.id);
				});
			}

			sessions.push({
				id: session.id,
				title: session.title ?? undefined,
				start: `${session.startDate} ${session.startHour}`,
				end: `${session.endDate} ${session.endHour}`,
				hasClashes: session.hasClashes,
				hasAnomalies: session.hasAnomalies,
				code: session.code ?? null,
				hourStart,
				hourEnd,
				allDay: session.isFullDay,
				status: EnumSessionStatus.VALIDATED,
				minDuration: session.minDuration ?? null,
				backgroundColor: session.style?.backgroundColor ?? undefined,
				borderColor: session.style?.borderColor ?? undefined,
				textColor: session.style?.fontColor ?? undefined,
				tagsId,
				resourceId: session.roomId ?? undefined,
				roomId: null,
				timezone: session.timeZone ?? null,
				isEverywhere: session.isEverywhere,
			});
		});

		return Promise.resolve(sessions);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getFacultySessions = async (
	clientId: string,
	eventId: string,
	participantId: string,
	isSpeakerBusySessionTimeSlot: boolean,
	calendarSettings: ICalendarSettings,
	isReadOnly?: boolean,
): Promise<Array<ICalendarSession> | ErrorAPI> => {
	try {
		const facultySessions: Array<DtoCalendarGetFaculty> =
			await Services.Events.Programme.CalendarService.getListFaculties(
				clientId,
				eventId,
				participantId,
				[...queryFilters(`isplanned${ESieveOperator.EQUALS}false`)],
			);

		const details: DtoFacultyGetDetails =
			await Services.Events.Programme.FacultiesService.getDetails(
				clientId,
				eventId,
				participantId,
			);

		const sessions: Array<ICalendarSession> = [];
		facultySessions.forEach((session) => {
			let invitationStatusAccepted = false;
			const sessionDetails = details.sessions?.find(
				(detail) => detail.id === session.id,
			);
			const chair = sessionDetails?.chairs?.find(
				(chair) => chair.participantId === participantId,
			);
			if (
				chair?.invitationStatus === EnumParticipantStatusInvitation.ACCEPTED
			) {
				invitationStatusAccepted = true;
			}
			const speakers = sessionDetails?.presentations?.map((presentation) =>
				presentation.speakers?.find(
					(speaker) => speaker.participantId == participantId,
				),
			);
			speakers?.forEach((speaker) => {
				if (
					speaker?.invitationStatus === EnumParticipantStatusInvitation.ACCEPTED
				) {
					invitationStatusAccepted = true;
				}
			});

			let hourStart = session.startHour
				? DateTime.fromISO(session.startHour).toLocaleString(
						DateTime.TIME_24_SIMPLE,
					)
				: null;
			let hourEnd = session.endHour
				? DateTime.fromISO(session.endHour).toLocaleString(
						DateTime.TIME_24_SIMPLE,
					)
				: null;

			let id = session.id;
			let code = session.code ?? null;
			let title = session.title;
			let timezone = session.timeZone ?? null;
			let hasClashes = session.hasClashes;
			let hasAnomalies = session.hasAnomalies;
			let isFullDay = session.isFullDay;
			let isWholeProgramme = session.isWholeProgramme;

			let start = `${session.startDate} ${session.startHour}`;
			let end = `${session.endDate} ${session.endHour}`;

			if (isWholeProgramme) {
				({ hourStart, hourEnd, start, end } =
					DateHelper.getCalendarWholeProgrammeDateSession(calendarSettings));
			}

			// if 'isSpeakerBusySessionTimeSlot' is not 'on' on settings we display presentation information instead of session
			if (
				!isSpeakerBusySessionTimeSlot &&
				session.presentations &&
				session.presentations.length > 0 &&
				(session.presentations[0].startHour || session.presentations[0].endHour)
			) {
				hourStart = session.presentations[0].startHour
					? DateTime.fromISO(session.presentations[0].startHour).toLocaleString(
							DateTime.TIME_24_SIMPLE,
						)
					: null;

				hourEnd = session.presentations[0].endHour
					? DateTime.fromISO(session.presentations[0].endHour).toLocaleString(
							DateTime.TIME_24_SIMPLE,
						)
					: null;

				start = `${session.presentations[0].startDate} ${session.presentations[0].startHour}`;
				end = `${session.presentations[0].endDate} ${session.presentations[0].endHour}`;
				id = session.presentations[0].id;
				code = session.presentations[0].code ?? null;
				title = session.presentations[0].title;
				timezone = session.presentations[0].timeZone ?? null;
				hasClashes = session.presentations[0].hasClashes;
				hasAnomalies = session.presentations[0].hasAnomalies;
				isFullDay = false;
				isWholeProgramme = false;
			}

			sessions.push({
				id,
				title: title ?? undefined,
				start,
				end,
				hasClashes,
				hasAnomalies,
				code,
				hourStart,
				hourEnd,
				allDay: isWholeProgramme ? true : isFullDay,
				status: session.status ?? EnumSessionStatus.VALIDATED,
				minDuration: null,
				backgroundColor: session.style?.backgroundColor ?? undefined,
				borderColor: session.style?.borderColor ?? undefined,
				textColor: session.style?.fontColor ?? undefined,
				tagsId: null,
				resourceId: session.roomId ?? undefined,
				roomId: session.roomId ?? null,
				timezone,
				invitationStatusAccepted,
				isReadOnly,
				isEverywhere: session.isEverywhere,
			});
		});

		return Promise.resolve(sessions);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

// #region 'PUT'
const putSession = async (
	clientId: string,
	eventId: string,
	sessionId: string,
	session: TSessionCalendar,
): Promise<null | ErrorAPI> => {
	if (session.isFullDay) {
		session.startHour = null;
		session.endHour = null;
	}

	const fetchData = async () =>
		Services.Events.Programme.CalendarService.putSession(
			clientId,
			eventId,
			sessionId,
			session,
		);

	return fetchData()
		.then(() => {
			return Promise.resolve(null);
		})
		.catch((e: ErrorAPI) => {
			return Promise.reject(new ErrorAPI(e.status, e.message));
		});
};

const CalendarController = {
	getCalendarSettings,
	getSessionsByRoom,
	getUnplannedSessions,
	putSession,
	getFacultySessions,
};

export default CalendarController;
