import ControllersApp from "@application/Controllers/ControllersApp";
import HelpersApp from "@application/helpers";
import {
	mapComponentData,
	mapDefaultValues,
	mapExistingData,
} from "@application/helpers/SessionHelper";
import type { TypeSessionExtraData } from "@application/Types/SessionType";
import {
	type DtoSession,
	type DtoSettingsScheduler,
	type DtoTagType,
	queryIncludeCustomFields,
	Services,
	useContextModule,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabs";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import { EnumCustomFieldScope } from "@key4-front-library/core/Enums";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";

/**
 * Represents the data of a session form.
 * With the raw data from the API, the component data and the custom form data
 */
interface SessionFormData {
	raw: DtoSession | null;
	componentData: PropsFormTabs;
	useFormData: TypeUseFormListForms;
}

const useEntity = (): {
	readListTagsType: () => { data?: Array<DtoTagType> };
	useSessionCustomFormQuery: (
		id?: string,
		listExtraData?: Array<TypeSessionExtraData>,
		dataSettingsScheduler?: DtoSettingsScheduler | null,
	) => UseQueryResult<SessionFormData>;
} => {
	const { event } = useContextModule();
	const { readWholeFormByScope } =
		ControllersBo.CustomFieldController.useEntity();
	const { read, readListTagsType: readSessionListTagsType } =
		ControllersApp.SessionController.useEntity();
	const { readPrimaryTag, readListSecondariesTags } =
		ControllersBo.SessionController.useEntity();

	const useSessionCustomFormQuery = (
		id?: string,
		listExtraData?: Array<TypeSessionExtraData>,
		dataSettingsScheduler?: DtoSettingsScheduler | null,
	): UseQueryResult<SessionFormData> =>
		useQuery({
			queryKey: ["session", "form", id ?? "create", event],
			queryFn: async (): Promise<SessionFormData> => {
				const formsData = await readWholeFormByScope(
					EnumCustomFieldScope.SESSION,
				);
				const secondariesTags = await readListSecondariesTags();

				const useFormData: TypeUseFormListForms = {};

				const componentData: PropsFormTabs = { tabs: [] };

				mapDefaultValues(formsData, useFormData, secondariesTags);
				mapComponentData(
					formsData,
					componentData,
					event,
					dataSettingsScheduler,
					id,
				);

				// EDIT SESSION
				let session: DtoSession | null = null;
				if (id) {
					session = await read(id);
					const primaryTag = await readPrimaryTag();
					mapExistingData(primaryTag, useFormData, session, event);
				}

				if (listExtraData) {
					HelpersApp.SessionHelper.mapFormTabsListExtraDataUseFormDefaultValue(
						useFormData,
						listExtraData,
					);
				}

				return { raw: session, componentData, useFormData };
			},
		});

	const useSessionTagsTypes = (): { data?: Array<DtoTagType> } => {
		const { data } = useQuery({
			queryKey: ["session", "list", "tagsType"],
			queryFn: async (): Promise<Array<DtoTagType>> =>
				await readSessionListTagsType(),
		});

		return { data };
	};

	return { readListTagsType: useSessionTagsTypes, useSessionCustomFormQuery };
};

export function useSession(
	id: string,
	eventId: string,
	clientId: string,
	includeCustomFields = true,
): UseQueryResult<DtoSession> {
	return useQuery({
		queryKey: ["session", id, clientId, eventId, includeCustomFields],
		queryFn: async () =>
			await Services.Events.Programme.SessionsService.get(
				clientId,
				eventId,
				id,
				queryIncludeCustomFields(includeCustomFields),
			),
	});
}

const SessionHook = {
	useEntity,
};

export default SessionHook;
