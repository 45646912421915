import { useState } from "react";

import AnomaliesFiltersPopover from "@application/components/_common/Anomalies/AnomaliesFiltersPopover";
import AnomaliesFactory from "@application/factory/anomalies/anomaliesFactory";
import GetTranslationsForAnomalies from "@application/helpers/translations/anomalies/GetTranslationsForAnomalies";
import type { IAnomaliesByType } from "@domain/interfaces/anomalies.interface";
import { K4Button } from "@infrastructure/components/interface/button/K4Button";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { FormControlLabel, Popover, Stack, Switch } from "@mui/material";

type Props = {
	isOnlyActive: boolean;
	onIsOnlyActiveChange: () => void;
	anomaliesCheckboxes: IAnomaliesByType[];
	onAnomalyCheckboxClick: (anomalyItem: IAnomaliesByType) => void;
	onSelectAllAnomaliesClick: (selectAll: boolean) => void;
};
const AnomaliesFilters = (props: Props) => {
	const {
		isOnlyActive,
		onIsOnlyActiveChange,
		anomaliesCheckboxes,
		onAnomalyCheckboxClick,
		onSelectAllAnomaliesClick,
	} = props;

	const translations = GetTranslationsForAnomalies();
	const [searchValue, setSearchValue] = useState("");

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSearchAnomaly = (search: string) => {
		setSearchValue(search);
	};

	return (
		<Stack
			spacing={1}
			direction={"row"}
			justifyContent={"flex-end"}
			flexWrap={"wrap"}
			alignItems={"center"}
		>
			<K4Button
				label={translations.buttons.filter}
				variant="contained-alt"
				size="small"
				startIcon={<Icon prefix="fal" iconName={"sort"} />}
				handleClick={handleFilterClick}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<AnomaliesFiltersPopover
					anomaliesCheckboxes={AnomaliesFactory.SearchAnomaliesCheckboxes({
						anomaliesCheckboxes,
						searchValue,
					})}
					onAnomalyCheckboxClick={onAnomalyCheckboxClick}
					onSelectAllAnomaliesClick={onSelectAllAnomaliesClick}
					searchValue={searchValue}
					onSearchAnomaly={handleSearchAnomaly}
				/>
			</Popover>
			<FormControlLabel
				control={
					<Switch
						checked={isOnlyActive}
						onChange={onIsOnlyActiveChange}
						inputProps={{ "aria-label": "only active" }}
					/>
				}
				label={translations.filters.isOnlyActive}
			/>
		</Stack>
	);
};

export default AnomaliesFilters;
