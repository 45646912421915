import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import DashboardFactory from "@application/factory/dashboard/DashboardFactory";
import BarChart from "@infrastructure/components/charts/Area/BarChart";
import Card from "@infrastructure/components/interface/card/Card";
import type { IChartSerie } from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import {
	ApplicationColors,
	Services,
	useContextModule,
} from "@key4-front-library/core";
import { alpha, Grid, Skeleton } from "@mui/material";

const DashboardBarChart = () => {
	const { client, event } = useContextModule();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [series, setSeries] = useState<Array<IChartSerie>>([]);

	const fetchdata = useCallback(async (clientId: string, eventId: string) => {
		setIsLoading(true);
		await Services.Events.Programme.DashboardService.getOverview(
			clientId,
			eventId,
		)
			.then((data) => {
				setSeries(DashboardFactory.TransformBarChartSeries(data));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		fetchdata(client.id, event.id);
	}, [event]);

	if (isLoading) {
		return (
			<Grid item xs={12} lg={6} display={"flex"}>
				<Skeleton
					variant="rounded"
					width="100%"
					height={"100%"}
					sx={{
						borderRadius: "16px",
						bgcolor: alpha(ApplicationColors.blue.main, 0.5),
						minHeight: 300,
					}}
				/>
			</Grid>
		);
	}

	return (
		<Grid item xs={12} lg={6} display={"flex"}>
			<Card
				title={t("old.programme.dashboard.charts.bar.title")}
				backgroundColor={ApplicationColors.blue.main}
			>
				<BarChart series={series} />
			</Card>
		</Grid>
	);
};

export default DashboardBarChart;
