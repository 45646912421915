import {
	type DtoMailMessage,
	EmailDetailContent,
	NoData,
} from "@key4-front-library/core";
import { Divider, Stack, Typography } from "@mui/material";

import { FacultyMailDetailHeader } from "./FacultyMailDetailHeader";

type FacutlyMailDetailsProps = {
	mail: DtoMailMessage | null;
};

export const FacultyMailDetail = (props: FacutlyMailDetailsProps) => {
	const { mail } = props;

	if (!mail) {
		return <NoData />;
	}

	return (
		<Stack divider={<Divider sx={{ opacity: "0.7" }} flexItem />} spacing={2}>
			<FacultyMailDetailHeader mail={mail} />
			<Typography variant={"h6"}>{mail.subject}</Typography>
			<EmailDetailContent mail={mail} />
		</Stack>
	);
};
