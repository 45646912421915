import { t } from "i18next";

import { Box, Typography } from "@mui/material";

type Props = {
	dateFrom: string;
	dateTo: string;
	dataCypressID?: string;
};

const Period = (props: Props) => {
	const { dateFrom, dateTo, dataCypressID } = props;

	const from = t("old.common.period.from");
	const to = t("old.common.period.to");

	return (
		<Typography variant={"body1"} fontWeight={"light"} color={"text.disabled"}>
			{`${from} `}
			<Box
				component="span"
				data-cy={
					dataCypressID ? `${dataCypressID}-period-date_from` : undefined
				}
			>
				{dateFrom}
			</Box>
			{` ${to} `}
			<Box
				component="span"
				data-cy={dataCypressID ? `${dataCypressID}-period-date_to` : undefined}
			>
				{dateTo}
			</Box>
		</Typography>
	);
};

export default Period;
