import {
	type DtoSessionGetClashes,
	QUERY_KEYS_PROGRAMME,
	Services,
	type TypeApiQueryString,
	type TypeApiResponsePagined,
} from "@key4-front-library/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";

interface Args {
	clientId: string;
	sessionId: string;
	eventId: string;
	queryStrings?: TypeApiQueryString[];
}

export const useClashes = (
	args: Args,
): UseQueryResult<TypeApiResponsePagined<Array<DtoSessionGetClashes>>> => {
	const { clientId, sessionId, eventId, queryStrings } = args;
	return useQuery({
		queryKey: [
			...QUERY_KEYS_PROGRAMME.clashes,
			"getList",
			clientId,
			eventId,
			sessionId,
			queryStrings,
		],
		queryFn: async () =>
			await Services.Events.Programme.SessionsService.getListPaginedClashes(
				clientId,
				eventId,
				sessionId,
				queryStrings,
			),
	});
};
