import BulletColorWithLabel from "@infrastructure/components/interface/dataDisplay/BulletColorWithLabel";
import type { K4DataGridCellBulletColorProps } from "@infrastructure/components/interface/datagrid/cell/K4DataGridCellBulletColor";
import K4DataGridCellChipColor, {
	type K4DataGridCellChipColorProps,
} from "@infrastructure/components/interface/datagrid/cell/K4DataGridCellChipColor";
import K4DataGridCellIcon, {
	type K4DataGridCellIconProps,
} from "@infrastructure/components/interface/datagrid/cell/K4DataGridCellIcon";
import DataGridHeader from "@infrastructure/components/interface/datagrid/header/DataGridHeader";
import type {
	GridColumnHeaderParams,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";

const getCellBulletColorWithLabel = (
	params: GridRenderCellParams<K4DataGridCellBulletColorProps>,
): JSX.Element => {
	if (!params.value) return <>getCellBulletColorWithLabel NOT SET PROPERLY</>;
	const { iconColor, label } = params.value;
	return <BulletColorWithLabel iconColor={iconColor} label={label} />;
};

const getCellIcon = (
	params: GridRenderCellParams<K4DataGridCellIconProps>,
): JSX.Element => {
	if (!params.value) return <>getCellIcon NOT SET PROPERLY</>;
	const { isDisplayed, icon } = params.value;
	return <K4DataGridCellIcon isDisplayed={isDisplayed} icon={icon} />;
};

const getCellChipColor = (
	params: GridRenderCellParams<K4DataGridCellChipColorProps>,
): JSX.Element => {
	if (!params.value) return <>getCellChipColor NOT SET PROPERLY</>;
	const { chip } = params.value;
	return <K4DataGridCellChipColor chip={chip} />;
};

const getRenderHeader = (params: GridColumnHeaderParams, dataCy?: string) => {
	const { field, headerName } = params.colDef;
	return (
		<DataGridHeader
			field={field}
			headerName={headerName}
			dataCypressID={dataCy}
		/>
	);
};

const DataGridHelper = {
	getCellBulletColorWithLabel,
	getCellIcon,
	getCellChipColor,
	getRenderHeader,
};
export default DataGridHelper;
