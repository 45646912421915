import { t } from "i18next";
import { useEffect, useState } from "react";

import SchedulerFactory from "@application/factory/scheduler/SchedulerFactory";
import type { TRoomView } from "@domain/interfaces/calendar.interface";
import K4AutocompleteMultiSelect from "@infrastructure/components/interface/controlledComponents/K4AutocompleteMultiSelect";
import type { IItemK4AutocompleteMultiSelect } from "@infrastructure/model/interfaces/components/K4AutocompleteMultiSelect.interface";
import { Stack } from "@mui/material";

interface IHeaderCalendarMultiroomProps {
	rooms: Array<IItemK4AutocompleteMultiSelect>;
	activeRooms: Array<IItemK4AutocompleteMultiSelect>;
	calendar: any;
	changeRooms: (roomId: Array<TRoomView>) => void;
}

const CalendarSelectMultiRoom = ({
	rooms,
	activeRooms,
	changeRooms,
}: IHeaderCalendarMultiroomProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(false);
	}, [rooms, isLoading]);

	const getOptionLabel = (option: IItemK4AutocompleteMultiSelect) =>
		option.label;
	const handleToggleOption = (
		selectedOptions: Array<IItemK4AutocompleteMultiSelect>,
	) => {
		changeRooms(
			SchedulerFactory.PopulateRoomsK4AutocompleteMultiSelectInverse(
				selectedOptions,
			),
		);
	};
	const handleClearOptions = () => {
		changeRooms([]);
	};
	const handleSelectAll = (isSelected: boolean) => {
		if (isSelected) {
			changeRooms(
				SchedulerFactory.PopulateRoomsK4AutocompleteMultiSelectInverse(rooms),
			);
		} else {
			handleClearOptions();
		}
	};

	return (
		<Stack
			direction="row"
			alignItems={"center"}
			justifyContent={"center"}
			mb="1.5em"
			mx="1em"
		>
			<K4AutocompleteMultiSelect
				items={rooms}
				selectedValues={activeRooms}
				label={t("old.programme.scheduler.roomsSelect")}
				selectAllLabel={t("old.form.select.selectAll")}
				onToggleOption={handleToggleOption}
				onClearOptions={handleClearOptions}
				onSelectAll={handleSelectAll}
				getOptionLabel={getOptionLabel}
			/>
		</Stack>
	);
};

export default CalendarSelectMultiRoom;
