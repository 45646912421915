import { ExternalRouting } from "@key4-front-library/core";

import { md5 } from "../crypto/md5";

export const gravatarHashEmailAddress = (email?: string) =>
	email ? md5(email) : [...Array(32)].map(() => "0").join("");

export const gravatarGetTestExistServiceUrl = (hash: string, importSize = 40) =>
	`${ExternalRouting.Gravatar}/avatar/${hash}?size=${importSize}`;

export const gravatarIsExistImage = async (
	serviceTestUrl: string,
): Promise<boolean> => {
	return fetch(`${serviceTestUrl}&default=404`, {
		method: "GET",
	})
		.then((response) => {
			const { status } = response;
			if (status === 200) {
				return true;
			}
			return false;
		})
		.catch(() => {
			return false;
		});
};
