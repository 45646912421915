/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable default-case */
/* eslint-disable max-lines-per-function */
import { t, type TFunction } from "i18next";
import { concat, drop, findIndex, toPairs, trim } from "lodash";
import { DateTime, Duration } from "luxon";
import type {
	FieldValues,
	UseFormGetValues,
	UseFormSetValue,
	UseFormTrigger,
} from "react-hook-form";
import * as yup from "yup";

import ConfigurationsApp from "@application/Configurations";
import {
	EnumSessionFieldKey,
	EnumSessionFormKey,
	EnumSessionSectionKey,
} from "@application/Enums/SessionEnum";
import HelpersApp from "@application/helpers/index";
import type {
	TypeSessionExtraData,
	TypeSessionStaticFieldData,
} from "@application/Types/SessionType";
import type {
	TSessionDisplay,
	TSessionUseFormModal,
} from "@domain/interfaces/session.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import type { IK4Tag } from "@infrastructure/model/interfaces/tags.interface";
import DateService from "@infrastructure/services/dates/date.service";
import {
	Culture,
	CustomFieldHelper,
	type DtoEvent,
	type DtoSession,
	type DtoSessionWrite,
	type DtoSettingsScheduler,
	type DtoTagType,
	EnumCustomFieldKind,
	EnumFormControlKind,
	type EnumSessionStatus,
	FormHelper,
	getISOFromDurationString,
	type ITagTypeModelWithTags,
	MINUTE_DURATION_REGEX,
	NUMBERS_REGEX,
	padStartDigitWith0,
	statusSessionArray,
	statusSessionObject,
	type TypeCustomFieldForm,
	type TypeCustomFieldValues,
	type TypeUseFormFieldValues,
	type TypeUseFormListForms,
	UseFormHelper,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabs";
import type { TypeAutoCompleteItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocomplete";
import type { TAutocompleteMultiBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiBulletColor";
import { SessionConfig } from "@key4-front-library/core/Bo/Configurations";

import { remapCustomFieldUseformToModel } from "./CustomFieldHelper";
import DateHelper from "./date.helper";

const DATETIME_FIELD_KEYS = [
	EnumSessionFieldKey.START_DATE,
	EnumSessionFieldKey.END_DATE,
	EnumSessionFieldKey.START_HOUR,
	EnumSessionFieldKey.END_HOUR,
];

const callbackDisplay = (
	keys: Array<string>,
	name: string,
	value: any,
	setValues: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
	trigger: UseFormTrigger<FieldValues>,
) => {
	const nameSplitted = name.split(".");

	const path = [nameSplitted[0], nameSplitted[1]].join(".");

	if (nameSplitted[2] === EnumSessionFieldKey.IS_WHOLE_PROGRAMME) {
		setValues([path, EnumSessionFieldKey.IS_FULL_DAY].join("."), false);
	}

	// A améliorer
	switch (nameSplitted[2] as EnumSessionFieldKey) {
		case EnumSessionFieldKey.IS_FULL_DAY:
		case EnumSessionFieldKey.IS_WHOLE_PROGRAMME:
		default:
			if (
				getValues([path, EnumSessionFieldKey.START_DATE].join(".")) ===
				"Invalid DateTime"
			) {
				setValues([path, EnumSessionFieldKey.START_DATE].join("."), null);
			}
			if (
				getValues([path, EnumSessionFieldKey.END_DATE].join(".")) ===
				"Invalid DateTime"
			) {
				setValues([path, EnumSessionFieldKey.END_DATE].join("."), null);
			}
			if (
				getValues([path, EnumSessionFieldKey.START_HOUR].join(".")) ===
				"Invalid DateTime"
			) {
				setValues([path, EnumSessionFieldKey.START_HOUR].join("."), null);
			}
			if (
				getValues([path, EnumSessionFieldKey.END_HOUR].join(".")) ===
				"Invalid DateTime"
			) {
				setValues([path, EnumSessionFieldKey.END_HOUR].join("."), null);
			}
			setValues([path, EnumSessionFieldKey.DURATION].join("."), null);
			break;
	}
	trigger();

	keys.forEach((key: string) => {
		if (value) {
			setValues([path, "metadata", key, "visibility"].join("."), false);
		} else {
			setValues([path, "metadata", key, "visibility"].join("."), true);
		}
	});
};

const setDurationValues = (
	path: string,
	setValues: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
) => {
	// If duration doesn't exist compute duration
	if (
		getValues(`${path}.${EnumSessionFieldKey.END_HOUR}`) &&
		getValues(`${path}.${EnumSessionFieldKey.START_HOUR}`)
	) {
		const dateStart: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumSessionFieldKey.START_DATE}`) ?? DateTime.now().toFormat("yyyy-MM-dd")} ${(
				getValues(`${path}.${EnumSessionFieldKey.START_HOUR}`) as DateTime
			).toFormat("HH:mm")}`,
			"yyyy-MM-dd HH:mm",
		);
		const dateEnd: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumSessionFieldKey.END_DATE}`) ?? DateTime.now().toFormat("yyyy-MM-dd")} ${(getValues(`${path}.${EnumSessionFieldKey.END_HOUR}`) as DateTime).toFormat("HH:mm")}`,
			"yyyy-MM-dd HH:mm",
		);
		const duration: Duration = dateEnd.diff(dateStart, ["hours", "minutes"]);
		if (!Number.isNaN(duration.minutes)) {
			if (duration.minutes > 0 || duration.hours > 0) {
				setValues(
					`${path}.${EnumSessionFieldKey.DURATION}`,
					`${padStartDigitWith0(duration.hours.toString())}:${padStartDigitWith0(duration.minutes.toString())}`,
				);
			} else {
				setValues(`${path}.${EnumSessionFieldKey.DURATION}`, null);
			}
		}
	}
};

const changeDatesOnDuration = (
	path: string,
	setValues: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
) => {
	const startDate: string | null = getValues(
		`${path}.${EnumSessionFieldKey.START_DATE}`,
	);
	const startHour: DateTime | null = getValues(
		`${path}.${EnumSessionFieldKey.START_HOUR}`,
	);

	const durationValue = getValues(`${path}.${EnumSessionFieldKey.DURATION}`);

	if (
		startHour &&
		durationValue &&
		startDate !== "Invalid DateTime" &&
		startHour.isValid
	) {
		const dateStart: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumSessionFieldKey.START_DATE}`) ?? DateTime.now().toFormat("yyyy-MM-dd")} ${(
				getValues(`${path}.${EnumSessionFieldKey.START_HOUR}`) as DateTime
			).toFormat("HH:mm")}`,
			"yyyy-MM-dd HH:mm",
		);

		let duration;

		if (typeof durationValue === "string") {
			duration = Duration.fromISOTime(durationValue);
		} else {
			duration = Duration.fromISOTime(durationValue.key);
		}

		if (duration.isValid) {
			const dateEnd: DateTime = dateStart.plus(duration);
			setValues(`${path}.${EnumSessionFieldKey.END_HOUR}`, dateEnd);
		}
	}
};

const triggerDates = (path: string, trigger: UseFormTrigger<FieldValues>) => {
	DATETIME_FIELD_KEYS.forEach((key: string) => {
		trigger(`${path}.${key}`);
	});
};

const getResolver: any = (
	useFormData: TypeUseFormListForms | undefined,
	t: TFunction,
) => {
	const resolver: any = {};
	if (!useFormData) {
		return yupResolver(yup.object({}));
	}

	toPairs(useFormData).forEach((form) => {
		const formId = form[0];
		if (form[1] === undefined) return;
		const formResolver: any = {};
		toPairs(form[1]).forEach((section) => {
			const sectionId = section[0];
			if (section[1] === undefined) return;
			const sectionResolver: any = {};
			toPairs(section[1]).forEach((field) => {
				const fieldId = field[0];
				const fieldValue = field[1];
				switch (fieldId) {
					case EnumSessionFieldKey.DURATION:
						sectionResolver[fieldId] = yup
							.mixed()
							.nullable()
							.test(
								"formatDuration",
								t("old.common.validation.durationWrongFormat") ?? "",
								function (_) {
									const { duration }: any = this.parent;
									if (!duration) {
										return true;
									}
									let d = duration;

									if (typeof d === "object") {
										d = d.key;
									}

									const durationSplitted = d.split(":");

									if (durationSplitted.length !== 2) {
										return false;
									}

									return (
										durationSplitted[0].match(NUMBERS_REGEX) !== null &&
										durationSplitted[1].match(MINUTE_DURATION_REGEX)
									);
								},
							);
						break;
					case EnumSessionFieldKey.IS_WHOLE_PROGRAMME:
						sectionResolver[fieldId] = yup.boolean();
						break;
					case EnumSessionFieldKey.END_HOUR:
						sectionResolver[fieldId] = yup
							.object()
							.nullable()
							.when(["isWholeProgramme", "isFullDay"], {
								is: (isWholeProgramme: boolean, isFullDay: boolean) =>
									!isWholeProgramme && !isFullDay,
								then: (schema) =>
									schema
										.nullable()
										.test(
											"timeValid",
											t("old.common.formControl.error.timePicker.invalid") ??
												"",
											(time: any) => {
												if (!time) return true;
												return time.invalid === null;
											},
										)
										.when("startHour", {
											is: (startHour: DateTime | null) => startHour !== null,
											then: (schema) =>
												schema
													.nullable()
													.required(
														t("old.common.formControl.error.required") ?? "",
													),
										}),
							});
						break;
					case EnumSessionFieldKey.START_HOUR:
						sectionResolver[fieldId] = yup
							.object()
							.nullable()
							.when(["isWholeProgramme", "isFullDay"], {
								is: (isWholeProgramme: boolean, isFullDay: boolean) =>
									!isWholeProgramme && !isFullDay,
								then: (schema) =>
									schema
										.nullable()
										.test(
											"timeValid",
											t("old.common.formControl.error.timePicker.invalid") ??
												"",
											(time: any) => {
												if (!time) return true;
												return time.invalid === null;
											},
										)
										.when("endHour", {
											is: (endHour: DateTime | null) => endHour !== null,
											then: (schema) =>
												schema
													.nullable()
													.required(
														t("old.common.formControl.error.required") ?? "",
													)
													.test(
														"timeRangeTest",
														t(
															"old.common.formControl.error.timePicker.startSuperiorToEnd",
														) ?? "",
														function (_) {
															const { endDate, startDate, startHour, endHour } =
																this.parent;
															if (
																startDate === "Invalid DateTime" ||
																endDate === "Invalid DateTime" ||
																!startDate ||
																!endDate ||
																!startHour ||
																!endHour ||
																startHour.invalid !== null ||
																endHour.invalid !== null
															) {
																return true;
															}

															return (
																DateTime.fromFormat(
																	`${startDate} ${startHour.toFormat("HH:mm")}`,
																	"yyyy-MM-dd HH:mm",
																) <
																DateTime.fromFormat(
																	`${endDate} ${endHour.toFormat("HH:mm")}`,
																	"yyyy-MM-dd HH:mm",
																)
															);
														},
													),
										}),
							});
						break;
					case EnumSessionFieldKey.PUBLICATION_DATE:
						sectionResolver[fieldId] = yup
							.object()
							.nullable()
							.test(
								"publicationDateValid",
								t("old.common.formControl.error.timePicker.invalid") ?? "",
								(time: any) => {
									if (!time) return true;
									return time.invalid === null;
								},
							);
						break;
					case EnumSessionFieldKey.END_DATE:
						sectionResolver[fieldId] = yup
							.string()
							.nullable()
							.when("isWholeProgramme", {
								is: (isWholeProgramme: boolean) => !isWholeProgramme,
								then: (schema) =>
									schema
										.nullable()
										.not(
											["Invalid DateTime"],
											t("old.common.formControl.error.datePicker.invalid") ??
												"",
										)
										.when("startDate", {
											is: (startDate: string | null) => startDate !== null,
											then: (schema) =>
												schema
													.nullable()
													.required(
														t("old.common.formControl.error.required") ?? "",
													),
										})
										.when("isFullDay", {
											is: (isFullDay: boolean) => isFullDay,
											then: (schema) =>
												schema
													.nullable()
													.required(
														t("old.common.formControl.error.required") ?? "",
													),
										}),
							});
						break;
					case EnumSessionFieldKey.START_DATE:
						sectionResolver[fieldId] = yup
							.string()
							.nullable()
							.when("isWholeProgramme", {
								is: (isWholeProgramme: boolean) => !isWholeProgramme,
								then: (schema) =>
									schema
										.nullable()
										.test(
											"negativeDuration",
											t("old.common.formControl.error.required"),
											function (_: any) {
												const { endHour, startHour, startDate, endDate } =
													this.parent;
												if (!startHour || !endHour) {
													return true;
												}
												if (!startDate && !endDate && startHour > endHour) {
													return false;
												}
												return true;
											},
										)
										.not(
											["Invalid DateTime"],
											t("old.common.formControl.error.datePicker.invalid") ??
												"",
										)
										.when("endDate", {
											is: (endDate: string | null) => endDate !== null,
											then: (schema) =>
												schema
													.nullable()
													.required(
														t("old.common.formControl.error.required") ?? "",
													)
													.test(
														"dateRangeTest",
														t(
															"old.common.formControl.error.datePicker.startSuperiorOrEqualsToEnd",
														) ?? "",
														(parent: any) => {
															const { endDate, startDate } = parent;
															if (
																startDate === "Invalid DateTime" ||
																endDate === "Invalid DateTime" ||
																!startDate ||
																!endDate
															) {
																return true;
															}
															return (
																DateTime.fromFormat(startDate, "yyyy-MM-dd") <=
																DateTime.fromFormat(endDate, "yyyy-MM-dd")
															);
														},
													),
										}),
							})
							.when("isFullDay", {
								is: (isFullDay: boolean) => isFullDay,
								then: (schema) =>
									schema
										.nullable()
										.required(t("old.common.formControl.error.required") ?? ""),
							});
						break;
				}

				CustomFieldHelper.setupResolver(
					t,
					sectionResolver,
					section[1],
					fieldId,
					fieldValue,
				);
			});

			if (Object.keys(sectionResolver).length > 0) {
				formResolver[sectionId] = yup.object(sectionResolver);
			}
		});
		if (Object.keys(formResolver).length > 0) {
			resolver[formId] = yup.object(formResolver);
		}
	});

	return yupResolver(yup.object(resolver));
};

/**
 * Transform session or default session to POST or PUT model
 * @param session
 * @param tagTypesWithTagConfig
 * @returns
 */
const getSessionDisplay = (
	session: any = ConfigurationsApp.SessionConfiguration.defaultModel2,
	tagTypesWithTagConfig?: Array<ITagTypeModelWithTags>,
): TSessionDisplay => {
	// Transform exploded TagTypes & TagIds to a flat TagIds array, all blended.
	let flatTagIds: Array<string> = [];
	let primaryTagId: string | null = null;

	session.tagTypes.forEach((tagtype: any) => {
		tagtype.tags.forEach((tag: any) => {
			flatTagIds.push(tag.id);
		});
	});

	// Find in tagtype configuration if a primary tag has been set previously.
	if (tagTypesWithTagConfig && tagTypesWithTagConfig.length > 0) {
		const primaryTagType: ITagTypeModelWithTags = tagTypesWithTagConfig[0];
		primaryTagType.tags.forEach((tag) => {
			if (flatTagIds.includes(tag.id)) {
				primaryTagId = tag.id;
			}
		});
	}

	// We need to remove primaryTagId from flatTagIds list to avoid a double values later.
	if (primaryTagId !== null) {
		flatTagIds = flatTagIds.filter((v) => v !== primaryTagId);
	}

	const sessionDisplay: TSessionDisplay = {
		id: session.id === "" ? null : session.id,
		description: session.description,
		duration: session.duration,
		endDate: session.endDate,
		code: session.code,
		endHour: session.endHour,
		expectedAudience: session.expectedAudience,
		isEverywhere: session.isEverywhere,
		isFullDay: session.isFullDay,
		isPrivate: session.isPrivate,
		isWholeProgramme: session.isWholeProgramme,
		maxPax: session.maxPax,
		organizedBy: session.organizedBy,
		publicationDate: session.publicationDate ? session.publicationDate : null,
		roomId: session.room ? session.room.id : "",
		startDate: session.startDate,
		startHour: session.startHour,
		status: session.status,
		timeZone: session.timeZone,
		title: session.title,
		primaryTagId,
		tagIds: flatTagIds,
		customFieldValues: session.customFieldValues,
		room: session.room,
		key: session.key,
	};

	return sessionDisplay;
};

const getSessionEntireDate = (
	startDate: string,
	endDate: string,
	isFullDay: boolean,
) => {
	const dateStartString = DateService.GetLocaleStringFromIsoString(startDate);
	const dateEndString = DateService.GetLocaleStringFromIsoString(endDate);
	const dateStartObject = DateService.GetDateFromIsoString(startDate);
	const dateEndObject = DateService.GetDateFromIsoString(endDate);
	const hourStart = DateService.Format.TimeSimple({
		date: dateStartObject!,
	});
	const hourEnd = DateService.Format.TimeSimple({ date: dateEndObject! });

	const dateEnd = dateStartString === dateEndString ? "" : dateEndString;
	const displayEntireDate = isFullDay
		? // only days
			`${dateStartString} - ${dateEnd}`
		: // days and hours
			`${dateStartString} ${hourStart} - ${dateEnd} ${hourEnd}`;
	return displayEntireDate;
};

const getSessionUseFormFromModel = (
	session: any = ConfigurationsApp.SessionConfiguration.defaultModel2,
	tagTypesWithTagConfig?: Array<ITagTypeModelWithTags>,
): TSessionUseFormModal => {
	return sessionDisplayToSessionUseForm(
		getSessionDisplay(session, tagTypesWithTagConfig),
	);
};

const getStatusSessionListItems = (
	t: TFunction,
): Array<TAutocompleteMultiBulletColorItem> => {
	return statusSessionArray.map((value) => {
		return {
			key: value.toString(),
			label: t(statusSessionObject[value].label),
			color: statusSessionObject[value].bgColor,
		};
	});
};

const hydrateTagsInSession = (
	session: TSessionUseFormModal,
	tagTypesWithTagConfig?: Array<ITagTypeModelWithTags>,
	sessionTags: Array<IK4Tag> = [],
): TSessionUseFormModal => {
	// Transform exploded TagTypes & TagIds to a flat TagIds array, all blended.
	let flatTagIds: Array<string> = [];
	let primaryTagId: string | null = null;

	sessionTags.forEach((tagtype) => {
		tagtype.tags.forEach((tag) => {
			if (tag.id) flatTagIds.push(tag.id);
		});
	});

	// Find in tagtype configuration if a primary tag has been set previously.
	if (tagTypesWithTagConfig && tagTypesWithTagConfig.length > 0) {
		const primaryTagType: ITagTypeModelWithTags = tagTypesWithTagConfig[0];
		primaryTagType.tags.forEach((tag) => {
			if (flatTagIds.includes(tag.id)) {
				primaryTagId = tag.id;
			}
		});
	}

	// We need to remove primaryTagId from flatTagIds list to avoid a double values later.
	if (primaryTagId !== null) {
		flatTagIds = flatTagIds.filter((v) => v !== primaryTagId);
	}
	session.tagIds = flatTagIds;
	session.primaryTagId = primaryTagId;

	return session;
};

const mapFormTabsEditUseFormDefaultValue = (
	useFormData: TypeUseFormListForms,
	session: DtoSession,
	primaryTag: DtoTagType | null,
	event: DtoEvent,
) => {
	toPairs(useFormData).forEach((formData) => {
		const formId = formData[0];
		const form = formData[1];
		toPairs(form).forEach((sectionData) => {
			const sectionId = sectionData[0];
			const section = sectionData[1];
			if (section.metadata?.tagIds) {
				setUseFormSecondariesTagsFromSessionDto(
					session,
					primaryTag,
					useFormData,
					formId,
					sectionId,
				);
			}

			toPairs(section).forEach((fieldData) => {
				const fieldId = fieldData[0];
				switch (fieldId) {
					case EnumSessionFieldKey.CODE:
						useFormData[formId][sectionId][fieldId] = session.code ?? null;
						break;
					case EnumSessionFieldKey.DESCRIPTION:
						useFormData[formId][sectionId][fieldId] =
							session.description ?? null;
						break;
					case EnumSessionFieldKey.START_DATE:
						useFormData[formId][sectionId][fieldId] = session.startDate ?? null;
						break;
					case EnumSessionFieldKey.END_DATE:
						useFormData[formId][sectionId][fieldId] = session.endDate ?? null;
						break;
					case EnumSessionFieldKey.END_HOUR:
						useFormData[formId][sectionId][fieldId] = session.endHour
							? DateTime.fromFormat(session.endHour, "HH:mm:ss")
							: null;
						break;
					case EnumSessionFieldKey.DURATION:
						useFormData[formId][sectionId][fieldId] = session.duration
							? Duration.fromISO(session.duration).toFormat("hh:mm")
							: null;
						break;
					case EnumSessionFieldKey.EXPECTED_AUDIENCE:
						useFormData[formId][sectionId][fieldId] =
							session.expectedAudience ?? null;
						break;
					case EnumSessionFieldKey.IS_EVERYWHERE:
						useFormData[formId][sectionId][fieldId] = session.isEverywhere;
						if (session.isEverywhere) {
							useFormData[formId][sectionId]
								.metadata!.expectedAudience.visibility = false;
							useFormData[formId][sectionId].metadata!.roomId.visibility =
								false;
						}
						break;
					case EnumSessionFieldKey.IS_FULL_DAY:
						useFormData[formId][sectionId][fieldId] = session.isFullDay;
						if (session.isFullDay) {
							useFormData[formId][sectionId].metadata!.startHour.visibility =
								false;
							useFormData[formId][sectionId].metadata!.endHour.visibility =
								false;
							useFormData[formId][sectionId].metadata!.duration.visibility =
								false;
						}
						break;
					case EnumSessionFieldKey.IS_PRIVATE:
						useFormData[formId][sectionId][fieldId] = session.isPrivate;
						break;
					case EnumSessionFieldKey.IS_WHOLE_PROGRAMME:
						useFormData[formId][sectionId][fieldId] = session.isWholeProgramme;

						if (session.isWholeProgramme) {
							useFormData[formId][sectionId].metadata!.startDate.visibility =
								false;
							useFormData[formId][sectionId].metadata!.endDate.visibility =
								false;
							useFormData[formId][sectionId].metadata!.startHour.visibility =
								false;
							useFormData[formId][sectionId].metadata!.endHour.visibility =
								false;
							useFormData[formId][sectionId].metadata!.isFullDay.visibility =
								false;
							useFormData[formId][sectionId].metadata!.duration.visibility =
								false;
						}
						break;
					case EnumSessionFieldKey.ORGANIZED_BY:
						useFormData[formId][sectionId][fieldId] =
							session.organizedBy ?? null;
						break;
					case EnumSessionFieldKey.PRIMARY_TAG_ID:
						if (
							session.tagTypes &&
							primaryTag &&
							session.tagTypes.length > 0 &&
							session.tagTypes[0].id === primaryTag.id &&
							session.tagTypes[0].tags?.[0]
						) {
							useFormData[formId][sectionId][fieldId] =
								session.tagTypes[0].tags[0].id;
						}
						break;
					case EnumSessionFieldKey.PUBLICATION_DATE:
						useFormData[formId][sectionId][fieldId] = session.publicationDate
							? DateTime.fromISO(session.publicationDate, {
									locale: event.culture ?? Culture[0].culture,
								})
							: null;
						break;
					case EnumSessionFieldKey.ROOM_ID:
						if (session.room)
							useFormData[formId][sectionId][fieldId] = session.room.id;
						break;
					case EnumSessionFieldKey.START_HOUR:
						useFormData[formId][sectionId][fieldId] = session.startHour
							? DateTime.fromFormat(session.startHour, "HH:mm:ss")
							: null;
						break;
					case EnumSessionFieldKey.STATUS:
						useFormData[formId][sectionId][fieldId] = session.status;
						break;
					case EnumSessionFieldKey.TIMEZONE:
						useFormData[formId][sectionId][fieldId] = session.timeZone ?? null;
						break;
					case EnumSessionFieldKey.TITLE:
						useFormData[formId][sectionId][fieldId] = session.title ?? null;
						break;
				}
			});
		});
	});
};

const mapFormTabsListExtraDataUseFormDefaultValue = (
	useFormData: TypeUseFormListForms,
	listExtraData: Array<TypeSessionExtraData>,
) => {
	toPairs(useFormData).forEach((formData) => {
		const formId = formData[0];
		const form = formData[1];
		toPairs(form).forEach((sectionData) => {
			const sectionId = sectionData[0];
			const section = sectionData[1];
			toPairs(section).forEach((fieldData) => {
				const fieldId = fieldData[0];
				const indexData = findIndex(listExtraData, (o) => o.key === fieldId);
				if (indexData !== -1) {
					if (
						fieldId === EnumSessionFieldKey.IS_FULL_DAY &&
						listExtraData[indexData].value
					) {
						useFormData[formId][sectionId].metadata!.startHour.visibility =
							false;
						useFormData[formId][sectionId].metadata!.endHour.visibility = false;
					}

					useFormData[formId][sectionId][
						fieldId as Exclude<EnumSessionFieldKey, EnumSessionFieldKey.TAG_IDS>
					] = listExtraData[indexData].value;
				}
			});
		});
	});
};

const mapFormTabsPropsStaticFields = (
	formsData: Array<TypeCustomFieldForm>,
	componentData: PropsFormTabs,
	event: DtoEvent,
	dataSettingsScheduler?: DtoSettingsScheduler | null,
	sessionId?: string,
): void => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			// Add static fields
			if (
				(Object.values(EnumSessionFormKey) as Array<string>).includes(
					form.data.key,
				) &&
				(Object.values(EnumSessionSectionKey) as Array<string>).includes(
					section.data.key,
				)
			) {
				toPairs(
					ConfigurationsApp.SessionConfiguration.staticListFormControlsObject[
						form.data.key as EnumSessionFormKey
					][section.data.key as EnumSessionSectionKey],
				).forEach((val: [string, TypeSessionStaticFieldData]) => {
					const formControl = val[1].component;
					let name = [form.data.id, section.data.id, formControl.id].join(".");
					formControl.propsComponent.label = t(
						formControl.propsComponent.label,
					);

					if (formControl.id === EnumSessionFieldKey.DURATION) {
						formControl.propsComponent.items =
							DateHelper.durationSuggestionOptions(4, 15).map((d) => {
								return { key: d, label: d };
							});
					}

					switch (formControl.kind) {
						case EnumFormControlKind.CONTAINER_SECONDARIES_TAGS_LIST:
							name = [form.data.id, section.data.id].join(".");
							break;
						case EnumFormControlKind.SELECT_BULLET_COLOR:
							formControl.propsComponent.items =
								formControl.propsComponent.items.map((item) => {
									return {
										...item,
										label: t(item.label),
									};
								});
							break;
						case EnumFormControlKind.DATE_PICKER:
							formControl.propsComponent.defaultCalendarMonth =
								DateTime.fromISO(event.startDate);
							if (dataSettingsScheduler?.programmeStartDate) {
								formControl.propsComponent.minDate = DateTime.fromISO(
									dataSettingsScheduler.programmeStartDate,
									{
										zone: "UTC",
									},
								);
							}
							if (dataSettingsScheduler?.programmeEndDate) {
								formControl.propsComponent.maxDate = DateTime.fromISO(
									dataSettingsScheduler.programmeEndDate,
									{
										zone: "UTC",
									},
								);
							}
							break;
						case EnumFormControlKind.CONTAINER_PRIMARY_TAG_SELECT:
							formControl.propsComponent.sessionId = sessionId;
							break;
						default:
							break;
					}

					componentData.tabs[i].content.sections[j].formControls.push({
						...formControl,
						name,
					});
				});
			}
		}
	}
};

const mapFormTabsUseFormDefaultValue = (
	formsData: Array<TypeCustomFieldForm>,
	useFormData: TypeUseFormListForms,
	secondariesTags: Array<DtoTagType> | null,
) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			if (
				(Object.values(EnumSessionFormKey) as Array<string>).includes(
					form.data.key,
				) &&
				(Object.values(EnumSessionSectionKey) as Array<string>).includes(
					section.data.key,
				)
			) {
				toPairs(
					ConfigurationsApp.SessionConfiguration.staticListFormControlsObject[
						form.data.key as EnumSessionFormKey
					][section.data.key as EnumSessionSectionKey],
				).forEach((val: [string, TypeSessionStaticFieldData]) => {
					if (val[0] === EnumSessionFieldKey.TAG_IDS) {
						if (secondariesTags) {
							secondariesTags.forEach((tag) => {
								useFormData[form.data.id][section.data.id][
									SessionConfig.SECONDARY_TAG_USE_FORM_KEY + tag.id
								] = !tag.max || tag.max > 1 ? [] : null;
							});
						}
					} else {
						useFormData[form.data.id][section.data.id][val[0]] = val[1].value;
					}

					useFormData[form.data.id][section.data.id].metadata![
						val[0] as EnumSessionFieldKey
					] = {
						visibility: true,
					};
				});
			}
		}
	}
};

const setUseFormSecondariesTagsFromSessionDto = (
	session: DtoSession,
	primaryTag: DtoTagType | null,
	useFormData: TypeUseFormListForms,
	formId: string,
	sectionId: string,
) => {
	let tags = session.tagTypes;
	if (tags && tags.length > 0) {
		if (primaryTag && tags[0].id === primaryTag.id) tags = drop(tags);

		tags.forEach((tagType) => {
			const fieldIdTag = SessionConfig.SECONDARY_TAG_USE_FORM_KEY + tagType.id;
			if (tagType.tags) {
				tagType.tags.forEach((tagTypeTag) => {
					// Check if Select or MultiSelect tags
					if (!tagType.max || tagType.max > 1) {
						useFormData[formId][sectionId][fieldIdTag] = concat(
							useFormData[formId][sectionId][fieldIdTag] as Array<string>,
							tagTypeTag.id,
						);
					} else {
						useFormData[formId][sectionId][fieldIdTag] = tagTypeTag.id;
					}
				});
			}
		});
	}
};

const mapUseFormToDtoSessionWrite = (
	useFormData: TypeUseFormListForms,
	sessionWrite: DtoSessionWrite,
) => {
	toPairs(useFormData).forEach((formObject) => {
		if (formObject[1] === undefined) return;
		toPairs(formObject[1]).forEach((sectionObject) => {
			if (sectionObject[1] === undefined) return;
			let tagsIds: Array<string> = [];
			toPairs(sectionObject[1]).forEach((fieldObject) => {
				const fieldId = fieldObject[0];
				const fieldValue = fieldObject[1];
				if (fieldId.startsWith(SessionConfig.SECONDARY_TAG_USE_FORM_KEY)) {
					if (fieldValue) {
						tagsIds = concat(tagsIds, fieldValue as string | Array<string>);
					}
					return;
				}

				// STATIC FIELDS
				switch (fieldId) {
					case EnumSessionFieldKey.CODE:
						sessionWrite.code = fieldValue as string | null;
						return;
					case EnumSessionFieldKey.DESCRIPTION:
						sessionWrite.description = fieldValue as string | null;
						return;
					case EnumSessionFieldKey.DURATION: {
						const duration = fieldValue as TypeAutoCompleteItem | string | null;
						let d: string | undefined;
						if (typeof duration === "object") {
							d = duration?.key;
						} else {
							d = duration;
						}
						sessionWrite.duration = d ? getISOFromDurationString(d) : null;
						return;
					}
					case EnumSessionFieldKey.END_DATE:
						sessionWrite.endDate = sessionWrite.isWholeProgramme
							? null
							: (fieldValue as string | null);
						return;
					case EnumSessionFieldKey.END_HOUR:
						if (sessionWrite.isWholeProgramme) {
							sessionWrite.endHour = null;
						} else {
							sessionWrite.endHour = fieldValue
								? (fieldValue as DateTime).toFormat("HH:mm:ss")
								: null;
						}
						return;
					case EnumSessionFieldKey.EXPECTED_AUDIENCE:
						sessionWrite.expectedAudience = fieldValue ? +fieldValue : null;
						return;
					case EnumSessionFieldKey.IS_EVERYWHERE:
						sessionWrite.isEverywhere = !!fieldValue;
						return;
					case EnumSessionFieldKey.IS_FULL_DAY:
						sessionWrite.isFullDay = !!fieldValue;
						return;
					case EnumSessionFieldKey.IS_PRIVATE:
						sessionWrite.isPrivate = !!fieldValue;
						return;
					case EnumSessionFieldKey.IS_WHOLE_PROGRAMME:
						sessionWrite.isWholeProgramme = !!fieldValue;
						return;
					case EnumSessionFieldKey.ORGANIZED_BY:
						sessionWrite.organizedBy = fieldValue as string | null;
						return;
					case EnumSessionFieldKey.PRIMARY_TAG_ID:
						sessionWrite.primaryTagId = fieldValue as string | null;
						return;
					case EnumSessionFieldKey.PUBLICATION_DATE:
						sessionWrite.publicationDate = fieldValue
							? (fieldValue as DateTime).toUTC().toISO()
							: null;
						return;
					case EnumSessionFieldKey.ROOM_ID:
						sessionWrite.roomId = sessionWrite.isEverywhere
							? null
							: (fieldValue as string | null);
						return;
					case EnumSessionFieldKey.START_DATE:
						sessionWrite.startDate = sessionWrite.isWholeProgramme
							? null
							: (fieldValue as string | null);
						return;
					case EnumSessionFieldKey.START_HOUR:
						if (sessionWrite.isWholeProgramme) {
							sessionWrite.startHour = null;
						} else {
							sessionWrite.startHour = fieldValue
								? (fieldValue as DateTime).toFormat("HH:mm:ss")
								: null;
						}
						return;
					case EnumSessionFieldKey.STATUS:
						sessionWrite.status = fieldValue as EnumSessionStatus;
						return;
					case EnumSessionFieldKey.TIMEZONE:
						return;
					case EnumSessionFieldKey.TITLE:
						sessionWrite.title = fieldValue as string | null;
						return;
				}

				// CUSTOM FIELDS
				if (fieldId === "customFieldValues") {
					if (!fieldValue) return;

					toPairs(fieldValue as TypeUseFormFieldValues).forEach(
						(customField) => {
							const customfieldId: string = customField[0];
							const customfieldValue = customField[1];
							const customFieldMetadataValue =
								sectionObject[1].metadata![customfieldId].customField!;
							switch (
								sectionObject[1].metadata![customfieldId].customField!.kind
							) {
								case EnumCustomFieldKind.SELECT:
								case EnumCustomFieldKind.RADIO: {
									const val =
										typeof customfieldValue === "string"
											? [customfieldValue]
											: [];
									const isInherited: boolean =
										customFieldMetadataValue.isInherited;
									sessionWrite.customFieldValues![customfieldId] = {
										isCustomized: isInherited,
										value: isInherited
											? customFieldMetadataValue.customDefault.value
											: val,
										customizedValue:
											JSON.stringify(val) !==
												JSON.stringify(
													customFieldMetadataValue.customDefault.value,
												) && isInherited
												? val
												: null,
									};
									return;
								}
								default: {
									if (customfieldValue instanceof DateTime) {
										return;
									}

									const valDefault:
										| string
										| boolean
										| Array<string>
										| null
										| undefined =
										typeof customfieldValue === "number"
											? `${customfieldValue}`
											: (customfieldValue as
													| string
													| boolean
													| Array<string>
													| null
													| undefined);

									sessionWrite.customFieldValues![customfieldId] = {
										isCustomized: customFieldMetadataValue.isInherited,
										value: customFieldMetadataValue.isInherited
											? customFieldMetadataValue.customDefault.value
											: valDefault,
										customizedValue:
											customFieldMetadataValue.customDefault.value !==
												valDefault && customFieldMetadataValue.isInherited
												? valDefault
												: null,
									};
									return;
								}
							}
						},
					);
				}
			});

			if (tagsIds.length > 0) {
				sessionWrite.tagIds = tagsIds;
			}
		});
	});
};

const PopulateTags = (session: any) => {
	return [
		{
			label: session.primaryTag?.label ?? "",
			backgroundColor: session.primaryTag?.backgroundColor ?? "",
			fontColor: session.primaryTag?.fontColor ?? "",
			borderColor: session.primaryTag?.borderColor ?? "",
		},
	];
};

// Same mapping for Create and Update session
const remapSessionForSaving = (
	session: TSessionUseFormModal,
): TypeCustomFieldValues => {
	session.code = trim(session.code ?? "") === "" ? null : session.code;
	session.title = trim(session.title ?? "") === "" ? null : session.title;
	session.description =
		trim(session.description ?? "") === "" ? null : session.description;
	session.organizedBy =
		trim(session.organizedBy ?? "") === "" ? null : session.organizedBy;

	if (session.isFullDay) {
		session.endHour = null;
		session.startHour = null;
	}
	if (session.isWholeProgramme) {
		session.endHour = null;
		session.startHour = null;
		session.startDate = null;
		session.endDate = null;
		session.isFullDay = false;
	}
	if (session.isEverywhere) {
		session.roomId = null;
		session.expectedAudience = null;
	}
	if (session.roomId === "") {
		session.roomId = null;
	}

	session.duration = null;

	if (session.startDate && session.endDate) {
		const dateFormat = "yyyy-MM-dd";
		session.startDate = DateTime.fromJSDate(
			new Date(session.startDate),
		).toFormat(dateFormat);
		session.endDate = DateTime.fromJSDate(new Date(session.endDate)).toFormat(
			dateFormat,
		);
	}
	if (session.publicationDate) {
		const datetimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
		session.publicationDate = DateTime.fromJSDate(
			new Date(session.publicationDate),
		)
			.toUTC()
			.toFormat(datetimeFormat);
	}
	const result = remapCustomFieldUseformToModel(session.customFieldValues);

	return result ?? {};
};

const sessionDisplayToSessionUseForm = (
	session: TSessionDisplay,
): TSessionUseFormModal => {
	const sessionUseForm: TSessionUseFormModal = {
		id: session.id,
		description: session.description,
		duration: session.duration,
		endDate: session.endDate,
		code: session.code,
		endHour: session.endHour,
		expectedAudience: session.expectedAudience,
		isEverywhere: session.isEverywhere,
		isFullDay: session.isFullDay,
		isPrivate: session.isPrivate,
		isWholeProgramme: session.isWholeProgramme,
		maxPax: session.maxPax,
		organizedBy: session.organizedBy,
		publicationDate: session.publicationDate,
		roomId: session.roomId,
		startDate: session.startDate,
		startHour: session.startHour,
		status: session.status,
		timeZone: session.timeZone,
		title: session.title,
		primaryTagId: session.primaryTagId,
		tagIds: session.tagIds,
		customFieldValues: {},
		room: session.room,
		key: session.key,
	};

	// Add customFieldValues & keep legacy field
	sessionUseForm.customFieldValues =
		CustomFieldHelper.remapCustomFieldModelToUseForm(session.customFieldValues);

	return sessionUseForm;
};

export function mapDefaultValues(
	formsData: Array<TypeCustomFieldForm>,
	useFormData: TypeUseFormListForms,
	secondariesTags: Array<DtoTagType> | null,
): void {
	// Add Forms / Sections useFormData
	UseFormHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);

	// Add Static fields useFormData
	HelpersApp.SessionHelper.mapFormTabsUseFormDefaultValue(
		formsData,
		useFormData,
		secondariesTags,
	);

	// Add Custom fields useFormData
	CustomFieldHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);
}

export function mapComponentData(
	formsData: Array<TypeCustomFieldForm>,
	componentData: PropsFormTabs,
	event: DtoEvent,
	dataSettingsScheduler?: DtoSettingsScheduler | null,
	sessionId?: string,
): void {
	// Add tabs and sections props
	FormHelper.mapFormTabsProps(formsData, componentData);

	// Add static fields props
	HelpersApp.SessionHelper.mapFormTabsPropsStaticFields(
		formsData,
		componentData,
		event,
		dataSettingsScheduler,
		sessionId,
	);

	// Add custom fields props
	CustomFieldHelper.mapFormTabsPropsFields(formsData, componentData);
}

export function mapExistingData(
	primaryTag: DtoTagType | null,
	useFormData: TypeUseFormListForms,
	session: DtoSession,
	event: DtoEvent,
): void {
	// Add Static fields useFormData
	HelpersApp.SessionHelper.mapFormTabsEditUseFormDefaultValue(
		useFormData,
		session,
		primaryTag,
		event,
	);

	// Add Custom fields useFormData
	CustomFieldHelper.mapFormTabsEditUseFormDefaultValue(
		useFormData,
		session.customFieldValues,
	);
}

const SessionHelper = {
	callbackDisplay,
	getResolver,
	getSessionDisplay,
	getSessionEntireDate,
	getSessionUseFormFromModel,
	getStatusSessionListItems,
	hydrateTagsInSession,
	mapFormTabsEditUseFormDefaultValue,
	mapFormTabsListExtraDataUseFormDefaultValue,
	mapFormTabsPropsStaticFields,
	mapFormTabsUseFormDefaultValue,
	mapUseFormToDtoSessionWrite,
	PopulateTags,
	remapSessionForSaving,
	sessionDisplayToSessionUseForm,
	changeDatesOnDuration,
	triggerDates,
	setDurationValues,
};

export default SessionHelper;
