import type { UserInformationWithMetadata } from "@application/Types";
import type { DtoContact } from "@key4-front-library/core";

export const contactDtoListToUserInformationWithMetadataList = (
	contacts?: Array<DtoContact>,
): Array<UserInformationWithMetadata> => {
	return (
		contacts?.map((contact) => {
			return {
				id: contact.id,
				type: "contact",
				user: {
					email: contact.email ?? undefined,
					lastname: contact.lastname ?? undefined,
					firstname: contact.firstname ?? undefined,
					link: { children: contact.key },
				},
			};
		}) ?? []
	);
};
