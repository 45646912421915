import type { DateTime } from "luxon";

import type { IPublish } from "@application/Configurations/publish.configuration";
import type {
	ISessionModelChairs,
	ISessionModelPresentations,
	ISessionModelRoom,
} from "@domain/model/session.model";
import type { IK4Tag } from "@infrastructure/model/interfaces/tags.interface";
import type {
	EnumSessionStatus,
	TypeCustomFieldValues,
	TypeUseFormCustomFieldsValues,
} from "@key4-front-library/core";
import type {
	PropsFormControlAutocompleteMultiChipColor,
	TAutocompleteMultiChipColorItem,
} from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiChipColor";
import type { PropsFormControlSelectChipColor } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelectChipColor";

export interface ISessionView {
	sessionDisplay: TSessionDisplay;
	sessionUseForm: TSessionUseFormModal;
	schedule: ISessionSchedule;
	tags: IK4Tag[] | undefined;
	publish: IPublishView;
}

export interface ISessionSchedule {
	startDate: string | null;
	endDate: string | null;
	startTime: string | null;
	endTime: string | null;
	dateInterval: string | null | undefined;
}

export interface IPublishView {
	activePublish: IPublish;
	shortDateTime: string | null | undefined;
}

export interface ISessionContainerPresentations
	extends ISessionModelPresentations {
	speakers: ISessionModelChairs[];
}

export type TTagTypeFilterForm = {
	label: string;
	id: string;
	tags: TAutocompleteMultiChipColorItem[];
};

export enum ESessionTagField {
	SELECT = "select",
	MULTISELECT = "multiselect",
}

export type ISessionTagsBulkActionSelectTag = {
	kind: ESessionTagField.SELECT;
	ids: string[];
	id: string;
	selectChipColor: PropsFormControlSelectChipColor;
};

export type ISessionTagsBulkActionMultiSelectTag = {
	kind: ESessionTagField.MULTISELECT;
	ids: string[];
	id: string;
	maxTags?: number;
	autocompleteMultiChipColor: PropsFormControlAutocompleteMultiChipColor;
};

export type ISessionSecondaryTagsBulkAction =
	| ISessionTagsBulkActionMultiSelectTag
	| ISessionTagsBulkActionSelectTag;

export type ISessionTagsBulkAction = {
	primaryTag: ISessionTagsBulkActionSelectTag | null;
	secondaryTags: ISessionSecondaryTagsBulkAction[];
};

export type TSessionBase = {
	roomId: string | null;
	code: string | null;
	startDate: string | null;
	startHour: string | null;
	endDate: string | null;
	endHour: string | null;
	timeZone: string | null;
	status: EnumSessionStatus;
	publicationDate: string | null;
	title: string | null;
	maxPax: number | null;
	description: string | null;
	organizedBy: string | null;
	expectedAudience: number | null;
	isEverywhere: boolean;
	isWholeProgramme: boolean;
	isFullDay: boolean;
	primaryTagId: string | null;
	duration: string | null;
	tagIds: string[] | null;
	isPrivate: boolean;
	room?: ISessionModelRoom | null;
	test?: DateTime;
	key: string;
};

export type TSessionBase2 = {
	roomId: string | null;
	code: string | null;
	startDate: string | null;
	startHour: DateTime | null;
	endDate: string | null;
	endHour: DateTime | null;
	timeZone: string | null;
	status: EnumSessionStatus;
	publicationDate: string | null;
	title: string | null;
	maxPax: number | null;
	description: string | null;
	organizedBy: string | null;
	expectedAudience: number | null;
	isEverywhere: boolean;
	isWholeProgramme: boolean;
	isFullDay: boolean;
	primaryTagId: string | null;
	duration: string | null;
	tagIds: string[] | null;
	isPrivate: boolean;
	room?: ISessionModelRoom | null;
	test?: DateTime;
	key: string;
};

export type TSessionUseFormModal = {
	id?: string | null;
	customFieldValues: TypeUseFormCustomFieldsValues | undefined | null;
} & TSessionBase;

export type TSessionUseFormModal2 = {
	id?: string | null;
	customFieldValues: TypeUseFormCustomFieldsValues | undefined | null;
} & TSessionBase2;

export type TSessionDisplay = {
	id?: string | null;
	customFieldValues: TypeCustomFieldValues | undefined | null;
} & TSessionBase;
