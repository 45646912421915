import type { IEntity } from "@infrastructure/model/interfaces/api/api-entity.interface";
import { ErrorAPI, queryString, Services } from "@key4-front-library/core";
import type { DtoSearchFilterPut } from "@key4-front-library/core/Dto";
import type { EnumSearchFilterScope } from "@key4-front-library/core/Enums";

const getList = async (
	clientId: string,
	eventId: string,
	scope: EnumSearchFilterScope,
): Promise<Array<any>> => {
	return await Services.Events.Programme.SearchFiltersService.getList(
		clientId,
		eventId,
		queryString("scope", scope),
	);
};

const create = async (
	clientId: string,
	eventId: string,
	name: string,
	value: string,
	scope: EnumSearchFilterScope,
): Promise<IEntity | ErrorAPI> => {
	try {
		return Promise.resolve(
			await Services.Events.Programme.SearchFiltersService.post(
				clientId,
				eventId,
				{
					scope,
					name,
					value,
				},
			),
		);
	} catch (response: any) {
		if (response instanceof ErrorAPI)
			return Promise.resolve(new ErrorAPI(response.status, response.message));
		return Promise.resolve(new ErrorAPI(500, "An error occured"));
	}
};

const update = async (
	clientId: string,
	eventId: string,
	searchFilterId: string,
	query: DtoSearchFilterPut,
): Promise<boolean | ErrorAPI> => {
	try {
		await Services.Events.Programme.SearchFiltersService.put(
			clientId,
			eventId,
			searchFilterId,
			{
				scope: query.scope,
				name: query.name,
				value: query.value,
			},
		);

		return Promise.resolve(true);
	} catch (response: any) {
		if (response instanceof ErrorAPI)
			return Promise.resolve(new ErrorAPI(response.status, response.message));
		return Promise.resolve(new ErrorAPI(500, "An error occured"));
	}
};

const remove = async (
	clientId: string,
	eventId: string,
	filterQueryId: string,
): Promise<boolean | ErrorAPI> => {
	try {
		await Services.Events.Programme.SearchFiltersService.deleteEntity(
			clientId,
			eventId,
			filterQueryId,
		);

		return Promise.resolve(true);
	} catch (response: any) {
		if (response instanceof ErrorAPI)
			return Promise.resolve(new ErrorAPI(response.status, response.message));
		return Promise.resolve(new ErrorAPI(500, "An error occured"));
	}
};

const SearchFilterController = {
	getList,
	create,
	update,
	remove,
};

export default SearchFilterController;
