import SchedulerFactory from "@application/factory/scheduler/SchedulerFactory";
import {
	ESchedulerView,
	type ICalendarSettings,
	type TRoomView,
} from "@domain/interfaces/calendar.interface";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { IconButton, Stack } from "@mui/material";

import CalendarSelectMultiRoom from "./CalendarSelectMultiRoom";
import CalendarSelectRoom from "./CalendarSelectRoom";
import HeaderCalendarMultiRoom from "./HeaderCalendarMultiRoom";
import HeaderCalendarRoom from "./HeaderCalendarRoom";

interface MultiRoomProps {
	rooms: Array<TRoomView> | null;
	changeRooms: (roomId: Array<TRoomView>) => void;
}

interface RoomProps {
	roomId: string;
	changeRoom: (roomId: string) => void;
}

interface HeaderSchedulerProps {
	type: ESchedulerView;
	changeView: (view: ESchedulerView) => void;
	calendarSettings: ICalendarSettings;
	calendarRef: React.MutableRefObject<any>;
	locale: string;
	expandScheduler: () => void;
	multiRoomProps?: MultiRoomProps;
	roomProps?: RoomProps;
	isExpanded: boolean;
}

const HeaderScheduler = (props: HeaderSchedulerProps) => {
	const {
		type,
		changeView,
		calendarSettings,
		calendarRef,
		locale,
		expandScheduler,
		multiRoomProps,
		roomProps,
		isExpanded,
	} = props;

	const renderExpandSection = (
		<Stack sx={{ marginLeft: "auto" }} px={".8rem"}>
			<IconButton
				sx={{ width: 45, height: 45 }}
				onClick={() => {
					expandScheduler();
				}}
			>
				<Icon
					prefix={isExpanded ? "fas" : "far"}
					iconName={
						(isExpanded
							? "arrow-down-left-and-arrow-up-right-to-center"
							: "arrow-up-right-and-arrow-down-left-from-center") as IconName
					}
				/>
			</IconButton>
		</Stack>
	);
	switch (type) {
		case ESchedulerView.MULTIROOM:
			return (
				<>
					<Stack direction={"row"} alignItems={"center"}>
						<Stack sx={{ flex: "2" }}>
							<HeaderCalendarMultiRoom
								range={calendarSettings.date}
								view={props.type}
								changeView={changeView}
								calendar={calendarRef}
								locale={locale}
							/>
						</Stack>
						{renderExpandSection}
					</Stack>
					{multiRoomProps?.rooms && (
						<CalendarSelectMultiRoom
							rooms={SchedulerFactory.PopulateRoomsK4AutocompleteMultiSelect(
								calendarSettings.rooms ?? [],
							)}
							activeRooms={SchedulerFactory.PopulateRoomsK4AutocompleteMultiSelect(
								multiRoomProps.rooms ?? [],
							)}
							changeRooms={multiRoomProps.changeRooms}
							calendar={calendarRef}
						/>
					)}
				</>
			);
		case ESchedulerView.ROOM:
			return (
				<>
					<Stack direction={"row"} alignItems={"center"}>
						<Stack sx={{ flex: "2" }}>
							<HeaderCalendarRoom
								view={type}
								changeView={changeView}
								calendar={calendarRef}
								locale={locale}
							/>
						</Stack>
						{renderExpandSection}
					</Stack>
					{roomProps && calendarSettings.rooms && (
						<CalendarSelectRoom
							roomId={roomProps.roomId}
							rooms={calendarSettings.rooms}
							changeRoom={roomProps.changeRoom}
						/>
					)}
				</>
			);
		default:
			return <></>;
	}
};

export default HeaderScheduler;
