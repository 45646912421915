//#region 'Imports'

import type { TRoomView } from "@domain/interfaces/calendar.interface";
import type { IItemK4AutocompleteMultiSelect } from "@infrastructure/model/interfaces/components/K4AutocompleteMultiSelect.interface";

//#endregion 'Imports'

//#region 'Rooms'
const PopulateRoomsK4AutocompleteMultiSelect = (array: TRoomView[]) => {
	const arrayWithValueAndLabelObjectKeys = array.map(
		({ id: value, title: label, ...rest }) => ({
			value,
			label,
			...rest,
		}),
	);
	return arrayWithValueAndLabelObjectKeys;
};

const PopulateRoomsK4AutocompleteMultiSelectInverse = (
	array: IItemK4AutocompleteMultiSelect[],
) => {
	const arrayWithValueAndLabelObjectKeys = array.map(
		({ value: id, label: title, ...rest }) => ({
			id,
			title,
			...rest,
		}),
	);
	return arrayWithValueAndLabelObjectKeys;
};
//#endregion 'Rooms'

const SchedulerFactory = {
	PopulateRoomsK4AutocompleteMultiSelect,
	PopulateRoomsK4AutocompleteMultiSelectInverse,
};
export default SchedulerFactory;
