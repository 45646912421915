import type React from "react";

import DragIconAndGravatar from "@infrastructure/components/interface/card/cardElements/DragIconAndGravatar";
import MenuItems from "@infrastructure/components/interface/card/cardElements/MenuItems";
import TitleAndIndicators from "@infrastructure/components/interface/card/cardElements/TitleAndIndicators";
import TitleSubtitleAndTags from "@infrastructure/components/interface/card/cardElements/TitleSubtitleAndTags";
import type { IMenuItems } from "@infrastructure/model/interfaces/menuItems.interface";
import {
	ApplicationColors,
	type DataCypress,
	type IK4Card,
	Link,
} from "@key4-front-library/core";
import {
	Card,
	CardContent,
	CardHeader,
	Stack,
	type SxProps,
} from "@mui/material";

type Props = {
	data: IK4Card;
	menuItems?: IMenuItems;
	handleCardSingleClick?: (data: IK4Card) => void;
	handleCardDoubleClick?: (data: IK4Card) => void;
	sx?: SxProps;
};
const K4Card = ({
	data,
	menuItems,
	handleCardSingleClick,
	handleCardDoubleClick,
	sx,
	dataCypressID,
}: Props & DataCypress) => {
	let clickTimeout: any = null;

	// TODO: move function to a helper file
	const handleClicks = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		if (clickTimeout !== null) {
			// double click executed right after the second click
			handleCardDoubleClick?.(data);
			clearTimeout(clickTimeout);
			clickTimeout = null;
		} else {
			clickTimeout = setTimeout(() => {
				// single click executed after waiting for 500ms
				handleCardSingleClick?.(data);
				clearTimeout(clickTimeout);
				clickTimeout = null;
			}, 500);
		}
	};

	return (
		<Card
			onClick={(event) => {
				handleClicks(event);
			}}
			sx={{
				minWidth: "250px",
				minHeight: "130px",
				height: "100%",
				...(handleCardSingleClick && {
					"&:hover": {
						opacity: ".6",
						cursor: "pointer",
					},
				}),
				boxShadow: `0 1px 4px ${ApplicationColors.grey.light}`,
				backgroundColor: data.backgroundColor,
				...sx,
			}}
			data-cy={dataCypressID}
		>
			<Stack direction={"row"}>
				<Stack sx={{ flexGrow: 1 }}>
					<CardHeader
						title={
							<Stack direction={"row"}>
								<DragIconAndGravatar
									isDraggableIcon={data.isDraggableIcon}
									isGravatar={data.isGravatar}
									email={data.email}
									firstname={data.firstname}
									lastname={data.lastname}
								/>
								<Link to={data.link}>
									<TitleAndIndicators
										headerTitle={data.headerTitle}
										hasClashes={data.hasClashes}
										hasAnomalies={data.hasAnomalies}
										status={data.status}
										dataCypressID={dataCypressID}
									/>
								</Link>
							</Stack>
						}
						action={
							<>
								{menuItems && <MenuItems menuItems={menuItems} data={data} />}
							</>
						}
					></CardHeader>
					<CardContent
						sx={{
							mt: -1,
						}}
					>
						<TitleSubtitleAndTags
							contentTitle={data.contentTitle}
							contentSubtitle={data.contentSubtitle}
							tags={data.tags}
							dataCypressID={dataCypressID}
						/>
					</CardContent>
				</Stack>
			</Stack>
		</Card>
	);
};
export default K4Card;
