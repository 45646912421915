export enum SettingsMenuItemReference {
	SCHEDULER = "scheduler",
	TAGS = "tags",
	ANOMALIES = "anomalies",
	NUMBERING = "numbering",
	PROGRAMME_EXPORT = "programme-export",
	MAIL_TEMPLATES = "mailTemplates",
	SESSION_TEMPLATES = "sessionTemplates",
	SESSION_VIEW_TEMPLATE = "sessions-view-templates-editor",
}
