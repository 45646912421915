import {
	type DtoSessionGetListAnomaly,
	QUERY_KEYS_PROGRAMME,
	Services,
	type TypeApiQueryString,
	type TypeApiResponsePagined,
} from "@key4-front-library/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";

interface Args {
	clientId: string;
	sessionId: string;
	eventId: string;
	queryStrings?: Array<TypeApiQueryString>;
}

export const useAnomalies = (
	args: Args,
): UseQueryResult<TypeApiResponsePagined<Array<DtoSessionGetListAnomaly>>> => {
	const { clientId, sessionId, eventId, queryStrings } = args;
	return useQuery({
		queryKey: [
			...QUERY_KEYS_PROGRAMME.anomalies,
			"getList",
			clientId,
			eventId,
			sessionId,
			queryStrings,
		],
		queryFn: async () =>
			await Services.Events.Programme.SessionsService.getListPaginedAnomalies(
				clientId,
				eventId,
				sessionId,
				queryStrings,
			),
	});
};
