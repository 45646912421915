import { t } from "i18next";

const GetTranslationsForSettingsAnomalies = () => {
	return {
		buttons: { edit: t("old.form.buttons.edit") },
		status: {
			draft: t(`old.programme.settings.anomalies.status.draft`),
			validated: t(`old.programme.settings.anomalies.status.validated`),
			readyToPublish: t(
				`old.programme.settings.anomalies.status.readyToPublish`,
			),
			plannedOrPublished: t(
				`old.programme.settings.anomalies.status.plannedOrPublished`,
			),
		},
		returnMessages: {
			success_modification: t(
				`old.programme.settings.anomalies.returnMessages.success_modification`,
			),
		},
		alert: {
			noAnomaly: t(`old.programme.settings.anomalies.alert.noAnomaly`),
		},
	};
};

export default GetTranslationsForSettingsAnomalies;
