import { Fragment, useCallback, useEffect, useState } from "react";

import SettingsAnomaliesSkeleton from "@application/components/settings/anomalies/Skeleton/SettingsAnomaliessSkeleton";
import StatusAnomaliesEditModale from "@application/components/settings/anomalies/Status/StatusAnomaliesEditModale";
import StatusAnomaliesRender from "@application/components/settings/anomalies/Status/StatusAnomaliesRender";
import IssuesDefinitions from "@application/Configurations/issues.configuration";
import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import GetTranslationsForAnomalies from "@application/helpers/translations/anomalies/GetTranslationsForAnomalies";
import GetTranslationsForSettingsAnomalies from "@application/helpers/translations/settings/GetTranslationsForSettingsAnomalies";
import type { ISettingsAnomaliesHookFormValues } from "@domain/interfaces/settings.anomalies.interface";
import type {
	IAnomaly,
	ISettingsAnomaliesModel,
} from "@domain/model/settings.anomalies.model";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import type { IErrorMessageReturn } from "@infrastructure/model/interfaces/api/api-errors-message-return.interface";
import {
	Services,
	Title,
	useContextModule,
	useSnackBarHook,
} from "@key4-front-library/core";
import type { EnumAnomaly } from "@key4-front-library/core/Enums";

const SettingsAnomalies = () => {
	const { sendSuccess, sendError } = useSnackBarHook();
	const { client, event } = useContextModule();
	const translations = GetTranslationsForSettingsAnomalies();
	const translationsAnomalies = GetTranslationsForAnomalies();

	const [anomalies, setAnomalies] = useState<Array<ISettingsAnomaliesModel>>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [openAnomaliesEditModale, setOpenAnomaliesEditModale] = useState(false);

	const actionsConfigurations = GetMenuActionsConfigurations();
	const getConfig = (type: MenuActionsReference) =>
		actionsConfigurations.find((x) => x.reference === type);
	const editConfig = getConfig(MenuActionsReference.EDIT);

	const fetchAnomalies = useCallback((clientId: string, eventId: string) => {
		setIsLoading(true);
		Services.Events.Programme.AnomaliesService.getListSettings(
			clientId,
			eventId,
			[{ key: "onlyEnabled", value: "false" }],
		)
			.then((_data: Array<any>) => {
				setAnomalies(_data);
			})
			.catch((_error: IErrorMessageReturn) => {
				(
					Object.keys(_error.message) as Array<keyof typeof _error.message>
				).forEach((key) => {
					sendError(_error.message[key]);
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);
	useEffect(() => {
		fetchAnomalies(client.id, event.id);
	}, [event, fetchAnomalies]);

	const handleModaleAnomaliesOpen = () => {
		setOpenAnomaliesEditModale(true);
	};

	const handleModaleAnomaliesClose = () => {
		setOpenAnomaliesEditModale(false);
	};

	const handleSetFormDefaultValues = useCallback(
		(_data: Array<ISettingsAnomaliesModel>) => {
			const formDefaultValues =
				SettingsFactory.TransformSettingsAnomaliesDataForDefaultValuesForm({
					anomalies: _data,
				});
			return formDefaultValues;
		},
		[],
	);

	const handleFormSubmit = (_data: ISettingsAnomaliesHookFormValues) => {
		const payload = SettingsFactory.TransformSettingsAnomaliesDataForWriting({
			anomaliesForm: _data,
		});
		const saveAnomalies = (
			clientId: string,
			eventId: string,
			payload: Array<IAnomaly>,
		) => {
			setIsLoading(true);
			Services.Events.Programme.AnomaliesService.putSettings(
				clientId,
				eventId,
				payload.map((anomaly) => {
					return {
						type: anomaly.type as unknown as EnumAnomaly,
						enabled: anomaly.enabled,
					};
				}),
			)
				.then(() => {
					fetchAnomalies(client.id, event.id);
					sendSuccess(translations.returnMessages.success_modification);
				})
				.catch((_error: IErrorMessageReturn) => {
					(
						Object.keys(_error.message) as Array<keyof typeof _error.message>
					).forEach((key) => {
						sendError(_error.message[key]);
					});
				})
				.finally(() => {
					handleModaleAnomaliesClose();
				});
		};

		saveAnomalies(client.id, event.id, payload);
	};

	if (isLoading) {
		return <SettingsAnomaliesSkeleton quantity={10} />;
	}

	return (
		anomalies && (
			<Fragment>
				<IconCard
					title={translationsAnomalies.anomalies.title}
					icon={IssuesDefinitions.anomalies.icon as IconName}
					children={
						<Fragment>
							<StatusAnomaliesRender
								anomalies={SettingsFactory.TransformSettingsAnomaliesData({
									anomalies,
									translations,
									translationsAnomalies,
								})}
							/>
						</Fragment>
					}
					button={{
						label: translations.buttons.edit,
						icon: editConfig?.icon!,
						onClick: handleModaleAnomaliesOpen,
					}}
				/>

				<DefaultModal
					open={openAnomaliesEditModale}
					title={
						<Title
							modaleMode={true}
							title={translationsAnomalies.anomalies.title}
						/>
					}
					maxWidth={"lg"}
				>
					<StatusAnomaliesEditModale
						anomalies={SettingsFactory.TransformSettingsAnomaliesData({
							anomalies,
							translations,
							translationsAnomalies,
							isEdit: true,
						})}
						onModaleClose={handleModaleAnomaliesClose}
						defaulValues={handleSetFormDefaultValues(anomalies)}
						onFormSubmit={handleFormSubmit}
					/>
				</DefaultModal>
			</Fragment>
		)
	);
};

export default SettingsAnomalies;
