import type { TFunction } from "i18next";

import type { TRoomDetails } from "@domain/interfaces/room.interface";
import type { IRoom, IRoomForm } from "@domain/model/room.model";
import type { PaginedResourceType } from "@infrastructure/model/@types/api/api-pagined-response.type";
import type { TPagination } from "@infrastructure/model/interfaces/api.interface";
import type { IPagination } from "@infrastructure/model/interfaces/api/api-pagination.interface";
import {
	type DtoRoom,
	ErrorAPI,
	queryFilters,
	queryStringPagination,
	queryStringSorts,
	Services,
	sieveStringFiltersPageList,
	useContextModule,
} from "@key4-front-library/core";
import type { TypeAutoCompleteSubLabelItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteSubLabel";

const getRoomDetails = async (
	clientId: string,
	eventId: string,
	roomId: string,
): Promise<TRoomDetails | ErrorAPI> => {
	try {
		const room = await Services.Events.Programme.RoomsService.getDetails(
			clientId,
			eventId,
			roomId,
		);
		return Promise.resolve({
			id: room.id,
			name: room.name ?? "N/A",
			capacity: room.capacity ?? 0,
		});
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

type getListRoomsProps = {
	clientId: string;
	eventId: string;
	pagination?: IPagination;
	sorts: Array<string>;
};

// ! Duplicated getListRoomsPagined ?
/**
 * @deprecated
 */
const getListRooms = async (
	props: getListRoomsProps,
): Promise<Array<IRoom> | ErrorAPI> => {
	const { clientId, eventId, pagination, sorts } = props;
	const fetchData = async () =>
		Services.Events.Programme.RoomsService.getListPagined(clientId, eventId, [
			...queryStringPagination(pagination),
			...queryStringSorts(sorts),
		]);

	return fetchData()
		.then((roomsData: PaginedResourceType<Array<any>>) => {
			const rooms: Array<IRoom> = [];
			for (const i in roomsData.data) {
				rooms.push({
					capacity: roomsData.data[i].capacity,
					hasClashes: roomsData.data[i].hasClashes,
					id: roomsData.data[i].id,
					name: roomsData.data[i].name,
				});
			}
			return Promise.resolve(rooms);
		})
		.catch((e: ErrorAPI) => {
			return Promise.reject(new ErrorAPI(e.status, e.message));
		});
};

const getListRoomsPagined = async (
	clientId: string,
	eventId: string,
	search: string,
	sorts: Array<string>,
	pagination?: TPagination,
): Promise<Array<any> | ErrorAPI> => {
	try {
		const filterString = sieveStringFiltersPageList(["name"], search);
		const rooms = await Services.Events.Programme.RoomsService.getListPagined(
			clientId,
			eventId,
			[
				...queryStringPagination(pagination),
				...queryStringSorts(sorts),
				...queryFilters(filterString),
			],
		);

		return Promise.resolve(
			rooms.data.filter((room) => {
				return {
					capacity: room.capacity,
					hasClashes: room.hasClashes,
					id: room.id,
					name: room.name,
				};
			}),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const getRoomListBulkAction = async (
	clientId: string,
	eventId: string,
	t: TFunction,
): Promise<Array<TypeAutoCompleteSubLabelItem> | ErrorAPI> => {
	try {
		const roomsData = Services.Events.Programme.RoomsService.getList(
			clientId,
			eventId,
			[...queryStringSorts(["id"])],
		);
		return Promise.resolve(
			(await roomsData).map((room: any) => {
				return {
					key: room.id,
					label: room.name,
					subLabel: room.capacity
						? t("old.room.selectCapacity.label", {
								capacity: room.capacity,
							})
						: t("old.room.selectCapacity.none"),
				};
			}),
		);
	} catch (e: any) {
		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const createRoom = async (
	clientId: string,
	eventId: string,
	room: IRoomForm,
) => {
	return Services.Events.Programme.RoomsService.post(clientId, eventId, room);
};

const saveOrderRooms = async (
	clientId: string,
	eventId: string,
	rooms: Array<IRoom>,
) => {
	const roomIds = rooms.map((room) => room.id);
	const updateData = async (ids: Array<string>) =>
		Services.Events.Programme.RoomsService.putReOrder(clientId, eventId, {
			ids,
		});
	updateData(roomIds);
};

const updateRoom = async (
	clientId: string,
	eventId: string,
	room: IRoomForm,
) => {
	return Services.Events.Programme.RoomsService.put(
		clientId,
		eventId,
		room.id,
		{
			name: room.name,
			capacity: room.capacity,
		},
	);
};

const deleteRoom = async (
	clientId: string,
	eventId: string,
	roomId: string,
) => {
	return Services.Events.Programme.RoomsService.deleteEntity(
		clientId,
		eventId,
		roomId,
	);
};

const useEntity = () => {
	const { client, event } = useContextModule();

	const readList = async (): Promise<Array<DtoRoom>> => {
		return await Services.Events.Programme.RoomsService.getList(
			client.id,
			event.id,
			[...queryStringSorts(["order"])],
		);
	};

	return { readList };
};

const RoomController = {
	createRoom,
	deleteRoom,
	getListRooms,
	getListRoomsPagined,
	getRoomDetails,
	getRoomListBulkAction,
	saveOrderRooms,
	updateRoom,
	useEntity,
};

export default RoomController;
