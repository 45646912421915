import { t } from "i18next";

const GetTranslationsForSettingsScheduler = () => {
	return {
		title: t(`old.programme.settings.title`),
		scheduler: {
			title: t(`old.programme.settings.scheduler.title`),
			settings: {
				startDate: t(`old.programme.settings.scheduler.settings.startDate`),
				endDate: t(`old.programme.settings.scheduler.settings.endDate`),
				startTime: t(`old.programme.settings.scheduler.settings.startTime`),
				endTime: t(`old.programme.settings.scheduler.settings.endTime`),
				dayTimeSlot: t(`old.programme.settings.scheduler.settings.dayTimeSlot`),
				programmeDuration: t(
					`old.programme.settings.scheduler.settings.programmeDuration`,
				),
			},
		},
		clashes: {
			title: t(`old.programme.settings.clashes.title`),
			settings: {
				facultyClashDetectionInterval: t(
					`old.programme.settings.clashes.settings.facultyClashDetectionInterval`,
				),
				roomClashDetectionInterval: t(
					`old.programme.settings.clashes.settings.roomClashDetectionInterval`,
				),
				isSpeakerBusySessionTimeSlot: t(
					`old.programme.settings.clashes.settings.isSpeakerBusySessionTimeSlot`,
				),
			},
		},
		unities: {
			day: t(`old.common.unities.day.single`),
			days: t(`old.common.unities.day.multiple`),
			hour: t(`old.common.unities.hour.single`),
			hours: t(`old.common.unities.hour.multiple`),
		},
		dateMaskFormat: t("old.common.date-mask-format"),
		timeMaskFormat: t("old.common.time-mask-format"),
		button: t("old.form.buttons.edit"),
	};
};

export default GetTranslationsForSettingsScheduler;
