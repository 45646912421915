import { t } from "i18next";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import {
	ButtonCancel,
	ButtonSave,
	DatePicker,
	Dialog,
	Services,
	useContextModule,
} from "@key4-front-library/core";
import DialogTitle from "@key4-front-library/core/Bo/Components/DialogTitle";
import {
	Box,
	DialogActions,
	DialogContent,
	Stack,
	Typography,
} from "@mui/material";

type ContainerExternalSessionLinkModalProps = {
	isOpen: boolean;
	changeIsOpen: (isOpen: boolean) => void;
	sessionId: string;
	refreshSession: () => void;
};

const ContainerExternalSessionLinkModal = (
	props: ContainerExternalSessionLinkModalProps,
) => {
	const { isOpen, changeIsOpen, sessionId, refreshSession } = props;
	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
	const { client, event } = useContextModule();

	const formMethods = useForm<any>({
		mode: "onSubmit",
	});

	const schema: any = yup.object({
		expirationDate: yup
			.string()
			.nullable()
			.required(t("old.common.formControl.error.required") ?? "")
			.test(
				"Invalid date time",
				t("old.common.formControl.error.datePicker.invalid") ?? "",
				(expirationDate: string) => !(expirationDate === "Invalid DateTime"),
			)
			.test(
				"Date in past",
				t("old.common.formControl.error.datePicker.dateInThePast") ?? "",
				(expirationDate: string) => {
					const selectedDate = new Date(expirationDate);
					return (
						selectedDate.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
					);
				},
			),
	});

	const {
		handleSubmit: onSubmit,
		control,
		reset,
		formState: { isValid },
	} = useForm<any>({
		mode: "all",
		defaultValues: {},
		resolver: yupResolver(schema),
	});

	const handleSubmit = async (form: { expirationDate: string }) => {
		setIsLoadingSubmit(true);
		const expirationDate = new Date(
			new Date(form.expirationDate).setHours(23, 59),
		).toISOString();

		await Services.Events.Programme.TokenService.createSessionToken(
			client.id,
			event.id,
			sessionId,
			{
				expireAt: expirationDate,
			},
		);

		changeIsOpen(false);
		setIsLoadingSubmit(false);
		reset();
		refreshSession();
	};

	const closeModal = () => {
		changeIsOpen(false);
		reset();
	};

	return (
		<Dialog isOpen={isOpen} maxWidth="md">
			<>
				<DialogTitle
					title={t("old.programme.sessionDetails.externalLink.modal.title")}
					onCloseClick={closeModal}
				/>
				<DialogContent>
					<>
						<Typography variant="body2">
							{t("old.programme.sessionDetails.externalLink.modal.subtitle")}
						</Typography>
						<Box mt={2}>
							<FormProvider {...formMethods}>
								<Controller
									name="expirationDate"
									control={control}
									render={({
										field: { value, onChange },
										fieldState: { invalid, error },
									}) => (
										<DatePicker
											label={t(
												"old.programme.sessionDetails.externalLink.modal.expirationDate",
											)}
											required
											disablePast
											onChange={onChange}
											error={invalid && error ? error.message : undefined}
											value={value}
											onClearClick={() => {
												onChange(null);
											}}
										></DatePicker>
									)}
								/>
							</FormProvider>
						</Box>
					</>
				</DialogContent>
				<DialogActions>
					<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
						<ButtonCancel onClick={closeModal} />
						<ButtonSave
							isLoading={isLoadingSubmit}
							onClick={onSubmit(handleSubmit)}
							disabled={!isValid}
						/>
					</Stack>
				</DialogActions>
			</>
		</Dialog>
	);
};

export default ContainerExternalSessionLinkModal;
